import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	allSeededData: "",
	createSeededData: "",
	updateSeededData: "",
	isSeddedDataStatus: "",
};

const seededDataSlice = createSlice({
	name: "contactUs",
	initialState: INITIAL_STATE,
	reducers: {
		allSeededDataRequest: (state) => state,
		createSeededDataRequest: (state) => state,
		updateSeededDataRequest: (state) => state,
		uploadDataRequest: (state) => state,
		getAllSeededDataResponse(state, { payload }) {
			return {
				...state,
				allSeededData: payload,
			};
		},
		createSeededDataResponse(state, { payload }) {
			return {
				...state,
				createSeededData: payload,
			};
		},
		updateSeededDataResponse(state, { payload }) {
			return {
				...state,
				updateSeededData: payload,
			};
		},
		isSeddedDataLoading(state, { payload }) {
			return {
				...state,
				isSeddedDataStatus: payload,
			};
		},
	},
});

export const {
	uploadDataRequest,
	allSeededDataRequest,
	updateSeededDataRequest,
	createSeededDataRequest,
	createSeededDataResponse,
	updateSeededDataResponse,
	isSeddedDataLoading,
	getAllSeededDataResponse,
} = seededDataSlice.actions;

export default seededDataSlice.reducer;
