import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	getSellersByID,
	submitReview,
	updateReviewSubmitStatus,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import AirSellerReaction from "../airSellerReaction/airSellerReaction";
import LoginModal from "./loginModal";
import SignUpModal from "./signUpModal";
import Backdrop from "@mui/material/Backdrop";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
import { useNavigate, useParams } from "react-router";
import { CategoryAutoComplete } from "../layout/categoryAutoComplete/categoryAutoComplete";

function ToAirModal({
	isToAir,
	setIsToAir,
	seAirValue,
	setThanks,
	setAiredReviews,
}) {
	const handleCancel = () => setIsToAir(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [isAirSellerSubmitted, setIsAirSellerSubmitted] = useState(false);
	const [loveOpenAfterLogin, setLoveOpenAfterLogin] = useState(false);

	let { id } = useParams();

	//get Api for sellers
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const {
		reviewSubmitStatus,
		loginResponseData,
		singleSellerData,
		verifyReviewData,
		sellerCategories,
	} = useSelector((state) => {
		return {
			sellerId: state?.seller?.sellerId,
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
			loginResponseData: state.authorization?.loginResponseData,
			singleSellerData: state?.seller?.singleSellerData?.data,
			verifyReviewData: state?.adminReview?.verifyReviewData,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		};
	});

	const token = localStorage.getItem("token");

	const [isReviewSubitOnLogin, setIsReviewSubmitOnLogin] = useState(false);

	useEffect(() => {
		dispatch(verifyReviewResponse(null));
	}, []);

	const [badReviews, setBadReviews] = useState({
		sellerId: id,
		titleId: "",
		categoryId: null,
		message: null,
		approvedByAdmin: true,
		bestWriter: false,
	});

	useEffect(() => {
		if (
			verifyReviewData?.verifyReview &&
			verifyReviewData?.verifyReview?.type === "to-air" &&
			!isAirSellerSubmitted
		) {
			setBadReviews({
				...badReviews,
				message: null,
				titleId: verifyReviewData?.verifyReview?.titleId,
			});
			setIsAirSellerSubmitted(false);
		} else if (
			verifyReviewData?.verifyReview &&
			verifyReviewData?.verifyReview?.type === "to-love" &&
			!isAirSellerSubmitted
		) {
			setBadReviews({
				...badReviews,
				message: null,
				titleId: null,
			});
			setIsAirSellerSubmitted(false);
		}
	}, [verifyReviewData, isAirSellerSubmitted]);

	const handleAir = () => {
		let token = localStorage.getItem("token");

		if (badReviews.sellerId && !badReviews.titleId) {
			getToasterNotifications("Please select a balloon!");
			return;
		}
		if (
			badReviews.sellerId &&
			badReviews.titleId &&
			!badReviews.categoryId &&
			sellerCategories &&
			sellerCategories?.length > 1
		) {
			getToasterNotifications("Please select a category!");
			return;
		}
		if (!badReviews.sellerId || !badReviews.titleId) {
			seAirValue(false);
			return;
		}
		if (!token) {
			setLoginModal(true);
			setIsReviewSubmitOnLogin(false);
			return;
		}
		if (
			badReviews.sellerId &&
			badReviews.titleId &&
			loginResponseData?.data?.id
		) {
			dispatch(
				verifyReviewRequest({
					sellerId: badReviews.sellerId,
					userId: loginResponseData?.data?.id,
				})
			);
			dispatch(getSellersByID(badReviews.sellerId));
			setAiredReviews(badReviews);
			setIsAirSellerSubmitted(true);
		}
	};

	useEffect(() => {
		if (id || isToAir) {
			setBadReviews({
				...badReviews,
				sellerId: id,
			});
			if (loginResponseData?.data?.id && isToAir) {
				dispatch(
					verifyReviewRequest({
						sellerId: id,
						userId: loginResponseData?.data?.id,
					})
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isToAir, loginResponseData]);

	useEffect(() => {
		if (
			reviewSubmitStatus === 200 &&
			isAirSellerSubmitted &&
			!isReviewSubitOnLogin
		) {
			setIsAirSellerSubmitted(false);
			setThanks(false);
			seAirValue(true);
			dispatch(updateReviewSubmitStatus(null));
			setIsAirSellerSubmitted(false);
		} else if (
			reviewSubmitStatus === 409 &&
			!verifyReviewData?.verifyReview?.message &&
			isAirSellerSubmitted
		) {
			dispatch(submitReview(badReviews));
			setIsAirSellerSubmitted(true);
			dispatch(updateReviewSubmitStatus(null));
			setIsToAir(true);
		} else if (reviewSubmitStatus === 403) {
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
			setIsAirSellerSubmitted(false);
			seAirValue(false);
			setIsToAir(false);
			setThanks(false);
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 409 && isAirSellerSubmitted) {
			// setShow(true);
			dispatch(submitReview(badReviews));
			seAirValue(false);
			setIsToAir(false);
			setThanks(false);
			// getToasterErrors(
			// 	"You've already written a story for this seller!  Please click the Tell button to update it!"
			// );
			dispatch(updateReviewSubmitStatus(null));
			setIsAirSellerSubmitted(false);
		} else if (reviewSubmitStatus === 400) {
			getToasterNotifications(
				"You've already clicked this seller!  You can edit your story from the Seller Balloon Page!"
			);
			dispatch(updateReviewSubmitStatus(null));
			setIsToAir(false);
			setIsAirSellerSubmitted(false);
		}
		if (reviewSubmitStatus === 401 && token && loginResponseData?.data?.id) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			localStorage.setItem("adminToken", "");
			dispatch(updateReviewSubmitStatus(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewSubmitStatus, badReviews, verifyReviewData]);

	useEffect(() => {
		if (
			badReviews.sellerId &&
			badReviews.titleId &&
			reviewSubmitStatus === 201
		) {
			setThanks(true);
			setIsToAir(false);
			setIsAirSellerSubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus, badReviews]);

	useEffect(() => {
		if (loveOpenAfterLogin) {
			seAirValue(true);
			setAiredReviews(badReviews);
		} else {
			seAirValue(false);
		}
	}, [loveOpenAfterLogin]);

	return (
		<Modal
			show={isToAir}
			onHide={handleCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0" />
			{isAirSellerSubmitted && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 2 }}
					open={isAirSellerSubmitted}
					onClick={() => setIsAirSellerSubmitted(false)}
				>
					<div className="d-flex  justify-content-center loaderCenter align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<h6 className="text-center fw-bold mb-sm-0 mb-5 mx-lg-0 mx-2">
					{verifyReviewData?.verifyReview?.titleId ? (
						<>
							<h6 className="text-center fw-bold mx-3 ">
								You've already clicked this Seller!
							</h6>
							<h6 className="text-center fw-bold mx-3 ">
								Would you like to edit your click and/or story?
							</h6>
						</>
					) : (
						`Please click the balloon ${
							sellerCategories?.length > 1 && "and also select category"
						}  that best describes your experience with ${
							singleSellerData?.seller?.sellerName
						}.`
					)}
				</h6>
				{sellerCategories && sellerCategories?.length > 1 && (
					<CategoryAutoComplete
						reviews={badReviews}
						setReviews={setBadReviews}
						sellerCategories={sellerCategories}
					/>
				)}
				<AirSellerReaction
					badReviews={badReviews}
					setBadReviews={setBadReviews}
				/>

				<div className="d-flex justify-content-center">
					<button
						disabled={isAirSellerSubmitted}
						type="button"
						onClick={handleAir}
						className="btn rounded-pill mb-4 mt-5 button-next"
					>
						{verifyReviewData?.verifyReview?.titleId ? "Update" : "Submit"}
					</button>
				</div>
			</div>
			<LoginModal
				isShow={showLoginModal}
				setIsShow={setLoginModal}
				setSignUp={setSignUpModal}
				reviews={badReviews}
				setThanks={setThanks}
				setIsReviewSubmitOnLogin={setIsReviewSubmitOnLogin}
				isReviewSubitOnLogin={isReviewSubitOnLogin}
				setIsTell={setIsToAir}
				setLoveOpenAfterLogin={setLoveOpenAfterLogin}
				isAir
				setIsParentModal={setIsToAir}
				fromSellerBalloonPage
				isNegativeReview
			/>
			<SignUpModal
				reviews={badReviews}
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setLoginModal}
				isParent
				setIsParent={setIsToAir}
			/>
		</Modal>
	);
}

export default ToAirModal;
