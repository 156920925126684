import React from "react";
import { Modal } from "react-bootstrap";
import "./modals.css";

function DetailModal({ isShowDetailPopup, setIsShowDetailPopup }) {
	const handleClose = () => setIsShowDetailPopup(false);
	return (
		<div>
			<Modal
				show={isShowDetailPopup}
				onHide={handleClose}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				dialogClassName="dialog-blur"
			>
				<Modal.Header
					closeButton
					className="fw-bold seller-classification-header  d-flex align-items-center justify-content-start"
				>
					<img src="/icons/logo2.png" className="mx-2 logo-width" alt="logo" />
					<div className="addseller-heading">Seller Classifications</div>
				</Modal.Header>
				<Modal.Body className="text-justify">
					<div className="detail-modal-parent">
						<h4 className="mt-3">Dear User,</h4>
						<div className="mx-3">
							<p className="mt-2">
								When you add a seller to L2a, our system will automatically
								generate a Balloon page for them. The page will be temporary
								until its final acceptance, which is based on several things,
								including the correct spelling of the seller's name, the correct
								URL and the correct classification you've placed the seller in.
							</p>
							<p>
								Please read the classification descriptions below in order to
								choose the right one so we can add your seller to L2a!
							</p>
						</div>
						<ol>
							<li className="fw-bold">
								<h4 className="mt-4" style={{ color: "#64b693" }}>
									U.S. National & Regional Online (U.S, Sellers who have the
									capacity to deliver their products or services to anyone in
									the United States.)
								</h4>
							</li>
							These sellers are able to serve customers and/or clients across
							the entire county (or across large geographical regions) in one or
							more of the following ways:
							<ul>
								<li>
									By providing numerous physical locations (e.g., Wells Fargo,
									Starbucks, Olive Garden, Chevron, Walmart).
								</li>
								<li>
									By being able to ship their products to anyone in the U.S.
									(e.g., Amazon, Ebay, Nordstrom, Home Depot, Cratejoy, Stitch
									Fix).
								</li>
								<li>
									By being about to deliver their products/services online
									(e.g., NYTimes, Apple Music, Netflix, Coursera, Betterhelp).
								</li>
							</ul>
							<li className="fw-bold">
								<h4 className="mt-4" style={{ color: "#64b693" }}>
									For U.S. Locals Only! (U.S. Sellers who deliver their products
									or services locally, i.e., to local residents and/or visiting
									tourists.)
								</h4>
							</li>
							These sellers enjoy (and actually require) direct human-to-human
							interaction in order to be of benefit to the local residents (or
							visiting tourists) they serve. Examples include
							independently-owned restaurants, hotels, day spas, specialty
							boutiques, coffee houses, kayak rentals, tennis classes, gyrotonic
							studios, judo dojos, rolfing therapists ... you get the idea!
							<li className="fw-bold">
								<h4 className="mt-4" style={{ color: "#64b693" }}>
									Outside the U.S. (These Sellers who are located Outside the
									U.S. and serve locally or nationally within their countries.)
								</h4>
							</li>
							These local or national sellers are based in countries other than
							the United States.
							<li className="fw-bold">
								<h4 className="mt-4" style={{ color: "#64b693" }}>
									B2B are Sellers who are located anywhere and who serve
									locally, nationally or globally.
								</h4>
							</li>
							B2B stands for Business-to-Business. These are sellers who sell to
							other businesses.
						</ol>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default DetailModal;
