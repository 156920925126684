import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./otherSellers.css";
import { useDispatch, useSelector } from "react-redux";
import { getSellersDataByCategoryId } from "../../redux/reducers/ducks/categoriesDuck";
import {
	getSellerId,
	updateReviewType,
} from "../../redux/reducers/ducks/getSellersDuck";
import { IconButton } from "@mui/material";
import { useParams } from "react-router";
import { appededUrl } from "../../utils/constant";

function OtherSellers({ refProp }) {
	const dispatch = useDispatch();

	const [openCategoriesArray, setOpenCategoriesArray] = useState([]);
	let { id } = useParams();

	const {
		isReviewDataLoading,
		selectedCategoryId,
		selectedCategory,
		sellerDataById,
		sellersInCategories,
	} = useSelector((state) => {
		return {
			sellerId: state?.seller?.sellerId,
			selectedCategoryId: state?.category?.selectedCategoryId,
			selectedCategory:
				state?.category?.sellersAgainstIndividualCategoryID?.data?.records,
			isReviewDataLoading: state?.seller?.isReviewDataLoading,
			sellerDataById: state?.seller?.sellerByID?.data?.record,
			sellersInCategories: state?.category?.sellersAgainstCategoryID,
		};
	});

	let catId =
		sellerDataById?.categories?.length > 0
			? sellerDataById?.categories[0]?.id
			: "";

	useEffect(() => {
		if (selectedCategoryId) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			catId = null;
		}
		if (!selectedCategoryId) {
			catId =
				sellerDataById?.categories?.length > 0
					? sellerDataById?.categories[0]?.id
					: "";
		}
		if (selectedCategoryId && id && !catId) {
			dispatch(
				getSellersDataByCategoryId({
					excludeSeller: id,
				})
			);
		}
	}, [selectedCategoryId]);

	useEffect(() => {
		if (
			catId &&
			id &&
			!selectedCategoryId &&
			sellerDataById?.categories?.length > 0
		) {
			dispatch(
				getSellersDataByCategoryId({
					excludeSeller: id,
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [catId, id]);

	let openIds = openCategoriesArray
		.filter((item) => item.isOpen === true)
		.map((item) => item.id);

	return (
		<>
			{isReviewDataLoading ? (
				<div />
			) : (
				<div
					className="w-100 mb-md-2 mt-md-2 rounded other-sellers-container"
					ref={refProp}
				>
					<h5 className="ms-3 d-none d-md-block text-white mb-2 pt-3 text-center">
						Others who want your love:
					</h5>
					<h5 className="ms-3 d-block d-md-none text-white mb-2 pt-3 text-center">
						Other Sellers who want your love!
					</h5>
					{sellersInCategories?.data?.find(
						(item) => item.categories.id === selectedCategoryId
					) && (
						<div key={selectedCategory?.id}>
							{selectedCategory?.sellers?.map((item) => {
								return (
									id !== item?.id && (
										<>
											<div className="row w-100" key={item?.id}>
												<div
													onClick={() => {
														dispatch(getSellerId(item.id));
														dispatch(
															updateReviewType({
																titleId: "",
																balloonType: "All Stories",
																airedOrLoved: "",
																category: null,
															})
														);
													}}
												>
													<a
														href={`${appededUrl(`/seller-balloon/${item.id}`)}`}
														className="ms-3 mb-2 links"
													>
														{item.sellerName}
													</a>
												</div>
											</div>
										</>
									)
								);
							})}
						</div>
					)}
					{sellersInCategories?.data?.length > 0 ? (
						sellersInCategories?.data?.map((i, index) => {
							return (
								<div key={index} className="mt-3">
									<div className="d-flex justify-content-between">
										<h5 className="ms-3 text-white mb-2 pt-3">
											{i?.categories?.categoryName}
										</h5>
										<div className="d-flex d-md-none">
											<IconButton
												aria-label="expand row"
												size="small"
												className="other-seller-arrow-icon"
											>
												{openIds.includes(i.categories.id) ? (
													<div
														onClick={() => {
															if (
																openCategoriesArray &&
																openCategoriesArray?.length > 0
															) {
																setOpenCategoriesArray((current) =>
																	current.filter((employee) => {
																		return employee.id !== i.categories.id;
																	})
																);
															}
														}}
													>
														<KeyboardArrowUpIcon />
													</div>
												) : (
													<div
														onClick={() => {
															if (
																openCategoriesArray &&
																openCategoriesArray?.length > 0
															) {
																setOpenCategoriesArray([
																	...openCategoriesArray,
																	{ isOpen: true, id: i?.categories?.id },
																]);
															} else {
																setOpenCategoriesArray([
																	{ isOpen: true, id: i?.categories?.id },
																]);
															}
														}}
													>
														<KeyboardArrowDownIcon />
													</div>
												)}
											</IconButton>
										</div>
									</div>
									<div className="d-block d-md-none">
										{openIds.includes(i.categories.id) &&
											i?.sellers?.map((item) => {
												return (
													<>
														<div className="row w-100" key={item?.id}>
															<div
																onClick={() => {
																	dispatch(getSellerId(item.id));
																	dispatch(
																		updateReviewType({
																			titleId: "",
																			balloonType: "All Stories",
																			airedOrLoved: "",
																			category: null,
																		})
																	);
																}}
															>
																<a
																	href={`${appededUrl(
																		`/seller-balloon/${item.id}`
																	)}`}
																	className="ms-3 mb-2 links"
																>
																	{item.sellerName}
																</a>
															</div>
														</div>
													</>
												);
											})}
									</div>
									<div className="d-none d-md-block">
										{i?.sellers?.map((item) => {
											return (
												<>
													<div className="row w-100" key={item?.id}>
														<div
															onClick={() => {
																dispatch(getSellerId(item.id));
																dispatch(
																	updateReviewType({
																		titleId: "",
																		balloonType: "All Stories",
																		airedOrLoved: "",
																		category: null,
																	})
																);
															}}
														>
															<a
																href={`${appededUrl(
																	`/seller-balloon/${item.id}`
																)}`}
																className="ms-3 mb-2 links"
															>
																{item.sellerName}
															</a>
														</div>
													</div>
												</>
											);
										})}
									</div>
								</div>
							);
						})
					) : (
						<div className="other-no-seller">
							No seller exist against this selected seller category
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default OtherSellers;
