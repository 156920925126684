export const notificationTypes = {
	ERROR: "error",
	INFO: "info",
	SUCCESS: "success",
	WARNING: "warning",
};

export const appededUrl = (url) => {
	let isTestServer = JSON.parse(process.env.REACT_APP_IS_TEST_SERVER);

	return isTestServer ? "/staging" + url : url;
};

export const getUniqueReviews = (allReview) => {
	return [
		...new Map(
			allReview?.map((review) => [
				`${review.userId}_${review.sellerId}`,
				review,
			])
		).values(),
	];
};
