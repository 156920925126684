import React, { useEffect, useRef, useState } from "react";
import Remarks from "../../components/remarks/remarks";
import Layout from "../../components/layout/parentLayout/Layout";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import ThanksModal from "../../components/modals/thanksModal";
import LoveModal from "../../components/modals/LoveModal";
import "./tellStory.css";
import { useDispatch, useSelector } from "react-redux";
import { Toast, ToastContainer } from "react-bootstrap";

import {
	getBalloon,
	getSellersByID,
	submitReview,
	updateAirSellerName,
	updateReviewSubmitStatus,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import LoginModal from "../../components/modals/loginModal";
import SignUpModal from "../../components/modals/signUpModal";
import Backdrop from "@mui/material/Backdrop";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
import { useLocation, useNavigate } from "react-router";
import { Editor } from "@tinymce/tinymce-react";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";
import { CategoryAutoComplete } from "../../components/layout/categoryAutoComplete/categoryAutoComplete";

function TellStory() {
	const [sellers, setSellers] = useState([]);
	const [air, setAir] = useState(false);
	const [thanks, setThanks] = useState(false);
	const [show, setShow] = useState(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [istellStorySubmitted, setIsTellStorySubmitted] = useState(false);
	const [isVisitLoveModal, setIsVisitLoveModal] = useState(false);
	const [isReviewSubitOnLogin, setIsReviewSubmitOnLogin] = useState(false);
	const [isAlreadySubmittedReview, setIsAlreadySubmitReview] = useState(false);
	const [isNegativeReview, setIsNegativeReview] = useState(false);
	const [loveOpenAfterLogin, setLoveOpenAfterLogin] = useState(false);

	const [isSellerSelected, setIsSellerSelected] = useState(false);

	const [review, setReviews] = useState({
		sellerId: "",
		titleId: "",
		categoryId: null,
		message: null,
		approvedByAdmin: true,
		bestWriter: false,
		balloonType: "",
	});

	//get Api for sellers
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(""));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const navigate = useNavigate();

	const [content, setContent] = useState();

	const { data, reviewSubmitStatus, loginResponseData, sellerCategories } =
		useSelector((state) => {
			return {
				data: state?.seller?.seller?.data,
				ballons: state?.seller?.balloon?.data,
				reviewSubmitStatus: state.seller?.reviewSubmitStatus,
				loginResponseData: state.authorization?.loginResponseData,
				sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
			};
		});

	const location = useLocation();

	const [selectedBalloon, setSelectedbaloon] = useState("");

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	useEffect(() => {
		setIsSellerSelected(false);
	}, [location]);

	useEffect(() => {
		setSellers(data?.records);
		dispatch(getBalloon());
		dispatch(verifyReviewResponse(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isAIrModal = !!(selectedBalloon && selectedBalloon.type === "to-air");
	let token = localStorage.getItem("token");

	const handleThanks = () => {
		setIsVisitLoveModal(false);
		if (!review.sellerId && review.titleId) {
			getToasterNotifications("Please sellect a seller!");
			return;
		}

		if (review.sellerId && !review.titleId) {
			getToasterNotifications("Please sellect a balloon!");
			return;
		}
		if (!review.sellerId && !review.titleId) {
			getToasterNotifications("Please sellect a seller and balloon");
			return;
		}
		if (
			review.sellerId &&
			review.titleId &&
			!review.categoryId &&
			sellerCategories &&
			sellerCategories?.length > 1
		) {
			getToasterNotifications("Please sellect a category!");
			return;
		}
		if (!token) {
			setLoginModal(true);
			setIsReviewSubmitOnLogin(true);
			return;
		}
		if (loginResponseData?.data?.id) {
			dispatch(
				verifyReviewRequest({
					sellerId: review.sellerId,
					userId: loginResponseData?.data?.id,
				})
			);
		}
		dispatch(getSellersByID(review?.sellerId));

		setIsTellStorySubmitted(true);
	};

	useEffect(() => {
		if (thanks) {
			setAir(false);
		}
	}, [thanks]);

	useEffect(() => {
		if (
			review.sellerId &&
			review.titleId &&
			reviewSubmitStatus === 200 &&
			token &&
			isAIrModal &&
			!isReviewSubitOnLogin
		) {
			setAir(true);
			setIsTellStorySubmitted(false);
		} else if (
			review.sellerId &&
			review.titleId &&
			reviewSubmitStatus === 200 &&
			token &&
			!isAIrModal
		) {
			dispatch(submitReview(review));
			dispatch(updateReviewSubmitStatus(""));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, review, reviewSubmitStatus, isAIrModal]);

	useEffect(() => {
		if (reviewSubmitStatus === 201) {
			setThanks(true);
			setIsTellStorySubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus]);

	useEffect(() => {
		if (
			reviewSubmitStatus === 409 &&
			!isVisitLoveModal &&
			istellStorySubmitted
		) {
			setIsAlreadySubmitReview(true);
			setAir(false);
			setIsTellStorySubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 400) {
			getToasterNotifications(
				"You've already clicked this seller!  You can edit your story from the Seller Balloon Page!"
			);
			setAir(false);
			setIsTellStorySubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 403 && !isVisitLoveModal) {
			setAir(false);
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
			setIsTellStorySubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 401 && token && loginResponseData?.data?.id) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			dispatch(updateReviewSubmitStatus(null));
			localStorage.setItem("adminToken", "");
		}
	}, [reviewSubmitStatus, dispatch, review, istellStorySubmitted]);

	useEffect(() => {
		if (isAlreadySubmittedReview) {
			getToasterNotifications(
				"You've already clicked this Seller! Please visit the seller balloon page to update it."
			);
		}
	}, [isAlreadySubmittedReview]);

	const editorRef = useRef(null);

	useEffect(() => {
		setReviews({ ...review, message: editorRef?.current?.getContent() });
	}, [editorRef.current, content]);

	const handleChange = (event) => {
		setContent({ content: event });
	};

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	// Update screenWidth on window resize
	const handleResize = () => {
		setScreenWidth(window.innerWidth);
	};

	useEffect(() => {
		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (review?.balloonType === "to-air") {
			setIsNegativeReview(true);
		} else if (review?.balloonType === "to-love") {
			setIsNegativeReview(false);
		}
	}, [review]);

	useEffect(() => {
		if (loveOpenAfterLogin) {
			setAir(true);
			setLoginModal(false);
		} else {
			setAir(false);
		}
	}, [loveOpenAfterLogin]);

	return (
		<Layout>
			<ToastContainer position="top-end" className="p-3">
				<Toast
					onClose={() => setShow(false)}
					bg={"warning"}
					show={show}
					delay={2000}
					autohide
				>
					{/* <Toast.Body>{message}</Toast.Body> */}
				</Toast>
			</ToastContainer>
			{istellStorySubmitted && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 1000 }}
					open={istellStorySubmitted}
					onClick={() => setIsTellStorySubmitted(false)}
				>
					<div className="d-flex  justify-content-center loaderCenter align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div className="container tellStory-wrapper-container mt-5">
				<div className="row text-center d-flex justify-content-center mt-4 mb-4">
					<div className="col-lg-4 col-md-12 col-sm-12">
						<Autocomplete
							id="free-solo-2-demo"
							className="inputRounded"
							popupIcon={""}
							options={sellers && sellers?.length > 0 ? sellers : []}
							onChange={(e, seller) => {
								if (seller) {
									setIsReviewSubmitOnLogin(false);
									setIsSellerSelected(true);
									setReviews({
										...review,
										sellerId: seller.id,
										categoryId: null,
									});
									dispatch(getSellersByID(seller.id));

									dispatch(updateAirSellerName(seller.sellerName));
									setIsAlreadySubmitReview(false);
								} else {
									setReviews({
										...review,
										sellerId: null,
										categoryId: null,
									});
								}
							}}
							getOptionLabel={(option) => option.sellerName}
							renderInput={(params) => (
								<div className="autocomplete-search-inputfield">
									<TextField
										className="inputRounded"
										sx={{
											"#free-solo-2-demo-label": {
												paddingLeft: "7px",
											},
										}}
										{...params}
										label="Search by Sellers"
										InputProps={{
											...params.InputProps,
										}}
									/>
								</div>
							)}
						/>
					</div>
				</div>
				<div className="row text-center d-flex justify-content-center mb-3">
					<div className="col-lg-4 col-md-12 col-sm-12 mb-3">
						{sellerCategories &&
							sellerCategories?.length > 1 &&
							isSellerSelected && (
								<CategoryAutoComplete
									reviews={review}
									setReviews={setReviews}
									sellerCategories={sellerCategories}
									notFromSellerBallonPage
								/>
							)}
					</div>
				</div>
				<h6 className="text-center fw-bold ">
					Please click the balloon that best describes your experience with this
					seller.
				</h6>
				<Remarks
					goodReviews={review}
					setGoodReviews={setReviews}
					setSelectedbaloon={setSelectedbaloon}
					isToTell
				/>
				<div className="form-outline  d-flex justify-content-center mt-3">
					<Editor
						onInit={(evt, editor) => (editorRef.current = editor)}
						apiKey={process.env.REACT_APP_EDITOR_KEY}
						init={{
							height: 350,
							menubar: false,
							width: screenWidth > 768 ? "70%" : "92%",
						}}
						value={content}
						onEditorChange={handleChange}
					/>
				</div>
				<div className="d-flex justify-content-center">
					<button
						type="button"
						onClick={handleThanks}
						disabled={isAlreadySubmittedReview || istellStorySubmitted}
						className="btn rounded-pill mt-5 button-next"
					>
						Submit
					</button>
				</div>
			</div>
			{/* Display air modal */}
			<LoveModal
				isAir={air}
				setIsAir={setAir}
				isVisitLoveModal={isVisitLoveModal}
				isLoveModal={isAIrModal}
				setIsVisitLoveModal={setIsVisitLoveModal}
				setThanksValue={setThanks}
				badReviews={review}
			/>
			<ThanksModal
				isReviewSubitOnLogin={isReviewSubitOnLogin}
				setIsReviewSubmitOnLogin={setIsReviewSubmitOnLogin}
				isThanks={thanks}
				setIsThanks={setThanks}
				reviews={review}
			/>
			<LoginModal
				setThanks={setThanks}
				isShow={showLoginModal}
				reviews={review}
				setIsShow={setLoginModal}
				setSignUp={setSignUpModal}
				notFromSellerBalloonPage
				setIsAlreadySubmitReview={setIsAlreadySubmitReview}
				isNegativeReview={isNegativeReview}
				setIsNegativeReview={setIsNegativeReview}
				setLoveOpenAfterLogin={setLoveOpenAfterLogin}
			/>
			<SignUpModal
				reviews={review}
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setLoginModal}
			/>
		</Layout>
	);
}

export default TellStory;
