import React from "react";
import Layout from "../../../components/layout/parentLayout/Layout";
import ChangePasswordComponent from "../../../components/changePassword/changePassword";

function ChangePassword() {
	return (
		<Layout>
			<div className="container">
				<img
					src="/icons/luv2a.gif"
					className="mx-auto mt-5 d-block w-25 my-auto"
					alt="logo"
				/>
				<h6 className="text-center mt-2 fst-italic">
					Love the sellers who make you happy <br></br> Breeze through Search to
					quickly find what you need.
				</h6>

				<ChangePasswordComponent />
			</div>
		</Layout>
	);
}

export default ChangePassword;
