import React, { useState } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import AddAsellerModal from "../../../modals/addAsellerModal";

function Footer() {
	const [showAddASellerModal, setShowAddASellerModal] = useState(false);

	return (
		<footer>
			<div className="container-fluid bottom-0 d-flex justify-content-center footer">
				<div className="row d-flex justify-content-center">
					<div className="col-lg-12 col-md-12 col-sm-12 mt-2 footer-container">
						<div className="privacy-mobile">
							<span>
								<Link to="/privacy" className="footer-links">
									Privacy
								</Link>
							</span>
						</div>
						<div className="footer-link-wrap">
							<span>
								<Link to="/contact-us" className="footer-links">
									Contact Us
								</Link>
							</span>
						</div>
						<div>
							<span>
								<Link to="/best-writers-sellers" className="footer-links">
									Writers Only
								</Link>
							</span>
						</div>
						<div>
							<span>
								<Link to="/guideline" className="footer-links">
									Guidelines
								</Link>
							</span>
						</div>
						<div className="d-md-block d-none">
							<span>
								<Link
									to="/seller-instructions"
									className="footer-links footer-link-wrap"
								>
									For Sellers
								</Link>
							</span>
						</div>
						<div
							className="d-md-block d-none"
							onClick={() => {
								setShowAddASellerModal(true);
							}}
						>
							<span className="footer-links">Add Seller</span>
						</div>
						<div className="footer-link-wrap d-md-block d-none">
							<span>
								<Link to="/investor-instructions" className="footer-links">
									For Investors
								</Link>
							</span>
						</div>
						{/* <div className="footer-link-wrap d-md-block d-none">
							<span>
								<Link to="/about" className="footer-links">
									About
								</Link>
							</span>
						</div> */}
						<div className="w-100 d-md-none d-flex justify-content-center ">
							<div>
								<span>
									<Link
										to="/seller-instructions"
										className="footer-links footer-link-wrap"
									>
										For Sellers
									</Link>
								</span>
							</div>
							<div
								onClick={() => {
									setShowAddASellerModal(true);
								}}
							>
								<span className="footer-links">Add Seller</span>
							</div>
							<div className="footer-link-wrap">
								<span>
									<Link to="/investor-instructions" className="footer-links">
										For Investors
									</Link>
								</span>
							</div>
							{/* <div className="footer-link-wrap">
								<span>
									<Link to="/about" className="footer-links">
										About
									</Link>
								</span>
							</div> */}
						</div>
					</div>
					<div className="row d-flex justify-content-center mt-2">
						<div className="col-lg d-flex justify-content-center">
							<span className="text-center text-decoration-none mb-2 footer-text">
								Copyright 2024 Love to Air{" "}
								<span className="spanR"> &#174;</span>
							</span>
						</div>
					</div>
				</div>
			</div>
			<AddAsellerModal
				isShow={showAddASellerModal}
				setIsShow={setShowAddASellerModal}
				isFromFooter
			/>
		</footer>
	);
}

export default Footer;
