import React from "react";
import SellersBalloonSection from "../../components/sellersBalloonSection/sellersBalloonSection";
import CommentSection from "../../components/commentsection/commentsection";
import OtherSellers from "../../components/otherSellers/otherSellers";
import Layout from "../../components/layout/parentLayout/Layout";
import "./sellerBalloon.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
	getSingleSellerData,
	getSingleSellerID,
	updateReviewSubmitStatus,
	updateReviews,
	updateSellerById,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import { sellerTypeArray } from "../../utils/addSellerHelper";
import { KeyboardArrowDown } from "@mui/icons-material";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";
import { updateBalloonPageRef } from "../../redux/reducers/ducks/categoriesDuck";

function SellerBalloon() {
	const dispatch = useDispatch();
	const ref = React.useRef(null);
	let { id } = useParams();

	const {
		singleSellerData,
		isReviewDataLoading,
		loginResponseData,
		balloonPageRef,
	} = useSelector((state) => ({
		singleSellerData: state?.seller?.singleSellerData?.data,
		loginResponseData: state.authorization?.loginResponseData,
		sellerId: state?.seller?.sellerId,
		balloonPageRef: state?.category?.balloonPageRef,
		isReviewDataLoading: state?.seller?.isReviewDataLoading,
	}));

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(null));
		if (loginResponseData?.data?.id) {
			dispatch(
				verifyReviewRequest({
					sellerId: id,
					userId: loginResponseData?.data?.id,
				})
			);
		}
	}, [dispatch, loginResponseData]);

	useEffect(() => {
		if (id) {
			dispatch(getSingleSellerID(id));
		}
	}, [id, dispatch]);

	useEffect(() => {
		dispatch(getSingleSellerData(null));
		dispatch(updateSellerById(null));
		dispatch(updateReviews(null));
	}, []);

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	const handleClick = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	let balloonRef = React.useRef(null);

	useEffect(() => {
		if (balloonPageRef && !isReviewDataLoading) {
			setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: "smooth", // You can remove this if you don't want smooth scrolling
				});
				dispatch(updateBalloonPageRef(null));
			}, 1000);
		}
	}, [balloonPageRef]);

	return (
		<Layout>
			<div className="container mt-3 seller_profile_container">
				<SellersBalloonSection balloonRef={balloonRef} />
				<div className="row mx-0 px-0 d-flex flex-lg-row mt-sm-0 mt-4 flex-reverse">
					<div
						className={` ${
							singleSellerData &&
							singleSellerData?.seller &&
							singleSellerData?.seller?.type === sellerTypeArray[0]
								? "px-none col-lg-8 col-md-12  mt-0 mt-md-3"
								: "px-none col-lg-12 col-md-12 col-sm-12 mt-3"
						}`}
					>
						<div
							className={`d-md-none mobile-other-seller-link ${
								singleSellerData?.seller &&
								singleSellerData?.seller?.type === sellerTypeArray[0]
									? "d-block"
									: "d-none"
							}`}
							onClick={handleClick}
						>
							Others who want your love! <KeyboardArrowDown />
						</div>
						<CommentSection />
					</div>
					{singleSellerData?.seller &&
						singleSellerData?.seller?.type === sellerTypeArray[0] && (
							<div className="px-none px-md-auto col-lg-4 col-md-12 mb-5  mt-md-5 mt-0 other-sellers">
								<OtherSellers refProp={ref} />
							</div>
						)}
				</div>
			</div>
		</Layout>
	);
}

export default SellerBalloon;
