import React, { useEffect } from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import { Link } from "react-router-dom";
import "./sellersInstructions.css";
import LoginModal from "../../components/modals/loginModal";
import AddAsellerModal from "../../components/modals/addAsellerModal";
import SignUpModal from "../../components/modals/signUpModal";
import { useDispatch } from "react-redux";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";

function SellersInstructions() {
	const [isShowSignInModal, setIsShowSignInModal] = React.useState(false);
	const [showAddASellerModal, setShowAddASellerModal] = React.useState(false);
	const [showSignUpModal, setSignUpModal] = React.useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	const handleRegister = async () => {
		let token = localStorage.getItem("token");
		if (token) {
			setIsShowSignInModal(false);
		}
		if (!token) {
			setIsShowSignInModal(true);
		} else {
			setIsShowSignInModal(false);
		}
	};

	return (
		<Layout>
			<div className="container sellersinstructions-wrapper-container p-4">
				{/* heading section  */}
				<div className="row d-flex justify-content-center">
					<h1 className="text-center mt-md-5 mt-0 fw-bold guidline-headings-main ">
						Welcome, Sellers!
					</h1>
					<h5 className="text-center  mb-3 fw-normal seller-ins-sub-headings">
						Don't be left off the list!
					</h5>
					<p className="mt-4 mb-4 text-justify">
						If you’re not already in our Seller database, you can add yourself
						by clicking here:{" "}
						<Link onClick={() => setShowAddASellerModal(true)}>
							Add a Seller.
						</Link>{" "}
						Once we verify your official business name and URL, it will appear
						in both our Seller and Category databases.
					</p>
					<p>
						If you're a bigger company that offers more than one service, your
						business can appear in up to three categories. For example,
						fictitious
						<b> Hometown Bank</b> can list itself in the Auto Financing, Home
						Mortgages and Credit Card categories because they offer all three of
						these financial services/products to their customers.
					</p>
					<p>
						If you have any questions, please{" "}
						<Link to={"/contact-us"}>Contact Us!</Link>
					</p>
				</div>
			</div>
			<AddAsellerModal
				isShow={showAddASellerModal}
				setIsShow={setShowAddASellerModal}
				handleRegister={handleRegister}
				isHideField
			/>
			<LoginModal isShow={isShowSignInModal} setIsShow={setIsShowSignInModal} />
			<LoginModal
				isShow={isShowSignInModal}
				setIsShow={setIsShowSignInModal}
				setSignUp={setSignUpModal}
			/>
			<SignUpModal
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setIsShowSignInModal}
			/>
		</Layout>
	);
}

export default SellersInstructions;
