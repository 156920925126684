import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Layout from "../../../components/layout/parentLayout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
	forgotRequest,
	updateIsForgot,
	updatePasswordToken,
} from "../../../redux/reducers/ducks/autorizeDuck";
import "./forgotPassword.css";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";

function ForgotPassword() {
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");

	const dispatch = useDispatch();
	const { forgot, isForgot } = useSelector((state) => {
		return {
			forgot: state.authorization?.updateToken,
			isForgot: state.authorization?.isForgot,
		};
	});
	// function made for handling the api
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!email) {
			setError("Email Required");
		} else {
			const requestData = {
				email: email,
			};
			dispatch(forgotRequest(requestData));
			dispatch(updateIsForgot(false));
		}
	};

	useEffect(() => {
		if ((forgot?.status === 200 || forgot?.status === 201) && !isForgot) {
			getToasterNotifications(forgot?.data?.message);
			dispatch(updatePasswordToken(""));
			dispatch(updateIsForgot(true));
		} else if (
			forgot?.response?.status === 400 ||
			forgot?.response?.status === 404
		) {
			getToasterErrors(forgot?.response?.data?.message);
			dispatch(updatePasswordToken(""));
			dispatch(updateIsForgot(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forgot, isForgot]);
	return (
		<Layout>
			<div className="container">
				<img
					src="/icons/luv2a.gif"
					className="mx-auto mt-5 d-block w-25 my-auto"
					alt="logo"
				/>
				<h6 className="text-center mt-2 fst-italic">
					Love the sellers who make you happy <br></br> Breeze through Search to
					quickly find what you need.
				</h6>
				<div className="d-flex justify-content-center forgot-form">
					<form className="mt-4  forgot-inner-form rounded-4 bg-white shadow">
						<h2 className="text-center mt-4 mb-4 fs-4">
							Please enter your email to reset password.
						</h2>
						<div className="form-group mb-3 w-75 mx-auto shadow-sm">
							<input
								type="email"
								required
								className="w-100 border rounded p-2 shadow-sm"
								name="email"
								id="inputEmail"
								placeholder="Email"
								onChange={(e) => {
									setEmail(e.target.value);
									setError("");
								}}
							/>
						</div>
						{!email && (
							<p className="text-danger text-center email-required">{error}</p>
						)}

						<div className="text-center mb-2">
							{/*created the signup button*/}
							<Link to="">
								<Button type="submit" onClick={handleSubmit} className="forgot">
									Submit
								</Button>
							</Link>
						</div>
					</form>
				</div>
			</div>
		</Layout>
	);
}

export default ForgotPassword;
