import { put, call, takeLatest } from "redux-saga/effects";
import {
	adminContactApi,
	adminContactStatusApi,
	adminReportBugApi,
	adminReportBugStatusApi,
	contactApi,
	reportBugApi,
} from "../../../api/contactUsApi";
import {
	adminAllBugReportRequest,
	adminAllBugReportResponse,
	adminAllBugReportStatusRequest,
	adminAllContactRequest,
	adminAllContactResponse,
	adminContactStatusRequest,
	contactRequest,
	reportBugRequest,
	reportBugResponse,
	updateContact,
} from "../../reducers/ducks/contactDuck";
import { handleAdminStatus } from "../../reducers/ducks/getSellersDuck";
import { adminReviewLoadingResponse } from "../../reducers/ducks/adminReviewDuck";

export function* contactUs({ payload }) {
	try {
		const data = yield call(contactApi, payload);
		yield put(updateContact(data));
	} catch (err) {
		return err;
	}
}

export function* getAdminContactSaga({ payload }) {
	try {
		yield put(adminReviewLoadingResponse(true));
		const data = yield call(adminContactApi, payload);
		yield put(adminAllContactResponse(data));
	} catch (err) {
		yield put(adminAllContactResponse(err));
		yield put(adminReviewLoadingResponse(false));
		return err;
	} finally {
		yield put(handleAdminStatus(true));
		yield put(adminReviewLoadingResponse(false));
	}
}

export function* adminContactStatusSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(adminContactStatusApi, payload);
		yield put(adminAllContactResponse(data));
	} catch (err) {
		yield put(adminAllContactResponse(err));
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* reportBugSaga({ payload }) {
	try {
		const data = yield call(reportBugApi, payload);
		yield put(reportBugResponse(data));
	} catch (err) {
		return err;
	}
}

export function* reportBugStatusSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		yield call(adminReportBugStatusApi, payload);
	} catch (err) {
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* adminReportBugSaga({ payload }) {
	try {
		const data = yield call(adminReportBugApi, payload);
		yield put(adminAllBugReportResponse(data));
	} catch (err) {
		return err;
	}
}
export function* watchContactSagas() {
	yield takeLatest(contactRequest.type, contactUs);
	yield takeLatest(adminAllContactRequest.type, getAdminContactSaga);
	yield takeLatest(adminContactStatusRequest.type, adminContactStatusSaga);
	yield takeLatest(reportBugRequest.type, reportBugSaga);
	yield takeLatest(adminAllBugReportRequest.type, adminReportBugSaga);
	yield takeLatest(adminAllBugReportStatusRequest.type, reportBugStatusSaga);
}
