import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Dropdown } from "react-bootstrap";
import "./commentsection.css";
import {
	getReview,
	handleNominateStatus,
	likeDislikeReviewResponse,
	submitLikeDislike,
	updateReviewSubmitStatus,
	updateReviewType,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import Pagination from "@mui/material/Pagination";
import EditModal from "../modals/commitEditModal";
import ThanksModal from "../modals/thanksModal";
import LoginModal from "../modals/loginModal";
import SignUpModal from "../modals/signUpModal";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import ToTellModal from "../modals/toTellModal";
import LoveModal from "../modals/LoveModal";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { updateReviewCurrentPage } from "../../redux/reducers/ducks/adminReviewDuck";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { ReviewCategoryFilter } from "./reviewCategoryFilter";
import { getUniqueReviews } from "../../utils/constant";

function CommentSection() {
	const [edit, setEdit] = useState({
		isEdit: false,
		reviewId: null,
		balloonId: null,
		categoryId: null,
		data: null,
		isLikeDislikeStatus: false,
	});
	const dispatch = useDispatch();

	const [likeType, setLikeType] = useState(null);
	library?.add(faCircle);

	const navigate = useNavigate();

	const {
		reviews,
		balloons,
		isReviewDataLoading,
		likeDislikeReviewData,
		reviewSubmitStatus,
		reviewLoader,
		reviewType,
		isReviewUpdate,
		loginResponseData,
		reviewCurrentPage,
		sellerCategories,
		userReviewsData,
	} = useSelector((state) => {
		return {
			reviews: state?.seller?.review?.data,
			sellerID: state?.seller?.sellerId,
			balloons: state?.seller?.balloon?.data?.title,
			isModalChanges: state?.seller?.isModalChanges,
			isReviewDataLoading: state?.seller?.isReviewDataLoading,
			isLogout: state?.seller?.isLogout,
			isReviewUpdate: state?.seller?.isReviewUpdate,
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
			likeDislikeReviewData: state?.seller?.likeDislikeReviewData,
			reviewLoader: state?.category?.reviewLoader,
			reviewType: state?.seller?.reviewType,
			loginResponseData: state.authorization?.loginResponseData,
			reviewCurrentPage: state?.adminReview?.reviewCurrentPage,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
			userReviewsData: state?.seller?.userReviewsData,
		};
	});

	const [show, setShow] = useState(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [tell, setTell] = useState(false);
	const [air, setAir] = useState(false);
	const [thanks, setThanks] = useState(false);
	const [selectedBallonType, setSelectedballonType] = useState(false);
	const [badReviews, setBadReviews] = useState(null);
	const balloon = useSelector((state) => state?.seller?.balloon?.data?.title);

	let token = localStorage.getItem("token");

	const handleChange = (event, value) => {
		dispatch(updateReviewCurrentPage(value));
	};
	let { id } = useParams();

	useEffect(() => {
		if (thanks) {
			setAir(false);
		}
	}, [thanks]);

	useEffect(() => {
		if (!selectedBallonType) {
			setAir(false);
		}
	}, [selectedBallonType]);

	useEffect(() => {
		if (reviewSubmitStatus === 409) {
			setTell(false);
			setThanks(false);
		} else if (
			reviewSubmitStatus === 401 &&
			token &&
			loginResponseData?.data?.id
		) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			localStorage.setItem("adminToken", "");
		}
	}, [reviewSubmitStatus]);

	useEffect(() => {
		if (air) {
			setTell(false);
		}
	}, [air]);

	useEffect(() => {
		dispatch(
			getReview({
				sellerId: id,
				page: reviewCurrentPage,
				titleId: reviewType?.titleId ? reviewType?.titleId : "",
				reviewTypes: reviewType?.titleId ? "" : reviewType?.airedOrLoved,
				categoryId: reviewType?.category?.id ? reviewType?.category?.id : null,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewCurrentPage, reviewType]);

	const handleEditCommit = (id, titleId, message, categoryId) => {
		setEdit({
			isEdit: true,
			reviewId: id,
			balloonId: titleId,
			data: message,
			isLikeDislikeStatus: true,
			categoryId: categoryId,
		});
	};

	const hanldeBadgeReview = () => {
		if (!token || token === "undefined") {
			setLoginModal(true);
		} else {
			setLoginModal(false);
		}
	};

	useEffect(() => {
		if (thanks) {
			setAir(false);
		}
	}, [thanks]);

	useEffect(() => {
		if (likeDislikeReviewData?.status === 201 && reviewLoader) {
			let notificationMessage = null;
			if (
				likeDislikeReviewData &&
				likeDislikeReviewData?.data &&
				likeDislikeReviewData?.data?.type === "like"
			) {
				notificationMessage = "Thanks for Nominating!";
			} else if (
				likeDislikeReviewData &&
				likeDislikeReviewData?.data &&
				likeDislikeReviewData?.data?.type === "report"
			) {
				notificationMessage =
					"Thanks for reporting! We'll review this user's story to make sure it's within our guidelines.";
			}
			if (notificationMessage) {
				getToasterNotifications(notificationMessage);
			}
			dispatch(likeDislikeReviewResponse(null));
		}
		if (likeDislikeReviewData?.response?.status === 401 && token) {
			navigate("/loginUser");
			dispatch(likeDislikeReviewResponse(null));
			localStorage.setItem("token", "");
			localStorage.setItem("adminToken", "");
		}
		if (likeDislikeReviewData?.response?.status === 403) {
			const notificationMessage =
				"There seems to be a problem with your account. Please contact us.";
			dispatch(likeDislikeReviewResponse(null));
			getToasterErrors(notificationMessage);
		}
		// if (likeDislikeReviewData?.response?.status === 409) {
		// 	let notificationMessage = "You’ve already reported this story!";
		// 	if (
		// 		likeDislikeReviewData &&
		// 		likeDislikeReviewData?.data &&
		// 		likeDislikeReviewData?.data?.type === "like"
		// 	) {
		// 		notificationMessage = "You’ve already Nominated this story!";
		// 	}
		// 	getToasterErrors(notificationMessage);
		// 	dispatch(likeDislikeReviewResponse(null));
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [likeDislikeReviewData, dispatch, reviewType, reviewLoader]);

	const arr = Array(reviews?.totalPages).fill();

	useEffect(() => {
		if (air) {
			setTell(false);
		}
	}, [air]);

	useEffect(() => {
		if (reviewSubmitStatus === 409) {
			setTell(false);
			setThanks(false);
		}
		if (reviewSubmitStatus === 401 && token && loginResponseData?.data?.id) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			dispatch(updateReviewSubmitStatus(null));
			localStorage.setItem("adminToken", "");
		}
	}, [reviewSubmitStatus]);

	const getBackground = (reviewType) => {
		if (reviewType.balloonType === "All Stories") {
			return "all-stories-background";
		} else if (
			reviewType.balloonType === "All Loved" ||
			reviewType.balloonType === "All Aired"
		) {
			return "all-stories-dropdown-item";
		} else {
			return `${reviewType.balloonType.slice(0, -1)}-dropdown-item`;
		}
	};

	const uniqueReviews = getUniqueReviews(reviews?.data);

	return (
		<>
			{isReviewDataLoading ? (
				<div />
			) : (
				<div className="w-100">
					<div className="col-12 " style={{ marginTop: "2.5rem" }}>
						{sellerCategories && sellerCategories?.length > 1 && (
							<ReviewCategoryFilter sellerCategories={sellerCategories} />
						)}
						<div className="w-100 web-dropdown-parent d-flex justify-content-between">
							<div>
								<Dropdown
									bsStyle="default"
									id="dropdown-no-caret"
									className="web-dropdown-parent"
								>
									<Tooltip
										componentsProps={{
											tooltip: {
												sx: {
													bgcolor: "#fea9dd",
													color: "black",
													fontWeight: "700",
													fontSize: "14px",
												},
											},
										}}
										placement="top"
									>
										<Dropdown.Toggle
											id="dropdown-basic"
											className={`text-capitalize border-0 ${getBackground(
												reviewType
											)}`}
										>
											{reviewType.balloonType
												? reviewType.balloonType
												: reviewType.airedOrLoved}
										</Dropdown.Toggle>
									</Tooltip>
									<Dropdown.Menu>
										<Dropdown.Item
											onClick={() => {
												dispatch(updateReviewCurrentPage(1));

												dispatch(
													updateReviewType({
														...reviewType,
														titleId: "",
														balloonType: "All Stories",
														airedOrLoved: "",
													})
												);
											}}
											className={`text-decoration-none all-stories-background`}
										>
											All Stories
										</Dropdown.Item>
										<Dropdown.Item
											onClick={() => {
												dispatch(updateReviewCurrentPage(1));
												dispatch(
													updateReviewType({
														...reviewType,
														titleId: "",
														balloonType: "All Loved",
														airedOrLoved: "to-love",
													})
												);
											}}
											className={`text-decoration-none all-stories-dropdown-item`}
										>
											All Loved
										</Dropdown.Item>
										<Dropdown.Item
											onClick={() => {
												dispatch(updateReviewCurrentPage(1));

												dispatch(
													updateReviewType({
														...reviewType,
														titleId: "",
														balloonType: "All Aired",
														airedOrLoved: "to-air",
													})
												);
											}}
											className={`text-decoration-none all-stories-dropdown-item`}
										>
											All Aired
										</Dropdown.Item>
										{balloon?.map((item, index) => {
											return (
												<Dropdown.Item
													key={index}
													onClick={() => {
														dispatch(updateReviewCurrentPage(1));

														dispatch(
															updateReviewType({
																...reviewType,
																titleId: item?.id,
																balloonType: item?.title,
																airedOrLoved: "",
															})
														);
													}}
													className={`text-decoration-none text-capitalize ${item.title.slice(
														0,
														-1
													)}-dropdown-item`}
												>
													{item.title}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button
									disabled={
										userReviewsData?.data &&
										userReviewsData?.data[0]?.bestWriter
									}
									onClick={() => setTell(true)}
									className="btn btn-success  float-end mx-auto all-stories-background"
								>
									Tell your Story!
								</button>
							</div>
						</div>
					</div>
					<div className="w-100 mt-2 mb-2 position-relative">
						{(reviewLoader || !isReviewUpdate) && (
							<Backdrop sx={{ color: "#fff", zIndex: 2 }} open={reviewLoader}>
								<div className="nominateLoader ">
									<CircularProgress color="inherit" />
								</div>
							</Backdrop>
						)}
						{uniqueReviews?.map((item) => {
							const date = moment?.utc(item?.createdAt);

							const formattedDate = moment
								.utc(item?.createdAt)
								.format("MMMM DD, YYYY");
							const balloonId = item?.titleId;
							const foundBalloonObj = balloons?.find(
								(obj) => obj.id === balloonId
							);

							const balloonTitle = foundBalloonObj?.title;

							const commentYear = date?.format("YYYY");

							const isUserLike = item?.likeData?.array?.filter(
								(mapItem) => mapItem.userId === loginResponseData?.data?.id
							);

							const isUserReport = item?.reportData?.array?.filter(
								(mapItem) => mapItem.userId === loginResponseData?.data?.id
							);

							const isuserFeedback =
								isUserReport?.length > 0 || isUserLike?.length > 0;

							const isReviewUpdated = item?.isUpdatedReviews ? "updated" : "";

							const isWinningDate = item?.winnerDate;

							return (
								<div
									className={`position-relative wrapper-commentSection-container mb-3`}
									key={item?.id}
								>
									<div className="row  bg-grey ">
										{/*header section of the comment box*/}
										<div className="col-xl-4 col-md-8 mt-2 col-4">
											<div className="row mt-2">
												<div className="col-4 mt-2 ml-md-0 ml-3 image_head">
													<span className="image_heading">{balloonTitle}</span>
												</div>
											</div>
										</div>
										<div className="col-xl-8 col-md-4 col-8 mt-4">
											<span className="d-flex justify-content-end date_text">
												{formattedDate}
												{isReviewUpdated && ` (${isReviewUpdated})`}
											</span>
										</div>
									</div>
									<div className="row m-0 p-0">
										<p
											className={`form-control mx-auto comment-section-height text_area-comments ${
												(window.innerWidth > 578 &&
													item.message.length > 320) ||
												(window.innerWidth <= 578 && item.message.length > 110)
													? item.type === "to-air"
														? balloonTitle + "-dropdown-item-border-new"
														: balloonTitle?.slice(0, -1) +
														  "-dropdown-item-border-new"
													: item.type === "to-air"
													? balloonTitle + "-dropdown-item-border"
													: balloonTitle?.slice(0, -1) + "-dropdown-item-border"
											}`}
											// className={`form-control mx-auto text_area-comments`}
											disabled
											id="textAreaExample2"
											dangerouslySetInnerHTML={{ __html: item?.message }}
										/>
									</div>
									{/*footer section of the commenting box*/}
									<div className="row mx-md-4 d-flex justify-content-around review_width">
										<div className="col-xl-6 col-12 p-md-0 d-flex justify-content-xl-start justify-content-between mb-md-3 mb-1  d-flex  mr-md-0 mr-2">
											<div className="d-flex ">
												<div className="avatar avatar-margin-left">
													<img
														src={`/icons/default-avatar.png`}
														className="tester-image"
													/>
													{/* <AccountCircleIcon fontSize="large" /> */}
												</div>
												<div>
													<span className="user-name">
														{item?.userName}
														{/* {" "}
														{user?.name.charAt(0).toUpperCase() +
															user?.name.slice(1).toLowerCase()} */}
													</span>
													<Tooltip
														componentsProps={{
															tooltip: {
																sx: {
																	bgcolor: "#fea9dd",
																	color: "black",
																	fontWeight: "700",
																	fontSize: "14px",
																},
															},
														}}
														title="Orignal Beta Tester"
														placement="top"
													>
														<>
															<span className="orignal-text">
																Orignal Beta Tester{" "}
																<span className="year-left">{commentYear}</span>
															</span>
															<span className="text-end d-xl-none orignal-text-mobile">
																Orignal Beta Tester{" "}
																<span className="year-left">{commentYear}</span>
															</span>
														</>
													</Tooltip>
												</div>
											</div>
											{loginResponseData?.data?.id === item?.userId &&
											token &&
											!isWinningDate ? (
												<div className="edit-btn-user">
													<button
														type="button"
														onClick={() => {
															if (!isWinningDate) {
																handleEditCommit(
																	item.id,
																	item.titleId,
																	item.message,
																	item.categoryId
																);
																dispatch(
																	verifyReviewRequest({
																		sellerId: id,
																		userId: loginResponseData?.data?.id,
																	})
																);
															}
														}}
														className="btn mb-3 mt-3 notify-btn  ml-5"
													>
														Edit
													</button>
												</div>
											) : (
												<></>
											)}
										</div>
										<div className="col-xl-6 p-md-0 col-12 mb-xl-0 mb-md-3 mb-3">
											<div className="row m-0 p-md-0 d-flex flex-xl-row flex-row-reverse  justify-content-between">
												<div
													className={`p-0 ${
														isWinningDate ? "col-12" : "col-10"
													} d-flex ${
														isWinningDate
															? "justify-content-sm-start justify-content-lg-end"
															: "justify-content-end"
													}  ml-3 nominate_button position-relative`}
												>
													{
														<div className="btn-helpful-parent position-relative already-clicked-parent">
															<button
																disabled={isuserFeedback && !isWinningDate}
																onClick={() => {
																	if (!isuserFeedback && !isWinningDate) {
																		if (loginResponseData?.data?.id) {
																			dispatch(
																				submitLikeDislike({
																					reviewId: item?.id,
																					type: "like",
																				})
																			);
																		} else {
																			hanldeBadgeReview();
																			handleNominateStatus(false);

																			setLikeType({
																				type: "like",
																				reviewId: item?.id,
																				userId: item?.userId,
																			});
																		}
																	}
																}}
																type="button"
																className={`btn  mt-3   ${
																	isWinningDate
																		? "notify-btn-winner"
																		: "notify-btn"
																}`}
															>
																{isWinningDate ? (
																	<div className="d-md-flex align-items-center">
																		{/* <img
																		src="/icons/ribbon2.png"
																		width="40"
																		height="40"
																	/>{" "} */}
																		<span className="ps-2">🎉 Winner! 🎉</span>{" "}
																	</div>
																) : (
																	<div>
																		<div className="d-md-flex d-none">
																			Nominate
																		</div>
																		<div className="d-md-none d-flex">
																			Nominate
																		</div>
																	</div>
																)}
																{isuserFeedback && (
																	<div className="w-100">
																		<div className="already-clicked">
																			Already Clicked.
																		</div>
																	</div>
																)}
															</button>

															{((item?.likeData?.count > 0 && !isWinningDate) ||
																!isWinningDate) && (
																<Badge pill className="badge">
																	{item?.likeData?.count > 0 &&
																		item?.likeData?.count}
																</Badge>
															)}
															{/* */}
															{/* {isWinningDate && (
															<Badge pill className="badge">
																
															</Badge>
														)} */}
														</div>
													}
												</div>

												<div className="col-xl-2 col-md-2 col-2 mt-3  text-start position-relative">
													<div className="btn-helpful-parent position-relative already-clicked-parent">
														<button
															type="button"
															disabled={
																isuserFeedback ||
																item?.userId === loginResponseData?.data?.id
															}
															className={`btn button-commentSection report-btn ${
																isWinningDate && "d-none"
															}`}
															onClick={() => {
																if (!isuserFeedback && !isWinningDate) {
																	if (loginResponseData?.data?.id) {
																		if (
																			item?.userId !==
																			loginResponseData?.data?.id
																		) {
																			if (loginResponseData?.data?.id) {
																				dispatch(
																					submitLikeDislike({
																						reviewId: item?.id,
																						type: "report",
																					})
																				);
																			}
																		}
																	} else {
																		setLikeType({
																			type: "report",
																			reviewId: item?.id,
																			userId: item?.userId,
																		});
																		hanldeBadgeReview();
																	}
																}
															}}
														>
															<FontAwesomeIcon
																className={"flag-icon-grey"}
																icon={faFlag}
															/>
														</button>
														{isuserFeedback && (
															<div className="w-100">
																<div className="already-clicked">
																	Already Clicked.
																</div>
															</div>
														)}
														{!isuserFeedback &&
															item?.userId === loginResponseData?.data?.id && (
																<div className="w-100">
																	<div className="already-clicked">
																		Cannot report your own story
																	</div>
																</div>
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
						{(reviews?.data?.length <= 0 || !reviews?.data) && (
							<div className="space" onClick={() => setTell(true)}>
								Be the first to tell your story about this Seller!
							</div>
						)}
					</div>
					<div className="d-flex mt-4 mb-3 justify-content-center">
						{reviews?.totalRecords > 0 && (
							<Pagination
								count={arr?.length}
								page={reviewCurrentPage}
								onChange={handleChange}
							/>
						)}
					</div>
					<EditModal reviewData={edit} setReviewData={setEdit} />
					<ThanksModal isThanks={show} setIsThanks={setShow} />
					<LoginModal
						isShow={showLoginModal}
						setIsShow={setLoginModal}
						setSignUp={setSignUpModal}
						likeType={likeType}
						setLikeType={setLikeType}
					/>
					<SignUpModal
						isShowSignUpModal={showSignUpModal}
						setIsShowSignUpModal={setSignUpModal}
						setIsLogin={setLoginModal}
					/>
					<ToTellModal
						isTell={tell}
						setIsTell={setTell}
						setTellValue={setAir}
						setSelectedballonType={setSelectedballonType}
						setThanks={setThanks}
						setBadReviews={setBadReviews}
					/>
					{/* <LoveModal isAir={air} setIsAir={setAir} setThanksValue={setThanks} /> */}
					<LoveModal
						isAir={air}
						setIsAir={setAir}
						setThanksValue={setThanks}
						badReviews={badReviews}
					/>
					<ThanksModal isThanks={thanks} setIsThanks={setThanks} />
				</div>
			)}
		</>
	);
}

export default CommentSection;
