import * as React from "react";
import { useDispatch } from "react-redux";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import "./adminReview.css";

export default function BalloonsTypes({ setType, isLoading, setPage }) {
	const dispatch = useDispatch();
	return (
		<>
			<div className="mt-1">
				<select
					defaultValue={""}
					className="rounded w-100 text-center review-select"
					aria-label="Default select example"
					onChange={(e) => {
						isLoading(true);
						dispatch(handleAdminStatus(false));
						setPage(0);
						setType(e.target.value);
					}}
				>
					<option defaultValue={""} className="d-none">
						Type
					</option>
					<option value="">None</option>
					<option value="to-love">Loved</option>
					<option value="to-air">Aired</option>
				</select>
			</div>
		</>
	);
}
