import "./thankYou.css";

function ThankYou() {
	return (
		<div className="d-flex flex-column thankYou">
			<div className="d-flex justify-content-center ">
				<div>
					<h1 className="d-flex justify-content-center fs-1 mt-4">
						Thank You!
					</h1>
					<div className="d-flex justify-content-center">
						<img src="/icons/beach.png" alt="12" className="w-50 mb-5 mt-4" />
					</div>
					<div className="d-flex justify-content-center">
						<div>
							<h3 className="d-flex justify-content-center fs-1">
								Love to Air {"   "}
								<span className="fs-5"> &#174;</span>
							</h3>
							<h5 className="d-flex justify-content-center fs-4">
								Silicon Beach, California
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ThankYou;
