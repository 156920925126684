import {
	Autocomplete,
	Backdrop,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { csvCategoriesRequest } from "../../../redux/reducers/ducks/adminReviewDuck";
import { useDispatch, useSelector } from "react-redux";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import { getCategory } from "../../../redux/reducers/ducks/categoriesDuck";

export const CsvCategories = () => {
	const columns = [
		{ id: "name", label: `Category Name`, minWidth: 200 },
		{ id: "code1", label: "Seller Name", minWidth: 100 },
	];

	const [isCategoryChange, setIsCategoryChange] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchCategories, setSearchCategories] = useState(null);
	const { csvCategoriesResponseData } = useSelector((state) => {
		return {
			csvCategoriesResponseData: state?.adminReview?.csvCategoriesResponseData,
		};
	});

	const { categoryData } = useSelector((state) => {
		return {
			categoryData: state?.category?.category?.data?.records,
		};
	});

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			csvCategoriesRequest({
				page: 1,
				pageSize: 10,
			})
		);
		dispatch(getCategory());
	}, []);

	useEffect(() => {
		dispatch(
			csvCategoriesRequest({
				id: searchCategories,
				page: page + 1,
				pageSize: rowsPerPage,
			})
		);
		setIsCategoryChange(false);
	}, [searchCategories, page, rowsPerPage]);

	const handleRowsPerPage = (e) => {
		setIsCategoryChange(true);
		setRowsPerPage(e.target.value);
	};

	const handleChangePage = (event, newPage) => {
		setIsCategoryChange(true);
		dispatch(handleAdminStatus(false));
		setPage(newPage);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{isCategoryChange && (
				<Backdrop sx={{ color: "#fff", zIndex: 100 }} open={isCategoryChange}>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<div className="parent-cat" />
					<div className="row  d-flex justify-content-end">
						<div className="col-md-4 col-12">
							<Autocomplete
								id="free-solo-2-demo"
								className=" inputField rounded w-100 text-start"
								popupIcon={""}
								sx={{
									".MuiOutlinedInput-root": {
										padding: "3px",
									},
									"#free-solo-2-demo": {
										textAlign: "start !important",
										marginLeft: "1rem",
									},
								}}
								onChange={(e, category) => {
									if (category) {
										dispatch(handleAdminStatus(false));
										setPage(0);
										setSearchCategories(category?.id);
										// setCategoryId(category?.id);
									} else {
										setSearchCategories(null);
										// setCategoryId("");
									}
								}}
								options={
									categoryData && categoryData?.length > 0 ? categoryData : []
								}
								getOptionLabel={(option) => {
									if (option && typeof option?.categoryName === "string") {
										return option?.categoryName;
									}
									return "";
								}}
								clearOnBlur={true}
								renderInput={(params) => {
									return (
										// created the inputfield for searching
										<div className="autocomplete-search-inputfield">
											<TextField
												className="text-center seller-textfield"
												id="autocomplete"
												sx={{
													"#free-solo-2-demo": {
														textAlign: "center",
													},
												}}
												{...params}
												placeholder="select Category"
											/>
										</div>
									);
								}}
							/>
						</div>
					</div>
					<Paper
						sx={{ width: "100%", overflow: "hidden", mt: 5 }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column?.id}
												align="center"
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{csvCategoriesResponseData?.records?.length > 0 ? (
										csvCategoriesResponseData?.records?.map((csvData) => {
											return (
												<>
													<TableRow
														hover
														role="checkbox"
														tabIndex={-1}
														key={csvData?.id}
													>
														<TableCell
															rowSpan={csvData?.sellerName?.length + 1}
														>
															{csvData?.categoryName}
														</TableCell>
														{/* <TableCell>
															{csvData?.sellerName?.join(",  ")}
														</TableCell> */}
													</TableRow>
													{csvData?.sellerName?.map((sellersName) => {
														return (
															<TableRow
																hover
																role="checkbox"
																tabIndex={-1}
																key={csvData.id}
															>
																<TableCell>{sellersName}</TableCell>
															</TableRow>
														);
													})}
												</>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No Category exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{csvCategoriesResponseData?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
									count={
										csvCategoriesResponseData?.totalRecords
											? csvCategoriesResponseData?.totalRecords
											: 0
									}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
					</Paper>
				</Card>
			</div>
		</div>
	);
};
