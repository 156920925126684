import {
	createCategory,
	deleteCategories,
	getAdminAllCategories,
	getAllCatCategorySellers,
	getAllCategories,
	getSellerByCategoriesId,
	getSellersByIndividualCategoreisId,
	updateCategoryName,
	updateCategoryStatus,
} from "../../../api/categoriesApi";
import { put, call, takeLatest } from "redux-saga/effects";
import {
	getSellersOnBaseOfCategoryId,
	getCategory,
	getSellersDataByCategoryId,
	updateCategory,
	updateIndividualCategoryIdLoading,
	deletedCategory,
	deletedCategoryData,
	latestReviewData,
	updateCategoryAdmin,
	editCategoryName,
	categoryCreated,
	getAdminCategory,
	updateCreateCategory,
	categorySellersRequest,
	categorySellersResponse,
} from "../../reducers/ducks/categoriesDuck";
import {
	getSellersByCategoryID,
	getSellersDataByIndividualCategoryID,
} from "../../reducers/ducks/categoriesDuck";
import { getCategoryLatestReview } from "../../../api/sellersApi";
import { handleAdminStatus } from "../../reducers/ducks/getSellersDuck";
import { adminReviewLoadingResponse } from "../../reducers/ducks/adminReviewDuck";

export function* getCategoriesData({ payload }) {
	try {
		const data = yield call(getAllCategories, payload);
		yield put(updateCategory(data));
	} catch (error) {
		// Handle error here
		return error;
	}
}

export function* getAllCategoriesSellersSaga({ payload }) {
	try {
		const data = yield call(getAllCatCategorySellers, payload);

		yield put(categorySellersResponse(data));
	} catch (error) {
		// Handle error here
		return error;
	}
}

export function* getSellerCategoryId({ payload }) {
	try {
		const data = yield call(getSellerByCategoriesId, payload);
		yield put(getSellersOnBaseOfCategoryId(data));
	} catch (err) {
		return err;
	}
}

export function* getSellersByCategoriesID({ payload }) {
	try {
		yield put(updateIndividualCategoryIdLoading(true));
		const data = yield call(getSellersByIndividualCategoreisId, payload);
		yield put(getSellersByCategoryID(data));
	} catch (err) {
		return err;
	} finally {
		yield put(updateIndividualCategoryIdLoading(false));
	}
}

export function* deleteCategory({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(deleteCategories, payload);
		yield put(deletedCategoryData(data));
	} catch (err) {
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* getCategoryLatestReviewSaga({ payload }) {
	try {
		yield put(updateIndividualCategoryIdLoading(true));
		const data = yield call(getCategoryLatestReview, payload);
		yield put(latestReviewData(data));
	} catch (err) {
		return err;
	} finally {
		yield put(updateIndividualCategoryIdLoading(false));
	}
}

export function* updateAdminCategory({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		yield call(updateCategoryStatus, payload);
	} catch (err) {
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* editCategory({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(updateCategoryName, payload);
		yield put(updateCreateCategory(data));
	} catch (err) {
		yield put(updateCreateCategory(err));
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* createCategories({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(createCategory, payload);
		yield put(updateCreateCategory(data));
	} catch (err) {
		yield put(updateCreateCategory(err));
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* getAdminCategoriesData({ payload }) {
	try {
		yield put(adminReviewLoadingResponse(true));
		const data = yield call(getAdminAllCategories, payload);
		if (data?.data?.records?.length > 0) {
			yield put(updateCategory(data));
		} else if (data?.response?.data?.message) {
			yield put(updateCategory([]));
		}
	} catch (error) {
		yield put(adminReviewLoadingResponse(false));

		// Handle error here
		return error;
	} finally {
		yield put(adminReviewLoadingResponse(false));
		yield put(handleAdminStatus(true));
	}
}

export function* watchCategoriesSaga() {
	yield takeLatest(categorySellersRequest.type, getAllCategoriesSellersSaga);
	yield takeLatest(getCategory.type, getCategoriesData);
	yield takeLatest(getSellersDataByCategoryId.type, getSellerCategoryId);
	yield takeLatest(
		getSellersDataByIndividualCategoryID.type,
		getSellersByCategoriesID
	);
	yield takeLatest(
		getSellersDataByIndividualCategoryID.type,
		getCategoryLatestReviewSaga
	);
	yield takeLatest(deletedCategory.type, deleteCategory);
	yield takeLatest(updateCategoryAdmin.type, updateAdminCategory);
	yield takeLatest(editCategoryName.type, editCategory);
	yield takeLatest(categoryCreated.type, createCategories);
	yield takeLatest(getAdminCategory.type, getAdminCategoriesData);
}
