import { getDefaultMiddleware } from "@reduxjs/toolkit";
import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import saga from "../middleware/RootSaga";

import RootReducer from "../reducers/RootReducer";

const sagaMiddleware = createSagaMiddleware();
const middleware = [
	...getDefaultMiddleware({
		thunk: false,
		immutableCheck: false,
		serializableCheck: false,
	}),
	sagaMiddleware,
];

const mdw = compose(applyMiddleware(...middleware));

const configureStore = (preloadedState = RootReducer(undefined, {})) => {
	const store = createStore(RootReducer, preloadedState, mdw);

	sagaMiddleware.run(saga);

	return store;
};

export default configureStore();
