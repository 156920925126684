import "../admin.css";
import React, { useEffect, useState } from "react";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Button, Grid } from "@mui/material";
import CategoryMainPage from "../categories/categoryMainPage";
import SellerMainPage from "../sellers/sellerMainPage";
import { useDispatch, useSelector } from "react-redux";
import UserMainPage from "../users/userMainPage";
import ReviewMainPage from "../review/reviewMainPage";
import { updateComponentPannel } from "../../../redux/reducers/ducks/adminPannel";
import { useNavigate } from "react-router";
import {
	loginResponse,
	refreshTokenResponse,
	updateLoginUser,
} from "../../../redux/reducers/ducks/autorizeDuck";
import ContactsMainPage from "../contacts/contactsMainPage";
import ProgressMainPage from "../progress/progressMainPage";
import { Logger } from "../logger/logger";
import "./adminPanel.css";
import { CsvCategories } from "../csvCategories/csvCategories";
import { BestWriter } from "../bestWriter/bestWriter";
import BugReportMainPage from "../reportBugAdminPage/reportBugAdminPage";
import { EmailPortal } from "../adminEmailPortal/adminEmailPortal";
import SeededMainPage from "../seededPage/seededPage";
// import { InstagramStoriesPortal } from "../adminInstgramStories/adminInstgramStories";
import TalentCompetition from "../VideoCompetition/talentCompetition";

function AdminPanel() {
	const dispatch = useDispatch();
	const [isMenuBarClick, setIsMenuBarClick] = useState(false);

	const { componentName } = useSelector((state) => {
		return {
			componentName: state?.adminComponent?.component,
		};
	});

	const token = localStorage.getItem("adminToken");

	const navigate = useNavigate();

	useEffect(() => {
		if (!token) {
			navigate("/admin");
			dispatch(updateLoginUser(null));
		}
	}, [token]);

	const handleSidebarItemClick = () => {
		setIsMenuBarClick(false);
	};

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	if (token) {
		return (
			<Grid container>
				<Grid item md={3}>
					<nav
						id="sidebarMenu"
						className={`d-lg-block sidebar sidebarBackground  bg-white  ${
							isMenuBarClick ? "d-block sideBarMobile" : "d-none"
						}`}
					>
						<div className="position-sticky">
							<div className="list-group list-group-flush mt-4 admin-panel-cursor">
								<div
									// onClick={() => {
									// 	localStorage.setItem("adminToken", "");
									// 	dispatch(updateLoginUser(null));
									// 	if (!localStorage.getItem("adminToken")) navigate("/admin");
									// }}
									className="logo-margin"
								>
									<img
										src="/icons/logo2.png"
										className="header-logo  mb-2"
										alt="logo"
									/>
									<img
										src="/icons/largeLogog.png"
										className="qdock-logo mb-2"
										alt="logo"
									/>
								</div>

								<div className="linksParent">
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://clicky.com/stats/?site_id=101423403"
										className="white text-decoration-none"
									>
										<div
											className={
												componentName === "Clicky"
													? "activeDiv my-1 py-2 px-4"
													: "catLink my-1 py-2 px-4"
											}
											onClick={() => {
												dispatch(updateComponentPannel("Clicky"));

												handleSidebarItemClick();
											}}
										>
											1. Visitors
										</div>
									</a>

									<div
										className={
											componentName === "Signups"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Signups"));
											handleSidebarItemClick();
										}}
									>
										2. Signups
									</div>
									<div
										className={`${
											componentName === "Users"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}`}
										onClick={() => {
											dispatch(updateComponentPannel("Users"));
											handleSidebarItemClick();
										}}
									>
										3. Users
									</div>
									<div
										className={
											componentName === "Balloons/Reviews"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Balloons/Reviews"));
											handleSidebarItemClick();
										}}
									>
										4. Balloons/Reviews
									</div>
									<div
										className={
											componentName === "Nominations/Winners"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Nominations/Winners"));
											handleSidebarItemClick();
										}}
									>
										5. Nominations/Winners
									</div>
									<div
										className={
											componentName === "Contact"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Contact"));
											handleSidebarItemClick();
										}}
									>
										6. Contact Us
									</div>
									<div
										className={`${
											componentName === "Category"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}`}
										onClick={() => {
											dispatch(updateComponentPannel("Category"));
											handleSidebarItemClick();
										}}
									>
										7. Category Portal
									</div>

									<div
										className={`${
											componentName === "Seller"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}`}
										onClick={() => {
											dispatch(updateComponentPannel("Seller"));
											handleSidebarItemClick();
										}}
									>
										8. Seller Portal
									</div>
									<div
										className={
											componentName === "Logger"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Logger"));
											handleSidebarItemClick();
										}}
									>
										9. Logger Portal
									</div>
									<div
										className={
											componentName === "Master Category/Seller"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Master Category/Seller"));
											handleSidebarItemClick();
										}}
									>
										10. Master Category/Seller
									</div>
									<div
										className={
											componentName === "Bug Report"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Bug Report"));
											handleSidebarItemClick();
										}}
									>
										11. Bug Report
									</div>
									<div
										className={
											componentName === "Email Logger"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Email Logger"));
											handleSidebarItemClick();
										}}
									>
										12. Email Logger
									</div>
									<div
										className={
											componentName === "Seeded Data"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Seeded Data"));
											handleSidebarItemClick();
										}}
									>
										13. Seeded Data
									</div>
									<div
										className={
											componentName === "Talent Competition"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}
										onClick={() => {
											dispatch(updateComponentPannel("Talent Competition"));
											handleSidebarItemClick();
										}}
									>
										14. Talent Contest
									</div>
									{/* <div
										className={`${
											componentName === "Instagram Stories"
												? "activeDiv my-1 py-2 px-4"
												: "catLink my-1 py-2 px-4"
										}`}
										onClick={() => {
											dispatch(updateComponentPannel("Instagram Stories"));
											handleSidebarItemClick();
										}}
									>
										13. Instagram Stories Portal
									</div> */}
									{/* <Link
										to="https://docs.google.com/spreadsheets/d/1Qs_R0zdhfdEi492TDI9Gz7ABlBeTy1Sg7a52zYWQR6U/edit?pli=1#gid=1288239390"
										target="_blank"
										className="white text-decoration-none"
										outerlink
									>
										<div
											className={
												componentName === "SpreadSheet"
													? "activeDiv my-1 py-2 px-4"
													: "catLink my-1 py-2 px-4"
											}
											onClick={() => {
												dispatch(updateComponentPannel("SpreadSheet"));

												handleSidebarItemClick();
											}}
										>
											14. SpreadSheet Workspace
										</div>
									</Link> */}
									<Link
										to="https://docs.google.com/document/d/13wT-nA2kd-GP7vO88pBorEIQnW9-rbYzTYQX6uYtL_A/edit?usp=sharing"
										target="_blank"
										className="white text-decoration-none"
										outerlink
									>
										<div
											className={
												componentName === "Email Content"
													? "activeDiv my-1 py-2 px-4"
													: "catLink my-1 py-2 px-4"
											}
											onClick={() => {
												dispatch(updateComponentPannel("Email Content"));

												handleSidebarItemClick();
											}}
										>
											15. Emails Content
										</div>
									</Link>
								</div>
							</div>
						</div>
					</nav>
				</Grid>
				<Grid item md={9}>
					<nav
						id="main-navbar"
						className="navbar nav-index navbar-expand-lg nav-height navbar-light bg-white fixed-top"
					>
						<div className="home">
							<Link className="text-decoration-none portalWrap text-dark text-capitalize">
								{componentName === "Review"
									? ""
									: componentName === "Contact"
									? "Contact Us"
									: componentName === "Category"
									? "Category Portal"
									: componentName === "Seller"
									? "Seller Portal"
									: componentName === "Clicky"
									? "Visitors"
									: componentName}
							</Link>
						</div>
						<div className="container-fluid menu-mobile">
							<Button
								className="navbar-toggler menu-border"
								type="button"
								onClick={() => setIsMenuBarClick(!isMenuBarClick)}
								data-toggle="collapse"
								data-target="#sidebarMenu"
								aria-controls="sidebarMenu"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<HorizontalSplitIcon size="large" className="menu-color" />
							</Button>
							<ul className="navbar-nav ms-auto d-flex flex-row profile">
								<li className="nav-item dropdown">
									<NavDropdown
										id="nav-dropdown-dark-example"
										title={<AccountCircleIcon />}
										menuVariant="dark"
									>
										<NavDropdown.Item
											onClick={() => {
												localStorage.setItem("adminToken", "");
												localStorage.setItem("Token", "");
												dispatch(loginResponse(null));
												dispatch(updateLoginUser(null));
												if (!localStorage.getItem("adminToken"))
													navigate("/admin");
											}}
										>
											Logout
										</NavDropdown.Item>
									</NavDropdown>
								</li>
							</ul>
						</div>
					</nav>
					{componentName === "Category" && (
						<CategoryMainPage pageName={componentName} />
					)}
					{componentName === "Seller" && (
						<SellerMainPage pageName={componentName} />
					)}
					{componentName === "Users" && <UserMainPage />}
					{componentName === "Balloons/Reviews" && (
						<ReviewMainPage pageName={componentName} />
					)}
					{componentName === "Contact" && (
						<ContactsMainPage pageName={componentName} />
					)}
					{componentName === "Signups" && (
						<ProgressMainPage pageName={componentName} />
					)}
					{componentName === "Logger" && <Logger pageName={componentName} />}

					{componentName === "Master Category/Seller" && (
						<CsvCategories pageName={componentName} />
					)}
					{componentName === "Nominations/Winners" && (
						<BestWriter pageName={componentName} />
					)}
					{componentName === "Bug Report" && (
						<BugReportMainPage pageName={componentName} />
					)}
					{componentName === "Email Logger" && (
						<EmailPortal pageName={componentName} />
					)}
					{componentName === "Seeded Data" && (
						<SeededMainPage pageName={componentName} />
					)}
					{componentName === "Talent Competition" && (
						<TalentCompetition pageName={componentName} />
					)}
					{/* {componentName === "Instagram Stories" && (
						<InstagramStoriesPortal pageName={componentName} />
					)} */}
				</Grid>
			</Grid>
		);
	} else {
		return <div>Unauthorized admin please login with correct account</div>;
	}
}

export default AdminPanel;
