import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	adminUser: null,
	allUserData: null,
	isRefreshToken: null,
	singleUserData: null,
	userBlock: null,
	adminUserResponse: null,
	deleteAdminUserResponse: null,
	latestWinner: null,
};

const mainSlice = createSlice({
	name: "main",
	initialState: INITIAL_STATE,
	reducers: {
		getAdminUsers: (state) => state,
		userBlockStatusRequest: (state) => state,
		deleteAdminUserRequest: (state) => state,
		getAllUsersResponse: (state) => state,
		getSingleUserRequest: (state) => state,
		latestWinnerRequest: (state) => state,
		editAdminUserData: (state) => state,
		updateAdminUsers(state, { payload }) {
			return {
				...state,
				adminUser: payload,
			};
		},
		userBlockStatusResponse(state, { payload }) {
			return {
				...state,
				userBlock: payload,
			};
		},
		latestWinnerResponse(state, { payload }) {
			return {
				...state,
				latestWinner: payload,
			};
		},
		getAllUserData(state, { payload }) {
			return {
				...state,
				allUserData: payload,
			};
		},
		getSingleUserData(state, { payload }) {
			return {
				...state,
				singleUserData: payload,
			};
		},
		updateAdminUserResponse(state, { payload }) {
			return {
				...state,
				adminUserResponse: payload,
			};
		},
		adminUserDeleteResponse(state, { payload }) {
			return {
				...state,
				deleteAdminUserResponse: payload,
			};
		},
	},
});

export const {
	getAdminUsers,
	updateAdminUsers,
	getAllUsersResponse,
	deleteAdminUserRequest,
	editAdminUserData,
	userBlockStatusResponse,
	userBlockStatusRequest,
	getSingleUserResponse,
	getAllUserData,
	getSingleUserData,
	getSingleUserRequest,
	updateAdminUserResponse,
	adminUserDeleteResponse,
	latestWinnerResponse,
	latestWinnerRequest,
} = mainSlice.actions;

export default mainSlice.reducer;
