import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import "./seller.css";

export default function CountryDialog({
	open,
	setOpen,
	selectedCountry,
	setSelectedCountry,
}) {
	const handleClose = () => {
		setOpen(false);
	};

	const [countries, setCountries] = React.useState([]);

	React.useEffect(() => {
		fetch(
			"https://valid.layercode.workers.dev/list/countries?format=select&value=code"
		)
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			});
	}, []);

	return (
		<React.Fragment>
			<Modal show={open} onHide={handleClose} size="lg" centered>
				<Modal.Header closeButton className="border-0 text-light" />

				<DialogTitle className="dialog-title" id="alert-dialog-title">
					What country do you live in?
				</DialogTitle>
				<DialogContent sx={{ height: "300px" }}>
					<Select
						options={countries?.filter(
							(item) =>
								item?.value !== "US" &&
								item?.value !== "UM" &&
								item?.value !== "VI"
						)}
						value={selectedCountry}
						onChange={(selectedOption) => setSelectedCountry(selectedOption)}
					/>
				</DialogContent>
			</Modal>
		</React.Fragment>
	);
}
