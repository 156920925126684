import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./youtubeModal.css";
import YouTube from "react-youtube";

function L2aYotubeModal({ isL2aYoutubeOpen, setIsL2aYoutubeOpen }) {
	const [selectedUrl, setSelectedurl] = useState("_gU4dGlrGis");

	const handleCancel = () => {
		setIsL2aYoutubeOpen(false);
	};

	const youtubDummyVedios = ["_gU4dGlrGis", "VxOXhkLEcyQ"];

	return (
		<Modal
			show={isL2aYoutubeOpen}
			onHide={handleCancel}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<div className="row my-5">
				<div className="col-lg-8 d-flex  pe-0 align-items-center">
					<div className="d-flex justify-content-center container">
						<YouTube
							videoId={selectedUrl}
							iframeClassName="vedio-width"
							className="vedio-width"
						/>
					</div>
				</div>
				<div
					className="col-lg-4 ps-0 youtubeModal"
					style={{ maxHeight: "500px", overflowY: "auto" }}
				>
					{youtubDummyVedios?.map((item, index) => {
						return (
							<YouTube
								key={index}
								videoId={item}
								className="d-flex justify-content-center mb-3"
								onPlay={() => setSelectedurl(item)}
								iframeClassName="side-vedio-width"
							/>
						);
					})}
				</div>
			</div>
		</Modal>
	);
}

export default L2aYotubeModal;
