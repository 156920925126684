import { useSelector } from "react-redux";
import displayVideosStyles from "./displayVideos.module.css";
import useFetchData from "../../hooks/useFetchData";
import { token } from "../../constants";

const DisplayVideos = () => {
	const { loginResponseData } = useSelector((state) => ({
		loginResponseData: state.authorization?.loginResponseData,
	}));

	const { data, loading, error } = useFetchData(
		`${process.env.REACT_APP_BASE_URL}/videos/get-user-videos/${loginResponseData?.data?.id}`,
		token
	);

	const downloadVideo = async (url) => {
		const response = await fetch(url);
		const blob = await response.blob();
		const blobUrl = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = blobUrl;
		link.download = "video.mp4"; // or any name you want
		link.click();
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	if (!data || !data.videoUrl || data.videoUrl.length === 0) {
		return <div>No videos found</div>;
	}
	console.log(data.videoUrl);
	return (
		<div className="d-flex gap-3">
			{data?.videoUrl.map((video, index) => (
				<div key={index} className="card align-items-center border border-0">
					<img
						src={"/icons/downloadImgProfile.png"}
						alt="Unable to load image"
						className="rounded-circle w-25"
					/>
					<div className="card-body">
						<button
							onClick={() => downloadVideo(video?.video_url)}
							className={displayVideosStyles.changepassword}
						>
							Download
						</button>
					</div>
				</div>
			))}
		</div>
	);
};

export default DisplayVideos;
