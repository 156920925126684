import { CircularProgress } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";
import {
	resetPasswordPayload,
	updateTokenVarification,
	VarifiedPassword,
} from "../../redux/reducers/ducks/autorizeDuck";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";

export const ChangePasswordVarifacation = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { tokenVarification } = useSelector((state) => {
		return {
			tokenVarification: state.authorization,
		};
	});
	const search = window.location.href;
	const url = search.split("?");
	const urlEmail = new URLSearchParams(url[1])?.get("email");
	const resetToken = new URLSearchParams(url[1])?.get("resetToken");
	const token = resetToken?.replaceAll(" ", "+");

	useEffect(() => {
		if (token && urlEmail) {
			const dispatchedData = {
				token: token,
				email: urlEmail,
			};
			setTimeout(() => {
				dispatch(VarifiedPassword(dispatchedData));
				dispatch(resetPasswordPayload(dispatchedData));
			}, 5000);
		}
	}, [token, urlEmail, dispatch]);

	useEffect(() => {
		if (
			tokenVarification?.tokenVarification?.status === 200 ||
			tokenVarification?.tokenVarification?.status === 201
		) {
			dispatch(updateTokenVarification(""));
			getToasterNotifications("Please reset your password here:");
			navigate("/password-change");
		} else if (
			tokenVarification?.tokenVarification?.response?.status === 404 ||
			tokenVarification?.tokenVarification?.response?.status === 401 ||
			tokenVarification?.tokenVarification?.response?.status === 404 ||
			tokenVarification?.tokenVarification?.response?.status === 409 ||
			tokenVarification?.tokenVarification?.response?.status === 400
		) {
			dispatch(updateTokenVarification(""));
			getToasterErrors(
				tokenVarification?.tokenVarification?.response?.data?.message
			);
			navigate("/forgot-password");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenVarification]);

	return (
		<div className="d-flex  loaderCenter justify-content-center align-item-center">
			<CircularProgress color="inherit" />
		</div>
	);
};
