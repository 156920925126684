import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
	loginRequest,
	updateIsLogin,
	updateLoginUser,
	updateToken,
} from "../../redux/reducers/ducks/autorizeDuck";
import "./modals.css";
import { Backdrop, CircularProgress } from "@mui/material";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
	getSellerId,
	submitLikeDislike,
	submitReview,
	updateReviewSubmitStatus,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
// import LoveModal from "./LoveModal";
// import ThanksModal from "./thanksModal";

function LoginModal({
	isShow,
	setIsShow,
	setSignUp,
	reviews,
	setThanks,
	isReviewSubitOnLogin,
	setIsReviewSubmitOnLogin,
	setIsTell,
	isTell,
	isAir,
	likeType,
	setLikeType,
	isFromReviewModal,
	setIsReportReview,
	notFromSellerBalloonPage,
	setIsAlreadySubmitReview,
	setIsParentModal,
	fromSellerBalloonPage,
	isNegativeReview,
	setLoveOpenAfterLogin,
	// setIsNegativeReview,
}) {
	const handleClose = () => setIsShow(false);
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [isLoginSubmitted, setIsLoginSubmitted] = useState(false);
	const [error, setError] = useState(null);
	const [isReCaptchaValue, setIsReCaptchaValue] = useState(false);
	const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);
	const [isVerifiedApiCall, setIsVerifiedApiCall] = useState(false);
	const [isEyeIconClick, setIsEyeIconClick] = useState(false);

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const {
		login,
		isLogin,
		reviewSubmitStatus,
		loginResponseData,
		verifyReviewData,
	} = useSelector((state) => {
		return {
			login: state.authorization?.accessToken,
			verifyReviewData: state?.adminReview?.verifyReviewData,
			isLogin: state.authorization?.isLogin,
			loginResponseData: state.authorization?.loginResponseData,
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
		};
	});

	function onChange(value) {
		if (value) {
			setIsReCaptchaValid(true);
			setIsReCaptchaValue(true);
		} else {
			setIsReCaptchaValue(false);
		}
	}

	// function made for handling the api
	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!email || !password) {
			setError("Please fill the input field.");
			return;
		} else {
			setIsLoginSubmitted(true);
			if (isReCaptchaValue) {
				const requestData = {
					email: email,
					password: password,
				};
				dispatch(updateIsLogin(false));
				dispatch(loginRequest(requestData));
				setIsReCaptchaValid(true);
				setIsLoginSubmitted(false);
			}
		}
	};

	useEffect(() => {
		if (isReCaptchaValue) {
			const requestData = {
				email: email,
				password: password,
			};
			dispatch(updateIsLogin(false));
			dispatch(loginRequest(requestData));
			setIsReCaptchaValid(true);
			setIsLoginSubmitted(false);
		}
	}, [isReCaptchaValue]);

	// we get login response if it ok then show toaster and call verify review api  if not then show error

	useEffect(() => {
		if ((login?.status === 201 || login?.status === 201) && !isLogin) {
			localStorage.setItem("token", login?.data?.access_token);
			dispatch(updateToken(null));

			getToasterNotifications(
				!login?.data?.keyLogin ? "Nice to meet you!" : "Nice to see you again!"
			);

			setIsVerifiedApiCall(true);
			dispatch(updateIsLogin(true));
			setIsLoginSubmitted(false);
			setIsReCaptchaValid(false);
			// we are calling verify review api after login response
			if (loginResponseData?.data?.id) {
				dispatch(
					verifyReviewRequest({
						sellerId: reviews?.sellerId,
						userId: loginResponseData?.data?.id,
					})
				);
			}
			if (!reviews?.sellerId) {
				setIsShow(false);
			}
			dispatch(updateLoginUser(null));
		} else if (
			login?.response?.status === 404 ||
			login?.response?.status === 401 ||
			login?.response?.status === 400
		) {
			getToasterErrors(login?.response?.data?.message);
			dispatch(updateIsLogin(false));
			setIsReCaptchaValid(false);
			dispatch(updateLoginUser(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [login, isLogin]);

	const handleSignUp = () => {
		setSignUp(true);
		setIsShow(false);
	};

	useEffect(() => {
		if (likeType && login?.status === 201) {
			if (
				likeType?.type === "like" ||
				likeType?.userId !== loginResponseData?.data?.id
			) {
				dispatch(
					submitLikeDislike({
						type: likeType?.type,
						reviewId: likeType?.reviewId,
					})
				);
				setLikeType(null);
				if (isFromReviewModal) {
					setIsReportReview(true);
				}
				dispatch(updateLoginUser(null));
			}
		}
	}, [login]);

	// logic for love reviews
	// after calling verify review api we are now submitting reviews for love
	// in first if we check it is not from seller balloon page
	// and either it submited already review to its parent
	// if it already submit then throw error
	// if not submit already then submit review and redirect page to seller-balloon

	useEffect(() => {
		if (verifyReviewData?.statusCode && !isNegativeReview) {
			if (
				notFromSellerBalloonPage &&
				isVerifiedApiCall &&
				verifyReviewData?.isSubmitted &&
				reviews?.sellerId
			) {
				setIsShow(false);
				dispatch(updateReviewSubmitStatus(null));
				setIsVerifiedApiCall(false);
				setIsAlreadySubmitReview(true);
				return;
			} else if (
				isVerifiedApiCall &&
				verifyReviewData?.isSubmitted &&
				reviews?.sellerId &&
				!notFromSellerBalloonPage &&
				fromSellerBalloonPage
			) {
				setIsShow(false);
				dispatch(getSellerId(reviews?.sellerId));
				navigate(`/seller-balloon/${reviews?.sellerId}`);
				dispatch(updateReviewSubmitStatus(null));
				setIsVerifiedApiCall(false);
				setIsParentModal(false);
				dispatch(
					submitReview({
						titleId: reviews?.titleId,
						message: reviews?.message,
						approvedByAdmin: true,
						bestWriter: false,
						sellerId: reviews?.sellerId,
					})
				);
				return;
			} else if (
				isVerifiedApiCall &&
				!verifyReviewData?.isSubmitted &&
				reviews?.sellerId
			) {
				dispatch(submitReview(reviews));
				setIsShow(false);
				setThanks(true);
				setIsVerifiedApiCall(false);
				if (isTell || isAir) {
					setIsTell(false);
				}
				if (isReviewSubitOnLogin) {
					setIsReviewSubmitOnLogin(false);
				}
			}
			if (isVerifiedApiCall && reviews?.sellerId) {
				dispatch(verifyReviewResponse(null));
			}
		}
	}, [
		verifyReviewData,
		isNegativeReview,
		notFromSellerBalloonPage,
		isVerifiedApiCall,
		reviews,
	]);

	// logic for aired reviews

	// after calling verify review api we are now submitting reviews
	// backing reviews to its parent component air where we open love modal
	// without submitting review
	// then after love we call multiple review api in love modal
	// actually we are backing all control to parent air component

	useEffect(() => {
		if (verifyReviewData?.statusCode && isNegativeReview) {
			if (
				notFromSellerBalloonPage &&
				isVerifiedApiCall &&
				verifyReviewData?.isSubmitted &&
				reviews?.sellerId
			) {
				setIsShow(false);
				dispatch(updateReviewSubmitStatus(null));
				setIsVerifiedApiCall(false);
				setIsAlreadySubmitReview(true);
				return;
			} else if (
				isVerifiedApiCall &&
				verifyReviewData?.isSubmitted &&
				reviews?.sellerId &&
				!notFromSellerBalloonPage &&
				fromSellerBalloonPage
			) {
				setIsShow(false);
				dispatch(getSellerId(reviews?.sellerId));
				navigate(`/seller-balloon/${reviews?.sellerId}`);
				dispatch(updateReviewSubmitStatus(null));
				setIsVerifiedApiCall(false);
				setIsParentModal(false);
				dispatch(
					submitReview({
						titleId: reviews?.titleId,
						message: reviews?.message,
						approvedByAdmin: true,
						bestWriter: false,
						sellerId: reviews?.sellerId,
					})
				);
				return;
			} else if (
				isVerifiedApiCall &&
				!verifyReviewData?.isSubmitted &&
				reviews?.sellerId
			) {
				// dispatch(submitReview(reviews));
				setThanks(false);
				setLoveOpenAfterLogin(true);
				setIsVerifiedApiCall(false);
				// if (isReviewSubitOnLogin) {
				// 	setIsReviewSubmitOnLogin(false);
				// }
			}
			if (isVerifiedApiCall && reviews?.sellerId) {
				dispatch(verifyReviewResponse(null));
			}
		}
	}, [
		verifyReviewData,
		isNegativeReview,
		notFromSellerBalloonPage,
		isVerifiedApiCall,
		reviews,
	]);

	useEffect(() => {
		if (
			reviews?.sellerId &&
			(reviewSubmitStatus === 200 || reviewSubmitStatus === 201)
		) {
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus]);

	useEffect(() => {
		if (isShow) {
			setError(null);
		}
	}, [isShow]);

	return (
		<Modal
			show={isShow}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			{!isLogin && isReCaptchaValid && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 2 }}
					open={!isLogin && isReCaptchaValid}
				>
					<div className="d-flex  justify-content-center loaderCenter align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}

			<Modal.Header
				closeButton
				className="fw-bold d-flex align-items-center justify-content-start"
			>
				<img src="/icons/logo2.png" className="mx-2 login-logo" alt="logo" />
				{reviews?.sellerId ? (
					<div className="mt-md-3 mt-4">
						Your actions are important! Login ensures that a user can click each
						seller one time.
					</div>
				) : (
					<div className="mt-2">Please Login to Complete this Action.</div>
				)}
			</Modal.Header>
			<Modal.Body className="text-center">
				<form className="mt-4 rounded-4 bg-white">
					<div className="form-group mb-3 sign-modal-input-width mx-auto shadow-sm">
						<input
							type="email"
							required
							className="w-100 border rounded p-2 shadow-sm"
							name="email"
							id="inputEmail"
							placeholder="Email"
							onChange={(e) => {
								setEmail(e.target.value);
								setError("");
							}}
						/>
					</div>
					{!email && (
						<p className="text-danger text-center required">{error}</p>
					)}
					<div className="form-group mb-3 position-relative sign-modal-input-width mx-auto shadow-sm">
						<input
							className="w-100 border rounded shadow-sm p-2 "
							name="full_name"
							minLength="3"
							type={isEyeIconClick ? "text" : "password"}
							id="inputFullName"
							placeholder="Password"
							onChange={(e) => {
								setPassword(e.target.value);
								setError("");
							}}
						/>
						{isEyeIconClick && (
							<span
								className="eye-icon"
								onClick={() => setIsEyeIconClick(false)}
							>
								<FontAwesomeIcon icon={faEye} />
							</span>
						)}
						{!isEyeIconClick && (
							<span
								className="eye-icon"
								onClick={() => setIsEyeIconClick(true)}
							>
								<FontAwesomeIcon icon={faEyeSlash} />
							</span>
						)}
					</div>
					{!password && (
						<p className="text-danger text-center required">{error}</p>
					)}

					<div className="text-center">
						{/*created the signup button*/}
						<Link to="">
							<Button
								type="submit"
								onClick={handleSubmit}
								className="login-submit"
							>
								Login
							</Button>
						</Link>
					</div>
					<div className="recaptchParent">
						{isLoginSubmitted && (
							<ReCAPTCHA
								style={{ display: "inline-block" }}
								theme="dark"
								// eslint-disable-next-line no-undef
								sitekey={process.env.REACT_APP_ReactCaptcha}
								onChange={onChange}
							/>
						)}
					</div>

					<div className="login-forgot">
						<p className="text-md-center text-start" onClick={handleSignUp}>
							<Link
								to=""
								className="fw-bold text-decoration-none sign-up-margin"
							>
								Sign up!
							</Link>
						</p>
						{/* <div className="text-center login-or">or</div> */}

						<div className="col-lg-6 pr-0 col-md-6 col-sm-12 login-link">
							<div className="text-md-center text-end forgot-link">
								<Link
									to="/forgot-password"
									className="text-decoration-none fw-bold"
								>
									Forgot Password?
								</Link>
							</div>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default LoginModal;
