import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
	adminImageUploadStatusResponse,
	bestWriterNominationRequest,
	bestWriterRequest,
} from "../../../redux/reducers/ducks/adminReviewDuck";
import { useDispatch, useSelector } from "react-redux";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, CircularProgress } from "@mui/material";
import { getToasterErrors } from "../../../utils/getToasterErrors";

export function UploadWriterImage({ showPopup, setShowPopup }) {
	const handleClose = () => {
		setShowPopup({
			isOpen: false,
			storyImage: null,
			id: null,
		});
	};

	const adminImageUploadStatus = useSelector(
		(state) => state?.adminReview?.adminImageUploadStatus
	);

	const [isSubmittedReview, setIsSubmittedReview] = React.useState(false);

	const [base64Image, setBase64Image] = React.useState();

	const handleImageChange = (e) => {
		const file = e.target.files[0];

		if (file) {
			const reader = new FileReader();

			reader.onloadend = () => {
				// The result property contains the base64-encoded image string
				const base64String = reader.result;
				setBase64Image(base64String);
			};

			reader.readAsDataURL(file);
		}
	};

	const dispatch = useDispatch();

	const handleBestWriterImage = () => {
		if (!base64Image) {
			getToasterNotifications("Please select Image");
			return;
		}
		setIsSubmittedReview(true);
		dispatch(
			bestWriterRequest({
				reviewId: showPopup?.id,
				storyImage: base64Image,
			})
		);
	};

	React.useEffect(() => {
		if (adminImageUploadStatus?.status === 200) {
			getToasterNotifications("Image added successfully");
			dispatch(adminImageUploadStatusResponse(null));
			setIsSubmittedReview(false);
			setShowPopup({
				isOpen: false,
				id: null,
				storyImage: null,
			});
			const requestData = {
				page: 1,
				pageSize: 10,
			};
			dispatch(bestWriterNominationRequest(requestData));
		} else if (adminImageUploadStatus?.response?.status === 404) {
			getToasterErrors(adminImageUploadStatus?.response?.data?.message);
			dispatch(adminImageUploadStatusResponse(null));
			setIsSubmittedReview(false);
			setShowPopup({
				isOpen: false,
				id: null,
				storyImage: null,
			});
		}
	}, [adminImageUploadStatus]);

	function BootstrapDialogTitle(props) {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle className="text-center" sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	}

	return (
		<React.Fragment>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={showPopup?.isOpen}
				className="best-writer-dialog"
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				{isSubmittedReview && (
					<Backdrop sx={{ color: "#fff", zIndex: 2 }} open={isSubmittedReview}>
						<div className="d-flex  loaderCenter justify-content-center align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				)}
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				>
					Upload best writer description
				</BootstrapDialogTitle>
				<DialogContent>
					<div className="w-100 text-center">
						<form>
							<input
								type="file"
								// value={base64Image}
								className="mt-3 inputField w-75 text-center admin-category-text-field"
								onChange={handleImageChange}
							/>
							<div className="text-center mt-2 ">
								<button
									type="submit"
									onClick={(e) => {
										e.preventDefault();
										handleBestWriterImage();
									}}
									className="btn addCategoryBtn px-5 py-2"
								>
									Submit
								</button>
							</div>
						</form>
					</div>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
