import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	changeRequest,
	resetPasswordPayload,
	updateIsChange,
} from "../../redux/reducers/ducks/autorizeDuck";
import "./changePassword.css";

import { getToasterNotifications } from "../../utils/getToasterNotification";
// import { getToasterErrors } from "../../utils/getToasterErrors";
import Remarks from "../remarks/remarks";
import UploadImageToS3WithNativeSdk from "../uploadVedioS3/uploadVedioS3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import UserProfileInfo from "../userProfileInfo/userProfileInfo";
import useFetchData from "../../hooks/useFetchData";
import { token } from "../../constants";
import DisplayVideos from "../displayVideos/displayVideos";

function ChangePasswordComponent({ isFromProfile }) {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [isSignupClicked, setIsSignupClicked] = useState(false);
	const [newPasswordisEyeIconClick, setNewPasswordIsEyeIconClick] =
		useState(false);
	const [
		confirmNewPasswordisEyeIconClick,
		setConfirmNewPasswordIsEyeIconClick,
	] = useState(false);

	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { change, isChange, resetPayload, loginResponseData } = useSelector(
		(state) => {
			return {
				change: state?.authorization?.changePass,
				isChange: state?.authorization?.isChange,
				resetPayload: state?.authorization?.resetPayload,
				loginResponseData: state.authorization?.loginResponseData,
			};
		}
	);

	const { data } = useFetchData(
		`${process.env.REACT_APP_BASE_URL}/videos/get-user-videos/${loginResponseData?.data?.id}`,
		token
	);

	console.log(data?.videoUrl);
	console.log(data?.videoUrl?.length);
	//const email = resetPayload?.email;
	// const token = resetPayload?.token;
	// let token = localStorage.getItem(`token`);

	useEffect(() => {
		dispatch(resetPasswordPayload(resetPayload));
	}, []);
	// function made for handling the api
	const passwordPattern =
		/^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,}$/;
	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsSignupClicked(true);
		if (!passwordPattern.test(newPassword, confirmPassword)) {
			setPasswordError(
				" Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character."
			);
			return;
		}
		const requestData = {
			password: newPassword,
			confirmPassword: confirmPassword,
		};
		dispatch(changeRequest(requestData));
	};

	useEffect(() => {
		if ((change?.status === 201 || change?.status === 200) && !isChange) {
			getToasterNotifications("Success - your password has been reset!");
			navigate("/loginUser");
			dispatch(updateIsChange(true));
			dispatch(resetPasswordPayload(null));
		}
		// else if (
		// 	change?.response?.status === 400 ||
		// 	change?.response?.status === 401
		// )
		// {
		// 	getToasterErrors(change?.response?.data?.message);
		// 	navigate("/forgot-password");
		// 	dispatch(updateIsChange(false));
		// } else if (change?.response?.status === 406) {
		// 	getToasterErrors(change?.response?.data?.message);
		// 	dispatch(updateIsChange(false));
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [change, isChange]);
	const handleConfirmPasswordChange = (event) => {
		const passwordValue = event.target.value;
		setConfirmPassword(passwordValue);
		setPasswordError("");
	};
	const handleNewPasswordChange = (event) => {
		const passwordValue = event.target.value;
		setNewPassword(passwordValue);
		setPasswordError("");
	};
	const [fullName, setFullName] = useState("");
	const [from, setFrom] = useState("");
	const [isOriginalMaterial, setIsOriginalMaterial] = useState(false);
	const handelSetFullName = (event) => {
		const fullname = event.target.value;
		setFullName(fullname);
	};
	const handelSetFromName = (event) => {
		const from = event.target.value;
		setFrom(from);
	};
	const handleCheckboxChange = (event) => {
		setIsOriginalMaterial(event.target.checked);
	};

	const [balloonId, setBalloonId] = useState("");
	console.log(from, fullName, isOriginalMaterial);
	// console.log(balloonId)
	return (
		<div className="container">
			<div className="d-flex border shadow-sm fit-content mx-auto rounded-4 pr-2">
				<div className="background-color left-rounded-3">
					{!isFromProfile && (
						<h2 className="text-center mt-4 mb-4 fs-4">New Password</h2>
					)}
					{isFromProfile && (
						<div className="row d-flex justify-content-center">
							<img
								className="profile-img-rounded"
								src="/icons/prfile-img.png"
							/>
						</div>
					)}
					{isFromProfile && (
						<div className="text-center mx-auto">
							<h3 className="text-gray-800 mt-2 text-2xl">
								{loginResponseData?.data?.name}
							</h3>
						</div>
					)}
				</div>
				<div className="d-flex flex-column ml-13">
					<form className="mt-4 pt-4 w-60 h-96 profile-form-width">
						<h4 className="ml-13">My Profile</h4>
						<hr className="len" />
						<UserProfileInfo />
						<h4 className="ml-13">Change Password</h4>
						<hr className="len" />
						<div className="d-flex ml-13">
							<div className="form-group w-60 mb-3 shadow-sm">
								<span className="font-bold">New Password</span>
								<br />
								<div className="form-group position-relative shadow-sm">
									<input
										className="w-100 border rounded shadow-sm p-2"
										name="newpassword"
										id="inputnewPassword"
										type={newPasswordisEyeIconClick ? "text" : "password"}
										placeholder="New Password"
										onChange={handleNewPasswordChange}
									/>
									{newPasswordisEyeIconClick && (
										<span
											className="eye-icon"
											onClick={() => setNewPasswordIsEyeIconClick(false)}
										>
											<FontAwesomeIcon icon={faEye} />
										</span>
									)}
									{!newPasswordisEyeIconClick && (
										<span
											className="eye-icon"
											onClick={() => setNewPasswordIsEyeIconClick(true)}
										>
											<FontAwesomeIcon icon={faEyeSlash} />
										</span>
									)}
								</div>

								{isSignupClicked && passwordError && (
									<div className="error-message error-size">
										{passwordError}
									</div>
								)}
							</div>
							<div className="form-group w-60 mb-3 ml-13 shadow-sm">
								<span className="font-bold">Confirm Password</span>
								<br />
								<div className="form-group position-relative shadow-sm">
									<input
										required
										className="w-100 border rounded shadow-sm p-2"
										name="confirmPassword"
										id="inputconfirmPassword"
										type={
											confirmNewPasswordisEyeIconClick ? "text" : "password"
										}
										placeholder="Confirm Password"
										onChange={handleConfirmPasswordChange}
									/>
									{confirmNewPasswordisEyeIconClick && (
										<span
											className="eye-icon"
											onClick={() => setConfirmNewPasswordIsEyeIconClick(false)}
										>
											<FontAwesomeIcon icon={faEye} />
										</span>
									)}
									{!confirmNewPasswordisEyeIconClick && (
										<span
											className="eye-icon"
											onClick={() => setConfirmNewPasswordIsEyeIconClick(true)}
										>
											<FontAwesomeIcon icon={faEyeSlash} />
										</span>
									)}
								</div>
								{isSignupClicked && passwordError && (
									<div className="error-message error-size">
										{passwordError}
									</div>
								)}
							</div>
						</div>
						<div className="text-center mb-5">
							{/*created the signup button*/}
							<Link to="">
								<Button
									type="submit"
									onClick={handleSubmit}
									className="change-passwor"
								>
									Update
								</Button>
							</Link>
						</div>
					</form>
					<div>
						{data?.videoUrl?.length > 0 && (
							<div className="">
								<h4 className="ml-13">Download your videos here</h4>
								<hr className="len" />
								<DisplayVideos />
							</div>
						)}
						{data?.videoUrl?.length < 2 && (
							<div>
								<h4 className="ml-13">Show Me Your Emoji! Talent Contest</h4>
								<hr className="len" />
								<Remarks setBalloonId={setBalloonId} />
								<UploadImageToS3WithNativeSdk
									balloonId={balloonId}
									fullName={fullName}
									from={from}
									isOriginalMaterial={isOriginalMaterial}
									handelSetFullName={handelSetFullName}
									handelSetFromName={handelSetFromName}
									handleCheckboxChange={handleCheckboxChange}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangePasswordComponent;
