import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/childLayout/childLayout";
import "./betaTest.css";

function BetaTest() {
	return (
		<Layout>
			<div className="container">
				<img
					src="/icons/logo1.png"
					className="mx-auto mt-3 d-block w-25"
					alt="logo"
				/>
				<div className="w-50 text-center mx-auto mt-5">
					<h2 className="mb-4">What's a Beta Tester?</h2>
					<p className="beta-paragraph">
						Beta testers are among the very first people to get access to new
						apps and websites. Being ahead of what’s called the Innovation
						Adoption Curve, they exist in only a small percentage of the overall
						population.
					</p>
					<Link to="/sign-up">
						<button className="btn beta-signup-btn rounded-pill">
							Sign Up Here!
						</button>
					</Link>

					<img className="mt-2 w-100" src="/icons/graph.png" alt="graph" />
					<hr />
					<h4>How many open spots are there for L2a testers?</h4>
					<p className="beta-paragraph">
						L2a will be conducting what’s called a Closed Beta Test. The number
						of participants is limited to 2,500.
					</p>
					<hr />
					<h3>You’re Valuable to Us</h3>
					<p className="beta-paragraph">
						Tech startups, like L2a, depend on beta testers to help them test
						and refine their mobile apps and websites.
					</p>
					<p className="beta-paragraph text-start">
						For instance, the tech-savvy testers will be informing us of common
						tech problems, such as slow loading pages, modal glitches or browser
						incompatibilities. Their input will go directly to the Head of
						Engineering.
					</p>
					<p className="beta-paragraph text-start">
						Others - who find they care passionately about L2a’s mission - will
						be sharing their feelings and thoughts about their overall
						subjective experience using the site. This type of feedback will go
						directly to the Founder’s inbox.
					</p>
					<p className="beta-paragraph text-start">
						Still other “perfectionist testers” will be pointing out any
						misspelled words or grammar errors we’ve made!
					</p>
					<hr />
					<h3>We Appreciate You!</h3>
					<p className="beta-paragraph">
						We value each and every beta tester’s opinion and perspective. Our
						goal is to make L2a be as easy-to-use, helpful and fun as possible.
						To this end, we absolutely depend on the intelligent input of our
						original beta testers.
					</p>
					<h5 className="beta-paragraph">So thank you in advance!</h5>
					<hr />
					<h2 className="mb-4">Additional Perks!</h2>
					<img
						className="float-start w-50"
						src="/icons/blackShirt.png"
						alt="black shirt"
					/>
					<img
						className="float-end w-50"
						src="/icons/whiteShirt.png"
						alt="white shirt"
					/>
					<p className="beta-paragraph">
						All 2,500 beta testers have the opportunity to win their choice of
						white or black Canvas-Brand t-shirts or $20 Amazon Gift cards at
						time of launch!
					</p>
					<div className="btn_signup">
						<div className="position-absolute h-25">
							<Link to="/sign-up">
								<button className="btn beta-signup-btn rounded-pill">
									Sign Up Here!
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default BetaTest;
