import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	adminReview: null,
	adminReviewAllSellers: null,
	adminReviewLoading: false,
	sentEmailRespose: null,
	userProgress: null,
	verifyReviewData: null,
	loggerData: null,
	pendingSellers: null,
	recentActivitiesData: null,
	reviewCurrentPage: 1,
	csvCategoriesResponseData: null,
	averageSignUpsData: null,
	averageMonthlySignUpsData: null,
	activeSideBarLink: null,
	bestWriterData: null,
	weeklyAverageData: null,
	monthlyAverageData: null,
	weeklyGraphData: null,
	monthlyGraphData: null,
	bestWriterNominatedResponseData: null,
	totalSignUpCount: null,
	clickUser: null,
	winnerDate: null,
	emailPortalData: null,
	sendBestWriterEmailData: null,
	isBestWriterNominated: null,
	alreadyWinnerReviewData: null,
	adminImageUploadStatus: null,
};

const mainSlice = createSlice({
	name: "main",
	initialState: INITIAL_STATE,
	reducers: {
		getAdminReview: (state) => state,
		sendBestWriterEmailRequest: (state) => state,
		updateReviewAdminStatus: (state) => state,
		userProgressRequest: (state) => state,
		getReviewSellers: (state) => state,
		deleteReviewRequest: (state) => state,
		getEmailSentPayload: (state) => state,
		bestWriterRequest: (state) => state,
		loggerRequest: (state) => state,
		pendingSellersRequest: (state) => state,
		recentActivitiesRequest: (state) => state,
		csvCategoriesRequest: (state) => state,
		averageMonthlySignUpsRequest: (state) => state,
		UpdateSellerTypeRequest: (state) => state,
		bestWriterNominationRequest: (state) => state,
		weeklyAverageRequest: (state) => state,
		weeklyGraphRequest: (state) => state,
		monthlyAverageRequest: (state) => state,
		monthlyGraphRequest: (state) => state,
		totalSignUpCountRequest: (state) => state,
		emailPortalRequest: (state) => state,
		alreadyWinnerReviewRequest: (state) => state,
		winnerDateRequest: (state) => state,
		Request: (state) => state,
		pendingSellersResponse(state, { payload }) {
			return {
				...state,
				pendingSellers: payload,
			};
		},
		updateIsBestWriterNominated(state, { payload }) {
			return {
				...state,
				isBestWriterNominated: payload,
			};
		},
		bestWriterNominatedResponse(state, { payload }) {
			return {
				...state,
				bestWriterNominatedResponseData: payload,
			};
		},
		updateReviewCurrentPage(state, { payload }) {
			return {
				...state,
				reviewCurrentPage: payload,
			};
		},
		recentActivitiesResponse(state, { payload }) {
			return {
				...state,
				recentActivitiesData: payload,
			};
		},
		loggerResponse(state, { payload }) {
			return {
				...state,
				loggerData: payload,
			};
		},
		csvCategoriesResponse(state, { payload }) {
			return {
				...state,
				csvCategoriesResponseData: payload,
			};
		},
		updateAdminReview(state, { payload }) {
			return {
				...state,
				adminReview: payload,
			};
		},
		verifyReviewResponse(state, { payload }) {
			return {
				...state,
				verifyReviewData: payload,
			};
		},
		userProgressResponse(state, { payload }) {
			return {
				...state,
				userProgress: payload,
			};
		},
		getAdminReviewAllSellers(state, { payload }) {
			return {
				...state,
				adminReviewAllSellers: payload,
			};
		},
		adminReviewLoadingResponse(state, { payload }) {
			return {
				...state,
				adminReviewLoading: payload,
			};
		},
		sentContactEmailResponse(state, { payload }) {
			return {
				...state,
				sentEmailRespose: payload,
			};
		},
		averageMonthlySignUpsResponse(state, { payload }) {
			return {
				...state,
				averageMonthlySignUpsData: payload,
			};
		},
		updateActiveHeaderLink(state, { payload }) {
			return {
				...state,
				activeSideBarLink: payload,
			};
		},
		bestWriterResponse(state, { payload }) {
			return {
				...state,
				bestWriterData: payload,
			};
		},
		weeklyAverageResponse(state, { payload }) {
			return {
				...state,
				weeklyAverageData: payload,
			};
		},
		monthlyAverageResponse(state, { payload }) {
			return {
				...state,
				monthlyAverageData: payload,
			};
		},
		weeklyGraphResponse(state, { payload }) {
			return {
				...state,
				weeklyGraphData: payload,
			};
		},
		monthlyGraphResponse(state, { payload }) {
			return {
				...state,
				monthlyGraphData: payload,
			};
		},
		totalSignUpCountResponse(state, { payload }) {
			return {
				...state,
				totalSignUpCount: payload,
			};
		},
		updateClickedUser(state, { payload }) {
			return {
				...state,
				clickUser: payload,
			};
		},
		emailPortalResponse(state, { payload }) {
			return {
				...state,
				emailPortalData: payload,
			};
		},
		sendBestWriterEmailResponse(state, { payload }) {
			return {
				...state,
				sendBestWriterEmailData: payload,
			};
		},
		winnerDateResponse(state, { payload }) {
			return {
				...state,
				winnerDate: payload,
			};
		},
		adminImageUploadStatusResponse(state, { payload }) {
			return {
				...state,
				adminImageUploadStatus: payload,
			};
		},
	},
});

export const {
	getAdminReview,
	adminReviewLoadingResponse,
	updateAdminReview,
	updateReviewAdminStatus,
	getReviewSellers,
	getAdminReviewAllSellers,
	getEmailSentPayload,
	sentContactEmailResponse,
	userProgressRequest,
	userProgressResponse,
	bestWriterRequest,
	deleteReviewRequest,
	verifyReviewResponse,
	loggerRequest,
	loggerResponse,
	pendingSellersRequest,
	pendingSellersResponse,
	recentActivitiesRequest,
	recentActivitiesResponse,
	updateReviewCurrentPage,
	csvCategoriesResponse,
	csvCategoriesRequest,
	averageMonthlySignUpsResponse,
	UpdateSellerTypeRequest,
	updateActiveHeaderLink,
	averageMonthlySignUpsRequest,
	bestWriterNominationRequest,
	bestWriterResponse,
	weeklyAverageRequest,
	monthlyAverageRequest,
	weeklyAverageResponse,
	monthlyAverageResponse,
	weeklyGraphRequest,
	weeklyGraphResponse,
	monthlyGraphRequest,
	monthlyGraphResponse,
	bestWriterNominatedResponse,
	totalSignUpCountRequest,
	totalSignUpCountResponse,
	updateClickedUser,
	emailPortalResponse,
	emailPortalRequest,
	updateIsBestWriterNominated,
	sendBestWriterEmailRequest,
	sendBestWriterEmailResponse,
	winnerDateRequest,
	alreadyWinnerReviewRequest,
	winnerDateResponse,
	adminImageUploadStatusResponse,
} = mainSlice.actions;

export default mainSlice.reducer;
