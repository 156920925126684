export const htmlFormat = (params) => {
	return `
  

  <table
    style="
          border-collapse: collapse;
          table-layout: fixed;
          border-spacing: 0;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          vertical-align: top;
          min-width: 320px;
          margin: 0 auto;
          background-color: #ededed;
          width: 100%;
        "
    cellpadding="0"
    cellspacing="0"
  >
    <tbody>
    <tr style="vertical-align: top">
      <td
        style="
                word-break: break-word;
                border-collapse: collapse !important;
                vertical-align: top;
              "
      >
        <div class="u-row-container">
          <div
            class="u-row"
            style="
                    margin: 20px auto;
                    min-width: 320px;
                    max-width: 600px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-word;
                    background-color: #ffffff;
                  "
          >
            <div
              style="
                      border-collapse: collapse;
                      display: table;
                      width: 100%;
                      background-color: transparent;
                    "
            >
              <!-- Header LOGO Start-->
              <table
                style="font-family: Fira Sans,sans-serif"
                role="presentation"
                cellpadding="0"
                cellspacing="0"
                width="100%"
                border="0"
              >
                <tbody>
                <tr>
                  <td
                    class="v-container-padding-padding"
                    style="
                              overflow-wrap: break-word;
                              word-break: break-word;
                              padding: 5px;
                              font-family: Fira Sans,sans-serif;
                            "
                    align="center"
                  >
                    <img
                      src="https://www.lovetoair.com/icons/logo2.png"
                      alt="header-logo"
                      style='width: 100px; margin-top: 20px;'
                    />
                    <p
                      style="
                                  font-size: 10px;
                                  font-family: Fira Sans,sans-serif;
                                  font-style: italic;
                                  margin-top: 0.5px;
                                  margin-bottom: 0px;
                                  color: #727171;
                                "
                    >
                       Love the sellers who make you happy

                    </p>

                    <p
                      style="
                                  font-size: 10px;
                                  font-family: Fira Sans,sans-serif;
                                  font-style: italic;
                                  margin-top: 0px;
                                  color: #727171;
                                "
                    >
                      Air your grievances about the ones who don't
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
              <!-- Header LOGO End -->
              <!-- Text Start -->
            
        
  <div style="padding: 0px 48px; margin-top: 20px;">${params}</div>
  <table style="display: flex;justify-content: center;">

    <tbody style="margin: auto">
      <tr>
        <td
          class="v-container-padding-padding"
          style="   
                    overflow-wrap: break-word;
                    word-break: break-word;
                    padding: 0px 48px;
                    font-family: Fira Sans,sans-serif;
                  "
          align="left"
        >
    <p
    style="text-align: center;
                font-size: 14px;
                font-family: Fira Sans,sans-serif;
                font-style: normal;
                margin-top: 25px;
                margin-bottom: 0px
              "
  >
    Need further assistance?</p>
  <div style="text-align: center;
                margin-bottom: 40px;color:#3d85c6;
              font-size: 14px;">
    <a href="https://www.lovetoair.com/contact-us" style="color:#3d85c6;">
      <strong>Contact Us!</strong>
    </a>
  </div>
</td>
  </tr>
  </tbody>
  </table>
  <table
                    id="u_content_heading_9"
                    style="font-family: Fira Sans,sans-serif"
                    role="presentation"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    border="0"
                  >
                    <tbody>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style="
                            overflow-wrap: break-word;
                            word-break: break-word;
                            padding: 0px 48px;
                            font-family: Fira Sans,sans-serif;
                          "
                        align="left"
                      >
                        <p
                          class="v-font-size"
                          style="
                              margin: 0px;
                              text-align: left;
                              word-wrap: break-word;
                              font-family: Fira Sans,sans-serif;
                              font-size: 14px;
                            "
                        >
                          Many wishes,
                        </p>
                        <p
                          class="v-font-size"
                          style="
                              margin: 0px;
                              line-height: 2;
                              text-align: left;
                              word-wrap: break-word;
                              font-family: Fira Sans,sans-serif;
                              font-size: 14px;
                            "
                        >
                          The L2a Team
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
  <!-- Text End -->
  <!-- Footer Start -->
  <div style="margin-top: 30px;border-bottom: 1px solid #dcf788;"></div>
              
              <table
                style="font-family: Fira Sans,sans-serif; margin-top: 20px;"
                role="presentation"
                cellpadding="0"
                cellspacing="0"
                width="100%"
                border="0"
              >
                <tbody>
                <tr>
                  <td
                    class="v-container-padding-padding"
                    style="
                              overflow-wrap: break-word;
                              word-break: break-word;
                              padding: 0px;
                              font-family: Fira Sans,sans-serif;
                            "
                    align="center"
                  >
                    <span
                      style="
                                color: #323c47;
                                font-size: 12px;
                                padding-left: 8px;
                                font-weight: 500;
                                font-family: Fira Sans,sans-serif;
                              "
                    >3435 Ocean Park Blvd., Santa Monica, California 90405</span
                    >
                  </td>
                </tr>
                </tbody>
              </table>
              <table
                style="font-family: Fira Sans,sans-serif"
                role="presentation"
                cellpadding="0"
                cellspacing="0"
                width="100%"
                border="0"
              >
                <tbody>
                <tr>
                  <td
                    class="v-container-padding-padding"
                    style="
                              overflow-wrap: break-word;
                              word-break: break-word;
                              padding-bottom: 28px;
                              font-family: Fira Sans,sans-serif;
                            "
                    align="center"
                  >
                            <span
                              style="
                                color: #323c47;
                                font-size: 12px;
                                padding-left: 8px;
                                font-weight: 500;
                                padding-top: 8px;
                                padding-bottom: 28px;
                                font-family: Fira Sans,sans-serif;
                              "
                            >Love To Air  ®  &nbsp; | &nbsp;&nbsp; <a href="https://www.lovetoair.com/privacy" style="color:#3d85c6;">Privacy Policy</a></span
                            >
                  </td>
                </tr>
                </tbody>
              </table>
  <!-- Footer End -->
  </div>
  </div>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
`;
};
