import React from "react";
import "./resetPassword.css";

function ResetPassword() {
	return (
		<div className="d-flex justify-content-center mt-5">
			<form className=" mt-4 rounded-4 w-50 bg-white shadow">
				<h4 className="text-center mt-2">Reset Password</h4>
				<div className="form-group mt-4 mb-3 w-75 mx-auto shadow-sm"></div>
				<div className="form-group mb-3 w-75 mx-auto shadow-sm">
					<label>
						Old Password <span className="text-danger">*</span>
					</label>
					<input
						type="Password"
						required
						className="w-100 border rounded p-2 shadow-sm"
						name="Enter your OLd Password"
						id="inputOldPassword"
						placeholder=" Enter Your Old Password"
					/>
				</div>
				<div className="form-group mb-3 w-75 mx-auto shadow-sm">
					<label>
						New Password <span className="text-danger">*</span>
					</label>
					<input
						type="password"
						required
						className="w-100 border rounded p-2 shadow-sm"
						name=" New Password"
						id="inputNewPassword"
						placeholder=" Enter your New  Passowrd"
					/>
				</div>
				<div className="form-group mb-3 w-75 mx-auto shadow-sm">
					<label>
						Confirm Password <span className="text-danger">*</span>
					</label>
					<input
						type="password"
						required
						className="w-100 border rounded p-2 shadow-sm"
						name=" Confirm password"
						id="inputConfirmPassword"
						placeholder="Re-Type your Password"
					/>
				</div>
				<div className="text-center">
					<button
						type="submit"
						className="btn mt-2 mb-4 rounded-pill button-reset"
					>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
}

export default ResetPassword;
