import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
	getSellerId,
	updateAirSellerName,
	updateLoveSellerName,
} from "../../redux/reducers/ducks/getSellersDuck";
import { useNavigate } from "react-router";
import { updateTokenActivation } from "../../redux/reducers/ducks/autorizeDuck";
import { appededUrl } from "../../utils/constant";

function ThanksModal({
	isThanks,
	setIsThanks,
	reviews,
	setIsReviewSubmitOnLogin,
	isReviewSubitOnLogin,
}) {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [isCorrectPath, setIsCorrectPath] = useState(false);

	useEffect(() => {
		dispatch(updateAirSellerName(""));
		dispatch(updateLoveSellerName(""));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isCorrect =
			window.location.pathname === appededUrl("/to-love") ||
			window.location.pathname === appededUrl("/to-tell") ||
			window.location.pathname === appededUrl("/to-air") ||
			window.location.pathname === appededUrl("/");
		setIsCorrectPath(isCorrect);
	}, [window]);

	const handleCancel = () => {
		if (isReviewSubitOnLogin) {
			setIsReviewSubmitOnLogin(false);
		}
		setIsThanks(false);
		dispatch(updateAirSellerName(""));
		dispatch(updateLoveSellerName(""));
		if (isCorrectPath) {
			dispatch(updateTokenActivation(null));
			dispatch(getSellerId(reviews?.sellerId));
			navigate(`/seller-balloon/${reviews?.sellerId}`);
		}
	};
	const { airSellerName, loveSellerName } = useSelector((state) => {
		return {
			airSellerName: state?.seller?.airSellerName,
			loveSellerName: state?.seller?.loveSellerName,
			sellerName: state?.seller?.singleSellerData?.data?.seller?.sellerName,
		};
	});
	return (
		<Modal
			show={isThanks}
			onHide={handleCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0" />
			<div>
				<div className="container mb-5">
					<img
						src="/icons/logo1.png"
						className="mx-auto d-block w-25"
						alt="logo"
					/>
					<div className="row d-flex justify-content-center">
						<p className="mt-4 mb-2 text-center fw-bold">
							Thanks for Clicking!
						</p>
					</div>
					<div>
						{(loveSellerName && !airSellerName) ||
						(!loveSellerName && airSellerName) ? (
							<h4 className="text-center fw-bold pb-1 bg-light">
								{loveSellerName ? loveSellerName : airSellerName}
							</h4>
						) : (
							<div></div>
						)}
						{loveSellerName && airSellerName ? (
							<h4 className="text-center fw-bold pb-1 bg-light">
								{airSellerName} &nbsp;-&nbsp; {loveSellerName}
							</h4>
						) : (
							<div></div>
						)}

						{(loveSellerName && !airSellerName) ||
						(!loveSellerName && airSellerName) ? (
							<p className=" mb-3 text-center">
								Your click have just been added to the Seller Balloon tally!
							</p>
						) : (
							""
						)}
						{loveSellerName && airSellerName ? (
							<p className=" mb-3 text-center">
								Your clicks have just been added to the Sellers' Balloon tally!
							</p>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default ThanksModal;
