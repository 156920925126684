import { all } from "redux-saga/effects";
import { watchCategoriesSaga } from "./sagas/categoriesSaga";
import { watchAuthorizeSagas } from "./sagas/authorizeSaga";
import { watchSellersSagas } from "./sagas/sellersSaga";
import { watchContactSagas } from "./sagas/contactSaga";
import { watchUsersSagas } from "./sagas/userSaga";
import { watchAdminReviewSagas } from "./sagas/adminReviewSaga";
import { watchInstagramSagas } from "./sagas/instaStoriesSaga";
import { watchSeededSagas } from "./sagas/seededSaga";

export default function* rootSaga() {
	yield all([
		watchAuthorizeSagas(),
		watchContactSagas(),
		watchSellersSagas(),
		watchCategoriesSaga(),
		watchUsersSagas(),
		watchAdminReviewSagas(),
		watchInstagramSagas(),
		watchSeededSagas(),
	]);
}
