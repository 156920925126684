import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { useRef, useState } from "react";

import { Editor } from "@tinymce/tinymce-react";

import "./adminContact.css";
import { useDispatch, useSelector } from "react-redux";
import {
	bestWriterRequest,
	getEmailSentPayload,
	sentContactEmailResponse,
} from "../../../redux/reducers/ducks/adminReviewDuck";
import { htmlFormat } from "./htmlFormat";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { adminAllContactRequest } from "../../../redux/reducers/ducks/contactDuck";

export function EmailResponsePopup(props) {
	const { onClose, openModal, responseEmail, reviewPage, userReviewId } = props;
	// eslint-disable-next-line
	const [content, setContent] = useState();

	const dispatch = useDispatch();
	const [emailSubject, setEmailSubject] = useState("");

	const sentEmailResponse = useSelector(
		(state) => state?.adminReview?.sentEmailRespose
	);
	const handleClose = () => {
		onClose();
	};

	const handleChange = (event) => {
		setContent({ content: event?.target?.value });
	};

	const editorRef = useRef(null);
	const requestData = {
		page: 1,
		pageSize: 10,
	};
	const handleContactMessage = () => {
		if (reviewPage) {
			if (editorRef.current) {
				const serialize = htmlFormat(editorRef.current.getContent());
				const payloadData = {
					reviewId: userReviewId,
					bestWriter: true,
					subject: emailSubject,
					emailBody: serialize.toString(),
				};
				dispatch(bestWriterRequest(payloadData));

				onClose();
			}
		} else {
			if (editorRef.current) {
				const serialize = htmlFormat(editorRef.current.getContent());
				const payloadData = {
					email: responseEmail,
					subject: emailSubject,
					emailBody: serialize.toString(),
				};
				dispatch(getEmailSentPayload(payloadData));
				onClose();
			}
		}
		dispatch(adminAllContactRequest(requestData));
	};

	React.useEffect(() => {
		if (sentEmailResponse?.status === 200) {
			getToasterNotifications(sentEmailResponse?.data?.message);
			dispatch(sentContactEmailResponse(""));
		}
	}, [sentEmailResponse]);

	return (
		<Dialog
			onClose={handleClose}
			open={openModal}
			PaperProps={{
				sx: {
					minWidth: "75%",
				},
			}}
		>
			<div>
				<div className="form-group mb-3 mt-3 w-75 mx-auto shadow-sm">
					<input
						type="text"
						required
						className="w-100 border rounded p-2 shadow-sm"
						name="email"
						id="inputEmail"
						placeholder="Type subject here"
						onChange={(e) => setEmailSubject(e.target.value)}
					/>
				</div>
				<div className="ps-3 pe-3">
					<Editor
						onInit={(evt, editor) => (editorRef.current = editor)}
						apiKey={process.env.REACT_APP_EDITOR_KEY}
						init={{
							height: 350,
							menubar: false,
						}}
						onEditorChange={handleChange}
					/>
				</div>

				{<span></span>}
				<div className="btnCenter">
					<button
						type="submit"
						onClick={handleContactMessage}
						className="btn contact-email-submit-button px-5 py-2"
					>
						Submit
					</button>
				</div>
			</div>
		</Dialog>
	);
}
