import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./remarks.css";
import { appededUrl } from "../../utils/constant";

function Remarks({
	setReviewData,
	isCommit,
	reviewData,
	setBalloonId,
	setGoodReviews,
	goodReviews,
	isTell,
	setSelectedbaloon,
	isToTell,
	balloonId,
	reviews,
	isEdit,
	setBalloonType,
	isFromFooter,
}) {
	const [animation, setAnimation] = useState({
		isAnimate: false,
		index: null,
	});

	const [isCorrectPath, setIsCorrectPath] = useState(false);

	const balloon = useSelector((state) => state?.seller?.balloon?.data?.title);

	useEffect(() => {
		if (!balloonId) {
			setAnimation({ ...animation, index: null });
		}
	}, [balloonId]);

	let isTestServer = JSON.parse(process.env.REACT_APP_IS_TEST_SERVER);

	const pathArray = isTestServer
		? window.location.pathname?.split("/")[2]
		: window.location.pathname?.split("/")[1];

	useEffect(() => {
		let isCorrect =
			window.location.pathname === appededUrl("/") ||
			window.location.pathname === appededUrl("/home") ||
			window.location.pathname === appededUrl("/add-seller") ||
			window.location.pathname === appededUrl("/outlier");
		setIsCorrectPath(isCorrect);
	}, [window]);

	// const [setSelectedBaloonId, setsetSelectedBaloonId] = useState('')
	// console.log(setSelectedBaloonId)
	return (
		<>
			<div className="parent-review-width mx-md-auto mx-1 mt-md-5 mt-4">
				<div className="row">
					{balloon?.map((i, index) => {
						return (
							<>
								<div
									className={`d-flex justify-content-center col-lg-2 col-4 tell-story-balloon  mb-sm-0 ${
										index > 2 ? "mb-3" : "mb-5"
									}`}
								>
									<div>
										<img
											src={`/icons/${i.imageName}.png`}
											onClick={() => {
												setAnimation({
													isAnimate: !animation.isAnimate,
													index: i.id,
												});
												if (pathArray === "seller-balloon" && !isTell) {
													if (isCommit) {
														if (!animation.isAnimate) {
															setReviewData({ ...reviewData, balloonId: i.id });
														} else {
															setReviewData({ ...reviewData, balloonId: null });
														}
													}
													if (isEdit) {
														setBalloonType(i.type);
													}
												}
												if (isToTell && !isTell) {
													setSelectedbaloon(i);
												}
												if (isTell) {
													setSelectedbaloon(i);
													if (!animation.isAnimate) {
														setGoodReviews({
															...reviews,
															titleId: i.id,
															balloonType: i.type,
														});
													} else {
														setGoodReviews({
															...reviews,
															titleId: null,
															balloonType: null,
														});
													}
												}
												if (isCorrectPath || isFromFooter) {
													if (!animation.isAnimate) {
														setBalloonId(i.id);
													} else {
														setBalloonId("");
													}
												} else if (
													window.location.pathname === appededUrl("/to-tell")
												) {
													if (!animation.isAnimate) {
														setGoodReviews({
															...goodReviews,
															titleId: i.id,
															balloonType: i.type,
														});
													} else {
														setGoodReviews({
															...goodReviews,
															titleId: null,
															balloonType: i.type,
														});
													}
												}
												console.log(i);
												// setsetSelectedBaloonId(i.id)
												setBalloonId(i.id);
											}}
											className={` ${
												(animation.isAnimate && i.id === animation.index) ||
												reviewData?.balloonId === i.id ||
												reviews?.titleId === i.id
													? "animation   w-100"
													: "w-100"
											}
                        `}
											alt="awesome"
										/>

										<div className="col-12 d-flex justify-content-center">
											<div
												onClick={() =>
													setAnimation({
														isAnimate: !animation.isAnimate,
														index: i.id,
													})
												}
												className={
													animation.isAnimate && i.id === animation.index
														? "love_shadow"
														: null
												}
											>
												<div
													className={`remark_image_heading love-remarks-title ${
														reviewData?.balloonId === i.id
															? "text-decoration-underline"
															: null
													}`}
												>
													{i.title}
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default Remarks;
