import {
	Autocomplete,
	Backdrop,
	CircularProgress,
	TextField,
	createFilterOptions,
} from "@mui/material";
import { sellerTypeArray } from "../../utils/addSellerHelper";
import { useDispatch, useSelector } from "react-redux";
import {
	allNonDefaultSellersResponse,
	getSellerId,
	getSellerName,
} from "../../redux/reducers/ducks/getSellersDuck";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddAsellerModal from "../../components/modals/addAsellerModal";
import "./Outlier.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { appededUrl } from "../../utils/constant";

const theme = createTheme({
	components: {
		MuiInputBase: {
			styleOverrides: {
				input: {
					"&::placeholder": {
						color: "dark", // Change the placeholder color here
					},
				},
			},
		},
	},
});

export const AllOutlierSearchBAr = ({
	searchFilter,
	setSearchFilters,
	showModal,
	setShowModal,
}) => {
	const { data, isAllNonDefaultSellersLoading } = useSelector((state) => ({
		data: state?.seller?.allNonDefaultSellers?.data,
		isAllNonDefaultSellersLoading: state?.seller?.isAllNonDefaultSellersLoading,
	}));
	const [addSellerName, setAddSellerName] = useState("");
	const [showAddASellerModal, setShowAddASellerModal] = useState(false);

	const dispatch = useDispatch();

	const handleClose = () => {
		setShowModal(false);
		setSearchFilters({ ...searchFilter, type: null });
		dispatch(allNonDefaultSellersResponse(null));
	};

	const filter = createFilterOptions();

	useEffect(() => {
		if (addSellerName) {
			setShowAddASellerModal(true);
		}
	}, [addSellerName]);

	return (
		<div className="row mb-4">
			<div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-start ">
				<ThemeProvider theme={theme}>
					<Autocomplete
						className="classification-field"
						popupIcon={""}
						onChange={(e, seller) => {
							if (seller) {
								setSearchFilters({ ...searchFilter, sellerName: seller });
								dispatch(getSellerId(seller?.id));
								dispatch(getSellerName(seller?.sellerName));
								window.location.href = appededUrl(
									`/seller-balloon/${seller?.id}`
								);
							} else {
								setSearchFilters({ ...searchFilter, sellerName: "" });
							}
						}}
						sx={{
							"& .MuiOutlinedInput-root": {
								padding: "2px",
								borderRadius: "37px",
							},
							"& .MuiAutocomplete-input": {
								paddingLeft: "17px !important",
							},
						}}
						value={searchFilter?.sellerName}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							if (filtered && filtered?.length > 0) {
								filtered.push({
									inputValue: params.inputValue,
								});

								return filtered;
							} else {
								return [
									{
										add_seller_name: params?.inputValue,
									},
								];
							}
						}}
						options={
							data?.records && data?.records?.length > 0 ? data?.records : []
						}
						getOptionLabel={(option) => {
							if (option?.sellerName) {
								return option.sellerName;
							}
							return "";
						}}
						clearOnBlur={true}
						renderInput={(params) => {
							return (
								<div className="autocomplete-search-inputfield">
									<TextField
										id="autocomplete"
										{...params}
										placeholder="Search Seller"
									/>
								</div>
							);
						}}
						renderOption={(props, option) => {
							return (
								<div
									style={{
										display: "flex",
										justifyContent: "start",
									}}
								>
									{option.add_seller_name && !option.id && (
										<div
											key="new-seller"
											style={{ width: "100%", textAlign: "center" }}
											onClick={() => {
												setAddSellerName(option.add_seller_name);
											}}
										>
											Add "{`${option?.add_seller_name}`}"
										</div>
									)}
									{option.id && !option.add_seller_name && (
										<div
											{...props}
											key={option.id}
											style={{
												display: "flex",
												justifyContent: "start",
												width: "100%",
												paddingBottom: "8px",
											}}
										>
											<div>{option.sellerName}</div>
										</div>
									)}
								</div>
							);
							// Regular option
						}}
					/>
				</ThemeProvider>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-start ">
				<Autocomplete
					className="classification-field"
					popupIcon={""}
					onChange={(e, classification) => {
						if (classification) {
							setSearchFilters({ ...searchFilter, type: classification });
							setShowModal(true);
						} else {
							setSearchFilters({ ...searchFilter, type: null });
							setShowModal(false);
						}
					}}
					sx={{
						"& .MuiOutlinedInput-root": {
							padding: "2px",
							borderRadius: "37px",
						},
						"& .MuiAutocomplete-input": {
							paddingLeft: "17px !important",
						},
					}}
					value={searchFilter?.type}
					options={
						sellerTypeArray && sellerTypeArray?.length > 0
							? sellerTypeArray?.map((data) => {
									if (data !== sellerTypeArray[0]) {
										return data;
									}
							  })
							: []
					}
					getOptionLabel={(option) => {
						if (option) {
							return option;
						}
						return "";
					}}
					clearOnBlur={true}
					renderInput={(params) => {
						return (
							<div className="autocomplete-search-inputfield">
								<TextField
									id="autocomplete"
									{...params}
									placeholder="Select Classification"
								/>
							</div>
						);
					}}
				/>
			</div>
			<Modal
				show={showModal}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-center"
				centered
				className="seller-list-Modal"
			>
				<Modal.Header closeButton className="border-0 text-light">
					<h5 className="text-start fw-bold w-100">{searchFilter?.type}</h5>
				</Modal.Header>
				<div className="popup-height" style={{ minHeight: "150px" }}>
					<div className="mb-5">
						{!isAllNonDefaultSellersLoading && searchFilter?.type ? (
							<>
								<Backdrop
									sx={{ color: "#fff", zIndex: 2 }}
									open={!isAllNonDefaultSellersLoading && searchFilter?.type}
								>
									<div
										className="d-flex popup-height justify-content-center align-item-center"
										style={{ minHeight: "150px" }}
									>
										<CircularProgress color="inherit" />
									</div>
								</Backdrop>
							</>
						) : (
							<>
								<div className="row mt-3">
									{data?.records?.length > 0 ? (
										data?.records?.map((item) => {
											return (
												<>
													<div className="col-lg-2 col-md-2" />
													<div
														key={item?.id}
														className="col-lg-10 col-md-10 d-flex"
														onClick={() => dispatch(getSellerId(item.id))}
													>
														<Link
															to={`/seller-balloon/${item.id}`}
															className="text-decoration-none links"
														>
															<span className="fw-bold">
																{item?.sellerName}
															</span>
															{" - "}
															<span style={{ color: "white" }}>
																{item?.categories[0]?.categoryName}
															</span>
														</Link>
													</div>
												</>
											);
										})
									) : (
										<div className="no-seller">
											No seller exist against this classification
										</div>
									)}
								</div>
								<div
									onClick={() => setShowAddASellerModal(true)}
									className="add-new-seller-Parent mb-3"
								>
									<button
										type="button"
										className="btn add-new-button-sellers mt-5"
									>
										Add a Seller to this Classification
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</Modal>
			<AddAsellerModal
				isShow={showAddASellerModal}
				setIsShow={setShowAddASellerModal}
				addSellerName={addSellerName}
				selectedClassification={searchFilter?.type}
				fromOutlier
			/>
		</div>
	);
};
