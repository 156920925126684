import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import {
	// adminUserDeleteResponse,
	// deleteAdminUserRequest,
	getAdminUsers,
	userBlockStatusRequest,
	userBlockStatusResponse,
} from "../../../redux/reducers/ducks/userDuck";
import UpdateUserPopup from "./updateUserPopup";
import moment from "moment";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import { Backdrop, CircularProgress, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserSearch from "./userSearch";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";
import { updateClickedUser } from "../../../redux/reducers/ducks/adminReviewDuck";
import { updateComponentPannel } from "../../../redux/reducers/ducks/adminPannel";

export default function UserMainPage() {
	const [page, setPage] = React.useState(0);
	const [isSubmittedUser, setIsSubmittedUser] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [userName, setUserName] = useState("");
	const {
		isAdminStatus,
		adminReviewLoading,
		// deleteAdminUserResponse,
		userBlock,
	} = useSelector((state) => {
		return {
			isAdminStatus: state?.seller?.isAdminChangeStatus,
			adminReviewLoading: state?.adminReview?.adminReviewLoading,
			deleteAdminUserResponse: state?.adminUser?.deleteAdminUserResponse,
			userBlock: state?.adminUser?.userBlock,
		};
	});
	const [openDialog, setOpenDialog] = useState({
		isOpen: false,
		id: null,
	});

	const dispatch = useDispatch();
	const handleChangePage = (event, newPage) => {
		setIsSubmittedUser(true);
		setPage(newPage);
		dispatch(handleAdminStatus(false));
	};

	const adminUser = useSelector((state) => state?.adminUser?.adminUser);

	const columns = [
		{ id: "name2", label: `#`, minWidth: 100 },
		{ id: "name", label: `Name`, minWidth: 170 },
		{ id: "name1", label: `User Name`, minWidth: 170 },
		{ id: "code1", label: "Email", minWidth: 100 },
		{ id: "code2", label: "Date", minWidth: 130 },
		{ id: "code3", label: "Status", minWidth: 100 },
		{ id: "code4", label: "Block", minWidth: 100 },
		{ id: "code5", label: "Actions", minWidth: 100 },
	];

	useEffect(() => {
		const requestData = {
			page: page + 1,
			username: userName,
			pageSize: rowsPerPage,
		};
		const timeout = setTimeout(() => {
			dispatch(getAdminUsers(requestData));
			setIsSubmittedUser(false);
		}, 2000);

		return () => clearTimeout(timeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, userName]);

	useEffect(() => {
		if (isAdminStatus) {
			const requestData = {
				page: page + 1,
				username: userName,
				pageSize: rowsPerPage,
			};
			dispatch(getAdminUsers(requestData));
			setIsSubmittedUser(false);
		}
	}, [isAdminStatus, page, rowsPerPage]);

	// const handleDelete = (id) => {
	// 	Swal.fire({
	// 		title: "Are you sure?",
	// 		text: "You will not be able to recover this item!",
	// 		showCancelButton: true,
	// 		confirmButtonText: "Yes",
	// 		cancelButtonText: "No",
	// 		reverseButtons: true,
	// 		customClass: {
	// 			title: "mt-4",
	// 			confirmButton: "btn bg-primary",
	// 			cancelButton: "btn btn-primary",
	// 		},
	// 	}).then((result) => {
	// 		if (result.isConfirmed) {
	// 			setIsSubmittedUser(true);

	// 			dispatch(deleteAdminUserRequest(id));
	// 		}
	// 	});
	// };

	const handleChange = (event, Id) => {
		const requestData = {
			userId: Id,
			blockStatus: event.target.value,
		};
		dispatch(userBlockStatusRequest(requestData));
		dispatch(handleAdminStatus(false));
	};

	const handleRowsPerPage = (e) => {
		setPage(0);
		setRowsPerPage(e.target.value);
	};

	const getProperClass = (status) => {
		if (status === "block") return "block-user-color";
		else return "unblock-user-color";
	};

	// useEffect(() => {
	// 	if (deleteAdminUserResponse?.status === 200) {
	// 		getToasterNotifications(deleteAdminUserResponse?.data?.message);
	// 		dispatch(adminUserDeleteResponse(null));
	// 	}
	// 	if (deleteAdminUserResponse?.response?.status === 404) {
	// 		getToasterErrors(deleteAdminUserResponse?.response?.data?.message);
	// 		dispatch(adminUserDeleteResponse(null));
	// 	}
	// }, [deleteAdminUserResponse]);

	useEffect(() => {
		if (userBlock?.response?.status === 401) {
			getToasterErrors(userBlock?.response?.data?.message);
			dispatch(userBlockStatusResponse(null));
		}
		if (userBlock?.status === 200) {
			getToasterNotifications(userBlock?.data?.message);
			dispatch(userBlockStatusResponse(null));
		}
	}, [userBlock]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{(isSubmittedUser || adminReviewLoading) && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 100 }}
					open={isSubmittedUser || adminReviewLoading}
				>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<div className="parent-cat">
						{/* <div> {pageName} Portal</div> */}
					</div>
					<UserSearch
						isUserName={userName}
						setIsUserName={setUserName}
						setPage={setPage}
					/>
					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{adminUser?.records?.length > 0 ? (
										adminUser?.records?.map((user, index) => {
											const date = moment?.utc(user?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");
											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={user?.id}
												>
													<TableCell className="ps-3">
														{index + 1 + page * 10}
													</TableCell>
													<TableCell>{user.name}</TableCell>
													<TableCell
														onClick={() => {
															dispatch(updateClickedUser(user.id));
															dispatch(
																updateComponentPannel("Balloons/Reviews")
															);
														}}
														className="user-panel-cursor"
													>
														{user.username}
													</TableCell>
													<TableCell>{user.email}</TableCell>
													<TableCell>{formattedDate}</TableCell>
													<TableCell>{user?.status}</TableCell>
													<TableCell>
														<FormControl sx={{ minWidth: 120 }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={user?.blockStatus}
																onChange={(e) => {
																	handleChange(e, user.id);
																}}
																className={`selectStatuses  ${getProperClass(
																	user?.blockStatus
																)}`}
																placeholder="Select status"
															>
																<MenuItem value="block">Block</MenuItem>
																<MenuItem value="unblock">Unblock</MenuItem>
															</Select>
														</FormControl>
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														{/* <DeleteIcon
															onClick={() => handleDelete(user.id)}
															className="text-danger user-panel-cursor iconMargin"
														/>{" "} */}
														<EditIcon
															onClick={() => {
																setOpenDialog({
																	isOpen: true,
																	id: user.id,
																	name: user.name,
																	email: user.email,
																	password: user.password,
																});
																setIsSubmittedUser(true);
															}}
															className="mx-1 admin-user-edit user-panel-cursor text-primary iconMargin"
														/>{" "}
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No User exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{adminUser?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									count={adminUser?.totalRecords}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
						<UpdateUserPopup
							setIsSubmittedUser={setIsSubmittedUser}
							showPopup={openDialog}
							setShowPopup={setOpenDialog}
							page={page}
						/>
					</Paper>
				</Card>
			</div>
		</div>
	);
}
