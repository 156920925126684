import React from "react";
import { Link } from "react-router-dom";
import "./childHeader.css";

function ChildHeader() {
	return (
		<>
			<div className="container-fluid header shadow">
				<Link to="/sign-up">
					<img
						src="/icons/logo2.png"
						className="p-1 child-header-logo mx-2"
						alt="logo"
					/>
				</Link>
				<Link to="" className="mx-4 child-beta-link">
					Beta
				</Link>
			</div>
		</>
	);
}

export default ChildHeader;
