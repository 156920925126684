import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/parentLayout/Layout";
import "./sellerProfile.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";

function SellerProfile() {
	const { sellerName, sellerUrl } = useSelector((state) => {
		return {
			sellerName: state?.seller?.singleSellerData?.data?.seller?.sellerName,
			sellerUrl: state?.seller?.singleSellerData?.data?.seller?.sellerUrl,
		};
	});

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	function replaceWwwWithHttps(url) {
		// Check if "https" is not already present in the URL
		if (
			url?.indexOf("https://") === -1 &&
			url?.includes("www") &&
			!url?.includes("https")
		) {
			// Replace "www" with "https"
			url = url?.replace("www.", "https://");
		} else if (!url?.includes("https")) {
			url = "https://" + url;
		}
		return url;
	}

	return (
		<Layout>
			<div className="seller-profiles-parent-comp">
				<div
					className="text-decoration-none seller-profiles-link d-flex align-items-center"
					rel="noreferrer"
				>
					{/* <button className="back-btn">
						<FontAwesomeIcon icon={faArrowLeft} />
						Back
					</button> */}
					<div className="ms-3">
						Seller Profile coming soon! In the meantime, you can visit the
						Seller's website here:
					</div>
				</div>
				<div className="seller-profile-page-container"></div>
				<div className="container">
					<div>
						<div className="text-center">
							<h1 className=" seller-profile-url-link text-center mb-md-4 mb-2 view-profile-link">
								<a
									href={replaceWwwWithHttps(sellerUrl)}
									target="_blank"
									className="seller-link-color"
									rel="noreferrer"
								>
									{sellerName}
								</a>
							</h1>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default SellerProfile;
