import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Beta from "./pages/beta/betaTest";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Seller from "./pages/sellerDetails/seller";
import LoveSeller from "./pages/loveSeller/loveSeller";
import ToTell from "./pages/tellStory/tellStory";
import SellerBalloon from "./pages/sellerBalloon/sellerBalloon";
import SellerProfile from "./pages/sellerProfile/sellerProfile";
import ResetPassword from "./pages/resetPassowrd/resetPassword";
import Signup from "./pages/auth/signup/signupForm";
import Login from "./pages/auth/loginIn/loginForm";
import Thanks from "./pages/thankYou/thankYou";
import ContactUs from "./pages/contactUs/contactUs";
import AboutUs from "./pages/aboutUs/aboutUs";
import SellersInstructions from "./pages/sellersInstructions/sellersInstructions";
import Privacy from "./pages/privacy/privacy";
import Guidelines from "./pages/guidelines/guidelines";
import AirSeller from "./pages/airSeller/airSeller";
import EmailThankYou from "./pages/emailThankYou/emailThankYou";
import EmailError from "./pages/emailError/emailError";
import InvestorInstructions from "./pages/investorInstructions/investorInstructions";
import BestWriterAndSeller from "./pages/bestWriterAndSeller/bestWriterAndSeller";
import ViewSellerProfile from "./pages/viewSellerProfile/viewSellerProfile";
import ForgotPassword from "./pages/auth/forgetPassword/forgotPassword";
// import WinATShirt from "./pages/winATShirt/winATShirt";
import ChangePassword from "./pages/auth/changePassword/changePassword";
import AdminPanel from "./pages/admin/adminPanel/adminPanel";
import AdminLogin from "./pages/admin/adminAuth/adminLogin";
import { EmailVerification } from "./pages/emailVarification/emailVarification";
import { ChangePasswordVarifacation } from "./pages/passwordVarification/changePasswordVarifacation";
import { UpdatePassword } from "./pages/auth/updatePassword/updatePassword";
import { ErrorPage } from "./pages/404Page/errorPage";
import ReactGA from "react-ga";
import { Outlier } from "./pages/outlier/Outlier";
import {
	loginResponse,
	refreshTokenResponse,
	updateLoginUser,
	updateToken,
} from "./redux/reducers/ducks/autorizeDuck";
import { useDispatch, useSelector } from "react-redux";
import { About } from "./pages/about/about";
import TalentContestPage from "./pages/talentContest/talentContest";
import Profile from "./pages/profile/profile";
// import { NominatedStory } from "./pages/nomatedStories/nomatedStories";

function App() {
	const dispatch = useDispatch();

	const { refreshToken } = useSelector((state) => ({
		refreshToken: state.authorization?.refreshToken,
	}));

	useEffect(() => {
		ReactGA.initialize("G-LDNY1DMP0S");
		ReactGA.pageview(window.location.pathname);
		ReactGA.event({
			category: "Visitor",
			action: "Unique Visit",
		});
	}, []);

	useEffect(() => {
		async function refresh() {
			try {
				dispatch(refreshTokenResponse());
			} catch (error) {
				return error;
			}
		}
		// Refresh the token every hour
		const intervalId = setInterval(() => {
			// let token = localStorage.getItem("token");
			// let adminToken = localStorage.getItem("adminToken");

			// if (token) {
			// 	localStorage.setItem("token", "");
			// 	window.location.href = "/loginUser";
			// } else if (adminToken) {
			// 	localStorage.setItem("adminToken", "");
			// 	window.location.href = "/admin";
			// }
			refresh();
		}, 1000 * 29 * 30);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		if (refreshToken?.response?.status === 401) {
			localStorage.setItem("adminToken", "");
			localStorage.setItem("token", "");
			dispatch(loginResponse());
			dispatch(updateLoginUser(null));
			dispatch(updateToken(null));
		}
	}, [refreshToken]);

	let isTestServer = JSON.parse(process.env.REACT_APP_IS_TEST_SERVER);

	useEffect(() => {
		if (
			isTestServer &&
			(window.location.pathname === "/staging" ||
				window.location.pathname === "/" ||
				window.location.pathname === "")
		) {
			window.location.href = "/staging/";
		}
	}, [window, isTestServer]);

	return (
		<BrowserRouter basename={isTestServer ? "/staging/" : "/"}>
			<Routes>
				<Route exact path="/" element={<Seller />} />
				<Route exact path="admin/admin-portal" element={<AdminPanel />} />
				<Route exact path="admin/login" element={<AdminLogin />} />
				<Route exact path="admin" element={<AdminLogin />} />
				<Route exact path="home" element={<Seller />} />
				<Route exact path="sign-up" element={<Signup />} />
				<Route exact path="loginUser" element={<Login />} />
				<Route exact path="login" element={<EmailVerification />} />
				<Route
					exact
					path="change-password"
					element={<ChangePasswordVarifacation />}
				/>
				<Route exact path="forgot-password" element={<ForgotPassword />} />
				<Route exact path="password-change" element={<ChangePassword />} />
				<Route exact path="update-password" element={<UpdatePassword />} />
				<Route exact path="thanks" element={<Thanks />} />
				<Route exact path="outlier" element={<Outlier />} />
				<Route exact path="beta" element={<Beta />} />
				<Route exact path="to-love" element={<LoveSeller />} />
				<Route exact path="to-air" element={<AirSeller />} />
				<Route exact path="to-tell" element={<ToTell />} />
				<Route exact path="contact-us" element={<ContactUs />} />
				<Route exact path="about-us" element={<AboutUs />} />
				<Route exact path="privacy" element={<Privacy />} />
				<Route exact path="profile" element={<Profile />} />
				<Route exact path="guideline" element={<Guidelines />} />
				<Route
					exact
					path="talent-competition"
					element={<TalentContestPage />}
				/>
				{/* <Route exact path="best-writers-sellers" element={<WinATShirt />} /> */}
				<Route
					exact
					path="seller-instructions"
					element={<SellersInstructions />}
				/>
				<Route
					exact
					path="investor-instructions"
					element={<InvestorInstructions />}
				/>
				<Route
					exact
					path="best-writers-sellers"
					element={<BestWriterAndSeller />}
				/>
				<Route exact path="seller-balloon/:id" element={<SellerBalloon />} />
				<Route
					exact
					path="seller-balloon/:id/seller-profile"
					element={<SellerProfile />}
				/>
				<Route exact path="reset-password" element={<ResetPassword />} />
				<Route
					exact
					path="email-verification-success"
					element={<EmailThankYou />}
				/>
				<Route exact path="email-verification-error" element={<EmailError />} />
				<Route
					exact
					path="view-seller-profile"
					element={<ViewSellerProfile />}
				/>
				<Route exact path="about" element={<About />} />
				<Route exact path="win-t-shirt" element={<ViewSellerProfile />} />
				<Route path={"*" || "/"} element={<ErrorPage />} />
				{/* <Route exact path="best-writer-story" element={<NominatedStory />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
