import React from "react";
import { Modal } from "react-bootstrap";

function ReputableModal({ isReputable, setIsReputable }) {
	const handleClose = () => setIsReputable(false);
	return (
		<div>
			<Modal
				show={isReputable}
				onHide={handleClose}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<h4>Reputable Sellers are known for:</h4>
				</Modal.Header>
				<Modal.Body className="text-justify">
					<ul>
						<li>
							having reachable customer service reps, or others, in positions to
							solve customers' problems quickly
						</li>
						<li>
							giving, doing, providing or delivering what they say they will
							give, do, provide or deliver
						</li>
						<li>
							adhering to the terms and total cost they lead you to believe are
							the terms and total cost
						</li>
						<li>
							conveying the straight-up truth in their advertisements, special
							offers, discounts and promotions
						</li>
						<li>
							making their Buyers feel satisfied or happy, even occasionally
							making them feel blissful
						</li>
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="close_btn border-0 bg-dark text-white p-2 w-25"
						onClick={() => {
							setIsReputable(false);
						}}
					>
						close
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default ReputableModal;
