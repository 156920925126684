import axios from "./Axios";

export const getAllSellers = async () => {
	try {
		let res = await axios.get("sellers/all-sellers", {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const addSellersApi = async (data) => {
	try {
		let token = localStorage.getItem("token");
		let res = await axios.post("sellers/add", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getAllBalloon = async () => {
	try {
		let res = await axios.get("review/all-titles", {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getCountById = async (id) => {
	try {
		let res = await axios.get(`review/count/${id}`, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getReviewApi = async (payload) => {
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
	try {
		let res = await axios.get(`review/seller_id`, {
			withCredentials: true,
			params: {
				seller_id: payload.sellerId,
				...(payload.titleId ? { tittle_id: payload.titleId } : {}),
				...(payload.reviewTypes ? { type: payload.reviewTypes } : {}),
				...(payload.categoryId ? { category_id: payload.categoryId } : {}),
				page: payload.page,
				timeZone: timeZone,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getUserReviewAgainstSellerApi = async (payload) => {
	try {
		let res = await axios.get(`review/users_reviews/seller`, {
			withCredentials: true,
			params: {
				seller_id: payload.sellerId,
				...(payload.userId ? { user_id: payload.userId } : {}),
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const editCommitApi = async (data) => {
	let token = localStorage.getItem("token");

	try {
		return axios.patch("review/update", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const submitReviewApi = async (data) => {
	let token = localStorage.getItem("token");

	try {
		return axios.post("review/submit", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const submitLikeDislikeApi = async (data) => {
	let token = localStorage.getItem("token");

	try {
		return axios.post("review/likeDislike", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const getCategoryLatestReview = async (id) => {
	try {
		let res = await axios.get(`categories/${id}`, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const updateSellerStatus = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");
		await axios.patch("/admin/sellers/update/status", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const updateSellersData = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");

		let res = await axios.patch("admin/sellers/update", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const createAdminSeller = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");

		let res = await axios.post("admin/sellers/create", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const deleteAdminSeller = async (Id) => {
	try {
		let token = localStorage.getItem("adminToken");

		return await axios.delete("admin/sellers/delete", {
			withCredentials: true,
			params: {
				id: Id,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const getAdminAllSellers = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");

		return await axios.get("admin/Seller_search", {
			withCredentials: true,
			params: data,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const getSellerById = async (id) => {
	try {
		return await axios.get(`sellers/id/${id}`, {
			withCredentials: true,
		});
	} catch (err) {
		return err;
	}
};

export const allNonDefaultSellersApi = async (payload) => {
	try {
		return await axios.get(`sellers/other-sellers`, {
			withCredentials: true,
			params: {
				page: payload.page,
				pageSize: payload.pageSize,
				type: payload.type,
				sellerName: payload.sellerName,
				country: payload.country,
				state: payload.state,
			},
		});
	} catch (err) {
		return err;
	}
};

export const submitMultipleReviewApi = async (data) => {
	let token = localStorage.getItem("token");

	try {
		return axios.post("review/submit/multiple", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const verifyReviewApi = async (payload) => {
	try {
		return axios.get("review/verify/status", {
			withCredentials: true,
			params: {
				userId: payload.userId,
				sellerId: payload.sellerId,
			},
		});
	} catch (err) {
		return err;
	}
};

export const noReviewSellersApi = async (payload) => {
	try {
		return axios.get("review/userID", {
			withCredentials: true,
			params: {
				userID: payload,
			},
		});
	} catch (err) {
		return err;
	}
};

export const alreadyWinnerReviewApi = async () => {
	try {
		return axios.get("review/bestWritter/nominate", {
			withCredentials: true,
		});
	} catch (err) {
		return err;
	}
};

export const categoryNoReviewSellerApi = async (payload) => {
	try {
		return axios.get("review/get", {
			withCredentials: true,
			params: {
				userId: payload.userId,
				sellerId: payload.sellerId,
			},
		});
	} catch (err) {
		return err;
	}
};

export const latestSellerReviewApi = async (id) => {
	try {
		let res = await axios.get(`sellers/${id}`, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const sellersTypeWiseApi = async (payload) => {
	try {
		let res = await axios.get(`categories/sellerType`, {
			withCredentials: true,
			params: payload,
		});
		return res;
	} catch (err) {
		return err;
	}
};
