import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	signUpUser: "",
	accessToken: "",
	updateToken: "",
	changePass: "",
	isSignup: false,
	isLogin: false,
	isForgot: false,
	isChange: false,
	refreshToken: "",
	tokenActivation: "",
	tokenVarification: "",
	resetPayload: "",
	loginResponseData: null,
};

const authorizeSlice = createSlice({
	name: "autorization",
	initialState: INITIAL_STATE,
	reducers: {
		signUpRequest: (state) => state,
		loginRequest: (state) => state,
		forgotRequest: (state) => state,
		changeRequest: (state) => state,
		refreshTokenResponse: (state) => state,
		VarifiedPassword: (state) => state,

		updateUser(state, { payload }) {
			return {
				...state,
				signUpUser: payload,
			};
		},

		updateLoginUser(state, { payload }) {
			return {
				...state,
				accessToken: payload,
			};
		},
		updatePasswordToken(state, { payload }) {
			return {
				...state,
				updateToken: payload,
			};
		},
		updatePassword(state, { payload }) {
			return {
				...state,
				changePass: payload,
			};
		},
		updateIsSignup(state, { payload }) {
			return {
				...state,
				isSignup: payload,
			};
		},
		updateIsLogin(state, { payload }) {
			return {
				...state,
				isLogin: payload,
			};
		},
		updateIsForgot(state, { payload }) {
			return {
				...state,
				isForgot: payload,
			};
		},
		updateIsChange(state, { payload }) {
			return {
				...state,
				isChange: payload,
			};
		},
		updateToken(state, { payload }) {
			return {
				...state,
				refreshToken: payload,
			};
		},
		updateTokenActivation(state, { payload }) {
			return {
				...state,
				tokenActivation: payload,
			};
		},
		updateTokenVarification(state, { payload }) {
			return {
				...state,
				tokenVarification: payload,
			};
		},
		resetPasswordPayload(state, { payload }) {
			return {
				...state,
				resetPayload: payload,
			};
		},
		loginResponse(state, { payload }) {
			return {
				...state,
				loginResponseData: payload,
			};
		},
	},
});

export const {
	loginResponse,
	loginRequest,
	updateLoginUser,
	updateIsLogin,
	signUpRequest,
	updateUser,
	forgotRequest,
	updateIsForgot,
	updateIsSignup,
	updatePasswordToken,
	changeRequest,
	updateIsChange,
	updatePassword,
	updateToken,
	refreshTokenResponse,
	updateTokenActivation,
	updateTokenVarification,
	VarifiedPassword,
	resetPasswordPayload,
} = authorizeSlice.actions;

export default authorizeSlice.reducer;
