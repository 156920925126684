import React from "react";
import Layout from "../../components/layout/parentLayout/Layout";

function InvestorInstructions() {
	return (
		<Layout>
			{/* heading section  */}
			<div className="container about-wrapper-container p-4">
				<img
					src="/icons/logo1.png"
					className="mx-auto mt-3 d-block contact-image-width"
					alt="logo"
				/>
				<div className="row d-flex mt-4 justify-content-center">
					<h1 className="text-center  fw-normal guidline-headings">
						Welcome, Investors!
					</h1>
					<p className="mt-4 mb-4 text-center">
						Love to Air is a Network Effects startup located in Santa Monica,
						CA. If you’d like to know more, please contact the founder at{" "}
						<span
							className="air-seller-cursor"
							onClick={() =>
								(window.location = "mailto:stephanie@lovetoair.com")
							}
						>
							stephanie@lovetoair.com
						</span>
						.
					</p>
				</div>
			</div>
		</Layout>
	);
}

export default InvestorInstructions;
