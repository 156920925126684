import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	contactUser: "",
	allContacts: null,
	reportBugData: null,
	adminAllBugReportData: null,
};

const contactSlice = createSlice({
	name: "contactUs",
	initialState: INITIAL_STATE,
	reducers: {
		contactRequest: (state) => state,
		reportBugRequest: (state) => state,
		adminAllContactRequest: (state) => state,
		adminAllBugReportRequest: (state) => state,
		adminAllBugReportStatusRequest: (state) => state,
		adminContactStatusRequest: (state) => state,
		updateContact(state, { payload }) {
			return {
				...state,
				contactUser: payload,
			};
		},
		adminAllContactResponse(state, { payload }) {
			return {
				...state,
				allContacts: payload,
			};
		},
		reportBugResponse(state, { payload }) {
			return {
				...state,
				reportBugData: payload,
			};
		},
		adminAllBugReportResponse(state, { payload }) {
			return {
				...state,
				adminAllBugReportData: payload,
			};
		},
	},
});

export const {
	adminContactStatusRequest,
	contactRequest,
	adminAllContactResponse,
	adminAllContactRequest,
	updateContact,
	reportBugRequest,
	reportBugResponse,
	adminAllBugReportRequest,
	adminAllBugReportResponse,
	adminAllBugReportStatusRequest,
} = contactSlice.actions;

export default contactSlice.reducer;
