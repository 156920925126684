import Dialog from "@mui/material/Dialog";
import "./sellerPage.css";
import { useDispatch, useSelector } from "react-redux";
import {
	createAdminSellerRequest,
	createAdminSellerResponse,
	googleAPIRequest,
	updateAdminSellerResponse,
	updateSellerData,
} from "../../../redux/reducers/ducks/getSellersDuck";
import { useEffect, useState } from "react";
import { getCategory } from "../../../redux/reducers/ducks/categoriesDuck";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, CircularProgress, DialogTitle } from "@mui/material";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { sellerTypeArray } from "../../../utils/addSellerHelper";
import { getToasterErrors } from "../../../utils/getToasterErrors";

export const CreateSellerModal = (props) => {
	const { setOpenDialog, openDialog, setIsChangeSellerData } = props;
	const [isSellerUrl, setIsSellerUrl] = useState(true);
	const [selectedGoogleAddress, setSelectedGoogleAddress] = useState(null);
	const [googleAddress, setGoogleAddress] = useState("");
	const {
		createAdminSellerData,
		categoryData,
		updateAdminResponse,
		googlePlaceResponse,
	} = useSelector((state) => {
		return {
			createAdminSellerData: state?.seller?.createAdminSellerData,
			categoryData: state?.category?.category?.data?.records,
			updateAdminResponse: state?.seller?.adminSellerResponse,
			googlePlaceResponse: state?.seller?.googlePlaceResponse,
		};
	});

	const [isSellerSubmit, setIsSellerSubmit] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (openDialog.isOpen) {
				dispatch(googleAPIRequest(googleAddress));
			}
		}, 1000);

		return () => clearTimeout(timeout);
	}, [googleAddress, openDialog]);

	const [isLoadingSellerData, setIsLoadingSellerData] = useState(false);
	const handleClose = () => {
		setOpenDialog({ openDialog: false });
		setError("");
		setIsChangeSellerData(false);
		setCategory("");
		setStatus("");
		setSelectedGoogleAddress(null);
		setClassificationType(null);
		setIsSellerSubmit(false);
	};

	const dispatch = useDispatch();

	const [status, setStatus] = useState("pending");
	const [classificationType, setClassificationType] = useState(
		sellerTypeArray[0]
	);
	const [category, setCategory] = useState("");
	const [error, setError] = useState(null);

	function isValidURL(url) {
		const urlRegex = /\./;
		return urlRegex.test(url);
	}

	const capitalizeFirstLetter = (str) => {
		return str
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};

	const handleSubmitSeller = (e) => {
		setIsSellerSubmit(true);
		if (
			classificationType &&
			classificationType !== sellerTypeArray[0] &&
			!selectedGoogleAddress &&
			!openDialog.id
		) {
			e.preventDefault();

			return;
		}
		if (!category && !openDialog.id) {
			e.preventDefault();
			setError("Please select a category");
			setOpenDialog({
				...openDialog,
				isOpen: true,
			});
		} else {
			const payloadData = {
				id: openDialog.id,
				sellerName: capitalizeFirstLetter(openDialog.sellerName),
				sellerUrl: openDialog.sellerUrl,
				updatedcategory: [category?.id],
			};
			if (!isValidURL(openDialog.sellerUrl)) {
				e.preventDefault();
				setIsSellerUrl(false);
			}
			if (
				openDialog.id &&
				openDialog.sellerName &&
				openDialog.sellerUrl &&
				isValidURL(openDialog.sellerUrl)
			) {
				e.preventDefault();
				dispatch(updateSellerData(payloadData));
				setIsChangeSellerData(true);
				setIsLoadingSellerData(true);
			} else {
				const postPayload = {
					sellerName: capitalizeFirstLetter(openDialog.sellerName),
					sellerUrl: openDialog.sellerUrl,
					approvedByAdmin: status ? status : "pending",
					isListing: true,
					categories: [category],
					type: classificationType,
					address: selectedGoogleAddress?.description,
				};

				if (
					openDialog.sellerName &&
					openDialog.sellerUrl &&
					isValidURL(openDialog.sellerUrl)
				) {
					e.preventDefault();
					dispatch(createAdminSellerRequest(postPayload));
					setIsLoadingSellerData(true);
					setIsChangeSellerData(true);
				}
			}
		}
	};

	useEffect(() => {
		if (createAdminSellerData?.response?.status === 500) {
			getToasterErrors(
				"Seller name or URL already exists. Please enter a unique name and URL."
			);
			dispatch(createAdminSellerResponse(null));
			setIsLoadingSellerData(false);
			setOpenDialog({
				...openDialog,
				isOpen: true,
			});
			setIsSellerSubmit(false);
		} else if (createAdminSellerData?.response?.status === 409) {
			getToasterErrors(createAdminSellerData?.response?.data?.message);
			dispatch(createAdminSellerResponse(null));
			setIsLoadingSellerData(false);
			setOpenDialog({
				...openDialog,
				isOpen: true,
			});
			setIsSellerSubmit(false);
		}
		if (
			createAdminSellerData?.status === 201 ||
			createAdminSellerData?.status === 200
		) {
			dispatch(createAdminSellerResponse(null));
			setIsLoadingSellerData(false);
			setOpenDialog({
				...openDialog,
				isOpen: false,
			});
			setCategory("");
			setStatus("");
			setSelectedGoogleAddress(null);
			setClassificationType(null);
			setIsSellerSubmit(false);
		}
	}, [createAdminSellerData]);

	useEffect(() => {
		if (updateAdminResponse?.status === 200) {
			getToasterNotifications(updateAdminResponse?.data?.message);
			dispatch(updateAdminSellerResponse(null));
			setIsChangeSellerData(true);
		}
	}, [updateAdminResponse]);

	useEffect(() => {
		setClassificationType(sellerTypeArray[0]);
		setCategory(openDialog.previousCategory);
	}, [openDialog]);
	useEffect(() => {
		dispatch(getCategory());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function BootstrapDialogTitle(props) {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	}

	return (
		<Dialog
			open={openDialog.isOpen}
			onClose={handleClose}
			fullWidth
			aria-labelledby="draggable-dialog-title"
		>
			{isLoadingSellerData && (
				<Backdrop sx={{ color: "#fff", zIndex: 2 }} open={isLoadingSellerData}>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<BootstrapDialogTitle
				id="customized-dialog-title"
				onClose={handleClose}
			/>
			<div className="d-flex justify-content-center">
				<div className="rounded-4 w-75 mb-3 mt-1">
					<form>
						<h5 className="text-center mt-3 fw-bold">
							{openDialog.isSellerCreate ? "Add Seller" : "Edit Seller"}
						</h5>

						<div className="p-3  text-center">
							<input
								className="inputField w-100 text-center admin-seller-text-field"
								value={openDialog.sellerName}
								required
								placeholder="Enter Seller Name"
								onChange={(event) => {
									setOpenDialog({
										...openDialog,
										sellerName: event.target.value,
									});
								}}
							/>
							{!openDialog.sellerName && isSellerSubmit && (
								<span className="text-danger">Seller Name required</span>
							)}
							<input
								className="inputField w-100 mt-3 text-center admin-seller-text-field"
								value={openDialog.sellerUrl}
								required
								placeholder="Enter Seller URL"
								onChange={(event) => {
									setIsSellerUrl(true);
									setOpenDialog({
										...openDialog,
										sellerUrl: event.target.value,
									});
								}}
							/>
							{!isSellerUrl && openDialog.sellerUrl && isSellerSubmit && (
								<span className="text-danger">Seller Url not valid</span>
							)}
							{!openDialog.sellerUrl && isSellerSubmit && (
								<span className="text-danger">Seller Url required</span>
							)}
							{!openDialog.isSellerCreate && (
								<div className="d-flex justify-content-center">
									<Autocomplete
										id="free-solo-2-demo"
										className="auto-category-seller mt-3 text-center"
										popupIcon={""}
										value={category ? category : openDialog?.previousCategory}
										onChange={(e, category) => {
											setCategory(category);
											setError("");
										}}
										sx={{
											".MuiOutlinedInput-root": {
												padding: "5px",
											},
										}}
										options={
											categoryData && categoryData?.length > 0
												? categoryData
												: []
										}
										renderOption={(props, option) => {
											return <div {...props}>{option?.categoryName}</div>;
										}}
										getOptionLabel={(option) => {
											if (option && typeof option?.categoryName === "string") {
												return option?.categoryName + ` (${option?.type})`;
											}
											return "";
										}}
										clearOnBlur={true}
										rules={{ required: true }}
										renderInput={(params) => {
											return (
												// created the inputfield for searching
												<div className="autocomplete-search-inputfield">
													<TextField
														className="mb-3 text-center"
														id="autocomplete"
														sx={{
															"#free-solo-2-demo": {
																textAlign: "center",
															},
														}}
														{...params}
														placeholder="Select Category"
													/>
												</div>
											);
										}}
									/>
								</div>
							)}
							{openDialog.isSellerCreate && (
								<>
									<div className="d-flex justify-content-center mt-3 ">
										<select
											className="w-100 text-center admin-seller-select"
											aria-label="Default select example"
											onChange={(e) => setStatus(e.target.value)}
										>
											<option selected className="d-none">
												Add Status
											</option>
											<option value="pending">Pending</option>
											<option value="rejected">Reject</option>
											<option value="approved">Approve</option>
										</select>
									</div>
									<div className="d-flex justify-content-center mt-3 ">
										<select
											className="w-100 text-center admin-seller-select"
											aria-label="Default select example"
											onChange={(e) => setClassificationType(e.target.value)}
										>
											<option selected className="d-none">
												Add Seller Classification
											</option>
											{sellerTypeArray.map((sellerType) => (
												<option value={sellerType} key={sellerType}>
													{sellerType}
												</option>
											))}
										</select>
									</div>
									<div className="d-flex justify-content-center">
										<Autocomplete
											id="free-solo-2-demo"
											className="auto-category-seller mt-3 text-center"
											popupIcon={""}
											onChange={(e, category) => {
												setCategory(category?.id);
												setError("");
											}}
											sx={{
												".MuiOutlinedInput-root": {
													padding: "5px",
												},
											}}
											options={
												categoryData && categoryData?.length > 0
													? categoryData
													: []
											}
											getOptionLabel={(option) => {
												if (
													option &&
													typeof option?.categoryName === "string"
												) {
													if (option?.categoryName === "Other") {
														return option?.categoryName;
													} else if (option?.categoryName === "Other B2B") {
														return (
															option?.categoryName?.replace("B2B", "").trim() +
															` (${option?.type})`
														);
													} else {
														return option?.categoryName + ` (${option?.type})`;
													}
												}
												return "";
											}}
											clearOnBlur={true}
											rules={{ required: true }}
											renderInput={(params) => {
												return (
													// created the inputfield for searching
													<div className="autocomplete-search-inputfield">
														<TextField
															className="mb-3 text-center"
															id="autocomplete"
															sx={{
																"#free-solo-2-demo": {
																	textAlign: "center",
																},
															}}
															{...params}
															placeholder="Select Category"
														/>
													</div>
												);
											}}
										/>
									</div>
									{!category && <p className="text-danger">{error}</p>}
								</>
							)}
							{openDialog.isSellerCreate &&
								classificationType &&
								classificationType !== sellerTypeArray[0] && (
									<div className="d-flex justify-content-center">
										<Autocomplete
											id="free-solo-2-demo"
											className="auto-category-seller-select mt-3 text-center"
											popupIcon={""}
											value={selectedGoogleAddress}
											sx={{
												".MuiOutlinedInput-root": {
													padding: "5px",
												},
											}}
											options={
												googlePlaceResponse &&
												googlePlaceResponse?.predictions?.length > 0
													? googlePlaceResponse?.predictions
													: []
											}
											getOptionLabel={(option) => {
												// Regular option
												return option?.description;
											}}
											onChange={(e, cat) => {
												setSelectedGoogleAddress(cat);
											}}
											clearOnBlur={true}
											rules={{ required: true }}
											renderInput={(params) => {
												return (
													// created the inputfield for searching
													<div className="autocomplete-search-inputfield">
														<TextField
															className="mb-5 text-center"
															id="autocomplete"
															sx={{
																"#free-solo-2-demo": {
																	textAlign: "center",
																},
															}}
															onChange={(event) => {
																setGoogleAddress(event.target.value);
															}}
															{...params}
															placeholder="Select City"
														/>
													</div>
												);
											}}
										/>
									</div>
								)}
							{classificationType &&
								classificationType !== sellerTypeArray[0] &&
								!selectedGoogleAddress &&
								isSellerSubmit && (
									<p className="text-danger text-center">City required</p>
								)}
						</div>

						<div className="text-center mb-3">
							<button
								type="submit"
								onClick={handleSubmitSeller}
								className="btn  seller-submit-button px-5 py-2"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</Dialog>
	);
};
