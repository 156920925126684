import axios from "./Axios";

export const getAllReviews = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/review/search", {
			params: data,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const reviewStatusApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.patch("admin/review/update/status", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const bestWriterApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.patch("admin/review/update/bestWriter/status", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const emailSentApi = async (data) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.post("general/email/sent", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const sendBestWriterEmail = async (data) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.post("admin/review/sendEmail/bestWriter", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const winnerDateApi = async (data) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.patch("admin/review/update/bestWriter/winningDate", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const getAllSellersInReview = async () => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/sellers/all_sellers", {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const deleteReviewApi = async (Id) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.delete("admin/review/delete", {
			withCredentials: true,
			params: {
				id: Id,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const progressDate = async (data) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.get("admin/user/details/count", {
			params: {
				start: data.startDate,
				end: data.endDate,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
