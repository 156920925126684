import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import {
	addSellersApi,
	allNonDefaultSellersApi,
	alreadyWinnerReviewApi,
	categoryNoReviewSellerApi,
	createAdminSeller,
	deleteAdminSeller,
	editCommitApi,
	getAdminAllSellers,
	getAllBalloon,
	getAllSellers,
	getCountById,
	getReviewApi,
	getSellerById,
	getUserReviewAgainstSellerApi,
	latestSellerReviewApi,
	noReviewSellersApi,
	sellersTypeWiseApi,
	submitLikeDislikeApi,
	submitMultipleReviewApi,
	submitReviewApi,
	updateSellersData,
	updateSellerStatus,
	verifyReviewApi,
} from "../../../api/sellersApi";
import {
	addSellerRequest,
	getBalloon,
	getSingleSellerID,
	getSellers,
	updateBalloon,
	getSingleSellerData,
	updateNewSeller,
	updateSeller,
	updateReviews,
	getReview,
	editCommit,
	submitReview,
	updateReviewSubmitStatus,
	reviewUpdateResponse,
	updateIsLoadingReviewData,
	submitLikeDislike,
	addSellerLoading,
	updateSellerAdmin,
	handleAdminStatus,
	updateSellerData,
	createAdminSellerRequest,
	deleteAdminSellerRequest,
	getAllAdminSellers,
	getSellersByID,
	updateSellerById,
	updateIsSingleSellerData,
	handleNominateStatus,
	createAdminSellerResponse,
	updateAdminSellerResponse,
	deleteAdminSellerResponse,
	editReviewResponse,
	likeDislikeReviewResponse,
	allNonDefaultSellersRequest,
	allNonDefaultSellersResponse,
	verifyReviewRequest,
	submitMultipleReviwRequest,
	allNonDefaultSellersLoading,
	googleAPIRequest,
	googleApiResponse,
	noReviewSellersRequest,
	noReviewSellersResponse,
	categoryNoReviewSellerRequest,
	categoryNoReviewSellerResponse,
	sellerLatestReviewRequest,
	sellerTypeWiseResponse,
	sellerTypeWiseRequest,
	userReviewsRequest,
	userReviewResponse,
	alreadyWinnerDataResponse,
	alreadyWinnerDataRequest,
} from "../../reducers/ducks/getSellersDuck";
import {
	adminReviewLoadingResponse,
	updateReviewCurrentPage,
	verifyReviewResponse,
} from "../../reducers/ducks/adminReviewDuck";
import {
	getCustomerReviewLoader,
	latestReviewData,
	updateIndividualCategoryIdLoading,
} from "../../reducers/ducks/categoriesDuck";
import { googlePlaceApi } from "../../../api/googlePlaceApi";

export function* getSellerData({ payload }) {
	try {
		const data = yield call(getAllSellers, payload);
		yield put(updateSeller(data));
	} catch (error) {
		yield put(updateSeller(error));
		return error;
	}
}

export function* addSellers({ payload }) {
	try {
		yield put(addSellerLoading(false));
		const data = yield call(addSellersApi, payload);
		yield put(updateNewSeller(data));
		yield call(getSellerData(null));
	} catch (err) {
		return err;
	} finally {
		yield put(addSellerLoading(true));
	}
}

export function* getBalloons() {
	try {
		const data = yield call(getAllBalloon);
		yield put(updateBalloon(data));
	} catch (err) {
		return err;
	}
}

export function* getCountValue({ payload }) {
	try {
		const data = yield call(getCountById, payload);
		yield put(getSingleSellerData(data));
	} catch (err) {
		return err;
	} finally {
		yield put(updateIsLoadingReviewData(false));
	}
}

export function* getCustomerReview({ payload }) {
	try {
		yield put(getCustomerReviewLoader(true));
		const data = yield call(getReviewApi, payload);
		yield put(updateReviews(data));
	} catch (err) {
		yield put(getCustomerReviewLoader(false));
		return err;
	} finally {
		yield put(getCustomerReviewLoader(false));
	}
}

export function* getUsersReviewAgainstSeller({ payload }) {
	try {
		const data = yield call(getUserReviewAgainstSellerApi, payload);
		yield put(userReviewResponse(data));
	} catch (err) {
		return err;
	}
}

export function* updateReview({ payload }) {
	try {
		yield put(reviewUpdateResponse(false));
		const data = yield call(editCommitApi, payload);
		yield put(editReviewResponse(data));
	} catch (err) {
		yield put(editReviewResponse(err));
		return err;
	} finally {
		yield put(reviewUpdateResponse(true));
	}
}

export function* submitReviews({ payload }) {
	try {
		yield put(reviewUpdateResponse(false));
		const data = yield call(submitReviewApi, payload);
		yield put(updateReviewSubmitStatus(data?.status));
		yield put(updateReviewCurrentPage(1));
	} catch (err) {
		yield put(updateReviewSubmitStatus(err?.response?.status));
	} finally {
		yield put(reviewUpdateResponse(true));
	}
}

export function* updateSellers({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(updateSellersData, payload);
		yield put(updateAdminSellerResponse(data));
		yield put(createAdminSellerResponse(data));
	} catch (err) {
		yield put(createAdminSellerResponse(err));
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* submitReviewsLikeDislike({ payload }) {
	try {
		yield put(handleNominateStatus(false));
		yield put(reviewUpdateResponse(false));
		const data = yield call(submitLikeDislikeApi, payload);
		yield put(likeDislikeReviewResponse(data));
	} catch (err) {
		yield put(likeDislikeReviewResponse(err));
		yield put(handleNominateStatus(true));
	} finally {
		yield put(handleNominateStatus(true));
		yield put(reviewUpdateResponse(true));
	}
}

export function* updateAdminSeller({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		yield call(updateSellerStatus, payload);
	} catch (err) {
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* createAdminSellersSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(createAdminSeller, payload);
		yield put(createAdminSellerResponse(data));
	} catch (err) {
		yield put(createAdminSellerResponse(err));
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* deleteAdminSellersSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(deleteAdminSeller, payload);
		yield put(deleteAdminSellerResponse(data));
	} catch (err) {
		yield put(deleteAdminSellerResponse(err));
		return err;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* getAdminSellerData({ payload }) {
	try {
		yield put(adminReviewLoadingResponse(true));
		const data = yield call(getAdminAllSellers, payload);
		yield put(updateSeller(data));
	} catch (error) {
		yield put(adminReviewLoadingResponse(false));
		return error;
	} finally {
		yield put(adminReviewLoadingResponse(false));
		yield put(handleAdminStatus(true));
	}
}

export function* sellerById({ payload }) {
	try {
		yield put(updateIsSingleSellerData(false));
		const data = yield call(getSellerById, payload);
		yield put(updateSellerById(data));
	} catch (error) {
		return error;
	} finally {
		yield put(updateIsSingleSellerData(true));
		yield put(handleAdminStatus(true));
	}
}

export function* allNonDefaultSellersSaga({ payload }) {
	try {
		yield put(allNonDefaultSellersLoading(false));
		const data = yield call(allNonDefaultSellersApi, payload);
		yield put(allNonDefaultSellersResponse(data));
		yield put(allNonDefaultSellersLoading(true));
	} catch (error) {
		yield put(allNonDefaultSellersLoading(true));
		return error;
	}
}

export function* submitMultipleReviews({ payload }) {
	try {
		yield put(reviewUpdateResponse(false));
		const data = yield call(submitMultipleReviewApi, payload);
		yield put(updateReviewSubmitStatus(data?.status));
	} catch (err) {
		yield put(updateReviewSubmitStatus(err?.response?.status));
	} finally {
		yield put(reviewUpdateResponse(true));
	}
}

export function* verifyReviewSaga({ payload }) {
	try {
		const responseData = yield call(verifyReviewApi, payload);
		yield put(updateReviewSubmitStatus(responseData?.data?.statusCode));
		yield put(verifyReviewResponse(responseData?.data));
	} catch (err) {
		yield put(updateReviewSubmitStatus(err?.response?.status));
		yield put(verifyReviewResponse(err?.response?.data));
	}
}

export function* googleApiSaga({ payload }) {
	try {
		const responseData = yield call(googlePlaceApi, payload);
		yield put(googleApiResponse(responseData));
	} catch (err) {
		yield put(googleApiResponse(err?.response));
	}
}

export function* noReviewSellersSaga({ payload }) {
	try {
		const responseData = yield call(noReviewSellersApi, payload);
		yield put(noReviewSellersResponse(responseData?.data));
	} catch (err) {
		yield put(noReviewSellersResponse(err?.response));
	}
}

export function* alreadyWinnerSaga({ payload }) {
	try {
		const responseData = yield call(alreadyWinnerReviewApi, payload);

		yield put(alreadyWinnerDataResponse(responseData?.data?.NominatedResult));
	} catch (err) {
		yield put(alreadyWinnerDataResponse(err?.response));
	}
}

export function* categoryNoReviewSellerSaga({ payload }) {
	try {
		const responseData = yield call(categoryNoReviewSellerApi, payload);
		yield put(categoryNoReviewSellerResponse(responseData?.data));
	} catch (err) {
		yield put(categoryNoReviewSellerResponse(err?.response));
	}
}

export function* latestSellerReviewSaga({ payload }) {
	try {
		yield put(updateIndividualCategoryIdLoading(true));

		const data = yield call(latestSellerReviewApi, payload);
		yield put(latestReviewData(data));
	} catch (error) {
		// yield put(adminReviewLoadingResponse(false));
		return error;
	} finally {
		yield put(updateIndividualCategoryIdLoading(false));
	}
}

export function* sellerTypeWiseSaga({ payload }) {
	try {
		yield put(updateIndividualCategoryIdLoading(true));

		const data = yield call(sellersTypeWiseApi, payload);
		yield put(sellerTypeWiseResponse(data));
	} catch (error) {
		yield put(sellerTypeWiseResponse(error));
		return error;
	} finally {
		yield put(updateIndividualCategoryIdLoading(false));
	}
}

export function* watchSellersSagas() {
	yield takeLatest(getSellers.type, getSellerData);
	yield takeLatest(getBalloon.type, getBalloons);
	yield takeLatest(addSellerRequest.type, addSellers);
	yield takeLatest(getSingleSellerID.type, getCountValue);
	yield takeLatest(getReview.type, getCustomerReview);
	yield takeLatest(editCommit.type, updateReview);
	yield takeEvery(submitReview.type, submitReviews);
	yield takeEvery(updateSellerAdmin.type, updateAdminSeller);
	yield takeLatest(submitLikeDislike.type, submitReviewsLikeDislike);
	yield takeLatest(updateSellerData.type, updateSellers);
	yield takeLatest(createAdminSellerRequest.type, createAdminSellersSaga);
	yield takeLatest(deleteAdminSellerRequest.type, deleteAdminSellersSaga);
	yield takeLatest(getAllAdminSellers.type, getAdminSellerData);
	yield takeLatest(getSellersByID.type, sellerById);
	yield takeLatest(allNonDefaultSellersRequest.type, allNonDefaultSellersSaga);
	yield takeLatest(submitMultipleReviwRequest.type, submitMultipleReviews);
	yield takeLatest(verifyReviewRequest.type, verifyReviewSaga);
	yield takeLatest(googleAPIRequest.type, googleApiSaga);
	yield takeLatest(noReviewSellersRequest.type, noReviewSellersSaga);
	yield takeLatest(userReviewsRequest.type, getUsersReviewAgainstSeller);
	yield takeLatest(sellerLatestReviewRequest.type, latestSellerReviewSaga);
	yield takeLatest(alreadyWinnerDataRequest.type, alreadyWinnerSaga);
	yield takeLatest(
		categoryNoReviewSellerRequest.type,
		categoryNoReviewSellerSaga
	);
	yield takeLatest(sellerTypeWiseRequest.type, sellerTypeWiseSaga);
}
