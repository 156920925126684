import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";

import TextField from "@mui/material/TextField";
import { getReviewSellers } from "../../../redux/reducers/ducks/adminReviewDuck";

export default function AllSeller({ setSellersId, isLoading, setPage }) {
	const dispatch = useDispatch();
	const { data } = useSelector((state) => ({
		data: state?.adminReview?.adminReviewAllSellers?.data,
	}));
	useEffect(() => {
		dispatch(getReviewSellers());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="">
				<Autocomplete
					id="free-solo-2-demo"
					className=" inputField rounded w-100 text-start"
					popupIcon={""}
					options={
						data?.records && data?.records?.length > 0 ? data?.records : []
					}
					onChange={(e, seller) => {
						if (seller) {
							isLoading(true);
							dispatch(handleAdminStatus(false));
							setPage(0);
							setSellersId(seller?.id);
						} else {
							setSellersId("");
						}
					}}
					sx={{
						".MuiOutlinedInput-root": {
							padding: "3px",
						},
						"#free-solo-2-demo": {
							textAlign: "start",
							marginLeft: "1rem",
						},
					}}
					getOptionLabel={(option) => option.sellerName}
					renderInput={(params) => (
						<div className="autocomplete-search-inputfield">
							<TextField
								className="text-center seller-textfield"
								sx={{
									"#free-solo-2-demo-label": {
										paddingLeft: "7px",
									},
								}}
								{...params}
								placeholder="Sellers"
								InputProps={{
									...params.InputProps,
								}}
							/>
						</div>
					)}
				/>
			</div>
		</>
	);
}
