import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import AirSellerReaction from "../../components/airSellerReaction/airSellerReaction";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import ThanksModal from "../../components/modals/thanksModal";
import LoveModal from "../../components/modals/LoveModal";
import { useDispatch, useSelector } from "react-redux";
import {
	getBalloon,
	getSellersByID,
	updateAirSellerName,
	updateReviewSubmitStatus,
	updateSellerById,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import "./airSeller.css";
import { Toast, ToastContainer } from "react-bootstrap";
import LoginModal from "../../components/modals/loginModal";
import SignUpModal from "../../components/modals/signUpModal";
import Backdrop from "@mui/material/Backdrop";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
import { useNavigate } from "react-router";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";
import { CategoryAutoComplete } from "../../components/layout/categoryAutoComplete/categoryAutoComplete";

function AirSeller() {
	const token = localStorage.getItem("token");
	const [sellers, setSellers] = useState([]);
	const [air, setAir] = useState(false);
	const [thanks, setThanks] = useState(false);
	const [show, setShow] = useState(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [message, setMessage] = useState("");
	const [isAirSellerSubmitted, setIsAirSellerSubmitted] = useState(false);
	const [isVisitLoveModal, setIsVisitLoveModal] = useState(false);
	const [isReviewSubitOnLogin, setIsReviewSubmitOnLogin] = useState(false);
	const [loveOpenAfterLogin, setLoveOpenAfterLogin] = useState(false);

	const [isSellerSelected, setIsSellerSelected] = useState(false);

	const [badReviews, setBadReviews] = useState({
		sellerId: "",
		categoryId: null,
		titleId: "",
		message: null,
		approvedByAdmin: true,
		bestWriter: false,
	});
	const [isAlreadySubmittedReview, setIsAlreadySubmitReview] = useState(false);

	//get Api for sellers
	const dispatch = useDispatch();
	const { data, reviewSubmitStatus, loginResponseData, sellerCategories } =
		useSelector((state) => ({
			data: state?.seller?.seller?.data,
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
			loginResponseData: state.authorization?.loginResponseData,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		}));

	const navigate = useNavigate();

	useEffect(() => {
		setSellers(data?.records);
		dispatch(verifyReviewResponse(null));
		dispatch(updateSellerById());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(null));
		dispatch(getBalloon());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsSellerSelected(false);
	}, [location]);

	useEffect(() => {
		if (thanks) {
			setAir(false);
		}
	}, [thanks]);

	const handleAir = () => {
		setIsVisitLoveModal(false);
		setLoginModal(false);
		if (!badReviews.sellerId && !badReviews.titleId) {
			getToasterNotifications("Please select a seller and balloon!");
			setAir(false);
			return;
		}
		if (!badReviews.sellerId && badReviews.titleId) {
			getToasterNotifications("Please select seller!");
			setAir(false);
			return;
		}
		if (
			badReviews.sellerId &&
			badReviews.titleId &&
			!badReviews.categoryId &&
			sellerCategories &&
			sellerCategories?.length > 1
		) {
			getToasterNotifications("Please select category!");
			setAir(false);
			return;
		} else if (badReviews.sellerId && !badReviews.titleId) {
			getToasterNotifications("Please select a balloon!");
			setAir(false);
			return;
		}
		if (!token) {
			setLoginModal(true);
			setIsReviewSubmitOnLogin(true);
			return;
		}
		if (badReviews.sellerId && badReviews.titleId) {
			if (loginResponseData?.data?.id) {
				dispatch(
					verifyReviewRequest({
						sellerId: badReviews.sellerId,
						userId: loginResponseData?.data?.id,
					})
				);
			}
			dispatch(getSellersByID(badReviews?.sellerId));
			setIsAirSellerSubmitted(true);
		}
	};

	useEffect(() => {
		if (
			badReviews.sellerId &&
			badReviews.titleId &&
			reviewSubmitStatus === 200 &&
			token &&
			!isReviewSubitOnLogin
		) {
			setIsAirSellerSubmitted(false);
			setAir(true);
			dispatch(updateReviewSubmitStatus(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, badReviews, reviewSubmitStatus]);

	useEffect(() => {
		if (
			reviewSubmitStatus === 409 &&
			!isVisitLoveModal &&
			isAirSellerSubmitted
		) {
			setIsAlreadySubmitReview(true);
			setAir(false);
			setIsAirSellerSubmitted(false);
			setMessage("");
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 401 && token && loginResponseData?.data?.id) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			dispatch(updateReviewSubmitStatus(null));
			localStorage.setItem("adminToken", "");
		}
		if (reviewSubmitStatus === 400) {
			getToasterNotifications(
				"You've already clicked this seller!  You can edit your story from the Seller Balloon Page!"
			);
			setAir(false);
			setIsAirSellerSubmitted(false);
			setMessage("");
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 403 && !isVisitLoveModal) {
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
			setAir(false);
			setIsAirSellerSubmitted(false);
			setMessage("");
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus, dispatch, badReviews, isAirSellerSubmitted]);

	useEffect(() => {
		if (isAlreadySubmittedReview) {
			getToasterNotifications(
				"You've already clicked this Seller! Please visit the seller balloon page to update it."
			);
		}
	}, [isAlreadySubmittedReview]);

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	useEffect(() => {
		if (loveOpenAfterLogin) {
			setAir(true);
			setLoginModal(false);
		} else {
			setAir(false);
		}
	}, [loveOpenAfterLogin]);

	return (
		<Layout>
			<>
				<ToastContainer position="top-end" className="p-3">
					<Toast
						onClose={() => setShow(false)}
						bg={"warning"}
						show={show}
						delay={2000}
						autohide
					>
						<Toast.Body>{message}</Toast.Body>
					</Toast>
				</ToastContainer>
				{isAirSellerSubmitted && (
					<Backdrop
						sx={{ color: "#fff", zIndex: 100 }}
						open={isAirSellerSubmitted}
						onClick={() => setIsAirSellerSubmitted(false)}
					>
						<div className="d-flex  justify-content-center loaderCenter align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				)}
				<div className="container mt-5">
					<div className="row text-center d-flex justify-content-center mt-4 mb-4">
						<div className="col-lg-4 col-md-12 col-sm-12">
							<Autocomplete
								id="free-solo-2-demo"
								className="inputRounded"
								popupIcon={""}
								options={sellers && sellers?.length > 0 ? sellers : []}
								onChange={(e, seller) => {
									if (seller) {
										setIsReviewSubmitOnLogin(false);
										setIsSellerSelected(true);
										setBadReviews({
											...badReviews,
											sellerId: seller?.id,
											categoryId: null,
										});
										dispatch(getSellersByID(seller.id));

										dispatch(updateAirSellerName(seller?.sellerName));
										setIsAlreadySubmitReview(false);
									} else {
										setBadReviews({
											...badReviews,
											sellerId: null,
											categoryId: null,
										});
									}
								}}
								getOptionLabel={(option) => option.sellerName}
								renderInput={(params) => (
									<div className="autocomplete-search-inputfield">
										<TextField
											className="inputRounded"
											sx={{
												"#free-solo-2-demo-label": {
													paddingLeft: "7px",
												},
											}}
											{...params}
											label="Search by Sellers"
											InputProps={{
												...params.InputProps,
											}}
										/>
									</div>
								)}
							/>
						</div>
					</div>
					<div className="row text-center d-flex justify-content-center mb-3">
						<div className="col-lg-4 col-md-12 col-sm-12 mb-3">
							{sellerCategories &&
								sellerCategories?.length > 1 &&
								isSellerSelected && (
									<CategoryAutoComplete
										reviews={badReviews}
										setReviews={setBadReviews}
										sellerCategories={sellerCategories}
										notFromSellerBallonPage
									/>
								)}
						</div>
					</div>
					<h6 className="text-center fw-bold mt-2 mb-sm-0 mb-5">
						Please click the balloon that best describes your experience with
						this seller.
					</h6>
					<AirSellerReaction
						badReviews={badReviews}
						setBadReviews={setBadReviews}
					/>
					<div className="d-flex justify-content-center">
						<button
							type="button"
							onClick={handleAir}
							disabled={isAlreadySubmittedReview || isAirSellerSubmitted}
							className="btn rounded-pill mt-5 air-button-next"
						>
							Submit
						</button>
					</div>
				</div>
			</>
			{/*displays the air modal*/}
			<LoveModal
				isAir={air}
				setIsAir={setAir}
				setThanksValue={setThanks}
				setIsVisitLoveModal={setIsVisitLoveModal}
				isVisitLoveModal={isVisitLoveModal}
				badReviews={badReviews}
			/>
			<ThanksModal
				reviews={badReviews}
				isThanks={thanks}
				setIsThanks={setThanks}
				isReviewSubitOnLogin={isReviewSubitOnLogin}
				setIsReviewSubmitOnLogin={setIsReviewSubmitOnLogin}
			/>
			<LoginModal
				setThanks={setThanks}
				isShow={showLoginModal}
				setIsShow={setLoginModal}
				setSignUp={setSignUpModal}
				reviews={badReviews}
				notFromSellerBalloonPage
				setIsAlreadySubmitReview={setIsAlreadySubmitReview}
				isNegativeReview
				setLoveOpenAfterLogin={setLoveOpenAfterLogin}
			/>
			<SignUpModal
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setLoginModal}
				reviews={badReviews}
			/>
		</Layout>
	);
}

export default AirSeller;
