import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	getReview,
	getSellersByID,
	getSingleSellerID,
	updateReviewSubmitStatus,
	userReviewsRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import LoveModal from "../modals/LoveModal";
import ThanksModal from "../modals/thanksModal";
import ToAirModal from "../modals/toAirModal";
import ToLoveModal from "../modals/toLoveModal";
import ToTellModal from "../modals/toTellModal";
import "./sellersBalloonSection.css";
import { sellerTypeArray } from "../../utils/addSellerHelper";

function SellersBalloonSection({ balloonRef }) {
	const [toLove, setToLove] = useState(false);
	const [toAir, setToAir] = useState(false);
	const [focusBtn, setFocusBtn] = useState(null);
	const [tell, setTell] = useState(false);
	const [air, setAir] = useState(false);
	const [thanks, setThanks] = useState(false);
	const [selectedBallonType, setSelectedballonType] = useState(false);
	const [isVisitLoveModal, setIsVisitLoveModal] = useState(false);
	const [badReviews, setBadReviews] = useState(null);

	const dispatch = useDispatch();

	const ref = React.useRef(null);

	const navigate = useNavigate();
	let { id } = useParams();

	const {
		singleSellerData,
		balloons,
		isReviewUpdate,
		isReviewDataLoading,
		sellerByID,
		sellerDataById,
		reviewType,
		reviewCurrentPage,
		loginResponseData,
		userReviewsData,
	} = useSelector((state) => {
		return {
			sellerId: state?.seller?.sellerId,
			singleSellerData: state?.seller?.singleSellerData?.data,
			isSingleSellerDataResponse: state?.seller?.isSingleSellerDataResponse,
			balloons: state?.seller?.balloon?.data?.title,
			sellerID: state?.seller?.sellerId,
			userReviewsData: state?.seller?.userReviewsData,
			isReviewUpdate: state?.seller?.isReviewUpdate,
			isReviewDataLoading: state?.seller?.isReviewDataLoading,
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
			sellerByID: state?.seller?.sellerId,
			sellerDataById: state?.seller?.sellerByID?.data?.record,
			reviewType: state?.seller?.reviewType,
			reviewCurrentPage: state?.adminReview?.reviewCurrentPage,
			loginResponseData: state.authorization?.loginResponseData,
		};
	});

	// useEffect(() => {
	// 	dispatch(getSingleSellerID(sellerId));
	// }, [sellerId, dispatch]);

	// const handleLove = () => setToLove(true);

	const handleClick = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		if (thanks) {
			setToLove(false);
			setAir(false);
		}
	}, [thanks]);

	useEffect(() => {
		if (!selectedBallonType) {
			setToLove(false);
			setAir(false);
		}
	}, [selectedBallonType]);

	useEffect(() => {
		if (isReviewUpdate) {
			setTimeout(() => {
				dispatch(
					getReview({
						sellerId: id,
						page: reviewCurrentPage,
						titleId: reviewType?.titleId ? reviewType?.titleId : "",
						reviewTypes: reviewType?.titleId ? "" : reviewType?.airedOrLoved,
						categoryId: reviewType?.category?.id,
					})
				);
				dispatch(getSingleSellerID(id));
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isReviewUpdate, reviewType]);

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(null));
		dispatch(getSellersByID(sellerByID));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (loginResponseData?.data?.id) {
			dispatch(
				userReviewsRequest({
					sellerId: id,
					userId: loginResponseData ? loginResponseData?.data?.id : "",
				})
			);
		}
	}, [id, loginResponseData]);

	useEffect(() => {
		if (air) {
			setToAir(false);
			setTell(false);
		}
	}, [air]);

	// const handleAir = () => setToAir(true);

	const handleTell = () => setTell(true);

	const heights = [
		"Disappointment",
		"Grr",
		"Frustrated",
		"Satisfied",
		"Awesome",
		"Happy",
	];

	// const heightsLarge = [
	// 	"Disappointment-lg",
	// 	"Grr-lg",
	// 	"Frustrated-lg",
	// 	"Satisfied-lg",
	// 	"Awesome-lg",
	// 	"Happy-lg",
	// ];

	const handleViewProfile = () => navigate("seller-profile");

	let countArray = [];

	const finalReviewData = singleSellerData?.result?.map((item) => {
		const balloonId = item?.titleId;
		countArray.push(item.count);
		const foundBalloonObj = balloons?.find((obj) => obj.id === balloonId);
		return { ...foundBalloonObj, count: item.count };
	});

	const asendingCount = countArray.sort(function (a, b) {
		return a - b;
	});

	useEffect(() => {
		setTimeout(() => {
			if (focusBtn) {
				setFocusBtn(null);
			}
		}, 3000);
	}, [focusBtn]);

	return (
		<>
			{isReviewDataLoading ? (
				<div>
					<Backdrop
						sx={{ color: "#fff", zIndex: 2 }}
						open={isReviewDataLoading}
					>
						<div className="d-flex h-100 justify-content-center align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				</div>
			) : (
				<div className="row m-0 p-0" ref={balloonRef}>
					<div className="col-lg-12 col-md-12 col-sm-12 mb-md-5 mb-0">
						<div className="row justify-content-md-center justify-content-start">
							<div className="col-lg-6 col-md-6 col-3 mx-md-0 mx-4">
								<div className="container-box">
									<div onClick={handleViewProfile}>
										<div className="integer-box">
											<div className="digit">
												{singleSellerData?.seller?.sellerName
													?.charAt(0)
													.toUpperCase()}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-7 mr-md-0 mr-2">
								<div className="design-99">
									<div>
										<div
											className="text-decoration-none mb-1  fw-bold font-99designs"
											onClick={handleViewProfile}
										>
											{singleSellerData?.seller?.sellerName}
											{/* <sup className="sup">
												{singleSellerData?.seller?.address
													? singleSellerData?.seller?.address
													: ""}
												{singleSellerData?.seller?.is_global &&
													"; Serves Globally"}
											</sup> */}
										</div>
									</div>
									<div className="classification-font-size">
										<div>
											<div className="mb-1  font-seller-profile">
												{singleSellerData?.seller?.type !==
												sellerTypeArray[0] ? (
													<div>
														{singleSellerData?.seller?.type}
														{singleSellerData?.seller?.is_global &&
															"; Serves Globally"}
													</div>
												) : (
													<div>U.S. National, Regional Online</div>
												)}
											</div>
											<div className="mb-1  font-seller-profile">
												{singleSellerData?.seller?.address ? (
													<div>{singleSellerData?.seller?.address}</div>
												) : (
													""
												)}
											</div>
										</div>
									</div>
									<div className="d-flex flex-column category-sections">
										<div className="mb-1 font-seller-profile">
											<Link to="seller-profile">View Seller Profile</Link>
										</div>
										<div className="freelance-market mb-md-3 mb-1">
											{singleSellerData &&
											sellerDataById?.categories?.length > 0
												? sellerDataById?.categories
														.map((category) => category.categoryName)
														.join(" - ")
												: ""}
										</div>
									</div>
								</div>
								<div className="row col-xl-6 col-lg-8  d-none  d-lg-flex  ms-0   justify-content-between">
									<div className="col-4  p-0">
										<button
											type="button"
											className={` ${
												focusBtn == "love" && "animation"
											} btn button-sellers`}
											onClick={() => {
												setToLove(true);
												setFocusBtn(null);
											}}
											disabled={
												userReviewsData?.data &&
												userReviewsData?.data[0]?.bestWriter
											}
										>
											<p className="tell-button">Love</p>
										</button>
									</div>
									<div className="col-4 p-0">
										<button
											type="button"
											className={` ${
												focusBtn == "air" && "animation"
											} btn button-sellers`}
											onClick={() => {
												setFocusBtn(null);
												setToAir(true);
											}}
											disabled={
												userReviewsData?.data &&
												userReviewsData?.data[0]?.bestWriter
											}
										>
											<p className="tell-button">Air</p>
										</button>
									</div>
									<div className="col-4 p-0">
										<button
											className="btn button-sellers"
											onClick={handleTell}
											disabled={
												userReviewsData?.data &&
												userReviewsData?.data[0]?.bestWriter
											}
										>
											<p className="tell-button">Tell</p>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						ref={ref}
						className="row col-12 mb-5 mt-4 d-lg-none  d-flex  ms-0   justify-content-between"
					>
						<div className="col-4 text-center p-0">
							<button
								type="button"
								className={` ${
									focusBtn == "love" && "animation"
								} btn button-sellers`}
								onClick={() => {
									setFocusBtn(null);
									setToLove(true);
								}}
								disabled={
									userReviewsData?.data && userReviewsData?.data[0]?.bestWriter
								}
							>
								<p className="tell-button">Love this Seller</p>
							</button>
						</div>
						<div className="col-4 p-0 text-center">
							<button
								type="button"
								className={` ${
									focusBtn == "air" ? "animation" : ""
								} btn button-sellers`}
								onClick={() => {
									setFocusBtn(null);
									setToAir(true);
								}}
								disabled={
									userReviewsData?.data && userReviewsData?.data[0]?.bestWriter
								}
							>
								<p className="tell-button">Air a Grievance</p>
							</button>
						</div>
						<div className="col-4  p-0 text-center">
							<button
								type="button"
								className="btn button-sellers"
								onClick={handleTell}
								disabled={
									userReviewsData?.data && userReviewsData?.data[0]?.bestWriter
								}
							>
								<p className="tell-button">Tell your Story</p>
							</button>
						</div>
					</div>
					<div className="col-lg-12  review-balloon-images col-md-12 col-sm-12 justify-content-center">
						<div className="row mx-0 px-0 mt-md-0 mt-2 mt-md-4">
							{finalReviewData?.map((item, indexNumber) => {
								const index = asendingCount.findIndex(
									(obj) => obj === item.count
								);

								return (
									<div
										className={`col-lg-2 col-md-4 col-4 ${
											item.title === "Frustrated" ||
											item.title === "Grrr" ||
											item.title === "Disappointed"
												? "mt-md-3 mt-5"
												: "mt-md-0 mt-md-3"
										}`}
										onClick={() => {
											handleClick();
											if (
												item.title === "Frustrated" ||
												item.title === "Grrr" ||
												item.title === "Disappointed"
											) {
												setFocusBtn("air");
											} else {
												setFocusBtn("love");
											}
										}}
										key={indexNumber}
									>
										<div className={`${heights[index]}`}>
											<img
												src={`/icons/${item.imageName}.png`}
												className="w-100"
												alt={`${item.imageName}`}
											/>
										</div>
										<p className="text-center text-secondary digit-font ">
											{item.count}
										</p>
										<p className={`text-center ml-4 mr-3  love-review-title `}>
											{item.title}
										</p>
									</div>
								);
							})}
						</div>
					</div>
					<ToLoveModal
						isToLove={toLove}
						setIsToLove={setToLove}
						setThanksValue={setThanks}
					/>
					<ToAirModal
						isToAir={toAir}
						setIsToAir={setToAir}
						seAirValue={setAir}
						setThanks={setThanks}
						setAiredReviews={setBadReviews}
					/>
					<ToTellModal
						setBadReviews={setBadReviews}
						isTell={tell}
						setIsTell={setTell}
						setTellValue={setAir}
						setSelectedballonType={setSelectedballonType}
						setThanks={setThanks}
					/>
					<LoveModal
						isAir={air}
						setIsAir={setAir}
						setThanksValue={setThanks}
						setIsVisitLoveModal={setIsVisitLoveModal}
						isVisitLoveModal={isVisitLoveModal}
						badReviews={badReviews}
						setBadReviews={setBadReviews}
					/>
					<ThanksModal isThanks={thanks} setIsThanks={setThanks} />
				</div>
			)}
		</>
	);
}

export default SellersBalloonSection;
