import React from "react";
import { Modal } from "react-bootstrap";

function SuccessModal({ isSuccess, isSetSuccess }) {
	const handleCancel = () => isSetSuccess(false);

	return (
		<Modal
			show={isSuccess}
			onHide={handleCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0"></Modal.Header>
			{/* <Modal.Body> */}
			<div>
				<div className="text-center  ">
					<h5 className="mb-3 p-3 bg-light">Submitted successfully</h5>

					<p>&nbsp;</p>
				</div>
			</div>
			{/* </Modal.Body> */}
		</Modal>
	);
}

export default SuccessModal;
