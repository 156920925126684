import React from "react";
import { Modal } from "react-bootstrap";

function SellerModal({ isSeller, setIsSeller }) {
	const handleClose = () => setIsSeller(false);
	return (
		<div>
			<Modal
				show={isSeller}
				onHide={handleClose}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<h4>Who is a Seller?</h4>
				</Modal.Header>
				<Modal.Body className="text-justify">
					Anyone who receives money in exchange for providing something such as
					a product, service, experience or anything else.
				</Modal.Body>
				<Modal.Footer>
					<button
						className="close_btn border-0 bg-dark text-white p-2 w-25"
						onClick={() => {
							setIsSeller(false);
						}}
					>
						close
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default SellerModal;
