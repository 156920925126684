import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getSellerId } from "../../redux/reducers/ducks/getSellersDuck";

function CategoriesModal({ catgory, setCategory, actualCategory }) {
	const handleClose = () => setCategory(false);
	const { SellerInCategory } = useSelector((state) => {
		return {
			SellerInCategory:
				state?.category?.sellersAgainstIndividualCategoryID?.data?.records
					?.sellers,
		};
	});
	const dispatch = useDispatch();

	return (
		<Modal
			show={catgory}
			onHide={handleClose}
			size="lg"
			aria-labelledby="contained-modal-title-center"
			centered
			className="seller-list-Modal"
		>
			<Modal.Header closeButton className="border-0 text-light">
				<h5 className="text-center fw-bold">{actualCategory?.categoryName}</h5>
			</Modal.Header>
			<div className="popup-height">
				<div className="container mb-5">
					{SellerInCategory?.map((item) => {
						return (
							<Link
								to={`/seller-balloon/${item.id}`}
								className="text-decoration-none links"
								key={item.id}
							>
								<div
									className="mx-3"
									onClick={() => {
										dispatch(getSellerId(item.id));
										handleClose();
									}}
								>
									{item?.sellerName}
								</div>
							</Link>
						);
					})}
				</div>
			</div>
		</Modal>
	);
}

export default CategoriesModal;
