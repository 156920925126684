import axios from "./Axios";

export const getAllAdminUser = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/search/user", {
			params: data,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const updateAdminUser = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.patch("admin/user/update", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const deleteAdminUser = async (Id) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.delete("admin/user/delete", {
			params: {
				id: Id,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const blockUserStatus = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.patch("admin/user/update/block/status", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const loggerApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("logger/log_data", {
			params: {
				username: data?.name,
				page: data?.page,
				pageSize: data?.pageSize,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const pendingSellersApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/sellers/pending_sellers", {
			params: {
				sellerName: data?.sellerName,
				page: data?.page,
				pageSize: data?.pageSize,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const recentActivitiesApi = async (data) => {
	let token = localStorage.getItem("token");

	try {
		return axios.get("review/all", {
			params: {
				page: data?.page,
				pageSize: data?.pageSize,
				timeZone: data?.timeZone,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const averageSignUpsApi = async () => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/signup/detail", {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const averageMonthlySignUpsApi = async () => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/monthly", {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const csvCategoriesApi = async (data) => {
	try {
		return axios.get("csv/combineSellersCategories", {
			params: {
				page: data?.page,
				pageSize: data?.pageSize,
				id: data?.id,
			},
			headers: {
				"Content-Type": "application/json",
			},
		});
	} catch (err) {
		return err;
	}
};

export const updateSellerTypeApi = async (data) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.patch("admin/sellers/update/type", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const bestWriterNominationApi = async (payload) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/review/nominate", {
			params: payload,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const alreadyNominationApi = async (payload) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/review/already-nominate", {
			params: payload,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const weeklyAverageSignUpsApi = async () => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/weekly/average", {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const weeklyGraphSignUpsApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/user/graph/weekly", {
			params: {
				start: data.startDate,
				end: data.endDate,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const monthlyGraphSignUpsApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/graph/monthly", {
			params: {
				start: data.startDate,
				end: data.endDate,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const monthlyAverageSignUpsApi = async () => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/monthly/average", {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const totalSignUpCountApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/signup/count", {
			params: {
				timeZone: data.timeZone,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const emailLoggerPortalApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("mailchip-logger/logger", {
			params: {
				page: data.page,
				pageSize: data.pageSize,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
