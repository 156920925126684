import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Backdrop, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import { emailPortalRequest } from "../../../redux/reducers/ducks/adminReviewDuck";
import moment from "moment";

export function EmailPortal() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	// const [userSearch, setUserSearch] = useState("");

	const [isLoggerChange, setIsLoggerChange] = useState(false);
	const { emailPortalData } = useSelector((state) => {
		return {
			emailPortalData: state?.adminReview?.emailPortalData,
		};
	});

	const dispatch = useDispatch();
	const handleChangePage = (event, newPage) => {
		setIsLoggerChange(true);

		dispatch(handleAdminStatus(false));
		setPage(newPage);
	};

	useEffect(() => {
		dispatch(
			emailPortalRequest({
				page: 1,
				pageSize: 10,
				// emailTo: userSearch,
			})
		);
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			dispatch(
				emailPortalRequest({
					// emailTo: userSearch,
					page: page + 1,
					pageSize: rowsPerPage,
				})
			);
			setIsLoggerChange(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [page, rowsPerPage]);

	const columns = [
		{ id: "name", label: `Email-From`, minWidth: 130 },
		{ id: "code1", label: "Email-To", minWidth: 100 },
		{ id: "code2", label: "Email-Subject", minWidth: 100 },
		{ id: "code2", label: "Status", minWidth: 100 },
		{ id: "code3", label: "Date", minWidth: 100 },
	];

	const handleRowsPerPage = (e) => {
		setIsLoggerChange(true);
		setRowsPerPage(e.target.value);
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{isLoggerChange && (
				<Backdrop sx={{ color: "#fff", zIndex: 100 }} open={isLoggerChange}>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					{/* <div className="parent-cat" /> */}
					<div className="row d-flex justify-content-end">
						{/* <div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-end">
							<input
								id="standard-basic"
								className="p-2 w-100"
								placeholder="Search Email To"
								value={userSearch}
								onChange={(e) => {
									// setPage(0);
									setUserSearch(e.target.value);
								}}
							/>
						</div> */}
					</div>
					<Paper
						sx={{ width: "100%", overflow: "hidden", mt: 5 }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{emailPortalData?.records?.length > 0 ? (
										emailPortalData?.records?.map((logs) => {
											const date = moment?.utc(logs?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");

											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={logs.id}
												>
													<TableCell>{logs?.emailFrom}</TableCell>
													<TableCell>{logs?.emailTo}</TableCell>

													<TableCell sx={{ whiteSpace: "noWrap" }}>
														{logs?.emailSubject}
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														{logs?.emailSent ? "Sent" : "Not Send"}
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														{formattedDate}
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No Log exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{emailPortalData?.records?.length > 0 && (
							<>
								{" "}
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
									count={
										emailPortalData?.totalRecords
											? emailPortalData?.totalRecords
											: 0
									}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
					</Paper>
				</Card>
			</div>
		</div>
	);
}
