import * as React from "react";
import "./userPage.css";

export default function UserSearch({ isUserName, setIsUserName, setPage }) {
	return (
		<div className="row d-flex justify-content-end">
			<div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-end">
				<input
					id="standard-basic"
					className="p-2 w-100 admin-search-user"
					placeholder="Search User Name"
					value={isUserName}
					onChange={(e) => {
						setPage(0);
						setIsUserName(e.target.value);
					}}
				/>
			</div>
		</div>
	);
}
