import { Backdrop, Card, CircularProgress } from "@mui/material";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import "./bestWriter.css";
import { useDispatch, useSelector } from "react-redux";
import {
	adminImageUploadStatusResponse,
	bestWriterNominationRequest,
	bestWriterRequest,
	getReviewSellers,
	sendBestWriterEmailResponse,
} from "../../../redux/reducers/ducks/adminReviewDuck";
import { getAllUsersResponse } from "../../../redux/reducers/ducks/userDuck";
import MessagePopup from "../review/messagePopup";
import moment from "moment";
import Swal from "sweetalert2";
import { getUniqueReviews } from "../../../utils/constant";
import { getToasterNotifications } from "../../../utils/getToasterNotification.js";
import { WriterDescription } from "./sendEmailPopup.js";
import { getToasterErrors } from "../../../utils/getToasterErrors.js";
import { Button } from "react-bootstrap";
import { getBalloon } from "../../../redux/reducers/ducks/getSellersDuck.js";
import { CheckCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { UploadWriterImage } from "../adminInstgramStories/uploadImage.js";
// import { WinnerDate } from "./winnerDate.js";
import EditIcon from "@mui/icons-material/Edit";

export const BestWriter = () => {
	const columns = [
		{ id: "Seller", label: "Seller", minWidth: "130px" },
		{ id: "User", label: "User", minWidth: "130px" },
		{ id: "Balloon", label: "Balloon", minWidth: 100 },
		{ id: "code3", label: "Story", minWidth: "100px" },
		{ id: "code1", label: "Nominations", minWidth: "100px" },
		{ id: "code2", label: "Date Nominated", minWidth: "200px" },
		{ id: "code4", label: "Winner", minWidth: "100px" },
		{ id: "code5", label: "Email", minWidth: "100px" },
		{ id: "code4", label: "Image", minWidth: 100 },
		// { id: "code7", label: "Winner Date", minWidth: "100px" },
		{ id: "code5", label: "Actions", minWidth: 100 },
		{ id: "code6", label: "", minWidth: "100px" },
	];

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [openWriterDesc, setOpenWriterDesc] = React.useState({
		isOpen: false,
		id: null,
		userId: null,
	});

	// const [openWinnerDate, setOpenWinnerDate] = React.useState({
	// 	isOpen: false,
	// 	id: null,
	// 	prevDate: null,
	// });

	const isAdminStatus = useSelector(
		(state) => state?.seller?.isAdminChangeStatus
	);

	const {
		bestWriterData,
		allUsers,
		allSellers,
		sendBestWriterEmailData,
		adminImageUploadStatus,
		adminReviewAllSellers,
		balloon,
	} = useSelector((state) => {
		return {
			bestWriterData: state?.adminReview?.bestWriterData,
			allUsers: state?.adminUser?.allUserData,
			allSellers: state?.adminReview?.adminReviewAllSellers?.data?.records,
			sendBestWriterEmailData: state?.adminReview?.sendBestWriterEmailData,
			adminImageUploadStatus: state?.adminReview?.adminImageUploadStatus,
			adminReviewAllSellers: state?.adminReview?.adminReviewAllSellers?.data,
			balloon: state?.seller?.balloon?.data?.title,
		};
	});

	const handleNominate = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You are nominating this review as winner",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
			reverseButtons: true,
			customClass: {
				title: "mt-4",
				confirmButton: "btn bg-primary",
				cancelButton: "btn btn-primary ",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				const formattedDate = moment(new Date()).format("YYYY-MM-DD");

				dispatch(
					bestWriterRequest({
						reviewId: id,
						bestWriter: true,
						winnerDate: formattedDate,
					})
				);
			}
		});
	};

	React.useEffect(() => {
		if (adminImageUploadStatus?.status === 200) {
			getToasterNotifications("Winner Selected For Best Review");
			dispatch(adminImageUploadStatusResponse(null));
			const requestData = {
				page: page + 1,
			};
			dispatch(bestWriterNominationRequest(requestData));
		}
	}, [adminImageUploadStatus]);

	const dispatch = useDispatch();

	const [openDialog, setOpenDialog] = React.useState({
		isOpen: false,
		id: null,
	});

	React.useEffect(() => {
		const requestData = {
			page: page + 1,
		};
		dispatch(bestWriterNominationRequest(requestData));
		dispatch(getReviewSellers());
		dispatch(getAllUsersResponse());
	}, []);

	React.useEffect(() => {
		const requestData = {
			page: page + 1,
			pageSize: rowsPerPage,
		};
		dispatch(bestWriterNominationRequest(requestData));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage]);

	const handleRowsPerPage = (e) => {
		setRowsPerPage(e.target.value);
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	React.useEffect(() => {
		dispatch(getBalloon());
	}, []);

	React.useEffect(() => {
		if (sendBestWriterEmailData?.data?.statusCode === 404) {
			getToasterErrors(sendBestWriterEmailData?.data?.message);
			dispatch(sendBestWriterEmailResponse());
		}
		if (sendBestWriterEmailData?.success) {
			const requestData = {
				page: page + 1,
			};
			dispatch(bestWriterNominationRequest(requestData));
			getToasterNotifications("emails sent successfully");
			dispatch(sendBestWriterEmailResponse());
		}
	}, [sendBestWriterEmailData]);

	const uniqueReviews = getUniqueReviews(
		bestWriterData && bestWriterData?.records
	);

	const [showUploadImage, setShowUploadImage] = React.useState({
		isOpen: false,
		storyImage: null,
		id: null,
	});

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{!isAdminStatus && (
				<Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={!isAdminStatus}>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<div className="parent-cat" />
					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{bestWriterData && bestWriterData?.records?.length > 0 ? (
										uniqueReviews?.map((cat) => {
											const singleSeller =
												allSellers &&
												allSellers?.filter(
													(item) => item?.id === cat?.sellerId
												);
											const singleUser =
												allUsers &&
												allUsers?.filter((item) => item?.id === cat?.userId);
											const date = cat?.winnerDate
												? moment?.utc(cat?.winnerDate)
												: null;
											const formattedDate = date
												? date.format("MMMM DD, YYYY")
												: null;
											// const formattedDateForReview = moment(new Date()).format(
											// 	"YYYY-MM-DD"
											// );
											const nominatedDate = cat?.nominatedDate
												? moment?.utc(cat?.nominatedDate)
												: null;
											const formattedNominatedDate = nominatedDate
												? nominatedDate.format("MMMM DD, YYYY")
												: null;
											const sellerObj = adminReviewAllSellers?.records?.find(
												(seller) => {
													return seller.id === cat.sellerId;
												}
											);

											const image = balloon?.find((img) => {
												return img.id === cat.titleId;
											});

											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={cat?.id}
												>
													<TableCell>
														<Link
															className="admin-seller-name-link"
															to={`/seller-balloon/${cat?.sellerId}`}
															target="_blank"
														>
															{sellerObj?.sellerName}
														</Link>
													</TableCell>

													<TableCell>
														{" "}
														{singleUser && singleUser[0]?.name}
													</TableCell>
													<TableCell>
														{" "}
														<div>
															<img
																src={`/icons/${image?.imageName}.png`}
																alt="image"
																style={{ width: "84px", height: "84px" }}
															/>
														</div>
													</TableCell>
													<TableCell
														onClick={() =>
															setOpenDialog({
																isOpen: true,
																msg: cat?.message,
																sellerName:
																	singleSeller && singleSeller[0]?.sellerName,
															})
														}
														className="seller-panel-cursor"
													>
														{cat?.message?.length > 0 ? <CheckCircle /> : ""}
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														{cat?.likeDislike?.length}
													</TableCell>
													<TableCell>{formattedNominatedDate}</TableCell>

													<TableCell>{formattedDate}</TableCell>

													<TableCell
														className="seller-panel-cursor"
														onClick={() => {
															if (!cat?.isEmailSent) {
																setOpenWriterDesc({
																	isOpen: true,
																	id: cat?.id,
																	userId: cat?.userId,
																});
															}
														}}
													>
														{cat?.winnerDate &&
															(cat?.isEmailSent ? (
																<div className="fw-bold">Already Sent</div>
															) : (
																<Button
																	variant="contained"
																	className="send-email-btn"
																	disabled={cat?.isEmailSent}
																>
																	Send Emails
																</Button>
															))}
													</TableCell>
													<TableCell>
														<img
															src={cat?.storyImage}
															width={30}
															height={30}
															alt="noInsaImage"
														/>
													</TableCell>
													{/* <TableCell
														className="seller-panel-cursor"
														onClick={() => {
															setOpenWinnerDate({
																isOpen: true,
																id: cat?.id,
																prevDate: formattedDate,
															});
														}}
													>
														<Button variant="primary">Winner Date</Button>
													</TableCell> */}
													<TableCell
														onClick={() =>
															setShowUploadImage({
																isOpen: true,
																storyImage: cat?.storyImage,
																id: cat?.id,
															})
														}
													>
														<EditIcon className="admin-seller-edit ms-1 seller-panel-cursor text-primary iconMargin" />
													</TableCell>
													<TableCell className="seller-panel-cursor">
														{!cat?.bestWriter && !cat?.winnerDate && (
															<Button
																variant="contained"
																className="send-email-btn"
																onClick={() => {
																	handleNominate(cat?.id);
																}}
															>
																Make Winner
															</Button>
														)}
														{/* <EditIcon className="admin-seller-edit ms-1 seller-panel-cursor text-primary iconMargin" /> */}
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No Best Writer Exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{bestWriterData?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
									count={
										bestWriterData?.totalRecords
											? bestWriterData?.totalRecords
											: 0
									}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
					</Paper>
				</Card>
				<MessagePopup showPopup={openDialog} setShowPopup={setOpenDialog} />
				<WriterDescription
					showPopup={openWriterDesc}
					setShowPopup={setOpenWriterDesc}
				/>
				<UploadWriterImage
					showPopup={showUploadImage}
					setShowPopup={setShowUploadImage}
				/>
				{/* <WinnerDate
					showPopup={openWinnerDate}
					setShowPopup={setOpenWinnerDate}
				/> */}
			</div>
		</div>
	);
};
