import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	component: "Category",
};

const mainSlice = createSlice({
	name: "main",
	initialState: INITIAL_STATE,
	reducers: {
		updateComponentPannel(state, { payload }) {
			return {
				...state,
				component: payload,
			};
		},
	},
});

export const { updateComponentPannel } = mainSlice.actions;

export default mainSlice.reducer;
