import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	getSellerId,
	sellerTypeWiseRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import "../autocomplete/autocomplete.css";
import {
	Backdrop,
	CircularProgress,
	FormControl,
	MenuItem,
	Select,
} from "@mui/material";
import { sellerTypeArray } from "../../utils/addSellerHelper";

export const HomeCategoryModal = ({
	handleClose,
	show,
	categoryId,
	setShowAddASellerModal,
}) => {
	const { isIndividualCategoryIdLoading, categoryName, sellerTypeWise } =
		useSelector((state) => {
			const categories = state?.category?.category?.data?.records;
			const selectedCategory = categories?.find(
				(item) => item.id === state?.category?.selectedCategoryId
			);

			return {
				isIndividualCategoryIdLoading:
					state.category.isIndividualCategoryIdLoading,
				categoryData:
					state?.category?.sellersAgainstIndividualCategoryID?.data?.records
						?.sellers,
				categoryName: selectedCategory?.categoryName,
				sellerTypeWise: state?.seller?.sellerTypeWise?.data?.records,
			};
		});

	const [selectedSellerType, setSelectedSellerType] = useState(
		sellerTypeArray[0]
	);

	const dispatch = useDispatch();

	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="lg"
			aria-labelledby="contained-modal-title-center"
			centered
			className="seller-list-Modal"
		>
			<Modal.Header closeButton className="border-0 text-light">
				<div className="d-flex align-items-center flex-md-row flex-column  justify-content-between w-100">
					<h5 className="text-center fw-bold mr-3">{categoryName}</h5>
					<div className="mx-5">
						{sellerTypeWise?.type !== "B2B" ? (
							<FormControl sx={{ minWidth: 120 }}>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={selectedSellerType ? selectedSellerType : ""}
									onChange={(e) => {
										dispatch(
											sellerTypeWiseRequest({
												type: e.target.value,
												id: categoryId.id,
											})
										);
										setSelectedSellerType(e.target.value);
									}}
									className="selected-values"
									placeholder="Select Seller Type"
								>
									{sellerTypeArray.slice(0, 3).map((elements, index) => {
										return (
											<MenuItem
												key={index}
												value={elements}
												className={`${
													elements === selectedSellerType && "d-none"
												}`}
												onClick={() => setSelectedSellerType(elements)}
											>
												{elements}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						) : (
							<h5 className="text-right fw-bold mr-3 mt-1"> B2B</h5>
						)}
					</div>
				</div>
			</Modal.Header>
			<div className="popup-height">
				<div className="mb-5">
					{isIndividualCategoryIdLoading ? (
						<>
							<Backdrop
								sx={{ color: "#fff", zIndex: 2 }}
								open={isIndividualCategoryIdLoading}
							>
								<div className="d-flex popup-height justify-content-center align-item-center">
									<CircularProgress color="inherit" />
								</div>
							</Backdrop>
						</>
					) : (
						<>
							<div className="row mt-3">
								{sellerTypeWise?.sellers?.length > 0 ? (
									sellerTypeWise?.sellers?.map((item, index) => {
										if (index % 2 !== 0) {
											return (
												<>
													<div
														key={item?.id}
														className="col-lg-5 col-md-5 d-flex"
														onClick={() => dispatch(getSellerId(item.id))}
													>
														<Link
															to={`/seller-balloon/${item.id}`}
															className="text-decoration-none links"
														>
															{item?.sellerName}
														</Link>
													</div>
													{/* <div className="col-1" /> */}
												</>
											);
										} else {
											return (
												<>
													<div className="col-lg-2 col-md-2" />
													<div
														key={item?.id}
														className="col-lg-5 col-md-5 d-flex"
														onClick={() => dispatch(getSellerId(item.id))}
													>
														<Link
															to={`/seller-balloon/${item.id}`}
															className="text-decoration-none links"
														>
															{item?.sellerName}
														</Link>
													</div>
												</>
											);
										}
									})
								) : (
									<div className="no-seller">
										As of yet, there are no{" "}
										{selectedSellerType === "For U.S. Locals Only!"
											? "US LOCAL ONLY"
											: selectedSellerType}{" "}
										sellers in this category!
									</div>
								)}
							</div>
						</>
					)}
				</div>
				<div
					onClick={() => setShowAddASellerModal(true)}
					className="add-new-seller-Parent mb-3"
				>
					<button type="button" className="btn add-new-button-sellers">
						Add a Seller to this Category
					</button>
				</div>
			</div>
		</Modal>
	);
};
