import { Backdrop, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import "./seatchSellerModal.css";
import {
	categorySellersRequest,
	getSellersDataByIndividualCategoryID,
	latestReviewData,
} from "../../redux/reducers/ducks/categoriesDuck";
import {
	getSearchModalCategoryId,
	getSellerId,
	sellerLatestReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import { Tooltip } from "@mui/material";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { appededUrl } from "../../utils/constant";

function SeatchSellerModal({
	showSeatchSellerModal,
	setShowSeatchSellerModal,
	setCategory,
	isShowReview,
	setCategoryName,
	setIsClose,
}) {
	const [categoryId, setCategoryId] = useState("");
	const [sellerId, setSellerId] = useState(null);
	useEffect(() => {
		if (showSeatchSellerModal) {
			dispatch(categorySellersRequest(""));
		}
	}, [showSeatchSellerModal]);

	const {
		latestReview,
		isIndividualCategoryIdLoading,
		allCategorySellersData,
	} = useSelector((state) => {
		return {
			isIndividualCategoryIdLoading:
				state.category.isIndividualCategoryIdLoading,
			latestReview: state.category.latestReview,
			allCategorySellersData: state?.category?.allCategorySellersData?.data,
		};
	});

	const navigate = useNavigate();

	const handleCancel = () => {
		setShowSeatchSellerModal(false);
		setCategoryId(null);
		setSellerId(null);
		dispatch(latestReviewData(null));
	};

	const hanldleCategory = () => {
		setCategory(true);
		setShowSeatchSellerModal(false);
		setCategoryId(null);
		setSellerId(null);
	};
	const dispatch = useDispatch();

	useEffect(() => {
		setCategoryId(null);
	}, [showSeatchSellerModal]);

	useEffect(() => {
		if (categoryId) {
			dispatch(getSellersDataByIndividualCategoryID(categoryId));
		} else if (sellerId) {
			dispatch(sellerLatestReviewRequest(sellerId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId, isShowReview, sellerId]);

	const hanldeLucky = () => {
		if (latestReview?.data?.["to-review"]?.id) {
			setIsClose(false);
			isShowReview(true);
			setShowSeatchSellerModal(false);
		}
		setSellerId(null);
		setCategoryId(null);
	};

	const [showElement, setShowElement] = React.useState(false);
	useEffect(() => {
		setTimeout(function () {
			if (!latestReview?.data?.["to-review"]?.id) {
				setShowElement(true);
			}
		}, 3000);
	}, [latestReview]);

	const getTooltipTitle = (latestReview) => {
		if (!latestReview && sellerId) {
			return "Coming Soon to this Seller!";
		} else if (!latestReview && categoryId) {
			return "Coming Soon to this Category!";
		} else {
			return "";
		}
	};

	return (
		<Modal
			show={showSeatchSellerModal}
			onHide={handleCancel}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="search-modal-parent"
		>
			<Modal.Header closeButton className="border-0" />
			{isIndividualCategoryIdLoading && (
				<>
					<Backdrop
						sx={{ color: "#fff", zIndex: 2 }}
						open={isIndividualCategoryIdLoading}
					>
						<div className="d-flex  justify-content-center loaderCenter align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				</>
			)}
			<div style={{ padding: "0px 10px" }}>
				<div>
					<img
						src="/icons/logo1.png"
						className="mx-auto mb-3 d-block my-auto popup_width"
						alt="logo"
					/>
				</div>
				{/* <div className='pt-1'>
          <div className='row mx-auto mt-3 d-flex justify-content-center input-group'>
            <AutoComp />
          </div>
        </div> */}

				<div className="row text-center d-flex justify-content-center mt-4 mb-3">
					<div className="col-lg-6 col-md-12 col-sm-12 mb-3">
						<Autocomplete
							id="free-solo-2-demo"
							className="inputRounded"
							popupIcon={""}
							onKeyDown={(e) => {
								if (e.code === "Enter" && e.target.value) {
									let singleSeller =
										allCategorySellersData &&
										allCategorySellersData?.filter(
											(val) =>
												val?.name?.toLowerCase() ===
												e.target.value?.toLowerCase()
										);

									if (singleSeller && singleSeller?.length > 0) {
										if (!singleSeller[0]?.isSeller) {
											setCategoryId(singleSeller?.id);
											setCategoryName(singleSeller);
											setSellerId(null);
											dispatch(getSearchModalCategoryId(singleSeller?.id));
											getToasterNotifications(
												`This is Category Name"${e.target.value}"`
											);
										} else if (singleSeller[0]?.isSeller) {
											setSellerId(singleSeller?.id);
											setCategoryId(null);
											dispatch(getSearchModalCategoryId(singleSeller?.id));

											setTimeout(() => {
												setShowSeatchSellerModal(false);
												navigate(`/seller-balloon/${singleSeller[0]?.id}`);
											}, 500);
										} else {
											setSellerId(null);
											setCategoryId(null);
											dispatch(getSearchModalCategoryId(null));
										}
									} else {
										getToasterNotifications(
											`Please check your spelling or add "${e.target.value}"`
										);
									}
								}
							}}
							onChange={(e, catSellers) => {
								if (!catSellers?.isSeller) {
									setCategoryId(catSellers?.id);
									setCategoryName(catSellers);
									setSellerId(null);
									dispatch(getSearchModalCategoryId(catSellers?.id));
								} else if (catSellers?.isSeller) {
									setSellerId(catSellers?.id);
									setCategoryId(null);
									dispatch(getSearchModalCategoryId(catSellers?.id));
								} else {
									setSellerId(null);
									setCategoryId(null);
									dispatch(getSearchModalCategoryId(null));
								}
							}}
							options={
								allCategorySellersData && allCategorySellersData?.length > 0
									? allCategorySellersData?.map((data) => {
											return {
												...data,
												name: data?.isSeller
													? data.name
													: data.name + " CATEGORY",
											};
									  })
									: []
							}
							getOptionLabel={(option) => {
								if (option && typeof option?.name === "string") {
									return option?.name;
								}
								return "";
							}}
							renderInput={(params) => (
								<div className="autocomplete-search-inputfield">
									<TextField
										className="inputRounded seller-textfield"
										{...params}
										label="Type Seller or Category"
										InputProps={{
											...params.InputProps,
										}}
									/>
								</div>
							)}
						/>
					</div>
				</div>
				<div className="text-center feeling-lucky-container mb-4">
					<div>
						<Link className="search-button text-decoration-none" to="#">
							<button
								type="submit"
								onClick={() => {
									if (categoryId) {
										hanldleCategory();
									} else if (sellerId) {
										window.location.href = appededUrl(
											`/seller-balloon/${sellerId}`
										);
										dispatch(getSellerId(sellerId));
									} else {
										getToasterNotifications("Please Select a Seller Category!");
									}
								}}
								className="l2a-btn"
							>
								Search Love to Air
							</button>
						</Link>
						<Link className="feeling-lucky text-decoration-none" to="#">
							<Tooltip
								title={getTooltipTitle(latestReview?.data?.["to-review"]?.id)}
								componentsProps={{
									tooltip: {
										sx: {
											bgcolor: "#fea9dd",
											color: "black",
											fontWeight: "700",
											fontSize: "14px",
										},
									},
								}}
								className="coming-soon-tooltip"
								placement="top"
							>
								<button
									type="submit"
									className={`${
										latestReview?.data?.["to-review"]?.id &&
										(categoryId || sellerId)
											? "feeling-lucky-btn"
											: "feeling-lucky-disable"
									}`}
									onClick={() => {
										if (
											latestReview?.data?.["to-review"]?.id &&
											(categoryId || sellerId)
										) {
											hanldeLucky();
										}
									}}
								>
									Tell me something good!
								</button>
							</Tooltip>
							{showElement &&
								((!latestReview?.data?.["to-review"]?.id && categoryId) ||
									(sellerId && !latestReview?.data?.["to-review"]?.id)) && (
									<span className="mb-3 coming-small fw-bold">
										{!latestReview?.data?.["to-review"]?.id && categoryId
											? "Coming Soon to this Category!"
											: "Coming Soon to this Seller!"}
									</span>
								)}
						</Link>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default SeatchSellerModal;
