import React, { useEffect } from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import "./guidelines.css";

function Guidelines() {
	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: "smooth", // You can remove this if you don't want smooth scrolling
			});
		}, 100);
	}, []);

	return (
		<Layout>
			<div className="container guidlines-wrapper-container p-4">
				{/* heading section  */}
				<div className="row d-flex justify-content-center">
					<h1 className="text-center mt-md-5 mt-2 fw-bold guidline-headings-main">
						Guidelines
					</h1>
					<h5 className="text-center  mb-4  fw-normal guidline-sub-headings">
						Going along with these Guidelines ensures your stories get and stay
						posted!
					</h5>
					<h3 className="text-center mt-4 mb-4 fw-bold guidline-sub-sub-headings">
						Tell the Truth:
					</h3>
					<p className="mb-4 text-justify">
						Whether you're praising or critiquing, the story you tell about your
						experience with any given Seller will have the most impact if you
						stick to the facts. Readers often have a sixth sense for the truth!
						As well, factually accurate information and details enable other
						users to make happier purchasing decisions - they also provide
						valuable information to interested Sellers who care about the
						experiences of their customers.
					</p>
				</div>
				{/*players section*/}
				<div className="row">
					<h3 className="text-center mt-4 mb-4 fw-bold guidline-sub-sub-headings">
						Keep things impersonal:
					</h3>
					<p>
						Please refrain from identifying a Seller's employees or individual
						business owners by name. You can, however, identify people by their
						positions. For instance, you can identify people as the
						receptionist, owner, designer, technician, customer service rep and
						so on.
					</p>
				</div>
				{/*reason why we are here section*/}
				<div className="row">
					<h3 className="text-center mt-4 fw-bold guidline-sub-sub-headings mb-4">
						Avoid:
					</h3>
					<p>
						To make sure your story gets and stays posted, please avoid defaming
						the Seller's reputation, exaggerating the Seller's behavior, making
						discriminatory remarks, making aggressive remarks and/or name
						calling.
					</p>
					<p>
						These behaviors are generally a sign of anger. We don't judge anger!
						We actually encourage you to vent it in a constructive, helpful and
						useful way by writing a factually accurate account of your
						experience and clicking a balloon that tells us how the Seller made
						you feel.
					</p>
					{/*why we are here section*/}
					<div className="row">
						<h3 className="text-center mt-4 mb-4 fw-bold guidline-sub-sub-headings">
							Give advice to Sellers (if you want to!):
						</h3>
						<p>
							Of course you don't have to, but if you've aired a grievance, you
							may find it satisfying to give advice to the Seller on how to fix,
							avoid or prevent your negative experience from happening to
							others. Sometimes Sellers really can't see the frustration they're
							causing Buyers. And sometimes they're eager to fix things!
						</p>
						<p>
							On the other hand, if you've loved a Seller, you may also find it
							satisfying to offer advice. Sellers absolutely love to know what
							you like best about them, including ideas you might have for their
							improvement in things like their promotional campaigns, rewards
							program, guarantee policies, shipping options or return policies.
							They're also interested in hearing your ideas for new product
							lines, new features or new services. Good Sellers want to make you
							happy, so feel free to speak up about all the things you love and
							appreciate about any given Seller!
						</p>
					</div>
					{/*L2a Community - We’re Helping Each Other section*/}
					<div className="row">
						<h3 className="text-center mt-4 mb-4 fw-bold guidline-sub-sub-headings">
							Trustworthy Stories:
						</h3>
						<p>
							Love to Air makes it a top priority to do whatever it takes to
							make sure our user stories are as genuine and unbiased as
							possible. Writers cannot in any way be affiliated, or in
							competition, with the Sellers they're writing about. Stories
							cannot be posted out of incentive or as a result of coercion.
						</p>
						<p>
							And while we employ a 2-step screening process for all content -
							and use proprietary technology, filters and algorithms to keep our
							stories/reviews as pure as possible - we can always use help with
							this! If you see a Seller story that you believe is likely fake,
							you can click the flag icon at the bottom of the story/review.
							This will send a signal telling us that you have a concern about
							the genuineness of that particular review, and we’ll investigate.
						</p>
						<p>
							Posting fabricated stories or fake reviews is a violation of our
							Terms of Service, and identified fake stories will be swiftly
							deleted.
						</p>
						<p>
							Users who post either positive or negative fake stories/reviews
							will be permanently barred from using Love to Air.
						</p>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Guidelines;
