import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import AllSearchBAr from "./allSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
	// deleteReviewRequest,
	getAdminReview,
	getReviewSellers,
	updateReviewAdminStatus,
} from "../../../redux/reducers/ducks/adminReviewDuck";
import { Button } from "react-bootstrap";
import { useState } from "react";
import MessagePopup from "./messagePopup";
import {
	getBalloon,
	getReview,
	handleAdminStatus,
} from "../../../redux/reducers/ducks/getSellersDuck";
import {
	Backdrop,
	CircularProgress,
	FormControl,
	MenuItem,
	Select,
	Tooltip,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./adminReview.css";
import DatePicker from "react-datepicker";
import "../progress/progressMainPage.css";
import moment from "moment";
import { getAllUsersResponse } from "../../../redux/reducers/ducks/userDuck";
import { Link } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import { getUniqueReviews } from "../../../utils/constant";
// import DeleteIcon from "@mui/icons-material/Delete";

// import { EmailResponsePopup } from "../contacts/emailPopup";
// import Swal from "sweetalert2";

export default function ReviewMainPage() {
	const [orderBy, setOrderBy] = useState("");
	const [orderType, setOrderType] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	// const [reviewId, setReviewId] = useState("");

	const [columnName, setColumnName] = useState("");
	const columns = [
		{ id: "code1", label: "Seller", minWidth: 150 },
		{ id: "code3", label: "Classification", minWidth: 230 },
		{ id: "code2", label: "User", minWidth: 120 },
		{ id: "code4", label: "Balloon", minWidth: 100 },
		{ id: "code5", label: "Story", minWidth: 100 },
		{ id: "code6", label: "Nominate", minWidth: 140 },
		{
			id: "Report",
			label: "Report",
			minWidth: 50,
		},
		{ id: "code8", label: "Date", minWidth: 100 },
		// { id: "code9", label: "Best Writer", minWidth: 100 },
		{ id: "code11", label: "Actions", minWidth: 100 },
	];

	const [page, setPage] = React.useState(0);
	const [sellerId, setSellerId] = useState("");
	const [userId, setUserId] = useState("");
	const [categoryID, setCategoryID] = useState("");
	const [msgSearch, setMsgSearch] = useState("");
	const [types, setTypes] = useState("");
	// const [isReviewDeleted, setIsReviewDeleted] = useState(false);

	let tommorow = new Date();
	tommorow.setDate(tommorow.getDate() + 1);

	const [loading, setLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState({
		isOpen: false,
		id: null,
	});

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(tommorow);

	useEffect(() => {
		const previousMonth = new Date(startDate.setFullYear(2023, 8, 30));
		setStartDate(previousMonth);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	const dispatch = useDispatch();

	const handleChangePage = (event, newPage) => {
		setLoading(true);
		setPage(newPage);
		dispatch(handleAdminStatus(false));
	};

	const {
		adminReview,
		user,
		adminReviewAllSellers,
		balloon,
		sellerID,
		adminReviewLoading,
		clickUser,
	} = useSelector((state) => ({
		adminReview: state?.adminReview?.adminReview,
		category: state?.category?.category?.data,
		user: state?.adminUser?.allUserData,
		adminReviewAllSellers: state?.adminReview?.adminReviewAllSellers?.data,
		balloon: state?.seller?.balloon?.data?.title,
		sellerID: state?.seller?.sellerId,
		adminReviewLoading: state?.adminReview?.adminReviewLoading,
		clickUser: state?.adminReview?.clickUser,
	}));
	const isAdminStatus = useSelector(
		(state) => state?.seller?.isAdminChangeStatus
	);

	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

	useEffect(() => {
		const requestData = {
			page: page + 1,
			timeZone: timeZone,
			...(types ? { type: types } : {}),
			...(sellerId ? { sellerId: sellerId } : {}),
			...(msgSearch ? { message: msgSearch } : {}),
			...(userId ? { userId: userId } : {}),
			...(categoryID ? { categoryId: categoryID } : {}),
			...(orderBy ? { orderBy: orderBy } : {}),
			...(orderType ? { orderType: orderType } : {}),
			...(rowsPerPage ? { pageSize: rowsPerPage } : {}),
			...(startDate
				? { startDate: moment(startDate)?.utc().format("YYYY-MM-DD") }
				: {}),
			...(endDate
				? { endDate: moment(endDate)?.utc().format("YYYY-MM-DD") }
				: {}),
		};
		dispatch(getAdminReview(requestData));
		dispatch(getReviewSellers());
		// setIsReviewDeleted(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dispatch,
		page,
		sellerId,
		userId,
		rowsPerPage,
		msgSearch,
		types,
		categoryID,
		orderBy,
		orderType,
		startDate,
		endDate,
		// isReviewDeleted,
	]);

	useEffect(() => {
		dispatch(getBalloon());
	}, []);

	useEffect(() => {
		if (clickUser) {
			setUserId(clickUser);
		}
	}, [clickUser]);

	useEffect(() => {
		if (sellerID) {
			dispatch(getReview({ sellerId: sellerID, page: page + 1 }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sellerID]);

	useEffect(() => {
		if (isAdminStatus) {
			const requestData = {
				page: page + 1,
				sellerId: "",
				userId: "",
				message: "",
				type: "",
				pageSize: rowsPerPage,
				timeZone: timeZone,
			};
			dispatch(getAdminReview(requestData));
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const [open, setOpen] = React.useState(false);
	// const handleClose = () => {
	// 	setOpen(false);
	// };

	const handleChange = (event, Id) => {
		const requestData = {
			reviewId: Id,
			approvedByAdmin: event.target.value,
		};
		dispatch(updateReviewAdminStatus(requestData));
		setTimeout(() => {
			dispatch(getAdminReview());
		}, 1000);
	};

	const handleRowsPerPage = (e) => {
		setPage(0);
		setRowsPerPage(e.target.value);
	};

	// const handleDelete = (id) => {
	// 	Swal.fire({
	// 		title: "Are you sure?",
	// 		text: "You will not be able to recover this item!",
	// 		showCancelButton: true,
	// 		confirmButtonText: "Yes",
	// 		cancelButtonText: "No",
	// 		reverseButtons: true,
	// 		customClass: {
	// 			title: "mt-4",
	// 			confirmButton: "btn bg-primary",
	// 			cancelButton: "btn btn-primary ",
	// 		},
	// 	}).then((result) => {
	// 		if (result.isConfirmed) {
	// 			dispatch(deleteReviewRequest(id));
	// 			setIsReviewDeleted(true);
	// 		}
	// 	});
	// };

	useEffect(() => {
		dispatch(getAllUsersResponse());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getProperClass = (approvedByAdmin) => {
		if (approvedByAdmin) return "seller-approve-color";
		return "seller-rejected-color";
	};

	const uniqueReviews = getUniqueReviews(
		adminReview?.data && adminReview?.data?.records
	);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{((!isAdminStatus && loading) || adminReviewLoading) && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 1000 }}
					open={(!isAdminStatus && loading) || adminReviewLoading}
				>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<p>
						These Date fields will have last 30 days Data selected By Default.
					</p>
					{/* <div className="parent-cat"><div> Review Portal</div></div> */}
					<div className="review-datePicker-child">
						<p></p>
						<div>
							<DatePicker
								className="datePicker-input inner-body-date-picker"
								dateFormat="MMM-dd-yyyy"
								selected={startDate}
								maxDate={endDate}
								onChange={(date) => setStartDate(date)}
							/>
						</div>
						<div className="second-datepicker inner-body-date-picker">
							<DatePicker
								className="datePicker-input"
								dateFormat="MMM-dd-yyyy"
								selected={endDate}
								maxDate={tommorow}
								minDate={startDate}
								onChange={(date) => setEndDate(date)}
							/>
						</div>
					</div>
					<AllSearchBAr
						msgSearchData={msgSearch}
						setMsgSearchData={setMsgSearch}
						setSellersID={setSellerId}
						setUsersID={setUserId}
						setCategoryId={setCategoryID}
						selectType={setTypes}
						sellerId={sellerId}
						setPage={setPage}
						isLoading={setLoading}
					/>
					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}

												{column.label === "Nominate" ||
												column.id === "Report" ? (
													orderBy === "ascending" &&
													(columnName === column.label ||
														columnName === column.id) ? (
														<Tooltip
															componentsProps={{
																tooltip: {
																	sx: {
																		bgcolor: "#fea9dd",
																		color: "black",
																		fontWeight: "700",
																		fontSize: "14px",
																	},
																},
															}}
															title="Descending Order"
															placement="top"
														>
															<div
																className="admin-review-panel-cursor"
																style={{
																	width: "100%",
																	textAlign: "center",
																}}
																onClick={() => {
																	setOrderBy("descending");
																	dispatch(handleAdminStatus(false));
																	setLoading(true);
																	setColumnName(column.label);
																	if (column.label === "Nominate") {
																		setOrderType("like");
																	}
																	if (column.id === "Report") {
																		setOrderType("report");
																		setColumnName(column.id);
																	}
																}}
															>
																<FontAwesomeIcon icon={faArrowUp} />
															</div>
														</Tooltip>
													) : (
														<Tooltip
															title="Ascending Order"
															componentsProps={{
																tooltip: {
																	sx: {
																		bgcolor: "#fea9dd",
																		color: "black",
																		fontWeight: "700",
																		fontSize: "14px",
																	},
																},
															}}
															placement="top"
														>
															<div
																className="admin-review-panel-cursor"
																style={{
																	width: "100%",
																	textAlign: "center",
																}}
																onClick={() => {
																	setOrderBy("ascending");
																	dispatch(handleAdminStatus(false));
																	setLoading(true);
																	setColumnName(column.label);
																	if (column.label === "Nominate") {
																		setOrderType("like");
																	}
																	if (column.id === "Report") {
																		setOrderType("report");
																		setColumnName(column.id);
																	}
																}}
															>
																<FontAwesomeIcon
																	icon={faArrowDown}
																	size="sm"
																	className="admin-review-panel-cursor"
																/>
															</div>
														</Tooltip>
													)
												) : (
													<Tooltip
														title="Ascending Order"
														componentsProps={{
															tooltip: {
																sx: {
																	bgcolor: "transparent",
																	color: "white",
																	fontWeight: "700",
																	fontSize: "0px",
																},
															},
														}}
														placement="top"
													>
														<div
															className="admin-review-panel-cursor"
															style={{
																width: "100%",
																textAlign: "center",
																color: "white",
															}}
														>
															<FontAwesomeIcon
																icon={faArrowDown}
																size="sm"
																className="admin-review-panel-cursor"
															/>
														</div>
													</Tooltip>
												)}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{adminReview?.data?.records?.length > 0 ? (
										uniqueReviews?.map((review) => {
											const date = moment?.utc(review?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");
											const sellerObj = adminReviewAllSellers?.records?.find(
												(seller) => {
													return seller.id === review.sellerId;
												}
											);

											const isReviewUpdated = review?.isUpdatedReviews
												? "Updated"
												: "";

											const userObj = user?.find((user) => {
												return user.id === review.userId;
											});

											// const splitted = review?.titleSlug?.split("-");

											// const type = `${splitted[1]}`.replace(/\b\w/g, (c) =>
											// 	c.toUpperCase()
											// );
											const image = balloon?.find((img) => {
												return img.id === review.titleId;
											});
											const count = review?.likeDislike?.filter((i) => {
												return i.type === "like";
											});
											const nominate = count?.length;

											const report = review?.likeDislike?.filter((i) => {
												return i.type === "report";
											});
											const Report = report?.length;

											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={review?.id}
												>
													<TableCell>
														{" "}
														<Link
															className="admin-seller-name-link"
															to={`/seller-balloon/${review?.sellerId}`}
															target="_blank"
														>
															{sellerObj?.sellerName}
														</Link>
													</TableCell>
													<TableCell className="ps-3">
														{sellerObj?.type ===
														"U.S. National, Regional Online. (Default)"
															? ""
															: sellerObj?.type}
													</TableCell>
													<TableCell>{userObj?.username}</TableCell>
													<TableCell>
														<div>
															<img
																src={`/icons/${image?.imageName}.png`}
																alt="image"
																className="w-100"
															/>
														</div>
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														<Button
															variant="contained"
															onClick={() =>
																setOpenDialog({
																	isOpen: true,
																	msg: review?.message,
																	sellerName: userObj?.username,
																})
															}
															className="iconButton"
															sx={{ ml: 5 }}
														>
															{review?.message?.length > 0 ? (
																<CheckCircle />
															) : (
																""
															)}
														</Button>
													</TableCell>
													<TableCell
														style={{
															textAlign: "center",
														}}
													>
														{nominate}
													</TableCell>
													<TableCell
														style={{
															textAlign: "center",
															color: Report ? "red" : "black",
															fontWeight: Report ? "600" : "200",
														}}
													>
														{Report}
													</TableCell>
													<TableCell>
														{formattedDate}{" "}
														{isReviewUpdated && `(${isReviewUpdated})`}
													</TableCell>

													{/* <TableCell
														onClick={() => {
															setOpen(true);
															setReviewId(review.id);
														}}
													>
														Best Writer
														{review?.bestWriter && "  (Already Nominated)"}
													</TableCell> */}
													<TableCell>
														<FormControl sx={{ minWidth: 120 }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={review?.approvedByAdmin}
																onChange={(e) => {
																	handleChange(e, review.id);
																	setLoading(true);
																}}
																className={`selectStatuses  ${getProperClass(
																	review?.approvedByAdmin
																)}`}
																placeholder="Select status"
															>
																<MenuItem value={false}>Reject</MenuItem>
																<MenuItem value={true}>Approve</MenuItem>
															</Select>
														</FormControl>
													</TableCell>
													{/* <TableCell>
														{" "}
														<DeleteIcon
															onClick={() => {
																handleDelete(review.id);
															}}
															className="text-danger admin-review-panel-cursor iconMargin"
														/>
													</TableCell> */}
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No review exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{adminReview?.data?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
									count={adminReview?.data?.totalRecords}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
						<MessagePopup showPopup={openDialog} setShowPopup={setOpenDialog} />
					</Paper>
				</Card>
			</div>
			{/* <EmailResponsePopup
				openModal={open}
				onClose={handleClose}
				userReviewId={reviewId}
				reviewPage
			/> */}
		</div>
	);
}
