import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import "../categories/popup.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import {
	Autocomplete,
	Backdrop,
	CircularProgress,
	TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "./seededData.css";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import {
	createSeededDataRequest,
	createSeededDataResponse,
	updateSeededDataRequest,
} from "../../../redux/reducers/ducks/seededDuck";
import {
	sellerTypeWiseRequest,
	sellerTypeWiseResponse,
} from "../../../redux/reducers/ducks/getSellersDuck";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";
// import moment from "moment";

export default function AddSeededData(props) {
	const { showDialog, setShowDialog } = props;
	const [isSubmittedSeeded, setIsSubmittedSeeded] = useState(false);
	const [selectedSellerCategories, setSelectedSellerCategories] = useState([]);

	const { categoryData, sellerData, sellerTypeWise, createSeededData } =
		useSelector((state) => {
			return {
				newCategoryData: state?.category?.newCategoryData,
				sellerData: state?.seller?.seller?.data?.records,
				categoryData: state?.category?.category?.data?.records,
				sellerTypeWise: state?.seller?.sellerTypeWise?.data?.records,
				createSeededData: state?.seededData?.createSeededData,
				updateSeededData: state?.seededData?.updateSeededData,
			};
		});

	const balloon = useSelector((state) => state?.seller?.balloon?.data?.title);

	const handleClose = () => {
		setShowDialog({
			...showDialog,
			isOpen: false,
		});
		dispatch(sellerTypeWiseResponse());
	};
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		const formattedDate = moment.utc(showDialog?.date).format("YYYY-MM-DD");

		const payloadData = {
			categoryName: showDialog?.categoryName,
			sellerName: showDialog?.sellerName,
			review: showDialog?.review,
			date: formattedDate,
			balloonName: showDialog?.balloonName,
			userName: showDialog?.userName,
		};
		if (!showDialog?.id) {
			if (
				!showDialog?.sellerName ||
				!showDialog?.categoryName ||
				!showDialog?.balloonName ||
				!showDialog?.date ||
				!showDialog?.userName
			) {
				setIsSubmittedSeeded(false);
				getToasterErrors("Please fill all fields", "top-right");
				return;
			} else {
				setIsSubmittedSeeded(true);
				dispatch(createSeededDataRequest(payloadData));
				setIsSubmittedSeeded(false);
				e.preventDefault();
			}
		} else {
			if (
				!showDialog?.sellerName ||
				!showDialog?.categoryName ||
				!showDialog?.balloonName ||
				!showDialog?.date ||
				!showDialog?.userName
			) {
				setIsSubmittedSeeded(false);
				getToasterErrors("Please fill all fields", "top-right");
				return;
			} else {
				setIsSubmittedSeeded(true);
				const finalPayload = {
					data: payloadData,
					id: showDialog?.id,
				};
				dispatch(updateSeededDataRequest(finalPayload));
				setIsSubmittedSeeded(false);
				e.preventDefault();
			}
		}
		setShowDialog({
			isOpen: false,
			categoryName: null,
			sellerName: null,
			review: null,
			date: null,
			balloonName: null,
			userName: null,
		});
	};

	const handleChange = (event) => {
		setShowDialog({
			...showDialog,
			review: event,
		});
	};

	useEffect(() => {
		if (createSeededData?.status === 201 || createSeededData?.status === 200) {
			dispatch(createSeededDataResponse());
			dispatch(sellerTypeWiseResponse());
			setSelectedSellerCategories([]);
			getToasterNotifications("Seeded Data successfully");
		}
	}, [createSeededData]);

	const editorRef = useRef(null);

	function BootstrapDialogTitle(props) {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	}

	return (
		<div>
			<Dialog
				open={showDialog.isOpen}
				onClose={handleClose}
				fullWidth
				aria-labelledby="draggable-dialog-title"
			>
				{isSubmittedSeeded && (
					<Backdrop sx={{ color: "#fff", zIndex: 2 }} open={isSubmittedSeeded}>
						<div className="d-flex  loaderCenter justify-content-center align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				)}
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				/>
				<div className="d-flex justify-content-center">
					<div className="rounded-4 w-75 mb-3 mt-1">
						<h5 className="text-center mt-3 fw-bold">Seed New Data</h5>
						<div className="p-3 mb-4 text-center">
							<form>
								<div className="d-flex justify-content-center">
									<Autocomplete
										id="free-solo-2-demo"
										className="auto-category-seller mt-3 text-center"
										popupIcon={""}
										onChange={(e, seller) => {
											if (seller) {
												setShowDialog({
													...showDialog,
													sellerName: seller?.sellerName,
												});
												setSelectedSellerCategories(seller?.categories);
											} else {
												setShowDialog({
													...showDialog,
													sellerName: null,
												});
												setSelectedSellerCategories([]);
											}
										}}
										sx={{
											".MuiOutlinedInput-root": {
												padding: "5px",
											},
										}}
										value={
											sellerData?.filter(
												(data) => data?.sellerName === showDialog?.sellerName
											)[0] || null
										}
										options={
											sellerTypeWise?.sellers?.length > 0
												? sellerTypeWise?.sellers
												: sellerData && sellerData?.length > 0
												? sellerData
												: []
										}
										getOptionLabel={(option) =>
											option.sellerName ? option.sellerName : ""
										}
										renderInput={(params) => {
											return (
												// created the inputfield for searching
												<div className="autocomplete-search-inputfield">
													<TextField
														className="mb-3 text-center"
														id="autocomplete"
														clearIcon={null}
														sx={{
															"#free-solo-2-demo": {
																textAlign: "center",
															},
														}}
														{...params}
														placeholder="Select Seller"
													/>
												</div>
											);
										}}
									/>
								</div>
								<div className="d-flex justify-content-center">
									<Autocomplete
										id="free-solo-2-demo"
										className="auto-category-seller mt-3 text-center"
										popupIcon={""}
										onChange={(e, category) => {
											if (category?.categoryName) {
												setShowDialog({
													...showDialog,
													categoryName: category?.categoryName,
												});
												dispatch(
													sellerTypeWiseRequest({
														id: category.id,
														// type: sellerTypeArray[0],
													})
												);
											} else {
												setShowDialog({
													...showDialog,
													categoryName: null,
												});
											}
										}}
										value={
											categoryData?.filter(
												(data) =>
													data?.categoryName === showDialog?.categoryName
											)[0] || null
										}
										sx={{
											".MuiOutlinedInput-root": {
												padding: "5px",
											},
										}}
										options={
											selectedSellerCategories?.length > 0
												? selectedSellerCategories
												: categoryData && categoryData?.length > 0
												? categoryData
												: []
										}
										getOptionLabel={(option) => {
											if (option && typeof option?.categoryName === "string") {
												if (option?.categoryName === "Other") {
													return option?.categoryName;
												} else if (option?.categoryName === "Other B2B") {
													return (
														option?.categoryName?.replace("B2B", "").trim() +
														` (${option?.type})`
													);
												} else {
													return option?.categoryName + ` (${option?.type})`;
												}
											}
											return "";
										}}
										clearOnBlur={true}
										rules={{ required: true }}
										renderInput={(params) => {
											return (
												// created the inputfield for searching
												<div className="autocomplete-search-inputfield">
													<TextField
														className="mb-3 text-center"
														id="autocomplete"
														sx={{
															"#free-solo-2-demo": {
																textAlign: "center",
															},
														}}
														{...params}
														placeholder="Select Category"
													/>
												</div>
											);
										}}
									/>
								</div>

								<input
									required
									className="inputField mt-3 w-100 text-center admin-category-text-field"
									placeholder="Enter Username"
									value={showDialog?.userName}
									onChange={(e) => {
										setShowDialog({
											...showDialog,
											userName: e?.target.value,
										});
									}}
								/>
								<div className="d-flex justify-content-center">
									<Autocomplete
										id="free-solo-2-demo"
										className="auto-category-seller mt-3 text-center"
										popupIcon={""}
										onChange={(e, selectedBalloon) => {
											setShowDialog({
												...showDialog,
												balloonName: selectedBalloon?.title,
											});
										}}
										sx={{
											".MuiOutlinedInput-root": {
												padding: "5px",
											},
										}}
										value={
											balloon?.filter(
												(data) => data?.title === showDialog?.balloonName
											)[0] || null
										}
										options={balloon && balloon?.length > 0 ? balloon : []}
										getOptionLabel={(option) =>
											option.title ? option.title : ""
										}
										clearOnBlur={true}
										rules={{ required: true }}
										renderInput={(params) => {
											return (
												// created the inputfield for searching
												<div className="autocomplete-search-inputfield">
													<TextField
														className="mb-3 text-center"
														id="autocomplete"
														sx={{
															"#free-solo-2-demo": {
																textAlign: "center",
															},
														}}
														{...params}
														placeholder="Select Balloon Type"
													/>
												</div>
											);
										}}
									/>
								</div>
								<div className="mt-1">
									<DatePicker
										className="seed-datePicker-input inner-seed-date-picker"
										dateFormat="MMM-dd-yyyy"
										selected={showDialog?.date}
										onChange={(date) => {
											setShowDialog({
												...showDialog,
												date: date,
											});
										}}
									/>
								</div>
								<div className="mt-3">
									<Editor
										onInit={(evt, editor) => (editorRef.current = editor)}
										apiKey={process.env.REACT_APP_EDITOR_KEY}
										init={{
											height: 350,
											menubar: false,
										}}
										value={showDialog?.review}
										onEditorChange={handleChange}
									/>
								</div>
								<div className="text-center mt-2 ">
									<button
										type="submit"
										onClick={handleSubmit}
										className="btn addCategoryBtn px-5 py-2"
									>
										Submit
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
