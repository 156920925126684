import React, { useEffect, useState } from "react";
import { Modal, Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	signUpRequest,
	updateIsSignup,
	updateUser,
} from "../../redux/reducers/ducks/autorizeDuck";
import "./modals.css";
import { Backdrop, CircularProgress } from "@mui/material";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function SignUpModal({
	isShowSignUpModal,
	setIsShowSignUpModal,
	setIsLogin,
	reviews,
	isParent,
	setIsParent,
}) {
	const handleClose = () => setIsShowSignUpModal(false);

	const [show, setShow] = useState(false);
	const [message, setMessage] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [isSignupClicked, setIsSignupClicked] = useState(false);
	const [isSignUpSubmitted, setIsSignUpSubmitted] = useState(false);
	const [isReCaptchaValue, setIsReCaptchaValue] = useState(false);
	const [isEyeIconClick, setIsEyeIconClick] = useState(false);

	const dispatch = useDispatch();
	const { signUpUser, isSignup } = useSelector((state) => {
		return {
			signUpUser: state.authorization?.signUpUser,
			isSignup: state.authorization?.isSignup,
		};
	});
	const passwordPattern =
		/^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,}$/;

	function isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	}

	// function made for handling the api
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!passwordPattern.test(password)) {
			setPasswordError(
				" Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character."
			);
			return setIsShowSignUpModal(true);
		}
		if (!isValidEmail(email)) {
			setEmailError("please enter valid email");
			return setIsShowSignUpModal(true);
		}
		setIsSignupClicked(true);

		event.preventDefault();
		if (isReCaptchaValue) {
			const requestData = {
				name: name,
				username: userName,
				email: email,
				password: password,
				roles: "l2a_user",
				status: "inactive",
				profileIcon: "",
				keyLogin: false,
				sellerId: reviews?.sellerId,
				titleId: reviews?.titleId,
				message: reviews?.message,
			};
			dispatch(updateIsSignup(false));
			dispatch(updateUser(null));
			dispatch(signUpRequest(requestData));
			setIsSignUpSubmitted(true);
		}
	};

	function onChange(value) {
		if (value) {
			setIsReCaptchaValue(true);
		} else {
			setIsReCaptchaValue(false);
		}
	}

	useEffect(() => {
		if (
			(signUpUser?.status === 201 || signUpUser?.status === 200) &&
			!isSignup
		) {
			getToasterNotifications(signUpUser?.data?.message);
			dispatch(updateIsSignup(true));
			setIsShowSignUpModal(false);
			dispatch(updateUser(null));
			setIsSignUpSubmitted(false);
			if (isParent) {
				setIsParent(false);
			}
		} else if (
			signUpUser?.response?.status === 400 ||
			signUpUser?.response?.status === 409
		) {
			getToasterErrors(signUpUser?.response?.data?.message);
			dispatch(updateIsSignup(false));
			dispatch(updateUser(null));
			setIsSignUpSubmitted(false);
		} else if (signUpUser?.response?.status === 500) {
			setMessage(signUpUser?.response?.data?.message);
			setShow(true);
			dispatch(updateIsSignup(false));
			setIsSignUpSubmitted(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signUpUser, isSignup]);

	const hanldleLogin = () => {
		setIsLogin(true);
		setIsShowSignUpModal(false);
	};

	useEffect(() => {
		if (isReCaptchaValue) {
			const requestData = {
				name: name,
				username: userName,
				email: email,
				password: password,
				roles: "l2a_user",
				status: "inactive",
				profileIcon: "",
				keyLogin: false,
				sellerId: reviews?.sellerId,
				titleId: reviews?.titleId,
				message: reviews?.message,
			};
			dispatch(updateIsSignup(false));
			dispatch(updateUser(null));
			dispatch(signUpRequest(requestData));
			setIsSignUpSubmitted(true);
			setName("");
			setEmail("");
			setPassword("");
			setUserName("");
		}
	}, [isReCaptchaValue]);

	const handlePasswordChange = (event) => {
		setPasswordError("");
		const passwordValue = event.target.value;
		setPassword(passwordValue);
		setPasswordError("");
	};

	useEffect(() => {
		if (isShowSignUpModal) {
			setPasswordError(null);
			setEmailError(null);
		}
	}, [isShowSignUpModal]);

	return (
		<div>
			{/*Toaster used to display error message if user cannot signup*/}
			<Modal
				show={isShowSignUpModal}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				{!isSignup && isSignUpSubmitted && (
					<Backdrop
						sx={{ color: "#fff", zIndex: 2 }}
						open={!isSignup && isSignUpSubmitted}
					>
						<div className="d-flex  justify-content-center loaderCenter align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				)}
				<ToastContainer position="top-end" className="p-3">
					<Toast
						onClose={() => setShow(false)}
						bg={"warning"}
						show={show}
						delay={2000}
						autohide
					>
						<Toast.Body>{message}</Toast.Body>
					</Toast>
				</ToastContainer>
				<Modal.Header
					closeButton
					className="fw-bold d-flex align-items-center justify-content-start"
				>
					<img src="/icons/logo2.png" className="mx-2 login-logo" alt="logo" />
					<div className="mt-4 mx-3 text-center fst-italic">
						Please Sign Up to Complete this Action.
					</div>
				</Modal.Header>
				<Modal.Body className="text-center">
					<div>
						<div className="d-flex justify-content-center signup">
							<form
								onSubmit={handleSubmit}
								className="mt-4 sign-modal-input-width rounded-4 bg-white"
							>
								<h2 className="text-center mt-4 mb-4 fs-4">Sign Up</h2>
								<div className="form-group mb-3 sign-modal-input-width  mx-auto shadow-md">
									<input
										className="w-100 border rounded shadow-sm p-2 "
										name="full_name"
										minLength="3"
										required
										pattern="\S(.*\S)?"
										title="This field is required and not include white spaces"
										type="text"
										id="inputFullName"
										placeholder="Name"
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
								<div className="form-group mb-3 sign-modal-input-width  mx-auto shadow-md">
									<input
										className="w-100 border rounded p-2 shadow-sm"
										minLength="3"
										required
										name="user_name"
										type="text"
										id="inputUserName"
										placeholder="Username"
										pattern="\S(.*\S)?"
										title="This field is required and not include white spaces"
										onChange={(e) => {
											setUserName(e.target.value);
										}}
									/>
								</div>
								<div className="form-group mb-3 sign-modal-input-width  mx-auto shadow-md">
									<input
										type="email"
										required
										className="w-100 border rounded p-2 shadow-md"
										name="email"
										id="inputEmail"
										placeholder="Email"
										pattern="\S(.*\S)?"
										title="This field is required and not include white spaces"
										onChange={(e) => {
											setEmail(e.target.value);
											setEmailError("");
										}}
									/>
									{isSignupClicked && emailError && (
										<div className="error-message">{emailError}</div>
									)}
								</div>
								<div className="form-group  position-relative mb-3 sign-modal-input-width  mx-auto shadow-md">
									<input
										className="w-100 border rounded p-2 shadow-sm"
										name="password"
										required
										type={isEyeIconClick ? "text" : "password"}
										pattern="\S(.*\S)?"
										title="This field is required and not include white spaces"
										id="inputPassword"
										placeholder="Password"
										onChange={handlePasswordChange}
									/>
									{isEyeIconClick && (
										<span
											className="eye-icon"
											onClick={() => setIsEyeIconClick(false)}
										>
											<FontAwesomeIcon icon={faEye} />
										</span>
									)}
									{!isEyeIconClick && (
										<span
											className="eye-icon"
											onClick={() => setIsEyeIconClick(true)}
										>
											<FontAwesomeIcon icon={faEyeSlash} />
										</span>
									)}
									<span
										className={`font-password   ${
											passwordError && "error-message"
										}`}
									>
										Password must be at least 8 characters long and include at
										least one uppercase letter, one lowercase letter, and one
										special character.
									</span>
								</div>
								<div className="text-center">
									{/*created the signup button*/}
									<button type="submit" className="signup-btn">
										Sign Up
									</button>
								</div>
								<div className="recaptchParent">
									{isSignupClicked && (
										<ReCAPTCHA
											style={{ display: "inline-block" }}
											theme="dark"
											sitekey={process.env.REACT_APP_ReactCaptcha}
											onChange={onChange}
										/>
									)}
								</div>
								<div className="row d-flex justify-content-center">
									<div className="col-lg-4 col-md-8 col-sm-8 signup-link">
										<p className="text-center">
											Already Signed up? Please{" "}
											<span onClick={hanldleLogin}>
												<Link to="" className="fw-bold text-decoration-none">
													Login!
												</Link>
											</span>
										</p>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/*displays the advertisement modal*/}
		</div>
	);
}

export default SignUpModal;
