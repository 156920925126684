import React from "react";
import ChildFooter from "../childLayout/childFooter/childFooter";
import ChildHeader from "../childLayout/childHeader/childHeader";

function ChildLayout({ children }) {
	return (
		<div>
			<ChildHeader />
			{children}
			<ChildFooter />
		</div>
	);
}

export default ChildLayout;
