import axios from "./Axios";

export const getInstagramStories = async (data) => {
	try {
		let res = await axios.get("instaStories/all", {
			params: {
				page: data?.page,
				pageSize: data?.pageSize,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const submitInstaStoryApi = async (data) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.post("instaStories/submit", data, {
			headers: {
				// "Content-Type": "multipart/form-data",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
