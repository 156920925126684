import { put, call, takeLatest } from "redux-saga/effects";
import {
	allInstagramStoriesRequest,
	getAllInstgramStoriesResponse,
	isInstgramLoading,
	submitInstagramStoriesRequest,
} from "../../reducers/ducks/instagramDuck";
import {
	getInstagramStories,
	submitInstaStoryApi,
} from "../../../api/instagramStoriesAPi";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";

export function* instagramSaga({ payload }) {
	try {
		yield put(isInstgramLoading(true));
		const data = yield call(getInstagramStories, payload);
		yield put(getAllInstgramStoriesResponse(data));
		yield put(isInstgramLoading(false));
	} catch (error) {
		return error;
	}
}

export function* submitInstaStorySaga({ payload }) {
	try {
		yield put(isInstgramLoading(true));
		const data = yield call(submitInstaStoryApi, payload);
		if (data?.status === 201) {
			getToasterNotifications(
				"Your Instagram Story has been added successfully!"
			);
		}
		yield put(isInstgramLoading(false));
	} catch (error) {
		yield put(isInstgramLoading(false));
		getToasterErrors(error?.message);
		return error;
	}
}

export function* watchInstagramSagas() {
	yield takeLatest(allInstagramStoriesRequest.type, instagramSaga);
	yield takeLatest(submitInstagramStoriesRequest.type, submitInstaStorySaga);
}
