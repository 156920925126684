import React, { useState, useEffect } from "react";
import { Navbar, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SeatchSellerModal from "../../../../pages/seatchSellerModal/seatchSellerModal";
import SearchIcon from "@mui/icons-material/Search";
import CategoriesModal from "../../../modals/categoriesModal";
import "./header.css";
import { useDispatch } from "react-redux";
import { updateIsLogout } from "../../../../redux/reducers/ducks/getSellersDuck";
import { useSelector } from "react-redux";
import "./animatedModal.css";
import CloseIcon from "@mui/icons-material/Close";
import {
	loginResponse,
	refreshTokenResponse,
	updateLoginUser,
	updateToken,
} from "../../../../redux/reducers/ducks/autorizeDuck";
import {
	latestReviewData,
	updateIndividualCategoryIdLoading,
} from "../../../../redux/reducers/ducks/categoriesDuck";
import ReviewModal from "../../../modals/reviewModal/reviewModal";
import { Grid } from "@mui/material";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import AddAsellerModal from "../../../modals/addAsellerModal";
import { updateActiveHeaderLink } from "../../../../redux/reducers/ducks/adminReviewDuck";
import ReportBug from "../../../reportBugModal/reportBug";

const Header = () => {
	const [catgory, setCategory] = useState(false);
	const [showSeatchSellerModal, setShowSeatchSellerModal] = useState(false);
	const [showAddASellerModal, setShowAddASellerModal] = useState(false);

	const [showReportBug, setShowReportBug] = useState(false);

	const [showReview, setShowReview] = useState(false);

	const [categoryname, setCategoryName] = useState(null);
	const [isClose, setIsClose] = useState(false);

	const [isBeforeWhite, setIsBeforeWhite] = useState(false);
	const [isShowMenue, setIsShowMenue] = useState(false);

	const { activeSideBarLink } = useSelector((state) => {
		return {
			activeSideBarLink: state.adminReview?.activeSideBarLink,
		};
	});

	const { refreshToken } = useSelector((state) => ({
		refreshToken: state.authorization?.refreshToken,
	}));

	const dispatch = useDispatch();

	const navigate = useNavigate();
	let token = localStorage.getItem("token");

	useEffect(() => {
		if (catgory) {
			setShowSeatchSellerModal(false);
		}
	}, [catgory]);

	const handleSeatchSellerModal = () => setShowSeatchSellerModal(true);

	useEffect(() => {
		if (showReview || isClose) {
			setIsBeforeWhite(true);
			setTimeout(() => {
				setIsBeforeWhite(false);
			}, 1000);
		}
	}, [showReview, isClose]);

	useEffect(() => {
		if (refreshToken?.response?.status === 401) {
			localStorage.setItem("adminToken", "");
			localStorage.setItem("token", "");
			dispatch(loginResponse());
			dispatch(updateLoginUser(null));
			dispatch(updateToken(null));
		}
	}, [refreshToken]);

	useEffect(() => {
		async function refresh() {
			try {
				dispatch(refreshTokenResponse());
			} catch (error) {
				return error;
			}
		}
		// Refresh the token every hour
		const intervalId = setInterval(() => {
			// let token = localStorage.getItem("token");
			// let adminToken = localStorage.getItem("adminToken");

			// if (token) {
			// 	localStorage.setItem("token", "");
			// 	window.location.href = "/loginUser";
			// } else if (adminToken) {
			// 	localStorage.setItem("adminToken", "");
			// 	window.location.href = "/admin";
			// }
			refresh();
		}, 1000 * 29 * 30);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<>
			<Navbar expand="md" className="navbar  shadow headr-nav-width">
				<Container fluid className="container-fluid-margin">
					<div className="d-flex align-items-center">
						<Link to="/home">
							<img
								src="/icons/logo2.png"
								className="header-logo mx-2"
								alt="logo"
								onClick={() => {
									dispatch(updateActiveHeaderLink("home"));
									navigate("/home");
								}}
							/>
						</Link>
						<div className="d-flex">
							<div className="report-bug-parent">
								<div className="beta-link report-bug-parent">Beta</div>
								<div
									className="report-bug"
									onClick={() => setShowReportBug(true)}
								>
									Report a bug.
								</div>
							</div>
							{/* <Link
								to="/best-writers-sellers"
								className="win_free fw-bold d-md-flex d-none"
							>
								Win A Free T-Shirt or Grrr Dad Hat!
							</Link> */}
						</div>
					</div>
					<div className="d-flex align-items-center">
						<div
							className="search_icon mt-md-2 mt-2"
							onClick={() => {
								dispatch(latestReviewData(null));
								dispatch(updateIndividualCategoryIdLoading(false));
							}}
						>
							<SearchIcon
								onClick={() => {
									handleSeatchSellerModal();
								}}
							/>
						</div>
						<div className="nav_item mt-2  w-100">
							<div className="mr-1 align-items-md-center align-items-start d-none d-md-flex flex-row">
								<div className="mt-1">
									<Link to="/about">
										<button className="btn justify-content-end login-btn rounded-pill">
											About
										</button>
									</Link>
								</div>
							</div>
							<div className="mr-1 align-items-md-center align-items-start d-flex flex-row">
								<div>
									{!token ? (
										<Link to="/loginUser">
											<button className="btn justify-content-end login-btn rounded-pill">
												Login
											</button>
										</Link>
									) : (
										<Link to="/loginUser">
											<button
												onClick={() => {
													localStorage.removeItem("token");
													dispatch(updateIsLogout(true));
													dispatch(updateLoginUser(null));
													dispatch(loginResponse());
												}}
												className="btn justify-content-end login-btn rounded-pill"
											>
												Logout
											</button>
										</Link>
									)}
								</div>
							</div>
						</div>
						<HorizontalSplitIcon
							fontSize="large"
							aria-controls="navbarScroll"
							className="custom-menu-icon ms-3 mb-1 d-md-none d-flex"
							onClick={() => setIsShowMenue(!isShowMenue)}
						/>
					</div>
				</Container>

				<HorizontalSplitIcon
					fontSize="large"
					className="custom-menu-icon mb-1 me-5 mt-2 navbar-toggler-desktop"
					onClick={() => setIsShowMenue(!isShowMenue)}
				/>
			</Navbar>
			{isShowMenue && (
				<Grid item lg={3} className="navbar navbar-grid">
					<nav
						id="sidebarMenu"
						className="w-100 d-lg-block bg-white  mt-0 pt-0"
					>
						<div className="d-flex navbar justify-content-between align-items-start mt-0 py-0 mb-0">
							<div className="d-flex flex-column">
								{/* <div
									className={`beta-parent-left d-flex d-md-none ${
										activeSideBarLink === "Beta"
											? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
											: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
									}`}
									onClick={() => {
										dispatch(updateActiveHeaderLink("Beta"));
										navigate("/");
										setIsShowMenue(false);
									}}
								>
									Beta
								</div> */}

								<div
									className={`win_free d-flex d-md-none fw-bold ${
										activeSideBarLink === "shirt"
											? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
											: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
									}`}
									onClick={() => {
										dispatch(updateActiveHeaderLink("shirt"));
										navigate("/best-writers-sellers");
									}}
								>
									{/* <Link to="/best-writers-sellers">
										Win A Free T-Shirt or Grrr Dad Hat!
									</Link> */}
								</div>
								<div className="nav_item ms-1 d-flex flex-column d-md-none">
									<div className="w-100 align-items-md-center align-items-start d-flex flex-row">
										<div
											className={` col-lg-12 w-100 col-md-12 col-sm-12 ml-2 mt-2 header-footer-container-desktop-login ${
												activeSideBarLink === "about"
													? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
													: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
											}`}
											onClick={() => {
												dispatch(updateActiveHeaderLink("about"));
												navigate("/about");
											}}
										>
											About
										</div>
									</div>
								</div>
								<div>
									<div
										className={`col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === "contact"
												? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("contact"));
											navigate("/contact-us");
										}}
									>
										Contact Us
									</div>
									<div
										className={`col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === "best"
												? "activeDiv-header  my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("best"));
											navigate("/best-writers-sellers");
										}}
									>
										Writers Only
									</div>
									<div
										className={` d-md-block  col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === "guideline"
												? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("guideline"));
											navigate("/guideline");
										}}
									>
										Guidelines
									</div>
									<div
										className={` d-md-block  col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === "forSellers"
												? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("forSellers"));
											navigate("/seller-instructions");
										}}
									>
										For Sellers
									</div>
									<div
										className={`col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === "privacy"
												? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("privacy"));
											navigate("/privacy");
										}}
									>
										Privacy
									</div>
									{token ? (
										<div
											className={`col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
												activeSideBarLink === "profile"
													? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
													: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
											}`}
											onClick={() => {
												dispatch(updateActiveHeaderLink("profile"));
												navigate("/profile");
											}}
										>
											Profile
										</div>
									) : undefined}
									{/* <div
										className={`col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === "winning"
												? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("winning"));
											navigate("/best-writer-story");
										}}
									>
										Winning Stories
									</div> */}

									{/* <div
										className={` d-md-block  col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === "Outliers"
												? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("Outliers"));
											navigate("/outlier");
										}}
									>
										Outliers
									</div> */}

									<div
										className={`col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container ${
											activeSideBarLink === ""
												? "activeDiv-header my-1 py-md-2 py-1  px-md-4 px-2"
												: "catLink-header my-1 py-md-2 py-1  px-md-4 px-2"
										}`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("add"));
											setShowAddASellerModal(true);
											setIsShowMenue(false);
										}}
									>
										Add a Seller
									</div>
									{/* <div
										className={` d-md-block d-none  col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container`}
										onClick={() => {
											dispatch(updateActiveHeaderLink("add"));
											setShowAddASellerModal(true);
											setIsShowMenue(false);
										}}
									>
										<button className="btn button-seller  add-btn-header">
											<span className="footer-links-header">Add a Seller</span>
										</button>
									</div> */}
								</div>
							</div>
							<div
								className={` d-md-none d-block  col-lg-12 col-md-12 col-sm-12 mt-2 header-footer-container`}
								onClick={() => {
									dispatch(updateActiveHeaderLink("add"));
									setShowAddASellerModal(true);
									setIsShowMenue(false);
								}}
							>
								{/* <button className="btn button-seller  add-btn-header">
									<span className="footer-links-header">Add a Seller</span>
								</button> */}
							</div>
						</div>
					</nav>
				</Grid>
			)}
			<AddAsellerModal
				isShow={showAddASellerModal}
				setIsShow={setShowAddASellerModal}
				isFromFooter
			/>
			<SeatchSellerModal
				showSeatchSellerModal={showSeatchSellerModal}
				setShowSeatchSellerModal={setShowSeatchSellerModal}
				isShowReview={setShowReview}
				setCategory={setCategory}
				setCategoryName={setCategoryName}
				setIsClose={setIsClose}
			/>
			<CategoriesModal
				actualCategory={categoryname}
				catgory={catgory}
				setCategory={setCategory}
			/>
			<div className={isBeforeWhite && "animated-modal-bg"}>
				<div
					className={`modal-container-animated  ${showReview && "one"} ${
						isClose && "modal-out"
					}`}
				>
					<div className={"modal-background-animated"}>
						<div className="modal-animated modal-parents">
							<div className="close-icon-end">
								<CloseIcon
									onClick={() => {
										setShowReview(false);
										setIsClose(true);
										setTimeout(() => {
											dispatch(latestReviewData(null));
										}, 2000);
									}}
								/>
							</div>

							<ReviewModal showReview={showReview} setIsClose={setIsClose} />
							<ReportBug
								showReportBug={showReportBug}
								setShowReportBug={setShowReportBug}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
