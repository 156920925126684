import React from "react";
import "./errorPage.css";
import { appededUrl } from "../../utils/constant";

export const ErrorPage = () => {
	return (
		<>
			<div className="d-flex align-items-center justify-content-center vh-100">
				{/* <h1 className="display-1 fw-bold">404</h1> */}
				{/* </div> */}
				<div className="page_404">
					<div className="container">
						<div className="row">
							<div className="col-sm-12 ">
								<div className="  text-center">
									<div className="four_zero_four_bg">
										<h1 className="text-center ">404</h1>
									</div>

									<div className="contant_box_404">
										<h3 className="h2">Look like you're lost</h3>

										<p>The page you are looking for not avaible!</p>

										<a href={`${appededUrl("/")}`}>
											<button className="link_404">Go to Home</button>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
