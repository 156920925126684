import moment from "moment";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export const InstaStory = ({
	showInstaStory,
	setShowInstStory,
	story,
	setStoryContent,
}) => {
	const handleClose = () => {
		setShowInstStory(false);
		setStoryContent(null);
	};

	return (
		<Modal
			show={showInstaStory}
			onHide={handleClose}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body className="modal-parent">
				<Link
					to={story?.instagramLink}
					target="_blank"
					className="instagram-modal-link"
				>
					<div className="row inst-modal-parent">
						<div className="col-sm-6 col-12">
							{" "}
							<img
								src={story?.storyImage}
								className="insta-modal-img"
								alt="logo"
							/>
						</div>
						<div className="col-sm-6 col-12 instgram-modal-dark insta-story-modal-text">
							<div className="pt-2 ">
								<div className="me-2 insta-heading">{story?.userName}</div>
								<div className="insta-date mb-2">
									{moment?.utc(story?.createdAt).format("MMMM DD, YYYY")}
								</div>
							</div>
							<div>
								<p className="card-text px-2 py-2 mb-3 mt-2">
									<p
										className="instagram-innerImage rounded w-100"
										dangerouslySetInnerHTML={{
											__html: story?.storyDetail,
										}}
									/>
								</p>
							</div>
						</div>
					</div>
				</Link>
			</Modal.Body>
		</Modal>
	);
};
