import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import { Badge } from "react-bootstrap";

import {
	deleteAdminSellerRequest,
	deleteAdminSellerResponse,
	getAllAdminSellers,
	getSellerId,
	handleAdminStatus,
	updateSellerAdmin,
} from "../../../redux/reducers/ducks/getSellersDuck";
import { CreateSellerModal } from "./sellerModals";
import AllSellerCategories from "./allSellerCategories";
import Swal from "sweetalert2";
import moment from "moment";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { Link } from "react-router-dom";
import { sellerTypeArray } from "../../../utils/addSellerHelper";
import { UpdateSellerTypeRequest } from "../../../redux/reducers/ducks/adminReviewDuck";
import "./sellerPage.css";

export default function SellerMainPage({ pageName }) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [category, setCategory] = useState("");
	const [searchSeller, setSearchSeller] = useState("");
	const sellersState = useSelector((state) => state?.seller);
	const sellers = sellersState?.seller?.data;

	const isAdminStatus = sellersState?.isAdminChangeStatus;
	// const adminReviewLoading = state?.adminReview?.adminReviewLoading,
	const sellerAdminDeleteResponse = sellersState?.sellerAdminDeleteResponse;
	const [isChangeSellerData, setIsChangeSellerData] = useState(false);
	const [sellerType, setSellerType] = useState(null);
	const [isActive, setIsActive] = useState({
		active: true,
		btnName: "allSellers",
	});

	const { adminReviewLoading } = useSelector((state) => {
		return {
			// isAdminStatus: state?.seller?.isAdminChangeStatus,
			adminReviewLoading: state?.adminReview?.adminReviewLoading,
			// sellerAdminDeleteResponse: state?.seller?.sellerAdminDeleteResponse,
		};
	});

	const [openDialog, setOpenDialog] = useState({
		isOpen: false,
		id: null,
		sellerName: null,
		sellerUrl: null,
		isSellerCreate: false,
	});

	const dispatch = useDispatch();
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		dispatch(handleAdminStatus(false));
	};
	const handleChange = (event, Id) => {
		const requestData = {
			sellerId: Id,
			approvedByAdmin: event.target.value,
			isListing: event.target.value === "approved" ? true : false,
		};
		dispatch(updateSellerAdmin(requestData));
	};

	const columns = [
		{ id: "name", label: `${pageName} Name`, minWidth: 170 },
		{ id: "url", label: `Seller Url`, minWidth: 170 },
		{ id: "code1", label: "Category", minWidth: 100 },
		{ id: "code2", label: "Date", minWidth: 100 },
		{ id: "code12", label: "Classification", minWidth: 230 },
		{ id: "code3", label: "Actions", minWidth: 100 },
		{ id: "code4", label: "Status", minWidth: 100 },
	];

	useEffect(() => {
		const requestData = {
			page: page + 1,
			categoryId: category,
			query: searchSeller,
			pageSize: rowsPerPage,
			status: isActive?.btnName === "pendingSellers" ? "pending" : null,
		};
		dispatch(getAllAdminSellers(requestData));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category, page, rowsPerPage, isActive]);

	const handleRowsPerPage = (e) => {
		setRowsPerPage(e.target.value);
		setPage(0);
	};

	useEffect(() => {
		const requestData = {
			page: page + 1,
			categoryId: category,
			query: searchSeller,
			pageSize: rowsPerPage,
			status: isActive?.btnName === "pendingSellers" ? "pending" : null,
		};
		const timeout = setTimeout(() => {
			dispatch(getAllAdminSellers(requestData));
			setIsChangeSellerData(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [searchSeller]);

	useEffect(() => {
		if (isAdminStatus) {
			const requestData = {
				page: page + 1,
				...(category ? { categoryId: category } : {}),
				...(searchSeller ? { query: searchSeller } : {}),
				...(rowsPerPage ? { pageSize: rowsPerPage } : {}),
				status: isActive?.btnName === "pendingSellers" ? "pending" : null,
			};
			dispatch(getAllAdminSellers(requestData));
			setIsChangeSellerData(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdminStatus]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this item!",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
			reverseButtons: true,
			customClass: {
				title: "mt-4",
				confirmButton: "btn bg-primary",
				cancelButton: "btn btn-primary ",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(deleteAdminSellerRequest(id));
				setIsChangeSellerData(true);
			}
		});
	};

	useEffect(() => {
		if (sellerAdminDeleteResponse?.status === 200) {
			getToasterNotifications(sellerAdminDeleteResponse?.data?.message);
			dispatch(deleteAdminSellerResponse(null));
		}
	}, [sellerAdminDeleteResponse]);

	function replaceWwwWithHttps(url) {
		// Check if "https" is not already present in the URL
		if (
			url.indexOf("https://") === -1 &&
			url.includes("www") &&
			!url.includes("https")
		) {
			// Replace "www" with "https"
			url = url.replace("www.", "https://");
		} else if (!url.includes("https")) {
			url = "https://" + url;
		}
		return url;
	}

	const getProperClass = (approvedByAdmin) => {
		if (approvedByAdmin === "pending") return "seller-pending-color";
		else if (approvedByAdmin === "approved") return "seller-approve-color";
		else return "seller-rejected-color";
	};

	return (
		<div className="cardParent">
			{(adminReviewLoading || isChangeSellerData) && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 100 }}
					open={adminReviewLoading || isChangeSellerData}
				>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<div className="all-sellers-center">
						<div className="all-sellers-child-center">
							<Button
								variant="contained"
								sx={{ ml: 1 }}
								onClick={() => {
									setIsActive({
										active: true,
										btnName: "allSellers",
									});
								}}
								className={
									isActive.active && isActive.btnName == "allSellers"
										? "addCategoryBtn-active"
										: "addCategoryBtn-notActive"
								}
							>
								All Sellers
							</Button>
							<div className="position-relative">
								<Button
									onClick={() => {
										setIsActive({
											active: true,
											btnName: "pendingSellers",
										});
									}}
									variant="contained"
									sx={{ ml: 1 }}
									className={
										isActive?.active && isActive.btnName == "pendingSellers"
											? "addCategoryBtn-active"
											: "addCategoryBtn-notActive"
									}
								>
									Pending Sellers
								</Button>
								{sellers?.pendingSeller > 0 && (
									<Badge pill className="admin-seller-badge">
										{sellers?.pendingSeller}
									</Badge>
								)}
							</div>
						</div>
					</div>
					<div className="parent-cat">
						<div>{/* <div>{pageName} Portal</div> */}</div>
						<div
							className="add-cat"
							onClick={() => {
								setOpenDialog({
									isOpen: true,
									isSellerCreate: true,
								});
							}}
						>
							<Button
								variant="contained"
								sx={{ ml: 1 }}
								className="addCategoryBtn"
							>
								Add {pageName}
							</Button>
						</div>
					</div>
					<div className="row">
						<AllSellerCategories
							sellerType={sellerType}
							setCategoryId={setCategory}
							setSellerType={setSellerType}
							setSearchData={setSearchSeller}
							searchData={searchSeller}
							setIsChangeSellerData={setIsChangeSellerData}
							setPage={setPage}
						/>
					</div>

					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												sortDirection="desc"
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{sellers?.records?.length > 0 ? (
										sellers?.records?.map((row) => {
											const date = moment?.utc(row?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");

											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={row.id}
												>
													<TableCell
														className="admin-seller-name-link"
														onClick={() => {
															dispatch(getSellerId(row?.id));
														}}
													>
														<Link
															className="admin-seller-name-link"
															to={`/seller-balloon/${row?.id}`}
															target="_blank"
														>
															{row.sellerName}
														</Link>
													</TableCell>
													<TableCell>
														<Link
															target="_blank"
															className="admin-seller-name-link"
															to={replaceWwwWithHttps(row.sellerUrl)}
														>
															{row.sellerUrl}
														</Link>
													</TableCell>
													{row?.categories?.length > 0 && (
														<TableCell>
															{row?.categories[0]?.categoryName}
														</TableCell>
													)}
													<TableCell>{formattedDate}</TableCell>
													{/* <TableCell>{row?.type}</TableCell> */}
													<TableCell>
														<FormControl sx={{ minWidth: 120 }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={row?.type}
																onChange={(event) => {
																	setIsChangeSellerData(true);
																	dispatch(
																		UpdateSellerTypeRequest({
																			sellerId: row.id,
																			type: event.target.value,
																		})
																	);
																}}
																className="selectStatuses"
																placeholder="Select status"
															>
																{sellerTypeArray.map((item) => {
																	return (
																		<MenuItem key={item} value={item}>
																			{item}
																		</MenuItem>
																	);
																})}
															</Select>
														</FormControl>
													</TableCell>
													{/* <TableCell>{row?.type}</TableCell>
													<TableCell>{row?.country}</TableCell>
													<TableCell>
														{row?.state === "null" ? "" : row?.state}
													</TableCell>
													<TableCell>
														{row?.address === "null" ? "" : row?.address}
													</TableCell> */}
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														<DeleteIcon
															onClick={() => {
																handleDelete(row.id);
															}}
															className="text-danger seller-panel-cursor iconMargin"
														/>{" "}
														<EditIcon
															onClick={() => {
																setOpenDialog({
																	isOpen: true,
																	id: row.id,
																	sellerName: row.sellerName,
																	sellerUrl: row.sellerUrl,
																	isSellerCreate: false,
																	previousCategory: row?.categories[0],
																});
																setIsChangeSellerData(true);
															}}
															className="admin-seller-edit ms-1 seller-panel-cursor text-primary iconMargin"
														/>{" "}
													</TableCell>
													<TableCell>
														<FormControl sx={{ minWidth: 120 }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={row?.approvedByAdmin}
																onChange={(e) => {
																	handleChange(e, row.id);
																	setIsChangeSellerData(true);
																}}
																className={`selectStatuses ${getProperClass(
																	row?.approvedByAdmin
																)}`}
																placeholder="Select status"
															>
																<MenuItem value="pending" className="d-none">
																	Pending
																</MenuItem>
																<MenuItem value="rejected">Reject</MenuItem>
																<MenuItem value="approved">Approve</MenuItem>
															</Select>
														</FormControl>
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No Seller exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{sellers?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									count={sellers?.totalRecords ? sellers?.totalRecords : 0}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
						<CreateSellerModal
							openDialog={openDialog}
							setOpenDialog={setOpenDialog}
							setIsChangeSellerData={setIsChangeSellerData}
						/>
					</Paper>
				</Card>
			</div>
		</div>
	);
}
