import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
	editAdminUserData,
	updateAdminUserResponse,
} from "../../../redux/reducers/ducks/userDuck";
import "./userPage.css";
import { useEffect } from "react";
import { getToasterNotifications } from "../../../utils/getToasterNotification";

export default function UpdateUserPopup(props) {
	const { showPopup, setShowPopup, setIsSubmittedUser } = props;
	const handleClose = () => {
		setShowPopup({ isOpen: false });
		setIsSubmittedUser(false);
	};
	const dispatch = useDispatch();

	const { updateUserResponse } = useSelector((state) => {
		return {
			updateUserResponse: state?.adminUser?.adminUserResponse,
		};
	});
	const handleSubmitUser = (e) => {
		const payloadData = {
			id: showPopup.id,
			name: showPopup.name,
			email: showPopup.email,
		};
		// Update api
		if (showPopup.name && showPopup.email) {
			e.preventDefault();
			dispatch(editAdminUserData(payloadData));
			setShowPopup({
				...showPopup,
				isOpen: false,
			});
		}
	};

	useEffect(() => {
		if (updateUserResponse?.status === 200) {
			getToasterNotifications(updateUserResponse?.data?.message);
			dispatch(updateAdminUserResponse(null));
		}
	}, [updateUserResponse]);
	function BootstrapDialogTitle(props) {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	}

	return (
		<div>
			<Dialog
				open={showPopup.isOpen}
				onClose={handleClose}
				fullWidth
				aria-labelledby="draggable-dialog-title"
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				/>
				<div className="d-flex justify-content-center">
					<div className="rounded-4 w-75 mb-2 mt-1">
						<form>
							<h5 className="text-center mt-3 fw-bold">Update User</h5>
							<div className="p-3 mb-4 text-center">
								<input
									required
									className="inputField  w-100 text-center admin-user-text-field"
									value={showPopup.name}
									onChange={(event) => {
										setShowPopup({
											...showPopup,
											name: event.target.value,
										});
									}}
								/>
								<input
									required
									className="inputField w-100 mt-3 text-center admin-user-text-field"
									value={showPopup.email}
									onChange={(event) => {
										setShowPopup({
											...showPopup,
											name: event.target.value,
										});
									}}
								/>

								<div className="text-center mt-3">
									<button
										onClick={handleSubmitUser}
										type="submit"
										className="btn user-submit-button px-3 py-2"
									>
										Save Changes
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
