import * as React from "react";

import { Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";

export default function AllSellerCategories({
	setCategoryId,
	setSearchData,
	searchData,
	setIsChangeSellerData,
	setPage,
}) {
	const { categoryData } = useSelector((state) => {
		return {
			categoryData: state?.category?.category?.data?.records,
		};
	});

	const dispatch = useDispatch();

	return (
		<div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-start">
				<Autocomplete
					id="free-solo-2-demo"
					className=" inputField rounded w-100 text-start"
					popupIcon={""}
					onChange={(e, category) => {
						setIsChangeSellerData(true);
						dispatch(handleAdminStatus(false));
						setPage(0);
						setCategoryId(category?.id);
					}}
					sx={{
						".MuiOutlinedInput-root": {
							padding: "3px",
						},
						"#free-solo-2-demo": {
							textAlign: "start",
							marginLeft: "1rem",
						},
					}}
					options={categoryData && categoryData?.length > 0 ? categoryData : []}
					getOptionLabel={(option) => {
						if (option && typeof option?.categoryName === "string") {
							return option?.categoryName;
						}
						return "";
					}}
					clearOnBlur={true}
					renderInput={(params) => {
						return (
							<div className="autocomplete-search-inputfield">
								<TextField
									className="mb-5 text-center seller-textfield"
									id="autocomplete"
									sx={{
										"#free-solo-2-demo": {
											textAlign: "start",
										},
									}}
									{...params}
									placeholder="Select Category"
								/>
							</div>
						);
					}}
				/>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6  sellerInputParent">
				<input
					id="standard-basic"
					className="p-2 w-100 admin-search-seller"
					placeholder="Search a Seller"
					value={searchData}
					onChange={(e) => {
						setPage(0);
						setSearchData(e.target.value);
					}}
				/>
			</div>
		</div>
	);
}
