import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import store from "./redux/store/GlobalStore";
import App from "./App";
import "./index.css";

const scriptElement = document.createElement("script");
scriptElement.async = true;
scriptElement.setAttribute("data-id", process.env.REACT_APP_CLICKY_ID);
scriptElement.setAttribute("src", "//static.getclicky.com/js");
document.body.appendChild(scriptElement);

const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);
