import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "./sweatalert2.css";

export const getToasterErrors = (title, position) => {
	return Swal.fire({
		toast: true,
		icon: "",
		title: `<p style=color:#fff> ${title} </p>`,
		background: "#000",
		animation: false,
		position: position ? position : "bottom",
		showConfirmButton: false,
		timer: 6000,
		timerProgressBar: false,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});
};

export const getModal = (showModal, handleCancel) => {
	return (
		<>
			<Modal
				show={showModal}
				onHide={handleCancel}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton className="border-0"></Modal.Header>
				<div>
					<div className="container mb-5">
						<img
							src="/icons/logo1.png"
							className="mx-auto d-block w-25"
							alt="logo"
						/>
						<div className="row d-flex justify-content-center">
							<p className="mt-4 mb-2 text-center fw-bold">
								Thanks for Clicking!
							</p>
						</div>
						<div>
							<p className=" mb-3 text-center">
								Your Clicks have just been added to the Seller Balloon tally!
							</p>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};
