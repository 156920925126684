import React from "react";
import { Modal } from "react-bootstrap";

function AdvertisementModal({ isView, setIsView }) {
	const handleCancel = () => setIsView(false);

	return (
		<Modal
			show={isView}
			onHide={handleCancel}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0"></Modal.Header>
			<div>
				<div className="d-flex justify-content-center">
					<img src="/icons/blackShirt.png" className="w-25" alt="blackshirt" />
					<img
						src="/icons/whiteShirt.png"
						className="mx-2 w-25"
						alt="whiteshirt"
					/>
				</div>
				<h5 className="text-center mb-5">
					Win A Free T-Shirt or $20 Amazon Gift Card
				</h5>
			</div>
		</Modal>
	);
}

export default AdvertisementModal;
