import Layout from "../../components/layout/parentLayout/Layout";
import "./about.css";

export const About = () => {
	return (
		<Layout>
			<div className="about-padding mb-5">
				<h1 className="mt-md-5 mt-2  d-flex about-heading justify-content-center fw-bold mb-1 main-heading-font">
					About
				</h1>
				<h5 className="d-flex about-heading sub-heading-font  justify-content-center fw-normal mb-5">
					Welcome, we're glad you stopped by!
				</h5>
				<div className="middle-text">
					<h3 className="d-flex mb-4 mt-4 justify-content-start sub-sub-heading-font fw-bold">
						What is L2a and how does it work?
					</h3>
					<div className="d-flex justify-content-start">
						Love to Air is an online platform where people go to express their
						feelings regarding the Sellers in their lives. They do this by
						clicking 1 of 6 emoji balloons.
					</div>
					<div className="mt-4">
						<div>
							For example, if they feel an airline or mobile carrier has treated
							them unfairly and made them feel badly, they can go to L2a and
							quickly AIR, or vent, their grievance by simply clicking a
							<span className="fw-bold me-1"> Disappointed, </span>{" "}
							<span className="fw-bold me-1">Frustrated</span> or{" "}
							<span className="fw-bold me-1">Grrr</span> balloon.
						</div>
					</div>
					<div className="d-flex mt-4">
						Their click will cause an instant uptick in the seller's balloon
						tally on the seller's single Balloon Page (i.e., there's only one
						page per seller/brand, not thousands based on store, branch or
						office physical addresses).
					</div>
					<div className="d-flex mt-4">
						But there's a catch! It's a positive catch, however! ;) In order to
						AIR a grievance about a seller, a user must LOVE another seller!
					</div>
					<div className="mt-4">
						For example, if Jessica clicks a Frustrated balloon - because, say
						her flight was canceled and the airline wants her to pay a fee to
						rebook it - she'll be asked to click either a{" "}
						<span className="fw-bold me-1">Satisfied, </span>{" "}
						<span className="fw-bold me-1">Happy</span> or{" "}
						<span className="fw-bold mx-1">Awesome</span> balloon for another
						seller.
					</div>
					<div className="text-start mt-4">
						In this way, the sellers who make Jessica feel respected and valued
						get the shout-outs they rightly deserve! (In this example, Jessica
						clicked a Happy balloon for an Auto Finance company because they
						helped her get her first auto loan on fair terms.)
					</div>
					<div className="d-flex mt-4">
						The clicks will add up! Over time other users will be able to glance
						at a seller's balloon page and instantly see how well any given
						seller is doing at keeping its customers satisfied (or not).
					</div>
					<div className="mt-4">
						Love to Air still subscribes to the adage, Customer is King! Our
						mission is to help you compare sellers so you can select the ones
						who stand the best chance of providing you with the easiest, most
						time-saving and happiest experiences.
					</div>
				</div>
			</div>
		</Layout>
	);
};
