import * as React from "react";
import "./updatePassword.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { appededUrl } from "../../../utils/constant";
export const UpdatePassword = () => {
	return (
		<>
			<div className="update-container">
				<div className="">
					<h1 className="fw-bold update-heading">Done And Done!</h1>
				</div>
				<div className="main-div">
					<div>
						<span className="d-flex pt-2">
							<CheckCircleOutlineIcon className="icon" size="large" />
							<p>
								Success! <br></br>Your password has been updated
							</p>
						</span>
					</div>
				</div>
				<div className="mt-4">
					<a href={`${appededUrl("/loginUser")}`}>
						<button className="update-button">Return To Login</button>
					</a>
				</div>
			</div>
		</>
	);
};
