import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
	categoryCreated,
	getAdminCategory,
	updateCreateCategory,
} from "../../../redux/reducers/ducks/categoriesDuck";
import { useDispatch, useSelector } from "react-redux";
import "./popup.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";

export default function AddCategory(props) {
	const { showDialog, setShowDialog } = props;
	const [categoryName, setCategoryName] = useState(null);
	const [status, setStatus] = useState("pending");
	const [isSubmittedCategory, setIsSubmittedCategory] = useState(false);
	const [categoryType, setCategoryType] = useState("All");

	const { newCategoryData } = useSelector((state) => {
		return {
			newCategoryData: state?.category?.newCategoryData,
		};
	});

	const handleClose = () => {
		setShowDialog(false);
	};
	const dispatch = useDispatch();

	const handleCategory = (e) => {
		if (!categoryName) {
			setIsSubmittedCategory(false);
		} else {
			setIsSubmittedCategory(true);
		}
		const payloadData = {
			categoryName: categoryName,
			approvedByAdmin: status,
			type: categoryType,
		};
		if (!categoryName) {
			setShowDialog(true);
		}
		if (categoryName) {
			dispatch(categoryCreated(payloadData));
			e.preventDefault();
		}
		// Create Api
		setTimeout(() => {
			dispatch(getAdminCategory(1));
		}, 2000);
	};

	useEffect(() => {
		if (newCategoryData?.status === 201) {
			getToasterNotifications("Category created successfully");
			setShowDialog(false);
			dispatch(updateCreateCategory(null));
			setIsSubmittedCategory(false);
			setCategoryName(null);
		}
		if (newCategoryData?.response?.data?.statusCode === 409) {
			getToasterErrors(newCategoryData?.response?.data?.message);

			dispatch(updateCreateCategory(null));
			setIsSubmittedCategory(false);
		}
	}, [newCategoryData]);

	useEffect(() => {
		setCategoryType("All");
	}, [showDialog]);

	function BootstrapDialogTitle(props) {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	}

	return (
		<div>
			<Dialog
				open={showDialog}
				onClose={handleClose}
				fullWidth
				aria-labelledby="draggable-dialog-title"
			>
				{isSubmittedCategory && (
					<Backdrop
						sx={{ color: "#fff", zIndex: 2 }}
						open={isSubmittedCategory}
					>
						<div className="d-flex  loaderCenter justify-content-center align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				)}
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				/>
				<div className="d-flex justify-content-center">
					<div className="rounded-4 w-75 mb-3 mt-1">
						<h5 className="text-center mt-3 fw-bold">Add Category</h5>
						<div className="p-3 mb-4 text-center">
							<form>
								<input
									required
									className="inputField w-100 text-center admin-category-text-field"
									placeholder="Enter Category Name"
									onChange={(e) => {
										setCategoryName(e.target.value);
									}}
								/>

								<div className="d-flex justify-content-center mt-3">
									<select
										className="w-100 text-center admin-category-select"
										aria-label="Default select example"
										onChange={(e) => setStatus(e.target.value)}
									>
										<option selected className="d-none">
											Add Status
										</option>
										<option value="pending">Pending</option>
										<option value="rejected">Reject</option>
										<option value="approved">Approve</option>
									</select>
								</div>
								<div className="d-flex justify-content-center mt-3">
									<select
										className="w-100 text-center admin-category-select"
										aria-label="Default select example"
										onChange={(e) => setCategoryType(e.target.value)}
									>
										<option selected className="d-none">
											Add Category Classification
										</option>
										<option value="All">All</option>
										<option value="B2B">B2B</option>
									</select>
								</div>
								<div className="text-center mt-2 ">
									<button
										type="submit"
										onClick={handleCategory}
										className="btn addCategoryBtn px-5 py-2"
									>
										Submit
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
