import { useSelector } from "react-redux";

function UserProfileInfo() {
	const { loginResponseData } = useSelector((state) => ({
		loginResponseData: state.authorization?.loginResponseData,
	}));

	return (
		<div className="d-flex ml-13">
			<div className={`form-group w-60 mb-3 shadow-sm`}>
				<span className="font-bold">Username</span>
				<br />
				<input
					disabled
					className="w-100 border rounded shadow-sm p-2"
					name="username"
					minLength="3"
					type="text"
					id="username"
					placeholder="Username"
					value={loginResponseData?.data?.username}
				/>
			</div>
			<div className={`form-group w-60 mb-3 ml-13 shadow-sm`}>
				<span className="font-bold">Email Address</span>
				<br />
				<input
					disabled
					className="w-100 border rounded shadow-sm p-2"
					name="email"
					minLength="3"
					type="text"
					id="email"
					placeholder="Email"
					value={loginResponseData?.data?.email}
				/>
			</div>
		</div>
	);
}

export default UserProfileInfo;
