import { token } from "../constants";
import axios from "./Axios";

export const getAllSeededData = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/seeded-data", {
			params: data,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const createSeededDataApi = async (data) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.post("admin/seeded-data", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const uploadVedioDataApi = async (data) => {
	try {
		return axios.post("videos/uploaded", data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const updateSeededDataApi = async (payload) => {
	let token = localStorage.getItem("adminToken");
	try {
		return axios.patch(`admin/seeded-data/${payload?.id}`, payload?.data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
