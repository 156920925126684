import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import { useDispatch } from "react-redux";
import { contactRequest } from "../../redux/reducers/ducks/contactDuck";
import "./contactUs.css";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { useNavigate } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const [emailValidate, setEmailValidate] = useState("");
	const dispatch = useDispatch();
	const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);

	const [isContactSubmit, setIsContactSubmit] = useState(false);

	const navigate = useNavigate();
	const validateEmail = (email) => {
		// Regular expression for email validation
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return emailRegex.test(email);
	};

	function onChange(value) {
		if (value) {
			setIsReCaptchaValid(true);
			setIsContactSubmit(false);
		} else {
			setIsReCaptchaValid(false);
		}
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsContactSubmit(true);
	};

	useEffect(() => {
		if (isReCaptchaValid) {
			if (validateEmail(email)) {
				const requestData = {
					email: email,
					message: message,
				};
				dispatch(contactRequest(requestData));
				setEmail("");
				setMessage("");
				try {
					getToasterNotifications(
						"Your request have been submitted successfully. We will contact you soon!"
					);
				} catch (e) {
					getToasterErrors(e?.response?.data?.message || "");
				}
				setTimeout(() => {
					navigate("/");
					setIsReCaptchaValid(false);
				}, 1000);
			} else {
				setEmailValidate("enter a valid email");
			}
		}
	}, [isReCaptchaValid]);

	return (
		<Layout>
			<div className="container contactUs-wrapper-container">
				<img
					src="/icons/luv2a.gif"
					className="mx-auto mt-4 d-block contact-image-width my-auto"
					alt="logo"
				/>
				<h6 className="text-center mt-2">
					We ask you to prove you're human when you sign up.
				</h6>
				<h6 className="text-center mt-2">
					We'll prove we are too if you Contact Us!
				</h6>
				<h6 className="text-center mt-2 fst-italic">
					In other words, a real person will reply to you, sans AI bots.
				</h6>
				<div className="d-flex justify-content-center contact_form">
					<form
						onSubmit={handleSubmit}
						className=" mt-4 bg-white shadow contactUs-form"
					>
						<h2 className="text-center mt-4 mb-4 fs-4">Contact Us</h2>
						<div className="form-group mb-3 input-fields-width mx-auto shadow-sm">
							<input
								type="email"
								required
								className="w-100 border rounded p-2 shadow-sm contact-email"
								name="email"
								id="inputEmail"
								placeholder="Email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
									setEmailValidate("");
								}}
							/>
							{emailValidate ? (
								<span className="text-danger">{emailValidate}</span>
							) : (
								""
							)}
						</div>
						<div className="form-group mb-3 input-fields-width mx-auto shadow-sm">
							<textarea
								className="form-control mt-3 mx-auto w-100 contactus-textarea"
								id="textAreaExample2"
								rows="10"
								required
								placeholder="Message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
							/>
						</div>
						{isContactSubmit && (
							<div className="recaptchParent">
								<ReCAPTCHA
									style={{ display: "inline-block" }}
									theme="dark"
									sitekey={process.env.REACT_APP_ReactCaptcha}
									onChange={onChange}
								/>
							</div>
						)}
						<div className="text-center">
							<button
								type="submit"
								className="btn mt-2 mb-4 rounded-pill contactus-btn"
							>
								Send Now!
							</button>
						</div>
					</form>
				</div>
			</div>
		</Layout>
	);
}

export default ContactUs;
