import React from "react";
import { Link } from "react-router-dom";
import "./sellersChoice.css";

function SellersChoice() {
	return (
		<>
			<>
				<div className="row seller-choice-height d-flex justify-content-md-center justify-content-around mt-1 sellerchoice-wrapper-container">
					{/* <div className="d-flex justify-content-center col-lg-2 col-1 col-xl-1  button-seller-margin" /> */}
					<div className="seller-choice-home">
						<Link to="/to-love" className="to-click-link">
							<button
								type="button"
								className="btn seller-choice-home button-seller seller-choice-btn"
							>
								Love A Seller
							</button>
						</Link>
					</div>
					<div className="seller-choice-home">
						<Link to="/to-air" className="to-click-link">
							<button
								type="button"
								className="btn seller-choice-home button-seller seller-choice-btn"
							>
								Air A Grievance
							</button>
						</Link>
					</div>
					<div className="seller-choice-home">
						<Link to="/to-tell" className="to-click-link">
							<button
								type="button"
								className="btn seller-choice-home button-seller seller-choice-btn click-tell-button"
							>
								Tell your Story
							</button>
						</Link>
					</div>
					{/* <div className="d-flex justify-content-center col-lg-2 col-1 col-xl-3 button-seller-margin" /> */}
				</div>
			</>
		</>
	);
}

export default SellersChoice;
