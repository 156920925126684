import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	submitReview,
	updateReviewSubmitStatus,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import Review from "../review/review";
import "./modals.css";
import LoginModal from "./loginModal";
import SignUpModal from "./signUpModal";
import Backdrop from "@mui/material/Backdrop";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
import { useNavigate, useParams } from "react-router";
import { CategoryAutoComplete } from "../layout/categoryAutoComplete/categoryAutoComplete";

function ToLoveModal({ isToLove, setIsToLove, setThanksValue }) {
	let { id } = useParams();

	const handleCancel = () => setIsToLove(false);
	const [isLoveSubmit, setIsLoveSubmit] = useState(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [goodReviews, setGoodReviews] = useState({
		sellerId: id,
		titleId: "",
		categoryId: null,
		message: null,
		approvedByAdmin: true,
		bestWriter: false,
	});

	const {
		singleSellerData,
		verifyReviewData,
		loginResponseData,
		sellerCategories,
	} = useSelector((state) => {
		return {
			singleSellerData: state?.seller?.singleSellerData?.data,
			isReviewUpdate: state?.seller?.isReviewUpdate,
			sellerId: state?.seller?.sellerId,
			verifyReviewData: state?.adminReview?.verifyReviewData,
			loginResponseData: state.authorization?.loginResponseData,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		};
	});

	const navigate = useNavigate();
	const token = localStorage.getItem("token");

	useEffect(() => {
		if (id || isToLove) {
			setGoodReviews({
				...goodReviews,
				sellerId: id,
			});
			if (loginResponseData?.data?.id && isToLove) {
				dispatch(
					verifyReviewRequest({
						sellerId: id,
						userId: loginResponseData?.data?.id,
					})
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isToLove, loginResponseData]);

	useEffect(() => {
		if (
			verifyReviewData?.verifyReview &&
			verifyReviewData?.verifyReview?.type === "to-love" &&
			!isLoveSubmit
		) {
			setGoodReviews({
				...goodReviews,
				message: null,
				titleId: verifyReviewData?.verifyReview?.titleId,
			});
			setIsLoveSubmit(false);
		} else if (
			verifyReviewData?.verifyReview &&
			verifyReviewData?.verifyReview?.type === "to-air" &&
			!isLoveSubmit
		) {
			setGoodReviews({
				...goodReviews,
				message: null,
				titleId: null,
			});
			setIsLoveSubmit(false);
		}
	}, [verifyReviewData, isLoveSubmit]);

	const handleThanks = () => {
		let token = localStorage.getItem("token");

		if (goodReviews.sellerId && !goodReviews.titleId) {
			getToasterNotifications("Please select a balloon!");
			return;
		}
		if (
			goodReviews.sellerId &&
			goodReviews.titleId &&
			!goodReviews.categoryId &&
			sellerCategories &&
			sellerCategories?.length > 1
		) {
			getToasterNotifications("Please select a category!");
			return;
		}
		if (!token) {
			setLoginModal(true);
			return;
		}
		if (!goodReviews.sellerId || !goodReviews.titleId) {
			setThanksValue(false);
			return;
		}
		if (goodReviews.sellerId && goodReviews.titleId) {
			if (loginResponseData?.data?.id) {
				dispatch(
					verifyReviewRequest({
						sellerId: goodReviews.sellerId,
						userId: loginResponseData?.data?.id,
					})
				);
			}
			setIsLoveSubmit(true);
		}
	};

	useEffect(() => {
		dispatch(verifyReviewResponse(null));
	}, []);

	useEffect(() => {
		if (id) {
			setGoodReviews({
				...goodReviews,
				sellerId: id,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	//get Api for sellers
	const dispatch = useDispatch();
	const { reviewSubmitStatus } = useSelector((state) => ({
		reviewSubmitStatus: state.seller?.reviewSubmitStatus,
	}));

	useEffect(() => {
		if (
			(reviewSubmitStatus === 409 || reviewSubmitStatus === 200) &&
			isLoveSubmit
		) {
			dispatch(submitReview(goodReviews));
			dispatch(updateReviewSubmitStatus(null));
			setIsLoveSubmit(true);
			// dispatch(verifyReviewResponse(null));
			setIsToLove(true);
		} else if (reviewSubmitStatus === 400) {
			setIsLoveSubmit(false);
			setIsToLove(false);
			getToasterNotifications(
				"You've already clicked this seller!  You can edit your story from the Seller Balloon Page!"
			);
			dispatch(updateReviewSubmitStatus(null));
		} else if (
			reviewSubmitStatus === 401 &&
			token &&
			loginResponseData?.data?.id
		) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			localStorage.setItem("adminToken", "");
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [
		reviewSubmitStatus,
		dispatch,
		goodReviews,
		verifyReviewData,
		isLoveSubmit,
	]);

	useEffect(() => {
		if (
			goodReviews.sellerId &&
			goodReviews.titleId &&
			reviewSubmitStatus === 201
		) {
			setThanksValue(true);
			setIsLoveSubmit(false);
			dispatch(updateReviewSubmitStatus(null));
			// dispatch(verifyReviewResponse(null));
			if (loginResponseData?.data?.id) {
				dispatch(
					verifyReviewRequest({
						sellerId: id,
						userId: loginResponseData?.data?.id,
					})
				);
			}
		}
	}, [reviewSubmitStatus, goodReviews, loginResponseData]);

	useEffect(() => {
		if (reviewSubmitStatus === 403) {
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
			setThanksValue(false);
			setIsLoveSubmit(false);
			setIsToLove(false);
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus, dispatch]);

	return (
		<Modal
			show={isToLove}
			onHide={handleCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0" />
			{isLoveSubmit && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 2 }}
					open={isLoveSubmit}
					onClick={() => setIsLoveSubmit(false)}
				>
					<div className="d-flex  justify-content-center loaderCenter align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<h6 className="text-center px-3 fw-bold mb-sm-0 mb-5 mx-lg-0 mx-2">
					{verifyReviewData?.verifyReview?.titleId ? (
						<>
							{" "}
							<h6 className="text-center fw-bold mx-3 ">
								You've already clicked this Seller!
							</h6>
							<h6 className="text-center fw-bold mx-3 ">
								Would you like to edit your click and/or story?
							</h6>
						</>
					) : (
						`Please click the balloon ${
							sellerCategories?.length > 1 && "and also select category"
						}  that best describes your experience with ${
							singleSellerData?.seller?.sellerName
						}.`
					)}
				</h6>
				{sellerCategories && sellerCategories?.length > 1 && (
					<CategoryAutoComplete
						sellerCategories={sellerCategories}
						reviews={goodReviews}
						setReviews={setGoodReviews}
					/>
				)}
				<Review goodReviews={goodReviews} setGoodReviews={setGoodReviews} />
				<div className="d-flex justify-content-center">
					<button
						type="button"
						disabled={isLoveSubmit}
						onClick={handleThanks}
						className="btn rounded-pill mb-4 mt-5 button-next"
					>
						{verifyReviewData?.verifyReview?.titleId ? "Update" : "Submit"}
					</button>
				</div>
			</div>
			<LoginModal
				isShow={showLoginModal}
				setIsShow={setLoginModal}
				setSignUp={setSignUpModal}
				reviews={goodReviews}
				setThanks={setThanksValue}
				setIsParentModal={setIsToLove}
				fromSellerBalloonPage
			/>
			<SignUpModal
				reviews={goodReviews}
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setLoginModal}
				isParent
				setIsParent={setIsToLove}
			/>
		</Modal>
	);
}

export default ToLoveModal;
