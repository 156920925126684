import React from "react";
import { Modal } from "react-bootstrap";

function BuyerModal({ isBuyer, setIsBuyer }) {
	const handleClose = () => setIsBuyer(false);
	return (
		<div>
			<Modal
				show={isBuyer}
				onHide={handleClose}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<h4>Who is a Buyer ?</h4>
				</Modal.Header>
				<Modal.Body className="text-justify">
					Anyone who gives someone his money in exchange for something else
				</Modal.Body>
				<Modal.Footer>
					<button
						className=" border-0 bg-dark text-white p-2 w-25"
						onClick={() => {
							setIsBuyer(false);
						}}
					>
						close
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default BuyerModal;
