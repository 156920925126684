import {
	Autocomplete,
	Backdrop,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
	addSellerRequest,
	getSellerId,
	getSellerName,
	googleAPIRequest,
	updateNewSeller,
} from "../../redux/reducers/ducks/getSellersDuck";
import Remarks from "../remarks/remarks";
import "./modals.css";
import LoginModal from "./loginModal";
import SignUpModal from "./signUpModal";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { sellerTypeArray } from "../../utils/addSellerHelper";
import DetailModal from "./detailModal";
import { getCategory } from "../../redux/reducers/ducks/categoriesDuck";
import { Report } from "@mui/icons-material";
import { Editor } from "@tinymce/tinymce-react";

function AddAsellerModal({
	isShow,
	setIsShow,
	isHideField,
	setShowModal,
	IsShowModal,
	selectWholeCategory,
	setSelectedWholeCategory,
	addSellerName,
	selectedClassification,
	fromHomePage,
	fromOutlier,
	isFromFooter,
	selectedOutsideUsCountry,
	setSelectedOutsideUsCountry,
	setOpen,
}) {
	const [email, setEmail] = useState("");
	const [sellerName, setSellerName] = useState("");
	const [message, setMessage] = useState("");
	const [category, setCategory] = useState(null);
	const [balloonId, setBalloonId] = useState("");
	const [showLoginModal, setLoginModal] = useState(false);
	const [showErrorDetail, setShowErrorDetail] = useState("");
	const [balloonMessage, setBalloonMessage] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [sellerType, setSellerType] = useState();
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countryState, setCountryState] = useState(null);
	const [sellerAddress, setSellerAddress] = useState(null);
	const [city, setCity] = useState("");
	const [isShowDetailPopup, setIsShowDetailPopup] = useState(false);
	const [selectedGoogleAddress, setSelectedGoogleAddress] = useState(null);
	const [googleAddress, setGoogleAddress] = useState("");
	const [isGlobalChecked, setIsGlobalChecked] = useState(false);
	const [isSubmitSeller, setIsSubmitSeller] = useState(false);
	const {
		categories,
		isAddSellerLoading,
		data,
		addSeller,
		googlePlaceResponse,
	} = useSelector((state) => {
		return {
			categories: state?.category?.category?.data?.records,
			isAddSellerLoading: state?.seller?.isAddSellerLoading,
			data: state?.seller?.addSeller,
			addSeller: state?.seller?.addSeller,
			googlePlaceResponse: state?.seller?.googlePlaceResponse,
		};
	});
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	const dispatch = useDispatch();

	const [sortedCategories, setSortedCategories] = useState([]);

	useEffect(() => {
		if (categories && categories?.length > 0) {
			let arrangeCategories = [...categories];
			if (arrangeCategories && arrangeCategories?.length > 0) {
				const sort = arrangeCategories?.sort((a, b) => {
					if (a.categoryName === "Other" || a.categoryName === "Other B2B")
						return -1;
					if (b.categoryName === "Other" || b.categoryName === "Other B2B")
						return 1;
					return 0;
				});
				setSortedCategories(sort);
			}
		}
	}, [categories]);

	function isValidURL(url) {
		const urlRegex = /\./;
		return urlRegex.test(url);
	}

	const token = localStorage.getItem("token");

	useEffect(() => {
		setSellerType(selectedClassification);
	}, [selectedClassification]);

	// Update screenWidth on window resize
	const handleResize = () => {
		setScreenWidth(window.innerWidth);
	};

	useEffect(() => {
		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const navigate = useNavigate();

	const handleSubmit = async () => {
		let token = localStorage.getItem("token");

		if (!isValidURL(email)) {
			setIsSubmitSeller(true);
			getToasterNotifications("Please enter valid URL");
		}
		if (
			!sellerName ||
			!category?.id ||
			!email ||
			!sellerType ||
			(sellerType &&
				sellerType !== sellerTypeArray[0] &&
				!selectedGoogleAddress)
		) {
			setShowErrorDetail("Please fill the input field.");
			return;
		} else {
			if (token && !balloonId && !isHideField) {
				getToasterNotifications("Please select a balloon!");
				return;
			}
			if (token) {
				setLoginModal(false);
			}
			if (!token) {
				setLoginModal(true);
				return;
			}
			if (!balloonMessage) {
				if (isValidURL(email) && token) {
					setIsSubmitSeller(false);
					const requestData = {
						sellerName: capitalizeFirstLetter(sellerName),
						sellerUrl: email,
						categories: [category?.id],
						approvedbyAdmin: true,
						...(message ? { message: message } : {}),
						...(balloonId ? { titleId: balloonId } : {}),
						approvedByAdmin: "pending",
						isListing: true,
						bestWriter: false,
						is_global: isGlobalChecked,
						...(sellerAddress ? { address: sellerAddress } : {}),
						...(countryState ? { state: countryState } : {}),
						...(selectedCountry?.name
							? { country: selectedCountry?.name }
							: {}),
						...(sellerType ? { type: sellerType } : {}),
						...(city ? { city: city } : {}),
						...(selectedGoogleAddress?.description
							? { address: selectedGoogleAddress?.description }
							: {}),
					};
					dispatch(updateNewSeller(null));
					dispatch(addSellerRequest(requestData));
					if (selectedOutsideUsCountry) {
						setSelectedOutsideUsCountry(null);
						setOpen(false);
					}
					if (IsShowModal) {
						setShowModal(false);
					}
				}
			}
		}
	};

	const capitalizeFirstLetter = (str) => {
		return str
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};

	useEffect(() => {
		if (selectedOutsideUsCountry) {
			setSellerType(sellerTypeArray[2]);
		}
	}, [selectedOutsideUsCountry]);

	useEffect(() => {
		if (selectWholeCategory) {
			setCategory(selectWholeCategory);
		} else {
			setCategory(null);
		}
	}, [selectWholeCategory]);

	useEffect(() => {
		if (message && !balloonId) {
			setBalloonMessage(true);
		}
		if (!message && !balloonId) {
			setBalloonMessage(false);
		}
		if (message && balloonId) {
			setBalloonMessage(false);
		}
	}, [message, balloonId]);

	useEffect(() => {
		if (data?.response?.data?.statusCode === 409) {
			dispatch(updateNewSeller(null));
			getToasterErrors(data?.response?.data?.message);
			setIsShow(false);
		}
		if (data?.response?.data?.statusCode === 401 && token) {
			navigate("/loginUser");
			dispatch(updateNewSeller(null));
			getToasterErrors(data?.response?.data?.message);
			setIsShow(true);
			localStorage.setItem("token", "");
			localStorage.setItem("adminToken", "");
		}
		if (data?.response?.data?.statusCode === 403) {
			setIsShow(true);
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const handleClose = () => {
		setIsShow(false);
		setShowErrorDetail("");
		setSellerName("");
		setCategory(null);
		setMessage();
		setCity("");
		setEmail("");
		setBalloonId(null);
		setSelectedCountry();
		setSellerType();
		setCountryState("");
		setSellerAddress("");
		dispatch(getCategory(""));
		if (selectedOutsideUsCountry) {
			setOpen(false);
			setSelectedOutsideUsCountry(null);
		}
		if (selectWholeCategory) {
			setSelectedWholeCategory(null);
		}
	};

	useEffect(() => {
		if (data?.status === 201) {
			setSellerName("");
			setCategory(null);
			setSelectedCountry();
			setCountryState(null);
			setSellerAddress(null);
			setMessage();
			setBalloonId(null);
			setShowErrorDetail("");
			dispatch(getCategory(""));
			if (!isHideField && fromHomePage) {
				setSelectedWholeCategory(null);
			}
			setEmail("");
			setSellerType();
			setCity("");
			setCountryState("");
			getToasterNotifications("Your seller has been added successfully!");
			setIsShow(false);
			if (addSeller && addSeller?.data?.seller?.id) {
				dispatch(getSellerId(addSeller?.data?.seller?.id));
				dispatch(getSellerName(addSeller?.data?.seller?.sellerName));
				navigate(`/seller-balloon/${addSeller?.data?.seller?.id}`);
			}
			dispatch(updateNewSeller(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAddSellerLoading, data]);

	useEffect(() => {
		if (addSellerName) {
			setSellerName(addSellerName);
		}
	}, [addSellerName]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (isShow) {
				dispatch(googleAPIRequest(googleAddress));
			}
		}, 1000);

		return () => clearTimeout(timeout);
	}, [googleAddress, isShow]);

	const editorRef = useRef(null);

	const checkBoxHandler = (e) => {
		setIsGlobalChecked(e.target.checked);
	};

	useEffect(() => {
		setIsGlobalChecked(false);
	}, [sellerType]);

	return (
		<Modal
			show={isShow}
			onHide={handleClose}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="fw-bold addseller-header">
				<img src="/icons/logo2.png" className="mx-2  logo-width" alt="logo" />
				<span className="addseller-heading">
					{selectedOutsideUsCountry
						? `Welcome, ${selectedOutsideUsCountry?.label}!`
						: "Add a Seller!"}
				</span>
			</Modal.Header>
			<Modal.Body className="text-center">
				{!isAddSellerLoading && (
					<Backdrop
						sx={{ color: "#fff", zIndex: 1000 }}
						open={!isAddSellerLoading}
					>
						<div className="nominateLoader ">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				)}
				<form>
					<div
						className={`${!isHideField && " d-flex justify-content-center"}`}
					>
						<div>
							{!selectedOutsideUsCountry && (
								<div className="fw-md-bold fw-normal add-seller-information">
									{isHideField
										? "Please enter your information:"
										: "Please enter the information about your seller:"}
								</div>
							)}
							{selectedOutsideUsCountry && (
								<div className="fst-italic add-seller-information">
									If you want to click a balloon for a seller in your country,
									you can do so here!
								</div>
							)}
							<div className="row mt-3">
								<div className="col-lg-2 col-md-2 col-sm-12 form-group field-justify-content"></div>
								<div className="col-lg-8 col-md-8 col-sm-12 form-group field-justify-content">
									<TextField
										id="name"
										value={sellerName}
										className={` rounded-3 mb-3 add-seller-input-w  ${
											isHideField && "w-100"
										}`}
										onChange={(e) => {
											setSellerName(e.target.value);
											setShowErrorDetail("");
										}}
										sx={{
											"& .MuiOutlinedInput-input": {
												padding: "10px",
											},
											"& .MuiInputBase-root": {
												borderRadius: "10px",
											},
										}}
										placeholder={isHideField ? "Business Name" : "Seller Name"}
										type="text"
									/>
									{!sellerName && showErrorDetail && (
										<p className="text-danger text-center">{showErrorDetail}</p>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-lg-2 col-md-2 col-sm-12 form-group"></div>
								<div className="col-lg-8 col-md-8 col-sm-12 form-group">
									<TextField
										placeholder={
											isHideField
												? "Business Web Address"
												: "Seller's Website Address (seller.com)"
										}
										sx={{
											"& .MuiOutlinedInput-input": {
												padding: "10px",
												borderRadius: "10px",
											},
											"& .MuiInputBase-root": {
												borderRadius: "10px",
											},
										}}
										className={` rounded-3 mb-3 add-seller-input-w  ${
											isHideField && "w-100"
										}`}
										value={email}
										type="url"
										id="name"
										onChange={(event) => {
											setEmail(event.target.value);
											setShowErrorDetail("");
											setIsSubmitSeller(false);
										}}
									/>
									{((!email && isSubmitSeller) ||
										(isSubmitSeller && !isValidURL(email))) && (
										<p className="text-danger text-center">
											{showErrorDetail
												? showErrorDetail
												: "Please Enter valid URL"}
										</p>
									)}
								</div>
							</div>
							<div className="row row-padding-right mb-2">
								<div className="col-lg-3 col-md-3 col-sm-12 form-group" />

								<div className="col-lg-6 col-md-6 col-sm-12 form-group">
									{!sellerType && (
										<span
											className="click-color"
											style={{ textDecorationLine: "underline" }}
											onClick={() => setIsShowDetailPopup(true)}
										>
											<Report style={{ color: "red" }}> </Report>
											Please select Seller's classification accurately. Sellers
											who are not classified correctly will be filtered
											automatically.
										</span>
									)}
								</div>
								<div className="col-lg-2 col-md-2 col-sm-12 form-group" />
							</div>
							<div className="row row-padding-right">
								<div className="col-lg-2 col-md-2 col-sm-12 form-group"></div>
								<div className="col-lg-8 mb-3 row-padding-right col-md-8 col-sm-12  form-group">
									<div className="d-flex field-justify-content">
										<Autocomplete
											sx={{
												"& .MuiOutlinedInput-root": {
													padding: "2px",
													borderRadius: "10px",
												},
												"& .MuiAutocomplete-input": {
													paddingLeft: "17px !important",
												},
											}}
											value={sellerType}
											popupIcon={""}
											onChange={(e, type) => {
												if (type) {
													if (type === sellerTypeArray[3]) {
														dispatch(getCategory("B2B"));
													} else {
														dispatch(getCategory("ALL"));
													}
													setSellerType(type);

													setShowErrorDetail("");
												} else {
													setSellerType(null);
												}
											}}
											readOnly={selectedOutsideUsCountry?.label ? true : false}
											options={
												sellerTypeArray && sellerTypeArray?.length > 0
													? sellerTypeArray
													: []
											}
											getOptionLabel={(option) => {
												// Regular option
												if (fromOutlier) {
													if (option !== sellerTypeArray[0]) {
														return option;
													}
												} else {
													return option;
												}
											}}
											className={`add-seller-input-w  ${
												isHideField && "w-100"
											} ${selectedOutsideUsCountry && "disabled-input-color"}`}
											renderInput={(params) => (
												<div className="autocomplete-search-inputfield">
													<TextField
														{...params}
														placeholder="Select Classifications"
														InputProps={{
															...params.InputProps,
														}}
													/>
												</div>
											)}
										/>
									</div>
									{!sellerType && showErrorDetail && (
										<p className="text-danger text-center ml-3">
											{showErrorDetail}
										</p>
									)}
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-lg-2 col-md-2 col-sm-12 form-group"></div>
								<div className="col-lg-8  col-md-8 col-sm-12 form-group">
									<div className="d-flex field-justify-content">
										<Autocomplete
											className={`p-0 border-2 add-seller-input-w  ${
												isHideField && "w-100"
											}`}
											popupIcon={""}
											defaultValue={selectWholeCategory}
											value={category}
											sx={{
												"& .MuiOutlinedInput-root": {
													padding: "2px",
													borderRadius: "10px",
												},
												"& .MuiAutocomplete-input": {
													paddingLeft: "17px !important",
												},
											}}
											onChange={(e, cat) => {
												if (cat?.id) {
													setCategory(cat);
												} else {
													setCategory(null);
												}
												setShowErrorDetail("");
											}}
											options={
												sortedCategories && sortedCategories?.length > 0
													? sortedCategories
													: []
											}
											getOptionLabel={(option) => {
												// Regular option
												return option?.categoryName;
											}}
											renderInput={(params) => (
												<div className="autocomplete-search-inputfield">
													<TextField
														{...params}
														placeholder="Select Category"
														InputProps={{
															...params.InputProps,
														}}
													/>
												</div>
											)}
										/>
									</div>
									{!category && showErrorDetail && (
										<p className="text-danger text-center">{showErrorDetail}</p>
									)}
								</div>
							</div>
							{sellerType && sellerType !== sellerTypeArray[0] && (
								<div className={`row mb-3`}>
									<div className="col-lg-2 col-md-2 col-sm-12 form-group"></div>
									<div className="col-lg-8  col-md-8 col-sm-12 form-group">
										<div className="d-flex field-justify-content">
											<Autocomplete
												className={`p-0 border-2 add-seller-input-w  ${
													isHideField && "w-100"
												}`}
												popupIcon={""}
												value={selectedGoogleAddress}
												sx={{
													"& .MuiOutlinedInput-root": {
														padding: "2px",
														borderRadius: "10px",
													},
													"& .MuiAutocomplete-input": {
														paddingLeft: "17px !important",
													},
												}}
												onChange={(e, cat) => {
													setSelectedGoogleAddress(cat);
												}}
												options={
													googlePlaceResponse &&
													googlePlaceResponse?.predictions?.length > 0
														? googlePlaceResponse?.predictions
														: []
												}
												getOptionLabel={(option) => {
													// Regular option
													return option?.description;
												}}
												renderInput={(params) => (
													<div className="autocomplete-search-inputfield">
														<TextField
															{...params}
															placeholder="Select City"
															InputProps={{
																...params.InputProps,
															}}
															onChange={(event) => {
																setGoogleAddress(event.target.value);
															}}
														/>
													</div>
												)}
											/>
										</div>
										{!selectedGoogleAddress && showErrorDetail && (
											<p className="text-danger text-center">
												{showErrorDetail}
											</p>
										)}
									</div>
								</div>
							)}
							{sellerType === sellerTypeArray[3] && (
								<div className="checkbox">
									<FormGroup>
										<FormControlLabel
											control={<Checkbox onChange={checkBoxHandler} />}
											label="Check here if your sellers serves customers globally."
										/>
									</FormGroup>
								</div>
							)}
							{!isHideField && (
								<div className="add-seller-review-section">
									<h5 className="text-center fw-md-bold fw-normal add-seller-information mb-1 add-seller-decription-text">
										Now, please click the balloon that describes your experience
										with this seller.
									</h5>
									<Remarks
										setBalloonId={setBalloonId}
										isFromFooter={isFromFooter}
									/>
									<div className="form-outline add-seller-editor d-flex justify-content-center mt-md-3 mt-1">
										{/* <textarea
											className="form-control tell-story-input addSeller-textarea-width"
											id="textAreaExample2"
											placeholder="Tell your Story Here! (Optional)"
											onChange={(e) => setMessage(e.target.value)}
										/> */}
										<Editor
											onInit={(evt, editor) => (editorRef.current = editor)}
											apiKey={process.env.REACT_APP_EDITOR_KEY}
											init={{
												height: 350,
												menubar: false,
												width: screenWidth > 768 ? "70%" : "92%",
											}}
											value={message}
											onEditorChange={(event) => {
												setMessage(event);
											}}
										/>
									</div>
								</div>
							)}
							<div className="text-center mt-2">
								<Link to="">
									<Button
										type="submit"
										className="btn add-seller-submit-button"
										onClick={() => {
											handleSubmit();
										}}
									>
										Submit
									</Button>
								</Link>
							</div>
						</div>
					</div>
				</form>
			</Modal.Body>
			<LoginModal
				isShow={showLoginModal}
				setIsShow={setLoginModal}
				setSignUp={setSignUpModal}
			/>
			<SignUpModal
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setLoginModal}
			/>
			<DetailModal
				isShowDetailPopup={isShowDetailPopup}
				setIsShowDetailPopup={setIsShowDetailPopup}
			/>
		</Modal>
	);
}

export default AddAsellerModal;
