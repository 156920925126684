import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reportBugRequest } from "../../redux/reducers/ducks/contactDuck";
import "./reportBug.css";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

function ReportBug({ showReportBug, setShowReportBug }) {
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const dispatch = useDispatch();
	const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);

	const [isBugSubmit, setIsBugSubmit] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsBugSubmit(true);
	};

	function onChange(value) {
		if (value) {
			setIsReCaptchaValid(true);
			setIsBugSubmit(false);
		} else {
			setIsReCaptchaValid(false);
		}
	}

	useEffect(() => {
		if (isReCaptchaValid) {
			const requestData = {
				email: email,
				reportMessage: message,
				status: "pending",
			};
			dispatch(reportBugRequest(requestData));
			setEmail("");
			setMessage("");
			setShowReportBug(false);
			try {
				getToasterNotifications(
					"Bug has been reported successfully.  Tech team will resolve it asap. Thanks for reporting!"
				);
			} catch (e) {
				getToasterErrors(e?.response?.data?.message || "");
			}
			setTimeout(() => {
				navigate("/");
			}, 1000);
		}
	}, [isReCaptchaValid]);

	return (
		<Modal
			show={showReportBug}
			onHide={() => setShowReportBug(false)}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="search-modal-parent"
		>
			<Modal.Header closeButton className="border-0" />
			<div className="d-flex justify-content-center contact_form">
				<form onSubmit={handleSubmit} className=" mt-4 contactUs-form">
					<h2 className="text-center mt-4 mb-4 fs-4">Report a bug!</h2>
					{/* <div className="form-group mb-3 input-fields-width mx-auto shadow-sm">
						<input
							type="email"
							className="w-100 border rounded p-2 shadow-sm contact-email"
							name="email"
							id="inputEmail"
							placeholder="Email (Optional)"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
					</div> */}
					<div className="form-group mb-3 input-fields-width mx-auto shadow-sm">
						<textarea
							className="form-control mt-3 mx-auto w-100 contactus-textarea"
							id="textAreaExample2"
							rows="10"
							required
							placeholder="Write bug details here"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</div>
					{isBugSubmit && (
						<div className="recaptchParent">
							<ReCAPTCHA
								style={{ display: "inline-block" }}
								theme="dark"
								sitekey={process.env.REACT_APP_ReactCaptcha}
								onChange={onChange}
							/>
						</div>
					)}
					<div className="text-center">
						<button
							type="submit"
							className="btn mt-2 mb-4 rounded-pill contactus-btn"
						>
							Send Now!
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
}

export default ReportBug;
