import * as React from "react";

import { Autocomplete } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import AllSeller from "./allSeller";
import AllUser from "./allUser";
import BalloonsTypes from "./balloonsType";
import "./adminReview.css";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";

export default function AllSearchBAr({
	setSellersID,
	setUsersID,
	setCategoryId,
	selectType,
	setPage,
	isLoading,
}) {
	const { categoryData } = useSelector((state) => {
		return {
			categoryData: state?.category?.category?.data?.records,
		};
	});

	const dispatch = useDispatch();

	return (
		<div className="row mb-5">
			{/* <div className=''> */}
			<div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-start admin-review-searchs">
				<Autocomplete
					id="free-solo-2-demo"
					className=" inputField rounded w-100 text-start"
					popupIcon={""}
					onChange={(e, category) => {
						if (category) {
							isLoading(true);
							dispatch(handleAdminStatus(false));
							setPage(0);
							setCategoryId(category?.id);
						} else {
							setCategoryId("");
						}
					}}
					sx={{
						".MuiOutlinedInput-root": {
							padding: "3px",
						},
						"#free-solo-2-demo": {
							textAlign: "start !important",
							marginLeft: "1rem",
						},
					}}
					options={categoryData && categoryData?.length > 0 ? categoryData : []}
					getOptionLabel={(option) => {
						if (option && typeof option?.categoryName === "string") {
							return option?.categoryName;
						}
						return "";
					}}
					clearOnBlur={true}
					renderInput={(params) => {
						return (
							// created the inputfield for searching
							<div className="autocomplete-search-inputfield">
								<TextField
									className="text-center seller-textfield"
									id="autocomplete"
									sx={{
										"#free-solo-2-demo": {
											textAlign: "center",
										},
									}}
									{...params}
									placeholder="Categories"
								/>
							</div>
						);
					}}
				/>
			</div>

			<div className="col-lg-3 col-md-6 col-sm-6 admin-review-searchs">
				<AllSeller
					setSellersId={setSellersID}
					setPage={setPage}
					isLoading={isLoading}
				/>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6 admin-review-searchs">
				<AllUser
					setUserID={setUsersID}
					setPage={setPage}
					isLoading={isLoading}
				/>
			</div>
			<div className="col-lg-3 col-md-6 col-sm-6 admin-review-searchs">
				<BalloonsTypes
					setType={selectType}
					setPage={setPage}
					isLoading={isLoading}
				/>
			</div>
			{/* <div className="col-lg-12 col-md-12 col-sm-12 admin-review-msg-search">
				<input
					id="standard-basic"
					className="p-2 ps-4 rounded w-100 review-user-text-field"
					placeholder="Search a review"
					value={msgSearchData}
					onChange={(e) => {
						isLoading(true);
						dispatch(handleAdminStatus(false));
						setPage(0);
						setMsgSearchData(e.target.value);
					}}
				/>
			</div> */}
		</div>
	);
}
