import { put, call, takeLatest } from "redux-saga/effects";
import {
	bestWriterApi,
	deleteReviewApi,
	emailSentApi,
	getAllReviews,
	getAllSellersInReview,
	progressDate,
	reviewStatusApi,
	sendBestWriterEmail,
	winnerDateApi,
} from "../../../api/adminReviewApi";
import {
	UpdateSellerTypeRequest,
	adminReviewLoadingResponse,
	averageMonthlySignUpsRequest,
	averageMonthlySignUpsResponse,
	bestWriterRequest,
	bestWriterNominationRequest,
	bestWriterResponse,
	csvCategoriesRequest,
	csvCategoriesResponse,
	deleteReviewRequest,
	getAdminReview,
	getAdminReviewAllSellers,
	getEmailSentPayload,
	getReviewSellers,
	loggerRequest,
	loggerResponse,
	pendingSellersRequest,
	pendingSellersResponse,
	recentActivitiesRequest,
	recentActivitiesResponse,
	sentContactEmailResponse,
	updateAdminReview,
	updateReviewAdminStatus,
	userProgressRequest,
	userProgressResponse,
	weeklyAverageRequest,
	monthlyAverageRequest,
	weeklyGraphRequest,
	monthlyGraphRequest,
	weeklyAverageResponse,
	monthlyAverageResponse,
	weeklyGraphResponse,
	monthlyGraphResponse,
	totalSignUpCountRequest,
	totalSignUpCountResponse,
	emailPortalRequest,
	emailPortalResponse,
	updateIsBestWriterNominated,
	sendBestWriterEmailRequest,
	sendBestWriterEmailResponse,
	alreadyWinnerReviewRequest,
	winnerDateResponse,
	winnerDateRequest,
	adminImageUploadStatusResponse,
} from "../../reducers/ducks/adminReviewDuck";
import { handleAdminStatus } from "../../reducers/ducks/getSellersDuck";
import {
	alreadyNominationApi,
	averageMonthlySignUpsApi,
	bestWriterNominationApi,
	csvCategoriesApi,
	emailLoggerPortalApi,
	loggerApi,
	monthlyAverageSignUpsApi,
	monthlyGraphSignUpsApi,
	pendingSellersApi,
	recentActivitiesApi,
	totalSignUpCountApi,
	updateSellerTypeApi,
	weeklyAverageSignUpsApi,
	weeklyGraphSignUpsApi,
} from "../../../api/adminUserApi";

export function* getAllAdminReviews({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		yield put(adminReviewLoadingResponse(true));
		const data = yield call(getAllReviews, payload);
		yield put(updateAdminReview(data));
	} catch (error) {
		yield put(adminReviewLoadingResponse(false));
		yield put(updateAdminReview(error?.response?.data));
	} finally {
		yield put(adminReviewLoadingResponse(false));
		yield put(handleAdminStatus(true));
	}
}
export function* updateAdminReviewSatus({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		yield call(reviewStatusApi, payload);
	} catch (error) {
		yield put(handleAdminStatus(true));
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* emailSentSaga({ payload }) {
	try {
		const data = yield call(emailSentApi, payload);
		yield put(sentContactEmailResponse(data));
	} catch (err) {
		yield put(sentContactEmailResponse(err));
		return err;
	}
}

export function* bestWriterSaga({ payload }) {
	try {
		const response = yield call(bestWriterApi, payload);
		yield put(adminImageUploadStatusResponse(response));
		yield put(updateIsBestWriterNominated(response?.data));
		yield put(handleAdminStatus(true));
	} catch (err) {
		yield put(adminImageUploadStatusResponse(err));
		return err;
	}
}

export function* allSellersInReview() {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(getAllSellersInReview);
		yield put(getAdminReviewAllSellers(data));
	} catch (error) {
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* userProgressSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const progressData = yield call(progressDate, payload);
		yield put(userProgressResponse(progressData?.data));
	} catch (error) {
		yield put(userProgressResponse(error));
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* deleteReviewSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		yield call(deleteReviewApi, payload);
	} catch (error) {
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* loggerSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(loggerApi, payload);
		yield put(loggerResponse(response?.data));
	} catch (error) {
		yield put(loggerResponse(error?.data));
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* csvCategoriesSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(csvCategoriesApi, payload);
		yield put(csvCategoriesResponse(response?.data));
	} catch (error) {
		yield put(csvCategoriesResponse(error?.data));

		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* pendingSellersSaga({ payload }) {
	try {
		const response = yield call(pendingSellersApi, payload);
		yield put(pendingSellersResponse(response?.data));
	} catch (error) {
		yield put(pendingSellersResponse(error?.data));

		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* recentActivitiesSaga({ payload }) {
	try {
		const response = yield call(recentActivitiesApi, payload);
		yield put(recentActivitiesResponse(response?.data));
	} catch (error) {
		yield put(recentActivitiesResponse(error?.data));
		return error;
	}
}

export function* averageMonthlySignUpsSaga() {
	try {
		const response = yield call(averageMonthlySignUpsApi);
		yield put(averageMonthlySignUpsResponse(response?.data));
	} catch (error) {
		yield put(averageMonthlySignUpsResponse(error?.data));
		return error;
	}
}
export function* updateSellerTypeSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		yield call(updateSellerTypeApi, payload);
		yield put(handleAdminStatus(true));

		// yield put(averageSignUpsResponse(response?.data));
	} catch (error) {
		yield put(handleAdminStatus(true));
		// yield put(averageSignUpsResponse(error?.data));
		return error;
	}
}

export function* bestWriterReviewsSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(bestWriterNominationApi, payload);
		yield put(bestWriterResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(bestWriterResponse(error?.data));
		return error;
	}
}

export function* alreadyWinnerReviewsSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(alreadyNominationApi, payload);
		yield put(bestWriterResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(bestWriterResponse(error?.data));
		return error;
	}
}

export function* weeklyAverageSaga() {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(weeklyAverageSignUpsApi);
		yield put(weeklyAverageResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(weeklyAverageResponse(error?.data));
		return error;
	}
}

export function* weeklyGraphSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(weeklyGraphSignUpsApi, payload);
		yield put(weeklyGraphResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(weeklyGraphResponse(error?.data));
		return error;
	}
}

export function* monthlyAverageSaga() {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(monthlyAverageSignUpsApi);
		yield put(monthlyAverageResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(monthlyAverageResponse(error?.data));
		return error;
	}
}

export function* monthlyGraphSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(monthlyGraphSignUpsApi, payload);
		yield put(monthlyGraphResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(monthlyGraphResponse(error?.data));
		return error;
	}
}

export function* totalSignUpCountSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(totalSignUpCountApi, payload);
		yield put(totalSignUpCountResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(totalSignUpCountResponse(error?.data));
		return error;
	}
}

export function* emailLoggerPortalSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(emailLoggerPortalApi, payload);
		yield put(emailPortalResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(emailPortalResponse(error?.data));
		return error;
	}
}

export function* sendBestWriterEmailsSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(sendBestWriterEmail, payload);
		yield put(sendBestWriterEmailResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(handleAdminStatus(true));
		yield put(sendBestWriterEmailResponse(error?.response));
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* winnerDateSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const response = yield call(winnerDateApi, payload);
		yield put(winnerDateResponse(response?.data));
		yield put(handleAdminStatus(true));
	} catch (error) {
		yield put(handleAdminStatus(true));
		yield put(winnerDateResponse(error?.response));
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* watchAdminReviewSagas() {
	yield takeLatest(getAdminReview.type, getAllAdminReviews);
	yield takeLatest(updateReviewAdminStatus.type, updateAdminReviewSatus);
	yield takeLatest(getReviewSellers.type, allSellersInReview);
	yield takeLatest(getEmailSentPayload.type, emailSentSaga);
	yield takeLatest(userProgressRequest.type, userProgressSaga);
	yield takeLatest(bestWriterRequest.type, bestWriterSaga);
	yield takeLatest(deleteReviewRequest.type, deleteReviewSaga);
	yield takeLatest(loggerRequest.type, loggerSaga);
	yield takeLatest(pendingSellersRequest.type, pendingSellersSaga);
	yield takeLatest(recentActivitiesRequest.type, recentActivitiesSaga);
	yield takeLatest(csvCategoriesRequest.type, csvCategoriesSaga);
	yield takeLatest(
		averageMonthlySignUpsRequest.type,
		averageMonthlySignUpsSaga
	);
	yield takeLatest(UpdateSellerTypeRequest.type, updateSellerTypeSaga);
	yield takeLatest(bestWriterNominationRequest.type, bestWriterReviewsSaga);
	yield takeLatest(alreadyWinnerReviewRequest.type, alreadyWinnerReviewsSaga);

	yield takeLatest(weeklyAverageRequest.type, weeklyAverageSaga);
	yield takeLatest(monthlyAverageRequest.type, monthlyAverageSaga);
	yield takeLatest(weeklyGraphRequest.type, weeklyGraphSaga);
	yield takeLatest(monthlyGraphRequest.type, monthlyGraphSaga);
	yield takeLatest(totalSignUpCountRequest.type, totalSignUpCountSaga);
	yield takeLatest(emailPortalRequest.type, emailLoggerPortalSaga);
	yield takeLatest(sendBestWriterEmailRequest.type, sendBestWriterEmailsSaga);
	yield takeLatest(winnerDateRequest.type, winnerDateSaga);
}
