import Layout from "../../components/layout/parentLayout/Layout";
import "./profile.css";
// import { useSelector } from "react-redux";
import ChangePasswordComponent from "../../components/changePassword/changePassword";
// import UploadImageToS3WithNativeSdk from "../../components/uploadVedioS3/uploadVedioS3";
// import Remarks from "../../components/remarks/remarks";

function Profile() {
	// const { loginResponseData } = useSelector((state) => ({
	// 	loginResponseData: state.authorization?.loginResponseData,
	// }));
	return (
		<Layout>
			<div className="container mb-5 privacy-wrapper-container p-4">
				<ChangePasswordComponent isFromProfile />
			</div>
		</Layout>
	);
}

export default Profile;
