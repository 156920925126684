import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./airSellerReaction.css";

function AirSellerReaction({ badReviews, setBadReviews }) {
	const [animation, setAnimation] = useState({
		isAnimate: false,
		index: null,
	});

	const balloon = useSelector((state) => state?.seller?.balloon?.data?.title);

	let isTestServer = JSON.parse(process.env.REACT_APP_IS_TEST_SERVER);

	const pathArray = isTestServer
		? window.location.pathname?.split("/")[2]
		: window.location.pathname?.split("/")[1];

	return (
		<>
			<div className="parent-review-width mx-md-auto mx-1 mt-4">
				<div className="row">
					{balloon?.map((i) => {
						return i.type === "to-air" ? (
							<>
								<div className="d-flex justify-content-center col-4 air-seller-cursor">
									<div>
										<img
											src={`/icons/${i.imageName}.png`}
											onClick={() => {
												setAnimation({
													isAnimate: !animation.isAnimate,
													index: i.id,
												});
												if (!animation.isAnimate) {
													setBadReviews({
														...badReviews,
														titleId: i.id,
													});
												} else {
													setBadReviews({
														...badReviews,
														titleId: "",
													});
												}
											}}
											className={
												(animation.isAnimate && i.id === animation.index) ||
												(badReviews?.titleId === i.id &&
													pathArray === "seller-balloon")
													? "animation w-100"
													: "w-100"
											}
											alt="awesome"
										/>
										<div className="d-flex justify-content-center col-lg-12 col-md-12 col-sm-12 ">
											<div
												onClick={() => {
													setAnimation({
														isAnimate: !animation.isAnimate,
														index: i.id,
													});
												}}
												className={
													animation.isAnimate && i.id === animation.index
														? "love_shadow"
														: null
												}
											>
												<div className="love-review-title">{i.title}</div>
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							""
						);
					})}
				</div>
			</div>
		</>
	);
}

export default AirSellerReaction;
