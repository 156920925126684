import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Layout from "../../../components/layout/parentLayout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
	loginRequest,
	updateIsLogin,
	updateLoginUser,
	updateToken,
} from "../../../redux/reducers/ducks/autorizeDuck";
import "./loginForm.css";
import { Backdrop, CircularProgress } from "@mui/material";
import {
	activeLoginUser,
	likeDislikeReviewResponse,
} from "../../../redux/reducers/ducks/getSellersDuck";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function LoginForm() {
	const navigate = useNavigate();

	const [password, setPassword] = useState("");
	const [isLoginSubmitted, setIsLoginSubmitted] = useState(false);
	const [email, setEmail] = useState("");
	const [error, setError] = useState(null);
	const [isReCaptchaValue, setIsReCaptchaValue] = useState(false);
	const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);
	const [isEyeIconClick, setIsEyeIconClick] = useState(false);
	const dispatch = useDispatch();
	const { login, isLogin } = useSelector((state) => {
		return {
			login: state.authorization?.accessToken,
			isLogin: state.authorization?.isLogin,
		};
	});

	const search = window.location.href;
	const url = search.split("?");
	const urlEmail = new URLSearchParams(url[1])?.get("email");
	const resetToken = new URLSearchParams(url[1])?.get("Token");
	const token = resetToken?.replaceAll(" ", "+");

	useEffect(() => {
		if (token && urlEmail) {
			const dispatchedData = {
				token: token,
				email: urlEmail,
				isActive: true,
			};
			dispatch(activeLoginUser(dispatchedData));
		}
	}, [token, urlEmail, dispatch]);

	function onChange(value) {
		if (value) {
			setIsReCaptchaValid(true);
			setIsReCaptchaValue(true);
		} else {
			setIsReCaptchaValue(false);
		}
	}

	useEffect(() => {
		if (token && urlEmail) {
			const dispatchedData = {
				token: token,
				email: urlEmail,
				isActive: true,
			};
			dispatch(activeLoginUser(dispatchedData));
		}
	}, [isReCaptchaValue]);
	// function made for handling the api
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!email || !password) {
			setError("Please fill the input field.");
		} else {
			setIsLoginSubmitted(true);
			if (isReCaptchaValue) {
				const requestData = {
					email: email,
					password: password,
				};
				dispatch(updateLoginUser(null));
				dispatch(updateIsLogin(false));
				dispatch(loginRequest(requestData));
				setIsReCaptchaValid(true);
				setIsLoginSubmitted(false);
			}
		}
	};

	useEffect(() => {
		if (isReCaptchaValue) {
			const requestData = {
				email: email,
				password: password,
			};
			dispatch(updateLoginUser(null));
			dispatch(updateIsLogin(false));
			dispatch(loginRequest(requestData));
			setIsReCaptchaValid(true);
			setIsLoginSubmitted(false);
		}
	}, [isReCaptchaValue]);

	useEffect(() => {
		if (
			(login?.status === 200 || login?.status === 201) &&
			!isLogin &&
			login?.data?.roles !== "l2a_admin"
		) {
			dispatch(updateToken(null));
			getToasterNotifications(
				!login?.data?.keyLogin ? "Nice to meet you!" : "Nice to see you again!"
			);
			setIsReCaptchaValid(false);
			localStorage.setItem("token", login?.data?.access_token);
			navigate("/");
			setIsLoginSubmitted(false);
			dispatch(updateLoginUser(null));
			dispatch(updateIsLogin(true));
			dispatch(likeDislikeReviewResponse(null));
		} else if (
			login?.response?.status === 404 ||
			login?.response?.status === 401 ||
			login?.response?.status === 400
		) {
			setIsLoginSubmitted(false);
			setIsReCaptchaValid(false);
			getToasterErrors("Please check your email / password and try again!");
			dispatch(updateIsLogin(false));
			dispatch(updateLoginUser(null));
		} else if (
			(login?.status === 200 || login?.status === 201) &&
			!isLogin &&
			login?.data?.roles === "l2a_admin"
		) {
			dispatch(updateToken(null));

			setIsLoginSubmitted(false);
			setIsReCaptchaValid(false);
			getToasterErrors("invalid credential");
			localStorage.setItem("token", "");
			dispatch(updateLoginUser(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [login, isLogin]);

	return (
		<Layout>
			<div className="container">
				<img
					src="/icons/luv2a.gif"
					className="mx-auto mt-5 d-block w-25 my-auto"
					alt="logo"
				/>
				<div className="d-flex justify-content-center login-form">
					<form className="mt-4 rounded-4 bg-white shadow login-container position-relative">
						{!isLogin && isReCaptchaValid && (
							<Backdrop
								sx={{ color: "#fff", zIndex: 2 }}
								open={!isLogin && isReCaptchaValid}
							>
								<div className="d-flex  justify-content-center loaderCenter align-item-center">
									<CircularProgress color="inherit" />
								</div>
							</Backdrop>
						)}
						<h2 className="text-center mt-4 mb-4 fs-4">Login</h2>
						<div className="form-group  mb-3 login-form-inputs mx-auto shadow-sm">
							<input
								type="email"
								required
								className="w-100 border rounded p-2 shadow-sm"
								name="email"
								id="inputEmail"
								placeholder="Enter email"
								onChange={(e) => {
									setEmail(e.target.value);
									setError("");
								}}
							/>
						</div>
						{!email && (
							<p className="text-danger text-center required">{error}</p>
						)}
						<div className="form-group position-relative mb-3 login-form-inputs mx-auto shadow-sm">
							<input
								className="w-100 border rounded shadow-sm p-2 "
								name="password"
								type={isEyeIconClick ? "text" : "password"}
								id="inputPassword"
								required
								placeholder="Password"
								onChange={(e) => {
									setPassword(e.target.value);
									setError("");
								}}
							/>
							{isEyeIconClick && (
								<span
									className="eye-icon"
									onClick={() => setIsEyeIconClick(false)}
								>
									<FontAwesomeIcon icon={faEye} />
								</span>
							)}
							{!isEyeIconClick && (
								<span
									className="eye-icon"
									onClick={() => setIsEyeIconClick(true)}
								>
									<FontAwesomeIcon icon={faEyeSlash} />
								</span>
							)}
						</div>
						{!password && (
							<p className="text-danger text-center required">{error}</p>
						)}
						<div className="text-center mb-2">
							{/*created the signup button*/}
							<Link to="">
								<Button type="submit" onClick={handleSubmit} className="login">
									Login
								</Button>
							</Link>
						</div>
						<div className="recaptchParent">
							{isLoginSubmitted && (
								<ReCAPTCHA
									style={{ display: "inline-block" }}
									theme="dark"
									sitekey={process.env.REACT_APP_ReactCaptcha}
									onChange={onChange}
								/>
							)}
						</div>
						<div className="row  login-parents d-flex justify-content-center mt-2">
							<div className="col-lg-6 col-md-6 col-sm-12 login-link">
								<p className="text-center">
									To join in, please{" "}
									<Link
										to="/sign-up"
										className="sign-up-anchor fw-bold text-decoration-none"
									>
										Sign up!
									</Link>
								</p>
							</div>

							<div className="col-lg-6  col-md-6 col-sm-12 login-link">
								<p className="text-center">
									<Link
										to="/forgot-password"
										className="text-decoration-none fw-bold"
									>
										Forgot Password?
									</Link>
								</p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Layout>
	);
}

export default LoginForm;
