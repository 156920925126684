import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Backdrop, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import moment from "moment";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
	adminAllContactRequest,
	adminContactStatusRequest,
} from "../../../redux/reducers/ducks/contactDuck";
import MessagePopup from "../review/messagePopup";
import { EmailResponsePopup } from "./emailPopup";

export default function ContactsMainPage() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [emailSearch, setEmailSearch] = useState("");
	const [responseEmail, setResponseEmail] = useState("");
	const [openDialog, setOpenDialog] = useState({
		isOpen: false,
		msg: null,
	});

	const [isChangeCategoryData, setIsChangeCategoryData] = useState(false);
	const { isAdminStatus, adminReviewLoading, contactResponseData } =
		useSelector((state) => {
			return {
				isAdminStatus: state?.seller?.isAdminChangeStatus,
				adminReviewLoading: state?.adminReview?.adminReviewLoading,
				contactResponseData: state?.contact?.allContacts,
			};
		});

	const dispatch = useDispatch();
	const handleChangePage = (event, newPage) => {
		dispatch(handleAdminStatus(false));
		setPage(newPage);
	};

	const { contacts } = useSelector((state) => {
		return {
			contacts: state?.contact?.allContacts?.data,
		};
	});
	const columns = [
		{ id: "name", label: `User's Email`, minWidth: 130 },
		{ id: "code1", label: "Message", minWidth: 100 },
		{ id: "code2", label: "Date", minWidth: 100 },
		{ id: "code4", label: "Email Reply", minWidth: 100 },
		{ id: "code4", label: "Email Satus", minWidth: 60 },
		{ id: "code3", label: "Status", minWidth: 100 },
	];

	useEffect(() => {
		const requestData = {
			page: page + 1,
			pageSize: rowsPerPage,
			email: emailSearch,
		};
		const timeout = setTimeout(() => {
			dispatch(adminAllContactRequest(requestData));
			setIsChangeCategoryData(false);
		}, 1000);

		return () => clearTimeout(timeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, emailSearch]);

	const handleStatusChange = (event, Id) => {
		const requestData = {
			contactId: Id,
			status: event.target.value,
		};
		dispatch(adminContactStatusRequest(requestData));
		setIsChangeCategoryData(true);
	};

	useEffect(() => {
		if (isAdminStatus) {
			const requestData = {
				page: page + 1,
				pageSize: rowsPerPage,
				email: emailSearch,
			};
			dispatch(adminAllContactRequest(requestData));
			setIsChangeCategoryData(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdminStatus, page, dispatch]);

	const handleRowsPerPage = (e) => {
		setRowsPerPage(e.target.value);
	};

	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const getProperClass = (status) => {
		if (status === "pending") return "contact-pending-color";
		else if (status === "complete") return "contact-approve-color";
		else return "contact-in-review-color";
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{(isChangeCategoryData || adminReviewLoading) && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 100 }}
					open={isChangeCategoryData || adminReviewLoading}
				>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<div className="parent-cat">{/* <div>Contact Portal</div> */}</div>
					<div className="row d-flex justify-content-end">
						<div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-end">
							<input
								id="standard-basic"
								className="p-2 w-100 admin-search-category"
								placeholder="Search email"
								value={emailSearch}
								onChange={(e) => {
									setPage(0);
									setEmailSearch(e.target.value);
								}}
							/>
						</div>
					</div>
					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{contactResponseData?.data?.records?.length > 0 ? (
										contactResponseData?.data?.records?.map((cat) => {
											const date = moment?.utc(cat?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");
											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={cat?.id}
												>
													<TableCell>{cat?.email}</TableCell>
													<TableCell
														onClick={() =>
															setOpenDialog({
																isOpen: true,
																msg: cat?.message,
															})
														}
													>
														{cat?.message?.length > 0
															? cat?.message?.split(" ")[0] + "..."
															: ""}
													</TableCell>
													<TableCell>{formattedDate}</TableCell>
													<TableCell
														onClick={() => {
															setOpen(true);
															setResponseEmail(cat.email);
														}}
														className="contact-res-cursor"
													>
														Respond
													</TableCell>
													<TableCell textAlign="center">
														{cat?.isEmail ? "Sent" : "Not Send"}
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														<FormControl sx={{ minWidth: 120 }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={cat?.status}
																onChange={(e) => {
																	handleStatusChange(e, cat.id);
																}}
																className={`selectStatuses ${getProperClass(
																	cat?.status
																)}`}
																placeholder="Select status"
															>
																<MenuItem value="pending">Pending</MenuItem>
																<MenuItem value="inreview">In Review</MenuItem>
																<MenuItem value="complete">Completed</MenuItem>
															</Select>
														</FormControl>
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}> No Contact exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{contacts?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
									count={contacts?.totalRecords}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
					</Paper>
				</Card>
			</div>
			<MessagePopup showPopup={openDialog} setShowPopup={setOpenDialog} />
			<EmailResponsePopup
				openModal={open}
				onClose={handleClose}
				responseEmail={responseEmail}
			/>
		</div>
	);
}
