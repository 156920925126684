import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import expireIn from "redux-persist-transform-expire-in";
import authorization from "./ducks/autorizeDuck";
import seller from "./ducks/getSellersDuck";
import category from "./ducks/categoriesDuck";
import contact from "./ducks/contactDuck";
import adminUser from "./ducks/userDuck";
import adminReview from "./ducks/adminReviewDuck";
import adminComponent from "./ducks/adminPannel";
import instgramStories from "./ducks/instagramDuck";
import seededData from "./ducks/seededDuck";

// https://github.com/sirLisko/redux-persist-transform-expire-in
const expirationDelay = 1 * 60 * 60 * 1000; // expire in 60 minutes
const expirationKey = "expirationKey";
const persistConfig = {
	key: `rootstore`,
	storage,
	transforms: [expireIn(expirationDelay, expirationKey, [])],
	blacklist: ["profile", "restaurants"],
};

const reducers = {
	authorization,
	contact,
	seller,
	category,
	adminUser,
	adminReview,
	adminComponent,
	instgramStories,
	seededData,
};

const rootReducer = persistCombineReducers(persistConfig, reducers);

const appReducer = (state, action) => {
	if (action.type === "authorization/logoutResponse") {
		return rootReducer({}, action);
	}
	return rootReducer(state, action);
};

export default appReducer;
