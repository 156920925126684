import React, { useEffect, useState } from "react";
import Review from "../../components/review/review";
import Layout from "../../components/layout/parentLayout/Layout";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import ThanksModal from "../../components/modals/thanksModal";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import {
	getBalloon,
	getSellersByID,
	submitReview,
	updateLoveSellerName,
	updateReviewSubmitStatus,
	updateSellerById,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import "./loveSeller.css";
import { useNavigate } from "react-router";
import LoginModal from "../../components/modals/loginModal";
import SignUpModal from "../../components/modals/signUpModal";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";
import { CategoryAutoComplete } from "../../components/layout/categoryAutoComplete/categoryAutoComplete";

function LoveSeller() {
	const navigate = useNavigate();

	const [sellers, setSellers] = useState([]);
	const [thanks, setThanks] = useState(false);
	const [isLoveSellerSubmitted, setIsLoveSellerSubmitted] = useState(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [isAlreadySubmittedReview, setIsAlreadySubmitReview] = useState(false);

	const [isSellerSelected, setIsSellerSelected] = useState(false);

	const dispatch = useDispatch();
	const { data, reviewSubmitStatus, loginResponseData, sellerCategories } =
		useSelector((state) => ({
			data: state.seller?.seller?.data,
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
			loginResponseData: state.authorization?.loginResponseData,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		}));

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(""));
		dispatch(verifyReviewResponse(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [goodReviews, setGoodReviews] = useState({
		sellerId: "",
		categoryId: null,
		titleId: "",
		message: null,
		approvedByAdmin: true,
		bestWriter: false,
	});

	//get Api for sellers
	useEffect(() => {
		setSellers(data?.records);
		dispatch(getBalloon());
		dispatch(updateSellerById());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsSellerSelected(false);
	}, [location]);

	let token = localStorage.getItem("token");

	const handleThanks = () => {
		if (!goodReviews.sellerId && !goodReviews.titleId) {
			getToasterNotifications("Please select a seller and balloon!");
			setThanks(false);
			return;
		}
		// sellerCategories && sellerCategories?.length > 1
		if (!goodReviews.sellerId && goodReviews.titleId) {
			getToasterNotifications("Please select a seller!");
			setThanks(false);
			return;
		}
		if (
			goodReviews.sellerId &&
			goodReviews.titleId &&
			!goodReviews.categoryId &&
			sellerCategories &&
			sellerCategories?.length > 1
		) {
			getToasterNotifications("Please select a Category!");
			setThanks(false);
			return;
		} else if (goodReviews.sellerId && !goodReviews.titleId) {
			getToasterNotifications("Please select a balloon!");
			setThanks(false);
			return;
		}
		if (!token) {
			setLoginModal(true);
			return;
		}
		if (goodReviews.sellerId && goodReviews.titleId) {
			// dispatch(submitReview(goodReviews));
			if (loginResponseData?.data?.id) {
				dispatch(
					verifyReviewRequest({
						sellerId: goodReviews.sellerId,
						userId: loginResponseData?.data?.id,
					})
				);
			}
			setIsLoveSellerSubmitted(true);
		}
	};

	useEffect(() => {
		if (
			goodReviews.sellerId &&
			goodReviews.titleId &&
			reviewSubmitStatus === 200 &&
			token
		) {
			dispatch(submitReview(goodReviews));
			dispatch(updateReviewSubmitStatus(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, goodReviews, reviewSubmitStatus]);

	useEffect(() => {
		if (
			goodReviews.sellerId &&
			goodReviews.titleId &&
			reviewSubmitStatus === 201 &&
			token
		) {
			setThanks(true);
			setIsLoveSellerSubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus]);

	useEffect(() => {
		if (reviewSubmitStatus === 409 && isLoveSellerSubmitted) {
			setIsLoveSellerSubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
			setIsAlreadySubmitReview(true);
		}

		if (reviewSubmitStatus === 401 && token && loginResponseData?.data?.id) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			dispatch(updateReviewSubmitStatus(null));
			localStorage.setItem("adminToken", "");
		}
		if (reviewSubmitStatus === 400) {
			getToasterNotifications(
				"You've already clicked this seller!  You can edit your story from the Seller Balloon Page!"
			);
			setIsLoveSellerSubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 403) {
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
			setIsLoveSellerSubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus, dispatch, goodReviews, isLoveSellerSubmitted]);

	useEffect(() => {
		if (isAlreadySubmittedReview) {
			getToasterNotifications(
				"You've already clicked this Seller! Please visit the seller balloon page to update it."
			);
		}
	}, [isAlreadySubmittedReview]);

	return (
		<Layout>
			{isLoveSellerSubmitted && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 2 }}
					open={isLoveSellerSubmitted}
					onClick={() => setIsLoveSellerSubmitted(false)}
				>
					<div className="d-flex  justify-content-center loaderCenter align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}

			<div className="container mt-5 loveSeller-wrapper-container">
				<div className="row text-center d-flex justify-content-center mt-5 mb-3">
					<div className="col-lg-4 col-md-12 col-sm-12 mb-3">
						<Autocomplete
							id="free-solo-2-demo"
							className="inputRounded"
							popupIcon={""}
							options={sellers && sellers?.length > 0 ? sellers : []}
							onChange={(e, seller) => {
								if (seller) {
									setIsSellerSelected(true);
									setGoodReviews({
										...goodReviews,
										sellerId: seller?.id,
										categoryId: null,
									});
									dispatch(updateLoveSellerName(seller.sellerName));
									setIsAlreadySubmitReview(false);
									dispatch(getSellersByID(seller.id));
								} else {
									setGoodReviews({
										...goodReviews,
										sellerId: null,
										categoryId: null,
									});
								}
							}}
							getOptionLabel={(option) => option.sellerName}
							renderInput={(params) => (
								<div className="autocomplete-search-inputfield">
									<TextField
										className="inputRounded"
										sx={{
											"#free-solo-2-demo-label": {
												paddingLeft: "7px",
											},
										}}
										{...params}
										label="Search by Sellers"
										InputProps={{
											...params.InputProps,
										}}
									/>
								</div>
							)}
						/>
					</div>
				</div>
				<div className="row text-center d-flex justify-content-center mb-3">
					<div className="col-lg-4 col-md-12 col-sm-12 mb-3">
						{sellerCategories &&
							sellerCategories?.length > 1 &&
							isSellerSelected && (
								<CategoryAutoComplete
									reviews={goodReviews}
									setReviews={setGoodReviews}
									sellerCategories={sellerCategories}
									notFromSellerBallonPage
								/>
							)}
					</div>
				</div>
				<h6 className="text-center fw-bold mb-sm-0 mb-5">
					Please click the balloon that best describes your experience with this
					seller.
				</h6>
				<Review goodReviews={goodReviews} setGoodReviews={setGoodReviews} />
				<div className="d-flex justify-content-center button-pad">
					<button
						type="button"
						onClick={handleThanks}
						disabled={isAlreadySubmittedReview || isLoveSellerSubmitted}
						className="btn mt-5 rounded-pill button-next"
					>
						Submit
					</button>
				</div>
			</div>
			{/* Display thanks modal */}
			<ThanksModal
				reviews={goodReviews}
				isThanks={thanks}
				setIsThanks={setThanks}
			/>
			<LoginModal
				isShow={showLoginModal}
				setIsShow={setLoginModal}
				setSignUp={setSignUpModal}
				reviews={goodReviews}
				setIsLoveSellerSubmitted={setIsLoveSellerSubmitted}
				setThanks={setThanks}
				notFromSellerBalloonPage
				setIsAlreadySubmitReview={setIsAlreadySubmitReview}
			/>
			<SignUpModal
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setLoginModal}
				reviews={goodReviews}
			/>
		</Layout>
	);
}

export default LoveSeller;
