export const sellerTypeArray = [
	"U.S. National, Regional Online. (Default)",
	"For U.S. Locals Only!",
	"Outside the U.S.",
	"B2B",
];

export const b2bCategories = [
	"Financial services",
	"Technology",
	"Manufacturing",
	"Construction",
	"Retail",
	"Telecommunications",
	"Insurance",
	"Healthcare",
];
