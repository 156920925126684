import React from "react";
import "./childFooter.css";

function ChildFooter() {
	return (
		<div className="container-fluid p-2 bottom-0 position-fixed d-flex justify-content-center footer">
			<div className="row d-flex justify-content-center mt-2">
				<div className="col-lg d-flex justify-content-center">
					<span className="text-center text-decoration-none child-footer-text">
						Copyright 2024 Love to Air <span className="spanr"> &#174;</span>
					</span>
				</div>
			</div>
		</div>
	);
}

export default ChildFooter;
