import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export const WorldModal = ({
	handleClose,
	openWorldModal,
	setShowAddASellerModal,
}) => {
	return (
		<Modal
			show={openWorldModal}
			onHide={handleClose}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body className="modal-parent">
				<div className="px-4 py-4 mb-3 mt-2">
					<div className="fw-bold text-center">The Power of Writers</div>
					<div className="mb-3">
						Did you know that only 5-10% of the population write the reviews
						that over 99% of us read?
					</div>
					<div className="mb-3">
						While writers make up only a small minority of the population, they
						regularly impact the vast majority - often leading the masses.
					</div>
					<div className="mb-3">
						To this end, we don't limit writers to writing only about the
						sellers they've had direct experience with.* In other words, we
						support writers who want to express their opinions about any
						brand/company/corporation whether they've had direct experience with
						them or not.
					</div>
					<div className="mb-3">
						For example, an L2a writer might have important thoughts or strong
						views regarding a particular company's stance on matters such as
						environmental sustainability, ethical labor practices, diversity,
						fair wages, philanthropy and much more.
					</div>
					<div className="mb-3">
						L2a's aim is to create a true free-speech platform ** so that it's
						utilized not only as a means for readers to suss out the sellers
						they want to do business with (aka sellers they want to give their
						hard-earned money to), but also so they can learn about how various
						sellers are impacting the world, the economy, society and humanity
						at large.
					</div>
					<div className="mb-4">
						If you resonate with one, or more (!), of the writer archetypes,
						know that we value your passionate, thoughtful and conscientious
						mind! We understand your need to express your views, thoughts and
						feelings and are honored to provide you with the online space for -
						what you may consider - your personal op-eds, open letters, praising
						commentaries or critical essays with regards to brands and
						sellers.***
					</div>
					<div className="mb-1">
						* If your seller is not already in our database, you can add them by
						clicking
						<Link
							className="ms-2"
							onClick={() => {
								setShowAddASellerModal(true);
								handleClose();
							}}
						>
							Add a Seller.
						</Link>
					</div>
					<div className="mb-1">
						** L2a aims to offer writers as much freedom to express themselves
						as is feasible! We do, however, kindly ask that your writings fit
						within our{" "}
						<Link className="ms-2 me-2" to="/guideline">
							Guidelines!
						</Link>{" "}
						🙏
					</div>
					<div className="mb-1">
						*** Your actual identity as a user is kept hidden; only your
						username is shown to readers.
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};
