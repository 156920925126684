import Layout from "../../components/layout/parentLayout/Layout";
import * as React from "react";
import { useDispatch } from "react-redux";
import "./Outlier.css";
import { allNonDefaultSellersRequest } from "../../redux/reducers/ducks/getSellersDuck";
import { AllOutlierSearchBAr } from "./allOutlierSellerSearch";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import {
	faPeopleGroup,
	faHandshakeSimple,
	faEarthOceania,
} from "@fortawesome/free-solid-svg-icons";
import AddAsellerModal from "../../components/modals/addAsellerModal";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";

export function Outlier() {
	const ref = React.useRef(null);
	const [searchFilter, setSearchFilters] = useState({
		type: null,
		sellerName: null,
	});
	library.add(faEarthOceania, faPeopleGroup, faHandshakeSimple);
	const [showAddASellerModal, setShowAddASellerModal] = useState(false);

	const [showModal, setShowModal] = useState(false);

	const dispatch = useDispatch();
	useEffect(() => {
		const payloadData = {
			...(searchFilter.type ? { type: searchFilter.type } : {}),
			...(searchFilter.sellerName
				? { sellerName: searchFilter.sellerName }
				: {}),
		};
		if (!searchFilter.sellerName) {
			dispatch(allNonDefaultSellersRequest(payloadData));
		}
		const timeout = setTimeout(() => {
			if (searchFilter.sellerName) {
				dispatch(allNonDefaultSellersRequest(payloadData));
			}
		}, 3000);
		return () => clearTimeout(timeout);
	}, [searchFilter]);

	// const handleClick = () => {
	// 	ref.current?.scrollIntoView({ behavior: "smooth" });
	// };

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	return (
		<Layout>
			<div className="container outlier-parent wrapper-seller-container">
				<div className="outlier-main-heading mb-5 d-md-block d-none fw-bold">
					Sellers Just Outside L2a Main!
				</div>
				<img
					src="/icons/logo1.png"
					className="mx-auto d-block my-auto image-width mt-md-0 d-md-none d-block"
					alt="logo"
				/>

				<span className="outlier-main-heading-mobile mb-md-0 mt-4 d-md-none d-block fw-bold">
					Sellers Just Outside L2a Main!
				</span>
				<div className="text-center d-md-none d-block">
					<div
						ref={ref}
						className="row mx-0 px-0 mx-auto d-flex justify-content-center input-group search-parent-div"
					>
						<AllOutlierSearchBAr
							searchFilter={searchFilter}
							setSearchFilters={setSearchFilters}
							showModal={showModal}
							setShowModal={setShowModal}
						/>
					</div>
				</div>
				<div className="add-new-seller-Parent mb-3 search-parent-div-btn d-md-none d-block">
					<button
						type="button"
						onClick={() => setShowAddASellerModal(true)}
						className="btn add-new-button-sellers"
					>
						Add a Seller
					</button>
				</div>

				<div className="row mx-auto d-flex justify-content-center">
					{/* <div className="d-flex justify-content-between mb-3"> */}
					<div
						className="col-lg-4 col-md-4 col-12"
						onClick={() => {
							setSearchFilters({
								...searchFilter,
								type: "For U.S. Locals Only!",
							});
							setShowModal(true);
						}}
					>
						{/* <p className="us-local">
							<FontAwesomeIcon icon={faPeopleGroup} />
						</p> */}
						<div className="fw-bold us-local mt-md-0 mt-2">
							U.S. For Locals Only!
						</div>
						<p className="mb-0">
							These sellers enjoy, and require, direct human-to-human
							interaction in order to be of benefit to the local residents (or
							visiting tourists) they serve. Examples include restaurants,
							hotels, day spas, specialty boutiques, kayak rentals, tennis
							classes... you get the idea!
						</p>
					</div>
					<div
						className="col-lg-4 col-md-4 col-12"
						onClick={() => {
							setSearchFilters({ ...searchFilter, type: "Outside the U.S." });
							setShowModal(true);
						}}
					>
						{/* <p className="us-local">
							<FontAwesomeIcon icon={faEarthOceania} />
						</p> */}
						<div className="fw-bold us-local mt-md-0 mt-3">
							Outside the U.S.
						</div>
						<p>
							These sellers are located outside the U.S. They serve the local
							residents and tourists in their respective cities and countries.
							Examples include London, England; Sydney Australia; and Lahore,
							Pakistan.
						</p>
					</div>
					<div
						className="col-lg-4 col-md-4 col-12"
						onClick={() => {
							setSearchFilters({ ...searchFilter, type: "B2B" });
							setShowModal(true);
						}}
					>
						{/* <p className="us-local">
							<FontAwesomeIcon icon={faHandshakeSimple} />
						</p> */}
						<div className="fw-bold us-local mt-md-0 mt-3">
							Business to Business
						</div>
						<p>
							B2B stands for Business-to-Business. These are sellers who sell to
							other businesses and can be U.S. National & Regional Online, For
							Locals Only or Outside the U.S. borders.
						</p>
					</div>
					{/* </div> */}
				</div>
				<img
					src="/icons/logo1.png"
					className="mx-auto d-block my-auto image-width  mt-5 mt-md-0 d-md-block d-none"
					alt="logo"
				/>
				<div className="text-center mt-4 d-md-block d-none">
					<div
						ref={ref}
						className="row mx-0 px-0  mx-auto d-flex justify-content-center input-group search-parent-div"
					>
						<AllOutlierSearchBAr
							searchFilter={searchFilter}
							setSearchFilters={setSearchFilters}
							showModal={showModal}
							setShowModal={setShowModal}
						/>
					</div>
				</div>
				<div className="add-new-seller-Parent search-parent-div-btn d-md-block d-none">
					<button
						type="button"
						onClick={() => setShowAddASellerModal(true)}
						className="btn add-new-button-sellers"
					>
						Add a Seller
					</button>
				</div>
			</div>

			<AddAsellerModal
				isShow={showAddASellerModal}
				setIsShow={setShowAddASellerModal}
				selectedClassification={searchFilter?.type}
				fromOutlier
			/>
		</Layout>
	);
}
