import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import { Backdrop, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import { loggerRequest } from "../../../redux/reducers/ducks/adminReviewDuck";
import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";

export function Logger() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [userSearch, setUserSearch] = useState("");

	const [isLoggerChange, setIsLoggerChange] = useState(false);
	const { loggerData } = useSelector((state) => {
		return {
			loggerData: state?.adminReview?.loggerData,
		};
	});

	const dispatch = useDispatch();
	const handleChangePage = (event, newPage) => {
		setIsLoggerChange(true);

		dispatch(handleAdminStatus(false));
		setPage(newPage);
	};

	useEffect(() => {
		dispatch(
			loggerRequest({
				page: 1,
				pageSize: 10,
				name: userSearch,
			})
		);
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			dispatch(
				loggerRequest({
					name: userSearch,
					page: page + 1,
					pageSize: rowsPerPage,
				})
			);
			setIsLoggerChange(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [userSearch, page, rowsPerPage]);

	const columns = [
		{ id: "name", label: `User Name`, minWidth: 130 },
		{ id: "code1", label: "User Email", minWidth: 100 },
		{ id: "code2", label: "Action", minWidth: 100 },
		{ id: "code3", label: "Date", minWidth: 100 },
	];

	const handleRowsPerPage = (e) => {
		setIsLoggerChange(true);
		setRowsPerPage(e.target.value);
	};

	const changeLoggerMethods = (method) => {
		if (method === "PATCH" || method === "PUT") {
			return "Updated";
		} else if (method === "POST") {
			return "Created";
		} else if (method === "DELETE") {
			return "Deleted";
		}
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{isLoggerChange && (
				<Backdrop sx={{ color: "#fff", zIndex: 100 }} open={isLoggerChange}>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<div className="parent-cat" />

					<div className="row d-flex justify-content-end">
						<div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-end">
							<input
								id="standard-basic"
								className="p-2 w-100"
								placeholder="Search User Name"
								value={userSearch}
								onChange={(e) => {
									setPage(0);
									setUserSearch(e.target.value);
								}}
							/>
						</div>
					</div>
					<Paper
						sx={{ width: "100%", overflow: "hidden", mt: 5 }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{loggerData?.records?.length > 0 ? (
										loggerData?.records?.map((logs) => {
											const date = moment?.utc(logs?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");
											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={logs.id}
												>
													<TableCell>{logs?.username}</TableCell>
													<TableCell>{logs?.useremail}</TableCell>
													<TableCell>
														{changeLoggerMethods(logs?.method)}
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														{formattedDate}
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No Log exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{loggerData?.records?.length > 0 && (
							<TablePagination
								component="div"
								onRowsPerPageChange={handleRowsPerPage}
								rowsPerPage={rowsPerPage}
								count={loggerData?.totalRecords ? loggerData?.totalRecords : 0}
								page={page}
								onPageChange={handleChangePage}
							/>
						)}
					</Paper>
				</Card>
			</div>
		</div>
	);
}
