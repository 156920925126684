import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	seller: null,
	balloon: null,
	addSeller: "",
	sellerId: "",
	singleSellerData: null,
	review: "",
	editUser: "",
	isReviewUpdate: false,
	isModalChanges: false,
	isReviewDataLoading: true,
	airSellerName: "",
	loveSellerName: "",
	sellerName: "",
	reviewSubmitStatus: null,
	isLogout: false,
	isLoginUser: false,
	isAddSellerLoading: true,
	isAdminChangeStatus: false,
	searchModalCategoryId: null,
	sellerByID: "",
	isSingleSellerDataResponse: null,
	isLikeDislikeStatus: null,
	createAdminSellerData: null,
	adminSellerResponse: null,
	sellerAdminDeleteResponse: null,
	editReview: null,
	likeDislikeReviewData: null,
	reviewType: {
		titleId: "",
		balloonType: "",
		airedOrLoved: "",
	},
	allNonDefaultSellers: null,
	isAllNonDefaultSellersLoading: false,
	googlePlaceResponse: null,
	noReviewSellersData: null,
	categoryNoReviewSeller: null,
	scrollableRecords: 10,
	sellerTypeWise: null,
	userReviewsData: null,
	alreadyWinnerData: null,
};

const mainSlice = createSlice({
	name: "main",
	initialState: INITIAL_STATE,
	reducers: {
		getSellers: (state) => state,
		alreadyWinnerDataRequest: (state) => state,
		googleAPIRequest: (state) => state,
		allNonDefaultSellersRequest: (state) => state,
		getBalloon: (state) => state,
		addSellerRequest: (state) => state,
		getSingleSellerID: (state) => state,
		getReview: (state) => state,
		editCommit: (state) => state,
		getSellersByID: (state) => state,
		sellerLatestReviewRequest: (state) => state,
		noReviewSellersRequest: (state) => state,
		userReviewsRequest: (state) => state,
		submitReview: (state) => {
			return state;
		},
		updateReview: (state) => state,
		submitLikeDislike: (state) => state,
		updateSellerAdmin: (state) => state,
		updateSellerData: (state) => state,
		createAdminSellerRequest: (state) => state,
		deleteAdminSellerRequest: (state) => state,
		getAllAdminSellers: (state) => state,
		submitMultipleReviwRequest: (state) => state,
		verifyReviewRequest: (state) => state,
		activeLoginUser: (state) => state,
		categoryNoReviewSellerRequest: (state) => state,
		sellerTypeWiseRequest: (state) => state,
		categoryNoReviewSellerResponse(state, { payload }) {
			return {
				...state,
				categoryNoReviewSeller: payload,
			};
		},
		setScrollableRecords(state, { payload }) {
			return {
				...state,
				scrollableRecords: payload,
			};
		},
		alreadyWinnerDataResponse(state, { payload }) {
			return {
				...state,
				alreadyWinnerData: payload,
			};
		},
		noReviewSellersResponse(state, { payload }) {
			return {
				...state,
				noReviewSellersData: payload,
			};
		},
		allNonDefaultSellersLoading(state, { payload }) {
			return {
				...state,
				isAllNonDefaultSellersLoading: payload,
			};
		},
		googleApiResponse(state, { payload }) {
			return {
				...state,
				googlePlaceResponse: payload,
			};
		},
		allNonDefaultSellersResponse(state, { payload }) {
			return {
				...state,
				allNonDefaultSellers: payload,
			};
		},
		reviewUpdateResponse(state, { payload }) {
			return {
				...state,
				isReviewUpdate: payload,
			};
		},
		updateReviewType(state, { payload }) {
			return {
				...state,
				reviewType: payload,
			};
		},
		getSearchModalCategoryId(state, { payload }) {
			return {
				...state,
				searchModalCategoryId: payload,
			};
		},
		updateIsSingleSellerData(state, { payload }) {
			return {
				...state,
				isSingleSellerDataResponse: payload,
			};
		},
		updateisLoginUser: (state, { payload }) => {
			return {
				...state,
				isLoginUser: payload,
			};
		},
		updateReviewSubmitStatus(state, { payload }) {
			return {
				...state,
				reviewSubmitStatus: payload,
			};
		},
		updateSeller(state, { payload }) {
			return {
				...state,
				seller: payload,
			};
		},
		updateBalloon(state, { payload }) {
			return {
				...state,
				balloon: payload,
			};
		},
		updateNewSeller(state, { payload }) {
			return {
				...state,
				addSeller: payload,
			};
		},
		getSellerId(state, { payload }) {
			return {
				...state,
				sellerId: payload,
			};
		},
		getSingleSellerData(state, { payload }) {
			return {
				...state,
				singleSellerData: payload,
			};
		},
		updateReviews(state, { payload }) {
			return {
				...state,
				review: payload,
			};
		},
		updateCommit(state, { payload }) {
			return {
				...state,
				review: payload,
			};
		},
		isModalChanges(state, { payload }) {
			return {
				...state,
				isModalChanges: payload,
			};
		},
		updateIsLoadingReviewData(state, { payload }) {
			return {
				...state,
				isReviewDataLoading: payload,
			};
		},
		updateAirSellerName(state, { payload }) {
			return {
				...state,
				airSellerName: payload,
			};
		},
		updateLoveSellerName(state, { payload }) {
			return {
				...state,
				loveSellerName: payload,
			};
		},
		getSellerName(state, { payload }) {
			return {
				...state,
				sellerName: payload,
			};
		},
		updateIsLogout(state, { payload }) {
			return {
				...state,
				isLogout: payload,
			};
		},
		addSellerLoading(state, { payload }) {
			return {
				...state,
				isAddSellerLoading: payload,
			};
		},
		handleAdminStatus(state, { payload }) {
			return {
				...state,
				isAdminChangeStatus: payload,
			};
		},
		updateSellerById(state, { payload }) {
			return {
				...state,
				sellerByID: payload,
			};
		},
		handleNominateStatus(state, { payload }) {
			return {
				...state,
				isLikeDislikeStatus: payload,
			};
		},
		createAdminSellerResponse(state, { payload }) {
			return {
				...state,
				createAdminSellerData: payload,
			};
		},
		updateAdminSellerResponse(state, { payload }) {
			return {
				...state,
				adminSellerResponse: payload,
			};
		},
		deleteAdminSellerResponse(state, { payload }) {
			return {
				...state,
				sellerAdminDeleteResponse: payload,
			};
		},
		editReviewResponse(state, { payload }) {
			return {
				...state,
				editReview: payload,
			};
		},
		userReviewResponse(state, { payload }) {
			return {
				...state,
				userReviewsData: payload,
			};
		},
		likeDislikeReviewResponse(state, { payload }) {
			return {
				...state,
				likeDislikeReviewData: payload,
			};
		},
		sellerTypeWiseResponse(state, { payload }) {
			return {
				...state,
				sellerTypeWise: payload,
			};
		},
	},
});

export const {
	allNonDefaultSellersLoading,
	allNonDefaultSellersRequest,
	allNonDefaultSellersResponse,
	addSellerLoading,
	updateIsLogout,
	getSellerName,
	reviewSubmitStatus,
	updateAirSellerName,
	updateLoveSellerName,
	getSellers,
	updateSeller,
	getBalloon,
	updateBalloon,
	addSellerRequest,
	updateNewSeller,
	getSellerId,
	getSingleSellerID,
	getSingleSellerData,
	getReview,
	updateReviews,
	editCommit,
	submitReview,
	submitLikeDislike,
	reviewUpdateResponse,
	updateCommit,
	updateReview,
	updateReviewSubmitStatus,
	isModalChanges,
	updateIsLoadingReviewData,
	updateisLoginUser,
	updateSellerAdmin,
	handleAdminStatus,
	updateSellerData,
	createAdminSellerRequest,
	deleteAdminSellerRequest,
	getAllAdminSellers,
	getSearchModalCategoryId,
	getSellersByID,
	updateSellerById,
	activeLoginUser,
	updateIsSingleSellerData,
	handleNominateStatus,
	createAdminSellerResponse,
	updateAdminSellerResponse,
	deleteAdminSellerResponse,
	editReviewResponse,
	likeDislikeReviewResponse,
	updateReviewType,
	submitMultipleReviwRequest,
	verifyReviewRequest,
	googleAPIRequest,
	googleApiResponse,
	noReviewSellersResponse,
	noReviewSellersRequest,
	categoryNoReviewSellerResponse,
	categoryNoReviewSellerRequest,
	setScrollableRecords,
	sellerLatestReviewRequest,
	sellerTypeWiseRequest,
	sellerTypeWiseResponse,
	userReviewResponse,
	userReviewsRequest,
	alreadyWinnerDataResponse,
	alreadyWinnerDataRequest,
} = mainSlice.actions;

export default mainSlice.reducer;
