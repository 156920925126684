import React from "react";

function EmailError() {
	return (
		<div className="container">
			<div className="row">
				<h1 className="text-center mt-5 text-danger">OOPS!!!!</h1>
				<h4 className="mt-2 text-center">Your email is not verified </h4>
			</div>
		</div>
	);
}

export default EmailError;
