import { Card, Paper, TableContainer } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./progressMainPage.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
	monthlyAverageRequest,
	weeklyAverageRequest,
	userProgressRequest,
	totalSignUpCountRequest,
} from "../../../redux/reducers/ducks/adminReviewDuck";
import moment from "moment";
import WeeklyCount from "../weeklyCount/weeklyCount";
import MonthlyCount from "../monthlyCount/monthlyCount";
import { getSellers } from "../../../redux/reducers/ducks/getSellersDuck";

const ProgressMainPage = () => {
	const [startDate, setStartDate] = useState(new Date());
	const dispatch = useDispatch();

	const {
		userProgress,
		weeklyAverageData,
		monthlyAverageData,
		totalSignUpCount,
	} = useSelector((state) => {
		return {
			userProgress: state?.adminReview?.userProgress,
			weeklyAverageData: state?.adminReview?.weeklyAverageData,
			monthlyAverageData: state?.adminReview?.monthlyAverageData,
			totalSignUpCount: state?.adminReview?.totalSignUpCount,
		};
	});

	useEffect(() => {
		dispatch(getSellers());
	}, []);

	const outputUser = {};

	for (const date in userProgress) {
		// eslint-disable-next-line
		if (userProgress.hasOwnProperty(date)) {
			outputUser[date] = userProgress[date] && userProgress[date]?.count;
		}
	}

	const userData = Object.entries(outputUser);

	useEffect(() => {
		const currentDate = new Date();

		currentDate.setDate(1);

		currentDate.setMonth(currentDate.getMonth() + 1);
		currentDate.setDate(currentDate.getDate() - 1);
	}, []);

	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

	useEffect(() => {
		dispatch(weeklyAverageRequest());
		dispatch(monthlyAverageRequest());
		dispatch(totalSignUpCountRequest({ timeZone: timeZone }));
	}, []);

	const chartData = {
		chart: {
			height: 350,
			type: "area",
			toolbar: {
				show: true,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: true,
					selection: true,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: true,
					customIcons: [],
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			type: "datetime",
		},
		tooltip: {
			x: {
				format: "MM/dd/yyyy HH:mm",
			},
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 90, 100],
			},
		},
		colors: ["#21144c", "#F87161", "#009E7E"],
		legend: {
			position: "top",
		},
		series: [
			{
				name: "Total Daily Signups",
				data: userData,
			},
		],
	};

	useEffect(() => {
		const date = moment(startDate);
		const lastDayMonth = date.endOf("month");
		const startOfMonthDay = moment(startDate).startOf("month");
		dispatch(
			userProgressRequest({
				startDate: moment(startOfMonthDay).format("YYYY-MM-DD"),
				endDate: moment(lastDayMonth).format("YYYY-MM-DD"),
			})
		);
	}, [startDate]);

	function getFormattedWeek(date, weekNumber) {
		const formattedMonthDate = moment(date).format("MMM: DD");
		return `Week ${weekNumber},Sunday,${formattedMonthDate}`;
	}

	let weeklyData = weeklyAverageData?.weeklyResult;

	let monthlyData = monthlyAverageData?.monthlyResult;

	return (
		<>
			<div className="cardParent">
				<div>
					<div className="mb-5">
						<h2 className="text-center">
							Total Number of SignUps:
							<span className="count-color ms-3">
								{totalSignUpCount?.totalCount}
							</span>{" "}
						</h2>
						<div className="row text-center">
							<div className="col-md-3 col-0" />
							<div className="col-md-4 col-12">
								<h4 className="mt-5 text-start">Total signups per week:</h4>
								<ul className="mb-1">
									{weeklyData &&
										Object.keys(weeklyData)?.map((items, index) => {
											const formatedDate = getFormattedWeek(
												items,
												Object.values(weeklyData)[index]?.weekNumber
											);

											return (
												items !== "2023-10-22" &&
												items !== "2023-10-29" &&
												items !== "2023-11-05" && (
													<li className="d-flex mt-2" key={items}>
														<div className="count-color">{formatedDate}: </div>
														<div className="count-color ms-3">
															{weeklyData &&
															Object.values(weeklyData)[index]?.count
																? Object.values(weeklyData)[index]?.count
																: 0}
														</div>
													</li>
												)
											);
										})}
								</ul>
								<h4 className="text-start mt-4">
									Average Weekly SignUps:
									<span className="count-color ms-3">
										{parseInt(totalSignUpCount?.resultWeeklyAverage) < 10
											? "0" +
											  parseInt(
													totalSignUpCount?.resultWeeklyAverage
											  )?.toFixed(0)
											: parseInt(
													totalSignUpCount?.resultWeeklyAverage
											  )?.toFixed(0)}
									</span>{" "}
								</h4>
							</div>
							<div className="col-md-4 col-12 d-flex justify-content-md-start justify-content-center">
								<div>
									<h4 className="mt-5 text-start">Total signups per month:</h4>
									<ul className="mb-1">
										{monthlyData &&
											Object.keys(monthlyData)?.map((items, index) => {
												const formatedDate = moment(items).format("MMMM");

												return (
													items !== "2023-10-01" &&
													items !== "2023-09-01" && (
														<li className="d-flex mt-2 ms-3" key={items}>
															<div className="count-color">
																{formatedDate}:{" "}
															</div>
															<div className="count-color ms-3">
																{monthlyData &&
																Object.values(monthlyData)[index]?.count
																	? Object.values(monthlyData)[index]?.count
																	: 0}
															</div>
														</li>
													)
												);
											})}
									</ul>
									<h4 className="d-flex text-start mt-4">
										Average Monthly SignUps:
										<span className="count-color ms-3">
											{parseInt(totalSignUpCount?.resultMonthAverage) < 10
												? "0" +
												  parseInt(
														totalSignUpCount?.resultMonthAverage
												  )?.toFixed(0)
												: parseInt(
														totalSignUpCount?.resultMonthAverage
												  )?.toFixed(0)}
										</span>{" "}
									</h4>
								</div>
							</div>
							<div className="col-md-1 col-0" />
						</div>
					</div>
					<Card className="card-content">
						<div className="mb-3  signUp-names-bold text-center">
							Total Daily SignUps
						</div>
						<div className="parent-cat" />
						<Paper sx={{ width: "100%", overflow: "hidden" }}>
							<TableContainer>
								<div className="">
									<div className="mt-3 datePoicker-parent">
										<div className="weekly mb-3" />
										<div className="datePicker-child">
											<div>
												<DatePicker
													className="datePicker-input"
													dateFormat="MMM-yyyy"
													maxDate={new Date()}
													selected={startDate}
													showMonthYearPicker
													dropdownMode="select"
													onChange={(date) => setStartDate(date)}
												/>
											</div>
										</div>
									</div>
									<div className="p-3">
										<ReactApexChart
											options={chartData}
											series={chartData.series}
											type="area"
											height={350}
										/>
									</div>
								</div>
							</TableContainer>
						</Paper>
					</Card>
				</div>
				<WeeklyCount />
				<WeeklyCount isGrowth />
				<MonthlyCount />
				<MonthlyCount isGrowth />
			</div>
		</>
	);
};

export default ProgressMainPage;
