import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Review from "../review/review";
import SearchIcon from "@mui/icons-material/Search";
import "./modals.css";
import {
	categoryNoReviewSellerRequest,
	getSellersByID,
	noReviewSellersRequest,
	submitMultipleReviwRequest,
	submitReview,
	updateLoveSellerName,
	updateReviewSubmitStatus,
} from "../../redux/reducers/ducks/getSellersDuck";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "./loginModal";
import Backdrop from "@mui/material/Backdrop";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { useNavigate } from "react-router";
import { CategoryAutoComplete } from "../layout/categoryAutoComplete/categoryAutoComplete";

function LoveModal({
	isAir,
	setIsAir,
	setThanksValue,
	badReviews,
	setBadReviews,
}) {
	const [showLoginModal, setLoginModal] = useState(false);
	const [isLoveSubmitted, setIsLoveSubmitted] = useState(false);
	const [allSellerInput, setAllSellerInput] = useState(null);
	const [categorySellerInput, setCategorySellerInput] = useState(null);

	const [isSellerSelected, setIsSellerSelected] = useState(false);

	const [catWiseSellers, setCatWiseSellers] = useState([]);
	//get Api for sellers
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(""));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const navigate = useNavigate();

	const {
		reviewSubmitStatus,
		loginResponseData,
		noReviewSellersData,
		sellerByID,
		categoryNoReviewSeller,
		sellerCategories,
	} = useSelector((state) => {
		return {
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
			loginResponseData: state.authorization?.loginResponseData,
			noReviewSellersData: state?.seller?.noReviewSellersData,
			sellerByID: state?.seller?.sellerByID,
			categoryNoReviewSeller: state?.seller?.categoryNoReviewSeller,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		};
	});

	const [goodReviews, setGoodReviews] = useState({
		sellerId: "",
		titleId: "",
		categoryId: null,
		message: null,
		approvedByAdmin: true,
		bestWriter: false,
	});

	useEffect(() => {
		if (isAir) {
			dispatch(
				categoryNoReviewSellerRequest({
					userId: loginResponseData?.data?.id,
					sellerId: badReviews?.sellerId,
				})
			);
		}
	}, [loginResponseData, sellerByID, isAir]);
	let token = localStorage.getItem("token");

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(null));
		setThanksValue(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsSellerSelected(false);
	}, [location]);

	const handleThanks = () => {
		let token = localStorage.getItem("token");

		if (!token) {
			setLoginModal(true);
		} else {
			if (!goodReviews.sellerId && !goodReviews.titleId) {
				getToasterNotifications("Please select a seller and balloon!");
				setThanksValue(false);
			}
			if (!goodReviews.sellerId && goodReviews.titleId) {
				getToasterNotifications("Please select a seller!");
				setThanksValue(false);
			} else if (goodReviews.sellerId && !goodReviews.titleId) {
				getToasterNotifications("Please select a balloon!");
				setThanksValue(false);
			}
			if (
				goodReviews.sellerId &&
				goodReviews.titleId &&
				!goodReviews.categoryId &&
				sellerCategories &&
				sellerCategories?.length > 1
			) {
				getToasterNotifications("Please select category!");
				setThanksValue(false);

				return;
			}
			if (
				goodReviews.sellerId &&
				goodReviews.titleId &&
				badReviews?.sellerId &&
				badReviews?.titleId
			) {
				const requestData = { Negative: badReviews, Positive: goodReviews };
				dispatch(submitMultipleReviwRequest(requestData));
				setIsLoveSubmitted(true);
			}
		}
	};

	const handleCancel = () => {
		setGoodReviews({
			sellerId: "",
			titleId: "",
			categoryId: null,
			message: null,
			approvedByAdmin: true,
			bestWriter: false,
		});
		if (!goodReviews.sellerId || !goodReviews.titleId) {
			getToasterNotifications(
				"Please spread some love so that we can post the grievance you just aired!"
			);
			setIsAir(false);
			setThanksValue(false);
		} else {
			setIsAir(false);
		}
	};

	useEffect(() => {
		if (
			goodReviews.sellerId &&
			goodReviews.titleId &&
			badReviews?.sellerId &&
			badReviews?.titleId &&
			reviewSubmitStatus === 201 &&
			token
		) {
			setThanksValue(true);
			setIsLoveSubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, goodReviews, reviewSubmitStatus, goodReviews]);

	useEffect(() => {
		if (reviewSubmitStatus === 409 && isLoveSubmitted) {
			setIsAir(true);
			dispatch(submitReview(goodReviews));
			if (badReviews) {
				setBadReviews(null);
			}
			getToasterErrors(
				"You've already written a story for this seller!  Please click the Tell button to update it!"
			);
			setIsLoveSubmitted(false);
			dispatch(updateReviewSubmitStatus(null));
		} else if (reviewSubmitStatus === 400) {
			getToasterNotifications(
				"You've already clicked this seller!  You can edit your story from the Seller Balloon Page!"
			);
			dispatch(updateReviewSubmitStatus(null));
		}
		if (reviewSubmitStatus === 401 && token && loginResponseData?.data?.id) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			dispatch(updateReviewSubmitStatus(null));
			localStorage.setItem("adminToken", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewSubmitStatus]);

	useEffect(() => {
		if (loginResponseData?.data?.id) {
			dispatch(noReviewSellersRequest(loginResponseData?.data?.id));
		}
	}, [loginResponseData, badReviews]);

	// eslint-disable-next-line no-undef
	let isTestServer = JSON.parse(process.env.REACT_APP_IS_TEST_SERVER);

	const pathArray = isTestServer
		? window.location.pathname?.split("/")[2]
		: window.location.pathname?.split("/")[1];

	let newCategoryData = categoryNoReviewSeller &&
		categoryNoReviewSeller?.length > 0 && [...categoryNoReviewSeller];

	function flattenArray(arr) {
		return arr?.reduce((acc, current) => {
			// Use the concat method to merge the accumulator with the current element.
			return acc.concat(
				Array.isArray(current) ? flattenArray(current) : current
			);
		}, []);
	}

	useEffect(() => {
		let final =
			newCategoryData &&
			newCategoryData?.map((item) => {
				let newItem = { ...item };
				newItem = item?.sellers?.map((seller) => {
					let newSeller = { ...seller };
					newSeller.categoryName = newItem?.categoryName;
					return newSeller;
				});
				return newItem;
			});
		if (final && final.length > 0) {
			final = flattenArray(final);
		}
		setCatWiseSellers(final);
	}, [categoryNoReviewSeller]);

	return (
		<Modal
			show={isAir}
			onHide={handleCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			{isLoveSubmitted && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 2 }}
					open={isLoveSubmitted}
					onClick={() => setIsLoveSubmitted(false)}
				>
					<div className="d-flex  justify-content-center loaderCenter align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<Modal.Header closeButton className="border-0" />
			<div>
				<h3 className="text-center fw-bold">Thanks for your Click!</h3>
				<p className="text-center">Let's end this on a happier note!</p>
				<p className="text-center">
					<small>
						In order to add your balloon to the seller's balloon tally,
						<br /> we ask that you offset your negative experience <br />
						with a happier one!
					</small>
				</p>
				<div className="bg-seller">
					<p
						className="text-center text-light pt-3 px-5 fw-bold mb-sm-0 mb-5"
						style={{ fontSize: "1.4rem" }}
					>
						Please select a Seller you've had a positive experience with and
						click a balloon below.
					</p>

					<div className="row text-center d-flex justify-content-center mt-4">
						<div
							className={`col-lg-6 col-md-12 col-sm-12  love-modal-input-mobile ${
								allSellerInput?.id?.length > 0 && "d-none"
							}`}
						>
							<p className="text-center text-light px-5 mb-0 mb-sm-0 mb-5">
								<small>
									Giving love to a seller in the same category fosters healthy
									competition and makes it easier for your fellow users to find
									the best sellers for them!
								</small>
							</p>
							<Autocomplete
								id="free-solo-2-demo"
								className="inputRounded"
								popupIcon={""}
								value={categorySellerInput}
								options={
									catWiseSellers && catWiseSellers?.length > 0
										? catWiseSellers
										: []
								}
								groupBy={(option) => option?.categoryName}
								disabled={allSellerInput?.id?.length > 0}
								onChange={(e, seller) => {
									setCategorySellerInput(seller);
									setIsSellerSelected(true);

									setAllSellerInput(null);
									setGoodReviews({
										...goodReviews,
										sellerId: seller?.id,
										categoryId: null,
									});
									dispatch(updateReviewSubmitStatus(""));
									if (seller) {
										dispatch(getSellersByID(seller?.id));
									}
									if (!seller) {
										setGoodReviews({
											...goodReviews,
											categoryId: null,
											sellerId: null,
										});
									}
									if (pathArray !== "seller-balloon") {
										dispatch(updateLoveSellerName(seller?.sellerName));
									}
								}}
								getOptionLabel={(option) => option?.sellerName}
								renderInput={(params) => (
									<div className="autocomplete-search-inputfield">
										<SearchIcon className="autocomplete-searchicon" />
										<TextField
											className="inputRounded seller-textfield"
											{...params}
											label="Others who want your love..."
											InputProps={{
												...params.InputProps,
											}}
										/>
									</div>
								)}
							/>
						</div>
					</div>

					{!allSellerInput && !categorySellerInput && (
						<h3 className="text-center text-light mt-3 px-5 mb-3 fst-italic">
							OR
						</h3>
					)}
					<div
						className={`row text-center d-flex justify-content-center mt-2 mb-3`}
					>
						<div
							className={` ${
								categorySellerInput?.id?.length > 0 && "d-none"
							} col-lg-6 col-md-12 col-sm-12 mb-4 love-modal-input-mobile`}
						>
							<Autocomplete
								id="free-solo-2-demo"
								className="inputRounded"
								popupIcon={""}
								disabled={categorySellerInput?.id?.length > 0}
								value={allSellerInput}
								options={
									noReviewSellersData && noReviewSellersData?.length > 0
										? noReviewSellersData?.filter(
												(items) => items?.id != badReviews?.sellerId
										  )
										: []
								}
								onChange={(e, seller) => {
									setAllSellerInput(seller);
									setIsSellerSelected(true);
									dispatch(getSellersByID(seller?.id));

									setCategorySellerInput(null);
									setGoodReviews({
										...goodReviews,
										sellerId: seller?.id,
										categoryId: null,
									});
									dispatch(updateReviewSubmitStatus(""));
									if (pathArray !== "seller-balloon") {
										dispatch(updateLoveSellerName(seller?.sellerName));
									}
									if (!seller) {
										setGoodReviews({
											...goodReviews,
											categoryId: null,
											sellerId: null,
										});
									}
								}}
								getOptionLabel={(option) => option?.sellerName}
								renderInput={(params) => (
									<div className="autocomplete-search-inputfield">
										<SearchIcon className="autocomplete-searchicon" />
										<TextField
											className="inputRounded seller-textfield"
											{...params}
											label="Search Other Sellers"
											InputProps={{
												...params.InputProps,
											}}
										/>
									</div>
								)}
							/>
						</div>
					</div>
				</div>
				<div className="row text-center d-flex justify-content-center mt-4">
					<div
						className={`col-lg-6 col-md-12 col-sm-12  love-modal-input-mobile `}
					>
						{sellerCategories &&
							goodReviews.sellerId &&
							sellerCategories?.length > 1 &&
							isSellerSelected && (
								<CategoryAutoComplete
									reviews={goodReviews}
									setReviews={setGoodReviews}
									sellerCategories={sellerCategories}
									notFromSellerBallonPage
								/>
							)}
					</div>
				</div>
				<Review goodReviews={goodReviews} setGoodReviews={setGoodReviews} />
				<div className="d-flex justify-content-center">
					<button
						type="button"
						disabled={isLoveSubmitted}
						onClick={handleThanks}
						className="btn rounded-pill mb-4 mt-5 button-next"
					>
						Submit
					</button>
				</div>
			</div>
			<LoginModal isShow={showLoginModal} setIsShow={setLoginModal} />
		</Modal>
	);
}

export default LoveModal;
