// import React, { useState } from "react";
// import { uploadFile } from "react-s3";

// const UploadImageToS3WithReactS3 = () => {
// 	const [selectedFile, setSelectedFile] = useState(null);

// 	const handleFileInput = (e) => {
// 		setSelectedFile(e.target.files[0]);
// 	};

// 	const handleUpload = async (file) => {
// 		uploadFile(file, config)
// 			.then((data) => console.log(data))
// 			.catch((err) => console.error(err));
// 	};

// 	return (
// 		<div>
// 			<input type="file" onChange={handleFileInput} />
// 			<button onClick={() => handleUpload(selectedFile)}> Upload to S3</button>
// 		</div>
// 	);
// };

// export default UploadImageToS3WithReactS3;

// const ACCESS_KEY = "AKIAQ5UEKWYU4RU5BJV4";
// const SECRET_ACCESS_KEY = "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";

// const config = {
// 	bucketName: S3_BUCKET,
// 	region: REGION,
// 	accessKeyId: ACCESS_KEY,
// 	secretAccessKey: SECRET_ACCESS_KEY,
// };
import React, { useState } from "react";
import AWS from "aws-sdk";
import { useDispatch } from "react-redux";
import { uploadDataRequest } from "../../redux/reducers/ducks/seededDuck";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import "./uploadVideoS3.css";

const S3_BUCKET = "pic-and-vid-central";
const REGION = "us-east-1";

AWS.config.update({
	accessKeyId: "AKIAQ5UEKWYU4RU5BJV4",
	secretAccessKey: "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",
});

const myBucket = new AWS.S3({
	params: { Bucket: S3_BUCKET },
	region: REGION,
});

const UploadImageToS3WithNativeSdk = ({
	balloonId,
	fullName,
	from,
	isOriginalMaterial,
	handelSetFullName,
	handelSetFromName,
	handleCheckboxChange,
}) => {
	const [progress, setProgress] = useState(0);
	const [selectedFile, setSelectedFile] = useState(null);
	const [errors, setErrors] = useState({ fullName: "", from: "" });

	const dispatch = useDispatch();
	const handleFileInput = (e) => {
		setSelectedFile(e.target.files[0]);
	};
	const validateFields = () => {
		let valid = true;
		let errors = { fullName: "", from: "" };

		if (!fullName) {
			errors.fullName = "Please enter your full name";
			valid = false;
		}
		if (!from) {
			errors.from = "Please enter where you are from";
			valid = false;
		}

		setErrors(errors);
		return valid;
	};
	const uploadFile = (file) => {
		if (!validateFields()) {
			return;
		}
		const params = {
			ACL: "public-read",
			Body: file,
			Bucket: S3_BUCKET,
			Key: `${Date.now().toString()}-${file.name}`,
		};

		myBucket
			.putObject(params)
			.on("httpUploadProgress", (evt) => {
				setProgress(Math.round((evt.loaded / evt.total) * 100));
			})
			.send((err) => {
				if (err) {
					console.log(err);
				} else {
					const url = `https://pic-and-vid-central.s3.amazonaws.com/${Date.now().toString()}-${
						file.name
					}`;
					dispatch(
						uploadDataRequest({
							videoUrl: url,
							balloonId: balloonId,
							status: "pending",
							fullName: fullName,
							location: from,
							isOriginal: isOriginalMaterial,
						})
					);
					getToasterNotifications("vedio uploaded successfully");
				}
			});
	};
	return (
		<div className="container d-flex flex-column around mt-5">
			{/* start input fields  */}
			{/* <div>
				<div className="d-flex gap-3">
					<label>Full Name</label>
					<input
						onChange={handelSetFullName}
						placeholder="Full Name"
						value={fullName}
					/>
				</div>
			</div> */}
			<div className="d-flex flex-column">
				<div className={`form-group w-60 mb-3 shadow-sm`}>
					<span className="font-bold">Full Name</span>
					<br />
					<input
						onChange={handelSetFullName}
						className="w-100 border rounded shadow-sm p-2"
						name="fullName"
						minLength="3"
						type="text"
						id="fullName"
						placeholder="Full Name"
						value={fullName}
						required={true}
					/>
					{errors.fullName && (
						<div className="error-message error-size">{errors.fullName}</div>
					)}
				</div>
				<div className={`form-group w-60 mb-3 shadow-sm`}>
					<span className="font-bold">Tell us where you're from!</span>
					<br />
					<input
						onChange={handelSetFromName}
						className="w-100 border rounded shadow-sm p-2"
						name="from"
						minLength="3"
						type="text"
						id="from"
						placeholder="Tell us where you're from!"
						value={from}
					/>
					{errors.from && (
						<div className="error-message error-size">{errors.from}</div>
					)}
				</div>
			</div>
			<div>
				<input
					type="checkbox"
					id="isOriginalMaterial"
					name="isOriginalMaterial"
					checked={isOriginalMaterial}
					onChange={handleCheckboxChange}
				/>
				<label className="px-2">Check box if your material is Original? </label>
			</div>
			{/* end input fields  */}
			<div>
				<div className="fit-content mt-3 mb-2">
					<input type="file" accept=".mp4" onChange={handleFileInput} />
					<div className="d-flex">File Upload Progress is {progress}%</div>
					<div>
						<p className="mt-4">
							By submitting your video, you agree to the terms of the contest!
						</p>

						<button
							className="change-passwords"
							onClick={() => uploadFile(selectedFile)}
							style={{ margin: "4% 0 0 40%", padding: "6px 10px" }}
						>
							Upload Video
						</button>
					</div>
				</div>
				{/* <DisplayVideos /> */}
			</div>
		</div>
	);
};

export default UploadImageToS3WithNativeSdk;
