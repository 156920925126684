import { CircularProgress } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	getSellersByID,
	submitReview,
	updateReviewSubmitStatus,
	userReviewsRequest,
	verifyReviewRequest,
} from "../../redux/reducers/ducks/getSellersDuck";
import Remarks from "../remarks/remarks";
import "./modals.css";
import LoginModal from "./loginModal";
import SignUpModal from "./signUpModal";
import "./modals.css";
import Backdrop from "@mui/material/Backdrop";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
import { useNavigate, useParams } from "react-router";
import { Editor } from "@tinymce/tinymce-react";
import { CategoryAutoComplete } from "../layout/categoryAutoComplete/categoryAutoComplete";

function ToTellModal({
	isTell,
	setIsTell,
	setTellValue,
	setSelectedballonType,
	setThanks,
	setBadReviews,
}) {
	const [isReviewSubitOnLogin, setIsReviewSubmitOnLogin] = useState(false);

	let { id } = useParams();

	//get Api for sellers
	const [selectedBalloon, setSelectedbaloon] = useState("");

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dispatch = useDispatch();
	const [isTellSubmit, setIsTellSubmit] = useState(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [loveOpenAfterLogin, setLoveOpenAfterLogin] = useState(false);
	const [isNegativeReview, setIsNegativeReview] = useState(false);

	const navigate = useNavigate();

	const {
		reviewSubmitStatus,
		loginResponseData,
		singleSellerData,
		verifyReviewData,
		sellerCategories,
	} = useSelector((state) => {
		return {
			sellerId: state?.seller?.sellerId,
			reviewSubmitStatus: state.seller?.reviewSubmitStatus,
			loginResponseData: state.authorization?.loginResponseData,
			singleSellerData: state?.seller?.singleSellerData?.data,
			verifyReviewData: state?.adminReview?.verifyReviewData,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		};
	});

	const [reviews, setReviews] = useState({
		sellerId: id,
		titleId: "",
		categoryId: null,
		message: null,
		approvedByAdmin: true,
		bestWriter: false,
		balloonType: null,
	});
	const [content, setContent] = useState(reviews?.message);

	const handleChange = (event) => {
		setContent({ content: event });
	};

	useEffect(() => {
		setContent(reviews?.message);
	}, [isTell]);
	const handleCancel = () => {
		setContent();
		setReviews({
			sellerId: id,
			titleId: "",
			categoryId: null,
			message: null,
			approvedByAdmin: true,
			bestWriter: false,
			balloonType: null,
		});
		setIsTell(false);
	};

	const token = localStorage.getItem("token");

	const isAIrModal = !!(selectedBalloon && selectedBalloon.type === "to-air");

	useEffect(() => {
		dispatch(updateReviewSubmitStatus(null));
		setThanks(false);
		dispatch(verifyReviewResponse(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (id || isTell) {
			if (loginResponseData?.data?.id && isTell) {
				dispatch(
					verifyReviewRequest({
						sellerId: id,
						userId: loginResponseData?.data?.id,
					})
				);
			}
		}
	}, [id, isTell, loginResponseData]);

	useEffect(() => {
		if (verifyReviewData?.verifyReview && !isTellSubmit) {
			setReviews({
				...reviews,
				message: verifyReviewData?.verifyReview?.message,
				titleId: verifyReviewData?.verifyReview?.titleId,
				categoryId: verifyReviewData?.verifyReview?.categoryId,
			});
			setIsTellSubmit(false);
		}
	}, [verifyReviewData, isTellSubmit, isTell]);

	useEffect(() => {
		setSelectedballonType(isAIrModal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAIrModal]);

	const handleAir = () => {
		let token = localStorage.getItem("token");

		if (id && !reviews?.titleId) {
			getToasterNotifications("Please select a balloon!");
			return;
		}
		if (
			id &&
			reviews.titleId &&
			!reviews.categoryId &&
			sellerCategories &&
			sellerCategories?.length > 1
		) {
			getToasterNotifications("Please select a category!");
			return;
		} else if (
			id &&
			reviews?.titleId &&
			reviews?.balloonType &&
			verifyReviewData?.verifyReview?.type &&
			verifyReviewData?.verifyReview?.type !== reviews?.balloonType &&
			reviews?.message === verifyReviewData?.verifyReview?.message
		) {
			getToasterErrors("Please tell your new story.");
			setIsTellSubmit(false);
			setReviews({
				...reviews,
				message: null,
			});
			setContent(null);
			return;
		} else if (id && reviews?.titleId && !reviews?.message) {
			getToasterNotifications("Please Enter your story");
			return;
		}
		if (!token) {
			setLoginModal(true);
			setIsReviewSubmitOnLogin(true);
			return;
		}
		if (!id || !reviews?.titleId) {
			setTellValue(false);
			return;
		}
		setBadReviews({
			titleId: reviews?.titleId,
			message: reviews?.message,
			categoryId: reviews?.categoryId,
			approvedByAdmin: true,
			bestWriter: false,
			sellerId: id,
		});
		if (loginResponseData?.data?.id) {
			dispatch(
				verifyReviewRequest({
					sellerId: id,
					userId: loginResponseData?.data?.id,
				})
			);
		}
		dispatch(getSellersByID(id));

		setIsTellSubmit(true);
	};

	useEffect(() => {
		if (reviewSubmitStatus === 401 && token && loginResponseData?.data?.id) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			dispatch(updateReviewSubmitStatus(null));
			localStorage.setItem("adminToken", "");
		} else if (reviewSubmitStatus === 400) {
			getToasterNotifications(
				"You've already clicked this seller!  You can edit your story from the Seller Balloon Page!"
			);
			dispatch(updateReviewSubmitStatus(null));
		}

		if (
			id &&
			reviews?.titleId &&
			reviewSubmitStatus === 409 &&
			token &&
			isTellSubmit
		) {
			dispatch(
				submitReview({
					titleId: reviews?.titleId,
					categoryId: reviews?.categoryId,
					message: reviews?.message,
					approvedByAdmin: true,
					bestWriter: false,
					sellerId: id,
				})
			);
			setIsTellSubmit(true);

			setReviews(null);
		} else if (
			id &&
			reviews?.titleId &&
			reviewSubmitStatus === 200 &&
			token &&
			isTellSubmit &&
			!isReviewSubitOnLogin
		) {
			if (!isAIrModal) {
				dispatch(
					submitReview({
						titleId: reviews?.titleId,
						categoryId: reviews?.categoryId,
						message: reviews?.message,
						approvedByAdmin: true,
						bestWriter: false,
						sellerId: id,
					})
				);
				setIsTellSubmit(true);
				setReviews(null);
			} else {
				setThanks(false);
				setTellValue(true);
				setIsTellSubmit(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, reviews, reviewSubmitStatus, isAIrModal, isTellSubmit]);

	useEffect(() => {
		if (id) {
			setReviews({
				...reviews,
				sellerId: id,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (reviewSubmitStatus === 201 && isTellSubmit) {
			setThanks(true);
			setIsTellSubmit(false);
			setIsTell(false);
			dispatch(updateReviewSubmitStatus(null));
		} else if (reviewSubmitStatus === 410 && isTellSubmit) {
			getToasterErrors("Review already Winner");
			dispatch(
				userReviewsRequest({
					sellerId: id,
					userId: loginResponseData ? loginResponseData?.data?.id : "",
				})
			);
			setThanks(true);
			dispatch(updateReviewSubmitStatus(null));

			setIsTellSubmit(false);
			setIsTell(false);
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus, isTellSubmit]);

	useEffect(() => {
		if (reviewSubmitStatus === 403) {
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
			setIsTellSubmit(false);
			setTellValue(false);
			setIsTell(false);
			dispatch(updateReviewSubmitStatus(null));
		}
	}, [reviewSubmitStatus, dispatch]);

	const editorRef = useRef(null);

	useEffect(() => {
		setReviews({ ...reviews, message: editorRef?.current?.getContent() });
	}, [editorRef.current, content]);

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	// Update screenWidth on window resize
	const handleResize = () => {
		setScreenWidth(window.innerWidth);
	};

	useEffect(() => {
		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	useEffect(() => {
		if (loveOpenAfterLogin) {
			setTellValue(true);
			setBadReviews(reviews);
		} else {
			setTellValue(false);
		}
	}, [loveOpenAfterLogin]);

	useEffect(() => {
		if (reviews?.balloonType === "to-air") {
			setIsNegativeReview(true);
		} else if (reviews?.balloonType === "to-love") {
			setIsNegativeReview(false);
		}
	}, [reviews]);

	return (
		<Modal
			show={isTell}
			onHide={handleCancel}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0" />
			{isTellSubmit && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 1000 }}
					open={isTellSubmit}
					onClick={() => setIsTellSubmit(false)}
				>
					<div className="d-flex  justify-content-center loaderCenter align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<h6 className="text-center fw-bold mx-lg-0 mx-2">
					{verifyReviewData?.verifyReview?.titleId ? (
						<>
							<h6 className="text-center fw-bold mx-3 ">
								You've already clicked this Seller!
							</h6>
							<h6 className="text-center fw-bold mx-3 ">
								Would you like to edit your click and/or story?
							</h6>
						</>
					) : (
						`Please click the balloon ${
							sellerCategories?.length > 1 && "and also select category"
						}  that best describes your experience with ${
							singleSellerData?.seller?.sellerName
						}.`
					)}
				</h6>
				{sellerCategories && sellerCategories?.length > 1 && (
					<CategoryAutoComplete
						reviews={reviews}
						setReviews={setReviews}
						sellerCategories={sellerCategories}
					/>
				)}
				<Remarks
					reviews={reviews}
					setGoodReviews={setReviews}
					isTell
					setSelectedbaloon={setSelectedbaloon}
				/>
				<div className="form-outline d-flex justify-content-center mt-3">
					{/* <textarea
						className="form-control tell-story-input"
						id="textAreaExample2"
						rows="5"
						value={reviews?.message ? reviews?.message : ""}
						onChange={(e) => {
							setReviews({ ...reviews, message: e.target.value });
						}}
						placeholder="Tell your Story Here!"
					/> */}
					<Editor
						onInit={(evt, editor) => (editorRef.current = editor)}
						apiKey={process.env.REACT_APP_EDITOR_KEY}
						init={{
							height: 350,
							menubar: false,
							width: screenWidth > 768 ? "70%" : "92%",
						}}
						onEditorChange={handleChange}
						value={content ? content : ""}
					/>
				</div>
				<div className="d-flex justify-content-center">
					<button
						disabled={isTellSubmit}
						type="button"
						onClick={handleAir}
						className="btn rounded-pill mb-4 mt-5 to-tell-submit-buton"
					>
						{verifyReviewData?.verifyReview?.titleId ? "Update" : "Submit"}
					</button>
				</div>
			</div>
			<LoginModal
				isShow={showLoginModal}
				setIsTell={setIsTell}
				setIsShow={setLoginModal}
				setSignUp={setSignUpModal}
				reviews={reviews}
				setThanks={setThanks}
				setIsReviewSubmitOnLogin={setIsReviewSubmitOnLogin}
				isReviewSubitOnLogin={isReviewSubitOnLogin}
				isTell
				setIsParentModal={setIsTell}
				setLoveOpenAfterLogin={setLoveOpenAfterLogin}
				fromSellerBalloonPage
				isNegativeReview={isNegativeReview}
				setIsNegativeReview={setIsNegativeReview}
			/>
			<SignUpModal
				reviews={reviews}
				isShowSignUpModal={showSignUpModal}
				setIsShowSignUpModal={setSignUpModal}
				setIsLogin={setLoginModal}
				isParent
				setIsParent={setIsTell}
			/>
		</Modal>
	);
}

export default ToTellModal;
