import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateReviewType } from "../../redux/reducers/ducks/getSellersDuck";
import { updateReviewCurrentPage } from "../../redux/reducers/ducks/adminReviewDuck";

export const ReviewCategoryFilter = () => {
	const { reviewType, sellerCategories } = useSelector((state) => {
		return {
			reviewType: state?.seller?.reviewType,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		};
	});
	const dispatch = useDispatch();

	return (
		<div className="w-100 mb-5 mt-4">
			<Autocomplete
				className="inputRounded "
				popupIcon={""}
				sx={{
					"& .MuiOutlinedInput-root": {
						padding: "2px",
						borderRadius: "37px",
					},
					"& .MuiAutocomplete-input": {
						paddingLeft: "17px !important",
					},
				}}
				value={reviewType && reviewType?.category ? reviewType?.category : {}}
				onChange={(e, category) => {
					if (category) {
						dispatch(updateReviewCurrentPage(1));
						dispatch(
							updateReviewType({
								...reviewType,
								category: category,
							})
						);
					} else {
						dispatch(
							updateReviewType({
								...reviewType,
								category: null,
							})
						);
					}
				}}
				getOptionLabel={(option) =>
					option?.categoryName ? option?.categoryName : ""
				}
				renderOption={(props, option) => {
					return <div {...props}>{option?.categoryName}</div>;
				}}
				id="combo-box-demo"
				options={
					sellerCategories && sellerCategories?.length > 0
						? sellerCategories
						: []
				}
				renderInput={(params) => (
					// created the inputfield for searching
					<div className="autocomplete-search-inputfield auto-textfield">
						<TextField
							className="mb-5"
							{...params}
							placeholder="Search by Categories"
						/>
					</div>
				)}
			/>
		</div>
	);
};
