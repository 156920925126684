import axios from "./Axios";

export const getAllCategories = async (payload) => {
	try {
		let res = await axios.get("categories/all-categories", {
			params: { type: payload },
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getAllCatCategorySellers = async (payload) => {
	try {
		let res = await axios.get("sellers/all", {
			params: { name: payload },
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getSellerByCategoriesId = async (payload) => {
	try {
		let res = await axios.get(`sellers/other/seller_id`, {
			withCredentials: true,
			params: {
				excludeSeller: payload?.excludeSeller,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getSellersByIndividualCategoreisId = async (id) => {
	try {
		let res = await axios.get(`categories/id/${id}`, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const deleteCategories = async (Id) => {
	try {
		let token = localStorage.getItem("adminToken");

		let res = await axios.delete(`admin/categories/delete`, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			params: {
				id: Id,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const updateCategoryStatus = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");

		let res = await axios.patch("admin/categories/update/status", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const updateCategoryName = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");

		let res = await axios.patch("admin/categories/update", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const createCategory = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");

		let res = await axios.post("admin/categories/create", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const getAdminAllCategories = async (data) => {
	try {
		let token = localStorage.getItem("adminToken");
		let res = await axios.get("admin/category_search", {
			withCredentials: true,
			params: data,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		return res;
	} catch (err) {
		return err;
	}
};
