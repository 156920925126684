import React, { useEffect, useState } from "react";

import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../modals.css";
import "./reviewModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
	getSellerId,
	getSellersByID,
	likeDislikeReviewResponse,
	sellerLatestReviewRequest,
	submitLikeDislike,
	updateReviewType,
} from "../../../redux/reducers/ducks/getSellersDuck";
import { getSellersDataByIndividualCategoryID } from "../../../redux/reducers/ducks/categoriesDuck";
import { getSingleUserRequest } from "../../../redux/reducers/ducks/userDuck";
import LoginModal from "../loginModal";
import SignUpModal from "../signUpModal";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";
import moment from "moment";
import { appededUrl } from "../../../utils/constant";

function ReviewModal({ showReview, setIsClose }) {
	const dispatch = useDispatch();
	let token = localStorage.getItem("token");

	const [showLoginModal, setLoginModal] = useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [isReportReview, setIsReportReview] = useState(false);
	const [countLike, setCountLike] = useState(null);

	const handleViewSellerBalloonPage = (showReviewParam, sellerId) => {
		dispatch(getSellerId(sellerId));
	};

	const [likeType, setLikeType] = useState(null);

	const {
		latestReview,
		balloons,
		sellerByID,
		isReviewUpdate,
		searchModalCategoryId,
		singleUserData,
		likeDislikeData,
		loginResponseData,
	} = useSelector((state) => {
		return {
			latestReview: state.category.latestReview,
			balloons: state?.seller?.balloon?.data?.title,
			isReviewUpdate: state?.seller?.isReviewUpdate,
			searchModalCategoryId: state?.seller?.searchModalCategoryId,
			sellerByID: state?.seller?.sellerByID,
			singleUserData: state?.adminUser?.singleUserData,
			likeDislikeData: state?.seller?.likeDislikeReviewData,
			loginResponseData: state.authorization?.loginResponseData,
		};
	});
	const data = latestReview?.data?.["to-review"]
		? latestReview?.data?.["to-review"]
		: "";

	const formattedDate = moment?.utc(data?.createdAt).format("MMMM DD, YYYY");
	const balloonId = data?.titleId;
	const foundBalloonObj = balloons?.find((obj) => obj.id === balloonId);
	const balloonTitle = foundBalloonObj?.imageName;

	const isUserNominated = data?.likeDislike?.filter((mapItem) => {
		return mapItem.userId === loginResponseData?.data?.id;
	});

	useEffect(() => {
		if (isReviewUpdate && showReview && latestReview?.data?.category) {
			setTimeout(() => {
				dispatch(getSellersDataByIndividualCategoryID(searchModalCategoryId));
			}, 2000);
		} else if (isReviewUpdate && showReview && !latestReview?.data?.category) {
			setTimeout(() => {
				dispatch(sellerLatestReviewRequest(searchModalCategoryId));
			}, 2000);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReviewUpdate]);

	useEffect(() => {
		dispatch(getSingleUserRequest(data?.userId));
	}, [dispatch, latestReview, data]);

	const hanldeBadgeReview = () => {
		if (!token || token === "undefined") {
			setLoginModal(true);
		} else {
			setLoginModal(false);
		}
	};

	useEffect(() => {
		if (
			latestReview?.data &&
			showReview &&
			latestReview?.data["to-review"].sellerId
		) {
			dispatch(getSellersByID(latestReview?.data["to-review"].sellerId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, showReview, latestReview]);

	useEffect(() => {
		let totalCount = 0;
		data?.likeDislike?.forEach((reviews) => {
			if (reviews?.type === "like") {
				totalCount++;
			}
		});
		setCountLike(totalCount);
	}, [latestReview]);

	useEffect(() => {
		if (likeDislikeData?.status === 201 && isReportReview) {
			let notificationMessage =
				"Thanks for reporting! We'll review this user's story to make sure it's within our guidelines.";
			if (likeDislikeData?.data?.type === "like") {
				notificationMessage = "Thanks for Nominating!";
			}
			getToasterNotifications(notificationMessage);
			dispatch(likeDislikeReviewResponse(null));
			setIsReportReview(false);
		}
		if (likeDislikeData?.status === 403 && isReportReview) {
			dispatch(likeDislikeReviewResponse(null));
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
		}
		if (likeDislikeData?.response?.status === 409 && isReportReview) {
			getToasterNotifications("You’ve already reported this story!");
			dispatch(likeDislikeReviewResponse(null));
			setIsReportReview(false);
		}
	}, [likeDislikeData, isReportReview]);

	const onMovePage = () => {
		setTimeout(() => {
			window.location.href = appededUrl(`/seller-balloon/${data?.sellerId}`);
		}, 1000);
	};

	return (
		<div className="latest-review-main-div">
			<div className="d-flex justify-content-between mt-2">
				<div className="d-flex">
					<img
						src={`/icons/${balloonTitle}.png`}
						className="review-modal-image-shadow"
						alt={balloonTitle}
					/>
					<div className="review-modal-seller-name-text mt-md-4 mt-3 review-seller-height">
						<Link
							className="text-decoration-none"
							onClick={() => {
								dispatch(
									updateReviewType({
										titleId: "",
										balloonType: "All Loved",
										airedOrLoved: "to-love",
										category: null,
									})
								);
								setIsClose(true);
								handleViewSellerBalloonPage(false, data?.sellerId);
								onMovePage();
							}}
						>
							{sellerByID?.data ? sellerByID?.data?.record?.sellerName : ""}
						</Link>
					</div>
				</div>
				<div className="fw-bold review-modal-seller-name">
					<Link
						// to="/seller-balloon"
						className="text-decoration-none"
						style={{ color: "black" }}
						onClick={() => {
							dispatch(
								updateReviewType({
									titleId: "",
									balloonType: "All Loved",
									airedOrLoved: "to-love",
									category: null,
								})
							);
							setIsClose(true);
							handleViewSellerBalloonPage(false, data.sellerId);
							onMovePage();
						}}
					>
						{foundBalloonObj?.title}
					</Link>
				</div>
				<div className="review-modal-header-date mt-md-4 mt-0">
					<div className="d-flex justify-content-end mt-xl-2 mt-0">
						{formattedDate}
					</div>
				</div>
			</div>
			<>
				{latestReview?.data?.["to-review"]?.id ? (
					<div>
						<div className=" rounded-bottom mb-1 review-modal-wrapper-container">
							<div className="position-relative">
								<div
									className={`row review-modal-msg mx-md-1 mx-2 ${balloonTitle}-dropdown-item-border`}
								>
									{/* <textarea
										className={`form-control mx-auto text_area ${balloonTitle}-dropdown-item-border`}
										id="textAreaExample2"
										rows="4"
										disabled
										placeholder="Tell your Story Here!"
										value={data?.message}
									/> */}
									<p
										className={`review-modal review-inner-p  text_area-comments`}
										dangerouslySetInnerHTML={{ __html: data?.message }}
									/>
								</div>
								<div className="row p-0 mx-md-0  mx-0 d-flex justify-content-between align-items-center">
									<div className="col-lg-4 p-0 col-4 d-flex">
										<div className="d-flex mt-md-3 mt-1">
											<img
												src={`/icons/default-avatar.png`}
												className="tester-image"
											/>
										</div>
										<div className="review-modal-user-name fw-bold">
											{singleUserData?.username.slice(0, 10)}
										</div>
									</div>
									<div className="position-relative col-lg-8 col-8 d-flex p-0 m-0 justify-content-end review-modal-nominate-button">
										<button
											type="button"
											disabled={
												isUserNominated?.length && !data?.winnerDate > 0
											}
											// eslint-disable-next-line prettier/prettier
											className={`btn mt-3 review-modal-notify-btn nominated-btn ${
												data?.winnerDate
													? "review-modal-notify-btn-winner"
													: "review-modal-notify-btn"
											}`}
											onClick={() => {
												if (!data?.winnerDate) {
													if (loginResponseData?.data?.id) {
														dispatch(
															submitLikeDislike({
																reviewId: data?.id,
																type: "like",
															})
														);
														setIsReportReview(true);
													} else {
														setLikeType({
															type: "like",
															reviewId: data?.id,
															userId: data?.userId,
														});
														hanldeBadgeReview(data?.id);
													}
												}
											}}
										>
											{data?.winnerDate ? (
												<p className="d-md-flex winner-text">🎉 Winner! 🎉</p>
											) : (
												<div>
													<p className="d-md-flex d-none">Nominate</p>
													<p className="d-md-none d-flex nominated-text">
														Nominate
													</p>
												</div>
											)}
										</button>
										{data?.likeDislike?.length > 0 && !data?.winnerDate && (
											<Badge pill className="latest-review-badge">
												{/*{data?.likeDislike[0]}*/}
												{countLike > 0 ? countLike : ""}
											</Badge>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="reviewCenter">
						No review exist against this selected category please select another
					</div>
				)}
				<LoginModal
					isShow={showLoginModal}
					setIsShow={setLoginModal}
					setSignUp={setSignUpModal}
					likeType={likeType}
					setLikeType={setLikeType}
					isFromReviewModal
					setIsReportReview={setIsReportReview}
				/>
				<SignUpModal
					isShowSignUpModal={showSignUpModal}
					setIsShowSignUpModal={setSignUpModal}
					setIsLogin={setLoginModal}
				/>
			</>
		</div>
	);
}

export default ReviewModal;
