import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import Remarks from "../remarks/remarks";
import "./modals.css";
import {
	editCommit,
	editReviewResponse,
	handleNominateStatus,
	updateReviewSubmitStatus,
} from "../../redux/reducers/ducks/getSellersDuck";
import { useEffect } from "react";
import { getToasterErrors } from "../../utils/getToasterErrors";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { useNavigate } from "react-router";
import { Editor } from "@tinymce/tinymce-react";
import { CategoryAutoComplete } from "../layout/categoryAutoComplete/categoryAutoComplete";

function EditModal({ reviewData, setReviewData }) {
	const dispatch = useDispatch();

	const { editReview, verifyReviewData, sellerCategories } = useSelector(
		(state) => ({
			editReview: state.seller?.editReview,
			verifyReviewData: state?.adminReview?.verifyReviewData,
			sellerCategories: state?.seller?.sellerByID?.data?.record?.categories,
		})
	);

	const [balloonType, setBalloonType] = useState(null);
	const token = localStorage.getItem("token");

	const handleSubmit = async () => {
		const finalData = {
			titleId: reviewData.balloonId,
			reviewId: reviewData.reviewId,
			message: reviewData.data,
			categoryId: reviewData.categoryId,
		};
		if (
			verifyReviewData?.verifyReview?.type &&
			balloonType &&
			verifyReviewData?.verifyReview?.type !== balloonType &&
			finalData?.message === verifyReviewData?.verifyReview?.message
		) {
			getToasterErrors("Please tell your new story.");
			setReviewData({
				...reviewData,
				data: null,
			});
			setContent(null);
			return;
		}
		if (!reviewData?.data) {
			getToasterErrors("Please Enter message");
			return;
		}
		if (!reviewData?.balloonId) {
			getToasterNotifications("Please select a balloon!");
			return;
		} else {
			dispatch(editCommit(finalData));
			handleCancel();
			handleNominateStatus(false);
			setBalloonType(null);
		}
	};

	const navigate = useNavigate();

	const [content, setContent] = useState(reviewData?.data);

	const handleCancel = () => {
		setBalloonType(null);
		setReviewData({ ...reviewData, isEdit: false });
	};

	useEffect(() => {
		if (editReview?.response?.data?.statusCode === 403) {
			dispatch(editReviewResponse(null));
			getToasterErrors(
				"There seems to be a problem with your account. Please contact us."
			);
		}
		if (editReview?.response?.data?.statusCode === 401 && token) {
			navigate("/loginUser");
			localStorage.setItem("token", "");
			dispatch(updateReviewSubmitStatus(null));
			localStorage.setItem("adminToken", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editReview, dispatch]);

	const editorRef = useRef(null);

	useEffect(() => {
		setReviewData({ ...reviewData, data: content });
	}, [editorRef.current, content]);

	const handleChange = (event) => {
		setContent(event);
	};

	useEffect(() => {
		setContent(reviewData?.data);
	}, [reviewData]);

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	// Update screenWidth on window resize
	const handleResize = () => {
		setScreenWidth(window.innerWidth);
	};

	useEffect(() => {
		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Modal
			show={reviewData.isEdit}
			onHide={handleCancel}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0" />
			<div>
				<h6 className="text-center fw-bold mx-3 ">
					You've already clicked this Seller! Would you like to edit your click
					and/or story?
				</h6>
				{sellerCategories && sellerCategories?.length > 1 && (
					<CategoryAutoComplete
						sellerCategories={sellerCategories}
						reviews={reviewData}
						setReviews={setReviewData}
					/>
				)}
				<Remarks
					setBalloonType={setBalloonType}
					isEdit
					reviewData={reviewData}
					setReviewData={setReviewData}
					isCommit
				/>
				<div className="form-outline d-flex justify-content-center mt-3">
					<Editor
						onInit={(evt, editor) => (editorRef.current = editor)}
						apiKey={process.env.REACT_APP_EDITOR_KEY}
						init={{
							height: 350,
							menubar: false,
							width: screenWidth > 768 ? "70%" : "92%",
						}}
						value={content ? content : ""}
						onEditorChange={handleChange}
					/>
				</div>
				<div className="d-flex justify-content-center">
					<button
						type="button"
						className="btn rounded-pill mb-4 mt-5 button-next"
						onClick={() => {
							handleSubmit();
						}}
					>
						Update
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default EditModal;
