import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	allInstgramStories: "",
	instagramLoadingStatus: false,
};

const instgramStoriesSlice = createSlice({
	name: "contactUs",
	initialState: INITIAL_STATE,
	reducers: {
		allInstagramStoriesRequest: (state) => state,
		submitInstagramStoriesRequest: (state) => state,
		getAllInstgramStoriesResponse(state, { payload }) {
			return {
				...state,
				allInstgramStories: payload,
			};
		},
		isInstgramLoading(state, { payload }) {
			return {
				...state,
				instagramLoadingStatus: payload,
			};
		},
	},
});

export const {
	getAllInstgramStoriesResponse,
	allInstagramStoriesRequest,
	submitInstagramStoriesRequest,
	isInstgramLoading,
} = instgramStoriesSlice.actions;

export default instgramStoriesSlice.reducer;
