import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import {
	getBalloon,
	getSellers,
	handleAdminStatus,
} from "../../../redux/reducers/ducks/getSellersDuck";
// import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";

import AddSeededData from "./addSeedData";
import MessagePopup from "../review/messagePopup";
import { allSeededDataRequest } from "../../../redux/reducers/ducks/seededDuck";
import { getCategory } from "../../../redux/reducers/ducks/categoriesDuck";
import { CheckCircle } from "@mui/icons-material";

export default function SeededMainPage() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openMessage, setOpenMessage] = useState({ isOpen: false, msg: null });
	const [isChangeCategoryData, setIsChangeCategoryData] = useState(false);

	const { allSeededData, isSeddedDataStatus } = useSelector(
		({ seededData }) => {
			return {
				allSeededData: seededData?.allSeededData,
				isSeddedDataStatus: seededData?.isSeddedDataStatus,
			};
		}
	);

	const dispatch = useDispatch();
	const handleChangePage = (event, newPage) => {
		dispatch(handleAdminStatus(false));
		setPage(newPage);
	};

	const [seededData, setSeededData] = useState({
		isOpen: false,
		id: null,
		sellerName: null,
		categoryName: null,
		balloonName: false,
		userName: null,
		review: null,
		date: new Date(),
	});

	const columns = [
		{ id: "Seller", label: `Seller`, minWidth: 130 },
		{ id: "code1", label: "Category", minWidth: 100 },
		{ id: "code2", label: "Username", minWidth: 100 },
		{ id: "code4", label: "Balloon", minWidth: 100 },
		{ id: "code4", label: "Review", minWidth: 60 },
		{ id: "code3", label: "DateToPost", minWidth: 100 },
		{ id: "code3", label: "Actions", minWidth: 100 },
	];

	useEffect(() => {
		const requestData = {
			page: page + 1,
			pageSize: rowsPerPage,
		};
		const timeout = setTimeout(() => {
			dispatch(allSeededDataRequest(requestData));
			setIsChangeCategoryData(false);
		}, 1000);

		return () => clearTimeout(timeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage]);

	useEffect(() => {
		dispatch(getBalloon());
		dispatch(getSellers());
		dispatch(getCategory(""));
	}, []);

	useEffect(() => {
		if (isSeddedDataStatus) {
			const requestData = {
				page: page + 1,
				pageSize: rowsPerPage,
			};
			dispatch(allSeededDataRequest(requestData));
			setIsChangeCategoryData(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSeddedDataStatus, page, dispatch]);

	const handleRowsPerPage = (e) => {
		setRowsPerPage(e.target.value);
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	return (
		<div className="cardParent">
			{(isChangeCategoryData || isSeddedDataStatus) && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 100 }}
					open={isChangeCategoryData || isSeddedDataStatus}
				>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					{/* <div>{pageName} Portal</div> */}
					<div className="add-cat mb-1">
						<Button
							onClick={() => {
								setSeededData({
									id: null,
									sellerName: null,
									categoryName: null,
									balloonName: false,
									userName: null,
									review: null,
									date: new Date(),
									isOpen: true,
								});
							}}
							variant="contained"
							sx={{ ml: 1 }}
							className="addCategoryBtn fw-bold"
						>
							Seed New Data
						</Button>
					</div>
					<div className="parent-cat">{/* <div>Contact Portal</div> */}</div>

					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{allSeededData?.data?.records?.length > 0 ? (
										allSeededData?.data?.records?.map((cat) => {
											// const date = moment?.utc(cat?.createdAt);
											// const formattedDate = date.format("MMMM DD, YYYY");
											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={cat?.id}
												>
													<TableCell>{cat?.sellerName}</TableCell>
													<TableCell>{cat?.categoryName}</TableCell>
													<TableCell>{cat?.userName}</TableCell>
													<TableCell>{cat?.balloonName}</TableCell>
													<TableCell
														textAlign="center"
														onClick={() => {
															setOpenMessage({
																isOpen: true,
																msg: cat?.review,
															});
														}}
														className="contact-res-cursor"
													>
														{cat?.review?.length > 0 ? <CheckCircle /> : ""}
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														{cat?.date}
													</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														<EditIcon
															onClick={() => {
																setSeededData({
																	isOpen: true,
																	id: cat.id,
																	sellerName: cat.sellerName,
																	categoryName: cat.categoryName,
																	userName: cat.userName,
																	balloonName: cat?.balloonName,
																	review: cat?.review,
																	date: new Date(),
																});
															}}
															className="admin-seller-edit ms-1 seller-panel-cursor text-primary iconMargin"
														/>{" "}
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}> No Contact exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{allSeededData?.data?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
									count={allSeededData?.data?.totalRecords}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}
					</Paper>
				</Card>
			</div>
			<MessagePopup showPopup={openMessage} setShowPopup={setOpenMessage} />

			<AddSeededData
				showDialog={seededData}
				isChangeCategoryData={isChangeCategoryData}
				setShowDialog={setSeededData}
				setIsChangeCategoryData={setIsChangeCategoryData}
			/>
		</div>
	);
}
