import { put, call, takeLatest } from "redux-saga/effects";
import {
	blockUserStatus,
	deleteAdminUser,
	getAllAdminUser,
	updateAdminUser,
} from "../../../api/adminUserApi";

import { handleAdminStatus } from "../../reducers/ducks/getSellersDuck";
import {
	adminUserDeleteResponse,
	deleteAdminUserRequest,
	editAdminUserData,
	getAdminUsers,
	getAllUserData,
	getAllUsersResponse,
	getSingleUserData,
	getSingleUserRequest,
	latestWinnerRequest,
	latestWinnerResponse,
	updateAdminUserResponse,
	updateAdminUsers,
	userBlockStatusRequest,
	userBlockStatusResponse,
} from "../../reducers/ducks/userDuck";
import {
	allUsers,
	latestWinner,
	singleUserById,
} from "../../../api/authorizationApi";
import { adminReviewLoadingResponse } from "../../reducers/ducks/adminReviewDuck";

export function* getAdminUserData({ payload }) {
	try {
		yield put(adminReviewLoadingResponse(true));
		const { data } = yield call(getAllAdminUser, payload);
		yield put(updateAdminUsers(data));
	} catch (error) {
		yield put(adminReviewLoadingResponse(false));
		yield put(updateAdminUsers(error));
		return error;
	} finally {
		yield put(adminReviewLoadingResponse(false));
		yield put(handleAdminStatus(true));
	}
}
export function* updateAdminUserData({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(updateAdminUser, payload);
		yield put(updateAdminUserResponse(data));
	} catch (error) {
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}
export function* deleteAdminUserData({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(deleteAdminUser, payload);
		yield put(adminUserDeleteResponse(data));
	} catch (error) {
		yield put(adminUserDeleteResponse(error));
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* getAllUsers() {
	try {
		const { data } = yield call(allUsers);
		yield put(getAllUserData(data));
	} catch (error) {
		return error;
	}
}

export function* getSingleUsersSaga({ payload }) {
	try {
		const { data } = yield call(singleUserById, payload);
		yield put(getSingleUserData(data));
	} catch (error) {
		return error;
	}
}

export function* userBlockSaga({ payload }) {
	try {
		yield put(handleAdminStatus(false));
		const data = yield call(blockUserStatus, payload);
		yield put(userBlockStatusResponse(data));
	} catch (error) {
		yield put(userBlockStatusResponse(error));
		return error;
	} finally {
		yield put(handleAdminStatus(true));
	}
}

export function* getLatestWinner() {
	try {
		const { data } = yield call(latestWinner);
		yield put(latestWinnerResponse(data));
	} catch (error) {
		return error;
	}
}

export function* watchUsersSagas() {
	yield takeLatest(getAdminUsers.type, getAdminUserData);
	yield takeLatest(editAdminUserData.type, updateAdminUserData);
	yield takeLatest(deleteAdminUserRequest.type, deleteAdminUserData);
	yield takeLatest(getAllUsersResponse.type, getAllUsers);
	yield takeLatest(getSingleUserRequest.type, getSingleUsersSaga);
	yield takeLatest(userBlockStatusRequest.type, userBlockSaga);
	yield takeLatest(latestWinnerRequest.type, getLatestWinner);
}
