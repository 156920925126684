import axios from "./Axios";

export const contactApi = async (data) => {
	try {
		return axios.post("contact-us/create", data);
	} catch (err) {
		return err;
	}
};

export const reportBugApi = async (data) => {
	try {
		return axios.post("report-bug/create", data);
	} catch (err) {
		return err;
	}
};

export const adminReportBugApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/bugReport/all_user", {
			params: data,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const adminContactApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.get("admin/contact/all_user", {
			params: {
				page: data.page ? data.page : 1,
				pageSize: data.pageSize ? data.pageSize : 10,
				email: data?.email ? data?.email : "",
			},
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const adminContactStatusApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.patch("admin/contact/update/status", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const adminReportBugStatusApi = async (data) => {
	let token = localStorage.getItem("adminToken");

	try {
		return axios.patch("admin/reportBug/update/status", data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
