import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdvertisementModal from "../../../components/modals/advertisementModal";
import Layout from "../../../components/layout/parentLayout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
	signUpRequest,
	updateIsSignup,
	updateUser,
} from "../../../redux/reducers/ducks/autorizeDuck";
import "./signupForm.css";
import { Backdrop, CircularProgress } from "@mui/material";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function SignupForm() {
	const [view, setView] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [isSignupClicked, setIsSignupClicked] = useState(false);
	const [isSignUpSubmitted, setIsSignUpSubmitted] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [isReCaptchaValue, setIsReCaptchaValue] = useState(false);
	const [isEyeIconClick, setIsEyeIconClick] = useState(false);

	const dispatch = useDispatch();

	const { signUpUser, isSignup } = useSelector((state) => {
		return {
			signUpUser: state.authorization?.signUpUser,
			isSignup: state.authorization?.isSignup,
		};
	});
	const passwordPattern =
		/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

	function isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	}

	// function made for handling the api
	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!passwordPattern.test(password)) {
			setPasswordError(
				" Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character."
			);
			return;
		}

		if (!isValidEmail(email)) {
			setEmailError("please enter valid email");
			return;
		}
		setIsSignupClicked(true);

		if (isReCaptchaValue) {
			const requestData = {
				name: name,
				username: userName,
				email: email,
				password: password,
				roles: "l2a_user",
				status: "inactive",
				profileIcon: "",
				keyLogin: false,
			};
			dispatch(updateIsSignup(false));
			dispatch(updateUser(null));
			dispatch(signUpRequest(requestData));
			setIsSignUpSubmitted(true);
		}
	};

	function onChange(value) {
		if (value) {
			setIsReCaptchaValue(true);
		} else {
			setIsReCaptchaValue(false);
		}
	}

	useEffect(() => {
		if (isReCaptchaValue) {
			const requestData = {
				name: name,
				username: userName,
				email: email,

				password: password,
				roles: "l2a_user",
				status: "inactive",
				profileIcon: "",
				keyLogin: false,
			};
			dispatch(updateIsSignup(false));
			dispatch(updateUser(null));
			dispatch(signUpRequest(requestData));
			setIsSignUpSubmitted(true);
			setName("");
			setEmail("");
			setPassword("");
			setUserName("");
		}
	}, [isReCaptchaValue]);

	useEffect(() => {
		if (
			(signUpUser?.status === 201 || signUpUser?.status === 200) &&
			!isSignup
		) {
			getToasterNotifications(signUpUser?.data?.message);
			dispatch(updateIsSignup(true));
			setIsSignUpSubmitted(false);
			setName("");
			setEmail("");
			setPassword("");
			setUserName("");
			dispatch(updateUser(null));
		} else if (
			signUpUser?.response?.status === 400 ||
			signUpUser?.response?.status === 409 ||
			signUpUser?.response?.status === 500
		) {
			getToasterErrors(signUpUser?.response?.data?.message);
			dispatch(updateIsSignup(false));
			dispatch(updateUser(null));
			setIsSignUpSubmitted(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signUpUser, isSignup]);

	const handlePasswordChange = (event) => {
		setPasswordError("");
		const passwordValue = event.target.value;
		setPassword(passwordValue);
		setPasswordError("");
	};

	return (
		<>
			<Layout>
				<div className="container">
					<img
						src="/icons/luv2a.gif"
						className="mx-auto mt-5 d-block w-25 my-auto"
						alt="logo"
					/>
					<div className="d-flex justify-content-center signup-container">
						<form
							onSubmit={handleSubmit}
							className="mt-4 rounded-4 bg-white shadow signup-form-width position-relative"
						>
							{!isSignup && isSignUpSubmitted && (
								<Backdrop
									sx={{ color: "#fff", zIndex: 2 }}
									open={!isSignup && isSignUpSubmitted}
								>
									<div className="d-flex  justify-content-center loaderCenter align-item-center">
										<CircularProgress color="inherit" />
									</div>
								</Backdrop>
							)}
							<h2 className="text-center mt-4 mb-4 fs-4">Sign Up</h2>
							<div className="form-group mb-3 signup-form-width-inputs mx-auto shadow-md">
								<input
									className="w-100 border rounded shadow-sm p-2 "
									name="full_name"
									required
									pattern="\S(.*\S)?"
									title="This field is required and not include white spaces"
									value={name}
									minLength="3"
									type="text"
									id="inputFullName"
									placeholder="Name"
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="form-group mb-3 signup-form-width-inputs mx-auto shadow-md">
								<input
									required
									pattern="\S(.*\S)?"
									title="This field is required and not include white spaces"
									value={userName}
									className="w-100 border rounded p-2 shadow-sm"
									minLength="3"
									type="text"
									id="inputUserName"
									placeholder="Username"
									onChange={(e) => {
										setUserName(e.target.value);
									}}
								/>
							</div>
							<div className="form-group mb-3 signup-form-width-inputs mx-auto shadow-md">
								<input
									type="email"
									required
									pattern="\S(.*\S)?"
									title="This field is required and not include white spaces"
									value={email}
									className="w-100 border rounded p-2 shadow-md"
									name="email"
									id="email"
									placeholder="Email"
									onChange={(e) => {
										setEmail(e.target.value);
										setEmailError("");
									}}
								/>
								{isSignupClicked && emailError && (
									<div className="error-message">{emailError}</div>
								)}
							</div>
							<div className="form-group position-relative mb-3 signup-form-width-inputs mx-auto shadow-md">
								<input
									className="w-100 border rounded p-2 shadow-sm"
									name="password"
									type={isEyeIconClick ? "text" : "password"}
									id="inputPassword"
									value={password}
									required
									placeholder="Password"
									onChange={handlePasswordChange}
									pattern="\S(.*\S)?"
									title="This field is required and not include white spaces"
								/>
								{isEyeIconClick && (
									<span
										className="eye-icon"
										onClick={() => setIsEyeIconClick(false)}
									>
										<FontAwesomeIcon icon={faEye} />
									</span>
								)}
								{!isEyeIconClick && (
									<span
										className="eye-icon"
										onClick={() => setIsEyeIconClick(true)}
									>
										<FontAwesomeIcon icon={faEyeSlash} />
									</span>
								)}
								<span
									className={`font-password   ${
										passwordError && "error-message"
									}`}
								>
									Password must be at least 8 characters long and include at
									least one uppercase letter, one lowercase letter, and one
									special character.
								</span>
							</div>
							<div className="text-center">
								{/*created the signup button*/}
								<button type="submit" className="signup-btn">
									Sign Up
								</button>
							</div>
							<div className="recaptchParent">
								{isSignupClicked && (
									<ReCAPTCHA
										style={{ display: "inline-block" }}
										theme="dark"
										sitekey={process.env.REACT_APP_ReactCaptcha}
										onChange={onChange}
									/>
								)}
							</div>
							<div className="row d-flex justify-content-center">
								<div className="col-lg-4 col-md-8 col-sm-8 signup-link">
									<p className="text-center">
										Already Signed up? Please{" "}
										<Link
											to="/loginUser"
											className="fw-bold text-decoration-none"
										>
											Login!
										</Link>
									</p>
								</div>
							</div>
						</form>
					</div>
				</div>
				{/*displays the advertisement modal*/}
				<AdvertisementModal isView={view} setIsView={setView} />
			</Layout>
		</>
	);
}

export default SignupForm;
