import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/parentLayout/Layout";
import "./privacy.css";

function Privacy() {
	return (
		<Layout>
			<div className="container privacy-wrapper-container p-4">
				{/* heading section  */}
				<div className="row d-flex justify-content-center">
					<h1 className="text-center mt-md-5 mt-0 fw-md-bold  guidline-headings-main">
						Privacy
					</h1>
					<h5 className="text-center  mb-3 fw-normal  privacy-sub-headings">
						Privacy is your right. We’re users too!
					</h5>
					<p className="mt-4 text-justify">
						At this time, L2a is operating in beta mode and is therefore not
						collecting any user data other than email addresses (which enable
						users to create accounts and thereby click balloons and tell their
						stories).
					</p>
					<p>
						As we grow, our Privacy Policy will evolve, and this page will be
						updated. Please know, however, that our sincere aim is to adhere to
						the highest standards for protecting and respecting your privacy.
						After all, we’re users too, and we also prefer that the information
						we share is handled correctly, prudently and with respect.
					</p>
					<p>
						If you have any questions, please{" "}
						<Link to={"/contact-us"}>Contact Us!</Link>
					</p>
				</div>
			</div>
		</Layout>
	);
}

export default Privacy;
