import React from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import "./talentContest.css";

function TalentContestPage() {
	return (
		<Layout>
			<div>
				<div className="container">
					<p className="coming-soon-center fw-bold">
						COMING SOON!&nbsp;{" "}
						<span style={{ fontSize: "3.5rem" }}>🎭🧑‍🎤🎸</span>
					</p>
				</div>
			</div>
		</Layout>
	);
}

export default TalentContestPage;
