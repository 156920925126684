import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SuccessModal from "../modals/successModal";
import AddAsellerModal from "../modals/addAsellerModal";
import LoginModal from "../modals/loginModal";
import { useDispatch, useSelector } from "react-redux";
import {
	getCategory,
	getSelectedCategoryId,
} from "../../redux/reducers/ducks/categoriesDuck";
import {
	getSellerId,
	getSellerName,
	sellerTypeWiseRequest,
	updateReviewType,
} from "../../redux/reducers/ducks/getSellersDuck";
import "./autocomplete.css";
// import {
// 	Backdrop,
// 	CircularProgress,
// 	FormControl,
// 	MenuItem,
// 	Select,
// } from "@mui/material";
import SignUpModal from "../modals/signUpModal";
import { updateReviews } from "../../redux/reducers/ducks/getSellersDuck";
import { verifyReviewResponse } from "../../redux/reducers/ducks/adminReviewDuck";
import { sellerTypeArray } from "../../utils/addSellerHelper";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { appededUrl } from "../../utils/constant";
import { HomeCategoryModal } from "../homeCategoryModal/homeCategoryModal";

const AutoComp = () => {
	const [categoryId, setCategoryId] = useState(null);
	const [showAddASellerModal, setShowAddASellerModal] = React.useState(false);
	const [show, setShow] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [sellers, setSellers] = useState([]);
	const [inputValue, setInputValue] = React.useState(null);
	const [isShowSignInModal, setIsShowSignInModal] = React.useState(false);
	const [showSignUpModal, setSignUpModal] = useState(false);
	const [selectWholeCategory, setSelectedWholeCategory] = useState(null);
	const [addSellerName, setAddSellerName] = useState("");
	// const [selectedSellerType, setSelectedSellerType] = useState(
	// 	sellerTypeArray[0]
	// );

	const dispatch = useDispatch();

	const {
		data,
		value,
		// isIndividualCategoryIdLoading,
		// categoryName,
		// sellerTypeWise,
	} = useSelector((state) => {
		const categories = state?.category?.category?.data?.records;
		const selectedCategory = categories?.find(
			(item) => item.id === state?.category?.selectedCategoryId
		);

		return {
			value: state?.seller?.seller?.data?.records,
			data: state?.category?.category?.data?.records,
			isIndividualCategoryIdLoading:
				state.category.isIndividualCategoryIdLoading,
			categoryData:
				state?.category?.sellersAgainstIndividualCategoryID?.data?.records
					?.sellers,
			categoryName: selectedCategory?.categoryName,
			sellerTypeWise: state?.seller?.sellerTypeWise?.data?.records,
		};
	});

	useEffect(() => {
		dispatch(verifyReviewResponse(null));
	}, []);

	useEffect(() => {
		if (categoryId?.id) {
			dispatch(getSelectedCategoryId(categoryId.id));
			// setSelectedSellerType(sellerTypeArray[0]);
			dispatch(
				sellerTypeWiseRequest({
					id: categoryId.id,
					type: sellerTypeArray[0],
				})
			);
		}
		if (!categoryId?.id) {
			dispatch(getSelectedCategoryId(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId]);

	useEffect(() => {
		if (sellers) {
			setInputValue(sellers[0]);
		}
	}, [sellers]);

	useEffect(() => {
		dispatch(getSelectedCategoryId(null));
		dispatch(getCategory(""));
		setSellers(value);
		dispatch(
			updateReviewType({
				titleId: "",
				balloonType: "All Stories",
				airedOrLoved: "",
				category: null,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filter = createFilterOptions();

	const handleClose = () => {
		setCategoryId(null);
		setInputValue(null);
		setShowAddASellerModal(false);
		setShow(false);
		setSelectedWholeCategory(null);
	};

	useEffect(() => {
		dispatch(updateReviews(null));
	}, []);

	const [addNewSeller, setAddNewSeller] = useState(false);

	useEffect(() => {
		if (addNewSeller) {
			setShowAddASellerModal(true);
			setAddNewSeller(false);
		}
	}, [addNewSeller]);

	return (
		<Container className="text-center">
			<div className="row ">
				<div className="col-lg-3 col-md-0 col-sm-0" />
				<div className="col-lg-3 col-md-6 autoComp-height col-sm-12 textfiled-margin">
					<Autocomplete
						className="inputRounded "
						popupIcon={""}
						sx={{
							"& .MuiOutlinedInput-root": {
								padding: "2px",
								borderRadius: "37px",
							},
							"& .MuiAutocomplete-input": {
								paddingLeft: "17px !important",
							},
						}}
						inputValue={inputValue?.title}
						value={inputValue?.title || null}
						onChange={(e, seller) => {
							if (typeof seller?.sellerName === "string") {
								dispatch(getSellerId(seller?.id));
								dispatch(getSellerName(seller?.sellerName));
								setTimeout(() => {
									setShowAddASellerModal(false);
								});
								window.location.href = appededUrl(
									`/seller-balloon/${seller?.id}`
								);
							} else {
								setInputValue(seller?.inputValue);
							}
						}}
						onKeyDown={(e) => {
							if (e.code === "Enter" && e.target.value) {
								let singleSeller =
									value &&
									value?.filter(
										(val) =>
											val?.sellerName?.toLowerCase() ===
											e.target.value?.toLowerCase()
									);
								if (singleSeller && singleSeller?.length > 0) {
									dispatch(getSellerId(singleSeller[0]?.id));
									dispatch(getSellerName(singleSeller[0]?.sellerName));
									setTimeout(() => {
										setShowAddASellerModal(false);
									});
									window.location.href = appededUrl(
										`/seller-balloon/${singleSeller[0]?.id}`
									);
								} else {
									getToasterNotifications(
										`Please check your spelling or add "${e.target.value}"`
									);
								}
							}
						}}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							if (filtered && filtered?.length > 0) {
								filtered.push({
									inputValue: params.inputValue,
								});
								return filtered;
							} else {
								return [
									{
										add_seller_name: params?.inputValue,
									},
								];
							}
						}}
						options={value && value?.length > 0 ? value : []}
						getOptionLabel={(option) =>
							option.sellerName ? option.sellerName : ""
						}
						renderOption={(props, option) => {
							return (
								<div
									style={{
										display: "flex",
										justifyContent: "start",
									}}
								>
									{option.add_seller_name && !option.id && (
										<div
											key="new-seller"
											style={{ width: "100%", textAlign: "center" }}
											onClick={() => {
												setAddNewSeller(!addNewSeller);
												setAddSellerName(option.add_seller_name);
											}}
											className="add-seller-cursor"
										>
											Add "{`${option?.add_seller_name}`}"
										</div>
									)}
									{option.id && !option.add_seller_name && (
										<div
											{...props}
											key={option.id}
											style={{
												display: "flex",
												justifyContent: "start",
												width: "100%",
												paddingBottom: "8px",
											}}
										>
											<div>{option.sellerName}</div>
										</div>
									)}
								</div>
							);
							// Regular option
						}}
						renderInput={(params) => (
							// created the inputfield for searching
							<div className="autocomplete-search-inputfield auto-textfield">
								<TextField
									className="mb-5"
									{...params}
									placeholder="Search by Sellers"
								/>
							</div>
						)}
					/>
					<AddAsellerModal
						isShow={showAddASellerModal}
						setIsShow={setShowAddASellerModal}
						setShowModal={setShow}
						selectWholeCategory={selectWholeCategory}
						setSelectedWholeCategory={setSelectedWholeCategory}
						IsShowModal={show}
						addSellerName={addSellerName}
						fromHomePage
					/>

					<LoginModal
						isShow={isShowSignInModal}
						setIsShow={setIsShowSignInModal}
						setSignUp={setSignUpModal}
					/>
					<SignUpModal
						isShowSignUpModal={showSignUpModal}
						setIsShowSignUpModal={setSignUpModal}
						setIsLogin={setIsShowSignInModal}
					/>
					<SuccessModal isSuccess={success} isSetSuccess={setSuccess} />
				</div>

				{/*input field for searching the category*/}

				<div className="col-lg-3 autoComp-height col-md-6 col-sm-12 ">
					<Autocomplete
						className="inputRounded "
						popupIcon={""}
						sx={{
							"& .MuiOutlinedInput-root": {
								padding: "2px",
								borderRadius: "37px",
							},
							"& .MuiAutocomplete-input": {
								paddingLeft: "17px !important",
							},
						}}
						value={categoryId}
						onChange={(e, category) => {
							if (category) {
								setShow(true);
								setSelectedWholeCategory(category);
								setCategoryId(category);
								setInputValue(category?.categoryName);
							} else {
								setCategoryId(null);
							}
						}}
						options={
							data && data?.length > 0
								? data?.map((obj) => {
										if (obj.categoryName === "Other B2B") {
											return { ...obj, categoryName: "Other" };
										}
										return obj;
								  })
								: []
						}
						getOptionLabel={(option) => {
							// Value selected with enter, right from the input
							if (option && typeof option?.categoryName === "string") {
								return option?.type === "B2B"
									? `${option?.categoryName} (B2B)`
									: option?.categoryName;
							}
							return "";
						}}
						clearOnBlur={true}
						renderInput={(params) => {
							return (
								// created the inputfield for searching
								<div className="autocomplete-search-inputfield auto-textfield">
									<TextField
										className="mb-5"
										{...params}
										placeholder="Search by Categories"
									/>
								</div>
							);
						}}
					/>
				</div>
				<div className="col-lg-3 col-md-0 col-sm-0" />
				<HomeCategoryModal
					setShowAddASellerModal={setShowAddASellerModal}
					show={show}
					handleClose={handleClose}
					categoryId={categoryId}
				/>
			</div>
		</Container>
	);
};

export default AutoComp;

{
	/* <Modal
show={show}
onHide={handleClose}
size="lg"
aria-labelledby="contained-modal-title-center"
centered
className="seller-list-Modal"
>
<Modal.Header closeButton className="border-0 text-light">
	<div className="d-flex align-items-center flex-md-row flex-column  justify-content-between w-100">
		<h5 className="text-center fw-bold mr-3">{categoryName}</h5>
		<div className="mx-5">
			{sellerTypeWise?.type !== "B2B" ? (
				<FormControl sx={{ minWidth: 120 }}>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={selectedSellerType ? selectedSellerType : ""}
						onChange={(e) => {
							dispatch(
								sellerTypeWiseRequest({
									type: e.target.value,
									id: categoryId.id,
								})
							);
							setSelectedSellerType(e.target.value);
						}}
						className="selected-values"
						placeholder="Select Seller Type"
					>
						{sellerTypeArray.slice(0, 3).map((elements, index) => {
							return (
								<MenuItem
									key={index}
									value={elements}
									className={`${
										elements === selectedSellerType && "d-none"
									}`}
									onClick={() => setSelectedSellerType(elements)}
								>
									{elements}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			) : (
				<h5 className="text-right fw-bold mr-3 mt-1"> B2B</h5>
			)}
		</div>
	</div>
</Modal.Header>
<div className="popup-height">
	<div className="mb-5">
		{isIndividualCategoryIdLoading ? (
			<>
				<Backdrop
					sx={{ color: "#fff", zIndex: 2 }}
					open={isIndividualCategoryIdLoading}
				>
					<div className="d-flex popup-height justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			</>
		) : (
			<>
				<div className="row mt-3">
					{sellerTypeWise?.sellers?.length > 0 ? (
						sellerTypeWise?.sellers?.map((item, index) => {
							if (index % 2 !== 0) {
								return (
									<>
										<div
											key={item?.id}
											className="col-lg-5 col-md-5 d-flex"
											onClick={() => dispatch(getSellerId(item.id))}
										>
											<Link
												to={`/seller-balloon/${item.id}`}
												className="text-decoration-none links"
											>
												{item?.sellerName}
											</Link>
										</div>
										{/* <div className="col-1" /> */
}
// 									</>
// 								);
// 							} else {
// 								return (
// 									<>
// 										<div className="col-lg-2 col-md-2" />
// 										<div
// 											key={item?.id}
// 											className="col-lg-5 col-md-5 d-flex"
// 											onClick={() => dispatch(getSellerId(item.id))}
// 										>
// 											<Link
// 												to={`/seller-balloon/${item.id}`}
// 												className="text-decoration-none links"
// 											>
// 												{item?.sellerName}
// 											</Link>
// 										</div>
// 									</>
// 								);
// 							}
// 						})
// 					) : (
// 						<div className="no-seller">
// 							As of yet, there are no{" "}
// 							{selectedSellerType === "For U.S. Locals Only!"
// 								? "US LOCAL ONLY"
// 								: selectedSellerType}{" "}
// 							sellers in this category!
// 						</div>
// 					)}
// 				</div>
// 			</>
// 		)}
// 	</div>
// 	<div
// 		onClick={() => setShowAddASellerModal(true)}
// 		className="add-new-seller-Parent mb-3"
// 	>
// 		<button type="button" className="btn add-new-button-sellers">
// 			Add a Seller to this Category
// 		</button>
// 	</div>
// </div>
// </Modal> */}
