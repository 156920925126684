import { Card, Paper, TableContainer } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./weeklyCount.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { weeklyGraphRequest } from "../../../redux/reducers/ducks/adminReviewDuck";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

const WeeklyCount = ({ isGrowth, isAverage }) => {
	const dispatch = useDispatch();

	const { weeklyGraphData } = useSelector((state) => {
		return {
			weeklyGraphData: state?.adminReview?.weeklyGraphData,
		};
	});

	const [startDate, setStartDate] = useState(new Date());

	const [signUpName, setSignUpName] = useState("Total Weekly SignUps");

	function getFormattedWeek(date, weekNumber) {
		const formattedMonthDate = moment(date).format("MMM: DD");
		return `Week ${weekNumber},Sunday,${formattedMonthDate}`;
	}

	let weeklyData = weeklyGraphData?.weeklyResult;

	let weeksNames = [];

	let weeksValues = [];

	for (const date in weeklyData) {
		// eslint-disable-next-line
		if (weeklyData.hasOwnProperty(date)) {
			if (weeklyData[date]) {
				const formatedDateInWeek = getFormattedWeek(
					date,
					weeklyData[date]?.weekNumber
				);
				weeksNames.push(formatedDateInWeek);
				if (isAverage) {
					weeksValues.push(Math.ceil(weeklyData[date].average * 100));
				} else if (isGrowth) {
					weeksValues.push(weeklyData[date].growthRate);
				} else {
					weeksValues.push(weeklyData[date].count);
				}
			}
		}
	}

	useEffect(() => {
		if (isAverage) {
			setSignUpName("Average weekly SignUps");
		} else if (isGrowth) {
			setSignUpName("Weekly SignUps Growth");
		} else {
			setSignUpName("Total Weekly SignUps");
		}
	}, [isGrowth, isAverage]);

	useEffect(() => {
		const date = moment(startDate);
		const lastDayMonth = date.endOf("month");
		dispatch(
			weeklyGraphRequest({
				startDate: moment(startDate).format("YYYY-MM-DD"),
				endDate: moment(lastDayMonth).format("YYYY-MM-DD"),
			})
		);
	}, [startDate]);

	const chartData = {
		chart: {
			height: 350,
			type: "area",
			toolbar: {
				show: true,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: true,
					selection: true,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: true,
					customIcons: [],
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 90, 100],
			},
		},
		colors: ["#21144c", "#F87161", "#009E7E"],
		legend: {
			position: "top",
		},
		series: [
			{
				name: signUpName,
				data: weeksValues,
			},
		],
		xaxis: {
			categories: weeksNames,
			offsetX: 13,
			offsetY: 2,
			labels: {
				style: {
					cssClass: "apexcharts-xaxis-label",
				},
			},
		},
	};

	return (
		<>
			<div className="WeekyCardParent">
				<div>
					<div className="mb-3  signUp-names-bold text-center">
						{signUpName}
					</div>
					<Card className="card-content">
						<div className="parent-cat" />
						<Paper sx={{ width: "100%", overflow: "hidden" }}>
							<TableContainer>
								<div className="">
									<div className="mt-3 datePoicker-parent">
										<div className="weekly mb-3" />
										<div className="datePicker-child">
											<div>
												<ReactDatePicker
													className="datePicker-input"
													dateFormat="MMM-yyyy"
													maxDate={new Date()}
													selected={startDate}
													showMonthYearPicker
													dropdownMode="select"
													onChange={(date) => setStartDate(date)}
												/>
											</div>
										</div>
									</div>
									<div className="p-3">
										<ReactApexChart
											options={chartData}
											series={chartData.series}
											type="area"
											height={350}
										/>
									</div>
								</div>
							</TableContainer>
						</Paper>
					</Card>
				</div>
			</div>
		</>
	);
};

export default WeeklyCount;
