import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { getAllUsersResponse } from "../../../redux/reducers/ducks/userDuck";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import { updateClickedUser } from "../../../redux/reducers/ducks/adminReviewDuck";

export default function AllUser({ setUserID, isLoading, setPage }) {
	const [user, setUser] = useState([]);
	const dispatch = useDispatch();
	const adminUser = useSelector((state) => state?.adminUser?.allUserData);
	const clickUser = useSelector((state) => state?.adminReview?.clickUser);
	useEffect(() => {
		dispatch(getAllUsersResponse());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [preSelectedUser, setPreSelectedUser] = useState();

	useEffect(() => {
		setUser(adminUser);
	}, [adminUser]);

	useEffect(() => {
		if (clickUser) {
			const selecteduser = adminUser?.filter(
				(singleUser) => singleUser?.id === clickUser
			);
			if (selecteduser && selecteduser?.length > 0) {
				setPreSelectedUser(selecteduser[0]);
			}
		} else if (!preSelectedUser) {
			setPreSelectedUser(null);
		}
	}, [clickUser, preSelectedUser]);

	return (
		<>
			<div className="">
				<Autocomplete
					id="free-solo-2-demo"
					className=" inputField rounded w-100 text-start"
					popupIcon={""}
					options={
						user && user?.length > 0
							? user?.filter((singleUser) => singleUser?.roles === "l2a_user")
							: []
					}
					renderOption={(props, item) => (
						<li {...props} key={item?.id}>
							{" "}
							{item?.username}{" "}
						</li>
					)}
					value={preSelectedUser ? preSelectedUser : null}
					onChange={(e, user) => {
						if (user) {
							isLoading(true);
							dispatch(handleAdminStatus(false));
							setPage(0);
							setUserID(user?.id);
							setPreSelectedUser(user);
						} else {
							setUserID("");
							setPreSelectedUser(null);
							dispatch(updateClickedUser(null));
						}
					}}
					sx={{
						".MuiOutlinedInput-root": {
							padding: "3px",
						},
						"#free-solo-2-demo": {
							textAlign: "start",
							marginLeft: "1rem",
						},
					}}
					getOptionLabel={(option) => option?.username}
					renderInput={(params) => (
						<div className="autocomplete-search-inputfield">
							<TextField
								className="text-center seller-textfield"
								sx={{
									"#free-solo-2-demo-label": {
										paddingLeft: "7px",
									},
								}}
								{...params}
								placeholder="User Name"
								InputProps={{
									...params.InputProps,
								}}
							/>
						</div>
					)}
				/>
			</div>
		</>
	);
}
