import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
	editCategoryName,
	getAdminCategory,
	updateCreateCategory,
} from "../../../redux/reducers/ducks/categoriesDuck";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./popup.css";
import { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";

export default function SimpleDialog(props) {
	const { open, openDialog, page, setIsChangeCategoryData, categoryName } =
		props;
	const handleClose = () => {
		openDialog({ isOpen: false });
	};
	const [isSubmittedCategory, setIsSubmittedCategory] = useState(false);

	const { newCategoryData } = useSelector((state) => {
		return {
			newCategoryData: state?.category?.newCategoryData,
		};
	});
	const dispatch = useDispatch();

	const handleSubmitCategory = (e) => {
		dispatch(updateCreateCategory(null));
		const payloadData = {
			id: open.id,
			categoryName: open.categoryName,
		};
		// Update api
		if (!open.categoryName) {
			openDialog({
				...open,
				isOpen: true,
			});
		}
		if (open.categoryName) {
			e.preventDefault();
			dispatch(editCategoryName(payloadData));
			setIsSubmittedCategory(true);
			setIsChangeCategoryData(true);
			setTimeout(() => {
				const requestData = {
					page: page + 1,
					categoryName: categoryName,
				};
				dispatch(getAdminCategory(requestData));
			}, 2000);
		}
	};

	useEffect(() => {
		if (newCategoryData?.status === 200) {
			openDialog({
				...open,
				isOpen: false,
			});
			getToasterNotifications(newCategoryData?.data?.message);
			dispatch(updateCreateCategory(null));
			setIsSubmittedCategory(false);
		}
		if (newCategoryData?.response?.data?.statusCode === 409) {
			getToasterErrors(newCategoryData?.response?.data?.message);
			openDialog({
				...open,
				isOpen: false,
			});
			dispatch(updateCreateCategory(null));
			setIsSubmittedCategory(false);
		}
	}, [newCategoryData]);

	function BootstrapDialogTitle(props) {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	}

	return (
		<div>
			<Dialog
				open={open.isOpen}
				onClose={handleClose}
				fullWidth
				aria-labelledby="draggable-dialog-title"
			>
				{isSubmittedCategory && (
					<Backdrop
						sx={{ color: "#fff", zIndex: 2 }}
						open={isSubmittedCategory}
					>
						<div className="d-flex  loaderCenter justify-content-center align-item-center">
							<CircularProgress color="inherit" />
						</div>
					</Backdrop>
				)}
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				/>
				<div className="d-flex justify-content-center">
					<div className="rounded-4 w-75 mb-3 mt-1">
						<h5 className="text-center mt-3 fw-bold">Update Category</h5>
						<div className="p-3 mb-4 text-center">
							<form>
								<input
									className="inputField w-100 text-center category-edit"
									value={open.categoryName}
									onChange={(event) => {
										openDialog({
											...open,
											categoryName: event.target.value,
										});
									}}
									required
								/>
								<div className="text-center mt-3">
									<button
										onClick={handleSubmitCategory}
										type="submit"
										className="btn addCategoryBtn px-3 py-2"
									>
										Save Changes
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
