import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	category: null,
	sellersAgainstCategoryID: null,
	sellersAgainstIndividualCategoryID: null,
	isIndividualCategoryIdLoading: true,
	delCategory: null,
	selectedCategoryId: null,
	latestReview: null,
	editCategory: null,
	createCategory: null,
	newCategoryData: null,
	reviewLoader: false,
	allCategorySellersData: null,
	rulesRef: null,
	balloonPageRef: null,
};

const categorySlice = createSlice({
	name: "main",
	initialState: INITIAL_STATE,
	reducers: {
		getCategory: (state) => state,
		getAdminCategory: (state) => state,
		getSellersDataByCategoryId: (state) => state,
		getSellersDataByIndividualCategoryID: (state) => state,
		deletedCategory: (state) => state,
		categorySellersRequest: (state) => state,
		updateCategoryAdmin: (state) => state,
		updaterulesRef(state, { payload }) {
			return {
				...state,
				rulesRef: payload,
			};
		},
		updateBalloonPageRef(state, { payload }) {
			return {
				...state,
				balloonPageRef: payload,
			};
		},
		updateCategory(state, { payload }) {
			return {
				...state,
				category: payload,
			};
		},
		categorySellersResponse(state, { payload }) {
			return {
				...state,
				allCategorySellersData: payload,
			};
		},
		getSellersOnBaseOfCategoryId(state, { payload }) {
			return {
				...state,
				sellersAgainstCategoryID: payload,
			};
		},
		getSellersByCategoryID(state, { payload }) {
			return {
				...state,
				sellersAgainstIndividualCategoryID: payload,
			};
		},
		updateIndividualCategoryIdLoading(state, { payload }) {
			return {
				...state,
				isIndividualCategoryIdLoading: payload,
			};
		},
		deletedCategoryData(state, { payload }) {
			return {
				...state,
				delCategory: payload,
			};
		},
		getSelectedCategoryId(state, { payload }) {
			return {
				...state,
				selectedCategoryId: payload,
			};
		},
		latestReviewData(state, { payload }) {
			return {
				...state,
				latestReview: payload,
			};
		},
		editCategoryName(state, { payload }) {
			return {
				...state,
				editCategory: payload,
			};
		},
		categoryCreated(state, { payload }) {
			return {
				...state,
				createCategory: payload,
			};
		},
		updateCreateCategory(state, { payload }) {
			return {
				...state,
				newCategoryData: payload,
			};
		},
		getCustomerReviewLoader(state, { payload }) {
			return {
				...state,
				reviewLoader: payload,
			};
		},
	},
});

export const {
	getCustomerReviewLoader,
	getCategory,
	updateIndividualCategoryIdLoading,
	updateCategory,
	getSellersDataByCategoryId,
	getSellersOnBaseOfCategoryId,
	getSellersDataByIndividualCategoryID,
	getSellersByCategoryID,
	deletedCategory,
	deletedCategoryData,
	getSelectedCategoryId,
	latestReviewData,
	updateCategoryAdmin,
	editCategoryName,
	categoryCreated,
	getAdminCategory,
	updateCreateCategory,
	categorySellersRequest,
	categorySellersResponse,
	updaterulesRef,
	updateBalloonPageRef,
} = categorySlice.actions;

export default categorySlice.reducer;
