import { useState, useEffect } from "react";
import axios from "axios";

const useFetchData = (url, token) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.get(url, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setData(response.data);
				setError(null);
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [url, token]);

	return { data, loading, error };
};

export default useFetchData;
