import { put, call, takeLatest } from "redux-saga/effects";
import {
	authRefresh,
	changePasswordApi,
	forgotPasswordApi,
	loginApi,
	signupApi,
	userActiveApi,
	variicationPasswordApi,
} from "../../../api/authorizationApi";
import {
	changeRequest,
	forgotRequest,
	loginRequest,
	loginResponse,
	refreshTokenResponse,
	signUpRequest,
	updateLoginUser,
	updatePassword,
	updatePasswordToken,
	updateToken,
	updateTokenActivation,
	updateTokenVarification,
	updateUser,
	VarifiedPassword,
} from "../../reducers/ducks/autorizeDuck";
import { activeLoginUser } from "../../reducers/ducks/getSellersDuck";

export function* signUp({ payload }) {
	try {
		const data = yield call(signupApi, payload);
		yield put(updateUser(data));
	} catch (error) {
		return error;
	}
}

export function* login({ payload }) {
	try {
		const data = yield call(loginApi, payload);
		yield put(updateLoginUser(data));
		yield put(loginResponse(data));
	} catch (error) {
		return error;
	}
}

export function* forgotPassword({ payload }) {
	try {
		const data = yield call(forgotPasswordApi, payload);
		yield put(updatePasswordToken(data));
	} catch (error) {
		return error;
	}
}

export function* changePassword({ payload }) {
	try {
		let data = yield call(changePasswordApi, payload);
		yield put(updatePassword(data));
	} catch (err) {
		return err;
	}
}

export function* userActiveSaga({ payload }) {
	try {
		const data = yield call(userActiveApi, payload);
		yield put(updateTokenActivation(data));
	} catch (error) {
		return error;
	}
}
export function* authRefrechToken() {
	const token = localStorage.getItem("token");
	const adminToken = localStorage.getItem("adminToken");
	try {
		const data = yield call(authRefresh);

		if (data) {
			yield put(updateToken(data));
			if (token) {
				localStorage.setItem("token", data?.data?.refresh_token);
			} else if (adminToken) {
				localStorage.setItem("adminToken", data?.data?.refresh_token);
			}
		}
	} catch (error) {
		yield put(updateToken(error));
		return error;
	}
}
export function* varificationPassword({ payload }) {
	try {
		const data = yield call(variicationPasswordApi, payload);
		yield put(updateTokenVarification(data));
	} catch (error) {
		return error;
	}
}
export function* watchAuthorizeSagas() {
	yield takeLatest(signUpRequest.type, signUp);
	yield takeLatest(loginRequest.type, login);
	yield takeLatest(forgotRequest.type, forgotPassword);
	yield takeLatest(changeRequest.type, changePassword);
	yield takeLatest(activeLoginUser.type, userActiveSaga);
	yield takeLatest(refreshTokenResponse.type, authRefrechToken);
	yield takeLatest(VarifiedPassword.type, varificationPassword);
}
