import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	loginRequest,
	refreshTokenResponse,
	updateIsLogin,
	updateLoginUser,
} from "../../../redux/reducers/ducks/autorizeDuck";
import "./adminLogin.css";
import { Backdrop, CircularProgress } from "@mui/material";
import { getToasterNotifications } from "../../../utils/getToasterNotification";
import { getToasterErrors } from "../../../utils/getToasterErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { updateComponentPannel } from "../../../redux/reducers/ducks/adminPannel";

function AdminLogin() {
	const navigate = useNavigate();

	const [show, setShow] = useState(false);
	const [message, setMessage] = useState("");
	const [password, setPassword] = useState("");
	const [isLoginSubmitted, setIsLoginSubmitted] = useState(false);
	const [email, setEmail] = useState("");
	const [isEyeIconClick, setIsEyeIconClick] = useState(false);

	const dispatch = useDispatch();
	const { login, isLogin } = useSelector((state) => {
		return {
			login: state.authorization?.accessToken,
			isLogin: state.authorization?.isLogin,
		};
	});

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	// function made for handling the api
	const handleSubmit = async (event) => {
		const requestData = {
			email: email,
			password: password,
		};
		if (email && password) {
			event.preventDefault();
			dispatch(updateLoginUser(null));
			dispatch(updateIsLogin(false));
			dispatch(loginRequest(requestData));
			setIsLoginSubmitted(true);
		}
	};

	const token = localStorage.getItem("adminToken");

	useEffect(() => {
		if (token) {
			navigate("/admin/admin-portal");
		}
		if (
			(login?.status === 200 || login?.status === 201) &&
			!isLogin &&
			login?.data?.roles === "l2a_admin"
		) {
			getToasterNotifications("Successfully logged in");

			navigate("/admin/admin-portal");
			setIsLoginSubmitted(false);
			dispatch(updateIsLogin(true));
		} else if (
			login?.response?.status === 404 ||
			login?.response?.status === 401
		) {
			setMessage();
			getToasterErrors(login?.response?.data?.message);
			setIsLoginSubmitted(false);
			dispatch(updateIsLogin(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [login, isLogin, token]);

	useEffect(() => {
		if (login?.data?.roles === "l2a_user" && isLoginSubmitted) {
			getToasterNotifications("please login with correct credentials");
			setIsLoginSubmitted(false);
		}
	}, [login, isLoginSubmitted]);

	useEffect(() => {
		if (!token) {
			dispatch(updateLoginUser(null));
		}
	}, [token]);

	useEffect(() => {
		if (login?.data?.roles === "l2a_admin") {
			localStorage.setItem("adminToken", login?.data?.access_token);
			localStorage.setItem("token", "");
		}
		if (login?.data?.roles !== "l2a_admin") {
			localStorage.setItem("adminToken", "");
		}
	}, [login]);

	useEffect(() => {
		dispatch(updateComponentPannel("Signups"));
	}, []);

	return (
		<div>
			<ToastContainer position="top-end" className="p-3">
				<Toast
					onClose={() => setShow(false)}
					bg={"warning"}
					show={show}
					delay={2000}
					autohide
				>
					<Toast.Body>{message}</Toast.Body>
				</Toast>
			</ToastContainer>
			<div className="container">
				<img
					src="/icons/luv2a.gif"
					className="mx-auto mt-5 d-block w-25 my-auto"
					alt="logo"
				/>
				<h6 className="text-center mt-2 fst-italic">
					Please enter your credentials to access the administrative features
				</h6>
				<div className="d-flex justify-content-center admin-login-form">
					<form className="mt-4 rounded-4 bg-white shadow admin-login-container position-relative">
						{!isLogin && isLoginSubmitted && (
							<Backdrop
								sx={{ color: "#fff", zIndex: 2 }}
								open={!isLogin && isLoginSubmitted}
							>
								<div className="d-flex  justify-content-center loaderCenter align-item-center">
									<CircularProgress color="inherit" />
								</div>
							</Backdrop>
						)}
						<h2 className="text-center mt-4 mb-4 fs-4">Login</h2>
						<div className="form-group mb-3 w-75 mx-auto shadow-sm">
							<input
								type="email"
								required
								className="w-100 border rounded p-2 shadow-sm"
								name="email"
								id="inputEmail"
								placeholder="Email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="form-group position-relative mb-3 w-75 mx-auto shadow-sm">
							<input
								className="w-100 border rounded shadow-sm p-2 "
								name="password"
								type={isEyeIconClick ? "text" : "password"}
								required
								id="inputPassword"
								placeholder="Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
							{isEyeIconClick && (
								<span
									className="eye-icon"
									onClick={() => setIsEyeIconClick(false)}
								>
									<FontAwesomeIcon icon={faEye} />
								</span>
							)}
							{!isEyeIconClick && (
								<span
									className="eye-icon"
									onClick={() => setIsEyeIconClick(true)}
								>
									<FontAwesomeIcon icon={faEyeSlash} />
								</span>
							)}
						</div>

						<div className="text-center mb-2">
							{/*created the signup button*/}
							<Button
								type="submit"
								onClick={handleSubmit}
								className="admin-login"
							>
								Login
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default AdminLogin;
