import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import "./bestWriterAndSeller.css";
import { useDispatch, useSelector } from "react-redux";
import { updaterulesRef } from "../../redux/reducers/ducks/categoriesDuck";
import { Link } from "react-router-dom";
// import { InstaStory } from "./instaStoryModal";
// import moment from "moment";
import WriterModal from "../../components/modals/writerModal";
import { WorldModal } from "./worldModal";
import { InstaStory } from "./instaStoryModal";
// import { appededUrl } from "../../utils/constant";
import AddAsellerModal from "../../components/modals/addAsellerModal";
import { alreadyWinnerDataRequest } from "../../redux/reducers/ducks/getSellersDuck";

function BestWriterAndSeller() {
	const [showInstaStory, setShowInstStory] = useState(false);

	const [storyContent, setStoryContent] = useState(null);
	const { rulesRef } = useSelector((state) => {
		return {
			rulesRef: state?.category?.rulesRef,
			alreadyWinnerData: state?.seller?.alreadyWinnerData,
		};
	});
	const [showAddASellerModal, setShowAddASellerModal] = useState(false);

	const [openWorldModal, setOpenWordModal] = useState(false);

	const handleClose = () => {
		setOpenWordModal(false);
	};
	const scrollRef = useRef(null);
	const scrollShirtsRef = useRef(null);
	const scrollIGPostRefRef = useRef(null);
	const [isWriterModal, setIsWriterModal] = useState(false);

	const dispatch = useDispatch();

	// const navigate = useNavigate();

	useEffect(() => {
		if (rulesRef?.isScrollDownToPoints) {
			setTimeout(() => {
				scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
				dispatch(updaterulesRef({ isScrollDownToPoints: false }));
			}, 500);
		} else if (rulesRef?.isScrollDownToShirts) {
			scrollShirtsRef?.current?.scrollIntoView({ behavior: "smooth" });
			dispatch(updaterulesRef({ isScrollDownToShirts: false }));
		} else if (rulesRef?.isScrollDownToIGPicture) {
			setTimeout(() => {
				scrollIGPostRefRef?.current?.scrollIntoView({ behavior: "smooth" });
				dispatch(updaterulesRef({ isScrollDownToIGPicture: false }));
			}, 500);
		}
	}, [rulesRef]);

	useEffect(() => {
		dispatch(alreadyWinnerDataRequest());
	}, []);

	return (
		<Layout>
			{/* heading section  */}
			<div className="container bestwriterandseller-wrapper-container mt-5 p-4">
				<div className="text-center fw-bold guidline-headings ">
					Writers Only
				</div>
				<h5 className="text-center  mb-3 fw-normal seller-ins-sub-headings">
					<Link to="#" onClick={() => setOpenWordModal(true)}>
						The Power of Writers
					</Link>
				</h5>

				<h1 className="text-center fw-bold mt-5 fw-normal ">
					COMING SOON! <span style={{ fontSize: "1.5rem" }}>🎉📓🖊️</span>
				</h1>
				{/* <h3 className="text-center fw-bold fw-normal "></h3> */}
				{/* <div className="row">
					<div>
						<h4
							ref={scrollShirtsRef}
							className="text-center black mb-5 winner-rules-margin  winner-cut-heading"
						>
							Winners receive cute Canvas Brand T-Shirts or Dad Hats!
						</h4>
						<div className="flex text-center mt-4">
							<div className="flex justify-center">
								<img
									className="best-shirt-width me-md-3 me-0 "
									src="/icons/best-writer-icons/black-shirt-image.png"
									alt="white shirt"
								/>
								<img
									className="best-shirt-width "
									src="/icons/best-writer-icons/white-shirt-image.png"
									alt="black shirt"
								/>
								{/* todo: we need to change work on classes to work on both mobile and laptop /}

								<img
									className="best-shirt-width  ms-md-3 ms-0 "
									src="/icons/best-writer-icons/MixCollage.png"
									alt="white shirt"
								/>
							</div>
						</div>
					</div>
					<div>
						<h1
							className="text-center fw-bold contest-rules-margin"
							ref={scrollRef}
						>
							Contest Rules
						</h1>
						<h5 className="text-center mb-3 fw-normal seller-ins-sub-headings">
							Winners are selected from reviews that meet the following
							criteria:
						</h5>
						<div className="row">
							{/* <div className="col-lg-1 col-0"></div> /}
							<div className="col-lg-12 col-md-12">
								<ul className="">
									<li className="p-2">
										Their review respects the the L2a{" "}
										<Link
											to="/guideline"
											className="text-decoration-none story-link"
										>
											Guidelines! 🙏
										</Link>{" "}
									</li>
									<li className="p-2">
										Their review expresses the true thoughts, feelings and
										perspectives of the writer.
									</li>
									<li className="p-2">
										Their review received 5 or more nominations by other users.
									</li>
								</ul>
							</div>
						</div>

						<h5 className="mt-3 fw-normal seller-ins-sub-headings">
							👍 Tips for writing positive reviews:
						</h5>
						<p className={"mx-5"}>
							Don't hold yourself back! Go ahead and make your positive reviews
							as colorful as you like! Embellishment is fun and welcomed. The
							reason? We all get a lift when we read good stories about good
							sellers. It's a "spreading the joy" kind of thing, and L2a loves
							to spread the word, and the joy, about good sellers. :)
						</p>

						<h5 className=" mt-3 fw-normal seller-ins-sub-headings">
							👍 Tips for writing negative reviews:
						</h5>
						<p className={"mx-5 mb-4"}>
							Oftentimes sellers are unaware that they've created a negative
							experience for you. L2a suggests that after you air/vent your
							grievance, you might consider offering the seller some advice.
							That's right. You, the customer (even of a humongous corporation),
							are in the position to tell the seller what they got "wrong" and
							what they need to do to get it right.
						</p>
					</div>
					<h1 className="text-center fw-bold contest-rules-margin my-4 mt-5 mb-4">
						L2a's Favorite Review Writers!
					</h1>
					<div
						ref={scrollIGPostRefRef}
						className="row instgram-row mx-0 px-0 insta-parent-div"
					>
						{alreadyWinnerData &&
							alreadyWinnerData?.length > 0 &&
							alreadyWinnerData.map((story) => {
								// const date = moment?.utc(story?.createdAt);
								// const formattedDate = date.format("MMMM DD, YYYY");
								return (
									// index === 0 && (
									<div
										className="col-md-4 insta-imag-col-margins mb-md-2 mb-2 position-relative"
										key={story.id}
									>
										<div className="insta-photo-div">
											<Link
												onClick={() => {
													dispatch(updateBalloonPageRef(true));
													dispatch(
														getSellersDataByCategoryId({
															excludeSeller: story?.seller?.id,
														})
													);
												}}
												to={appededUrl(`/seller-balloon/${story?.seller?.id}`)}
												className="ms-2  insta-inner-div-anchor text-decoration-none text-black"
											>
												<img
													width="30"
													height="30"
													src="/icons/small-icons/book1.png"
													alt="white shirt"
												/>{" "}
												Read Winning Review!
											</Link>
										</div>
										<div className="h-100 mb-2">
											<div className="h-100 mt-5">
												{story?.storyImage ? (
													<div
														style={{
															border: "3px solid black", // Inner border
															outline: "6px solid var(--limegreen)", // Outer border
															outlineOffset: "7px",
															// height: "73%",
														}}
														className="d-flex flex-row mb-1 p-1 "
													>
														<img
															src={story?.storyImage}
															className="w-100"
															alt="logo"
															style={{ objectFit: "cover" }}
														/>
													</div>
												) : (
													<div
														style={{
															border: "3px solid black", // Inner border
															outline: "6px solid var(--limegreen)", // Outer border
															outlineOffset: "7px",
															// height: "73%",
														}}
														className="d-flex flex-row mb-1 p-1 "
													>
														<div className="text-animation-p-div">
															<h2 className="inner-text-animation text-capitalize">
																Winner{" "}
																<span
																	style={{
																		color: "#da72ec",
																		textTransform: "capitalize",
																		fontStyle: "italic",
																	}}
																>
																	{story?.userName}'s
																</span>{" "}
																Photo
																<br /> Coming Soon!
															</h2>
														</div>
													</div>
												)}
												<p className="card-text insta-card-text px-2">
													<span>
														L2a's newest winning writer is{" "}
														<span className="text-capitalize">
															{" "}
															{story?.userName}!{" "}
														</span>
														Find out if{" "}
														<strong>{story?.seller?.sellerName}</strong> was was
														loved❤️! OR not 🥲?
													</span>

													<div className="insta-photo-div">
														<Link
															target="_blank"
															to="https://www.instagram.com/lovetoair/"
															className="ms-0 d-flex insta-inner-div-anchor text-decoration-none text-black"
														>
															<img
																width="30"
																height="30"
																src="/icons/best-writer-icons/insta.png"
																alt="white shirt"
															/>{" "}
															<div className="simple-text-space">
																Follow us on IG
															</div>
														</Link>
													</div>
												</p>
											</div>
										</div>
									</div>
									// )
								);
							})}
						{((alreadyWinnerData && alreadyWinnerData?.length <= 1) ||
							!alreadyWinnerData) && (
							<div className="insta-imag-col-margins col-md-4 mb-md-2 mb-2 position-relative">
								<div className="insta-photo-div">
									<Link className="ms-2  insta-inner-div-anchor text-decoration-none text-black">
										<img
											width="30"
											height="30"
											src="/icons/small-icons/book1.png"
											alt="white shirt"
										/>{" "}
										Read Winning Review!
									</Link>
								</div>
								<div className="h-100 mb-2">
									<div className="mt-5">
										<div
											style={{
												border: "3px solid black", // Inner border
												outline: "6px solid var(--limegreen)", // Outer border
												outlineOffset: "7px",
											}}
											className="d-flex flex-row mb-1 p-1 "
										>
											<img
												src="/icons/male1.jpg"
												className="w-100"
												alt="logo"
											/>
										</div>
										<p className="card-text insta-card-text px-2">
											<span
												className="instagram-innerImage rounded w-100"
												dangerouslySetInnerHTML={{
													__html: "Coming Soon",
												}}
											/>
											<div className="insta-photo-div">
												<Link
													target="_blank"
													to="https://www.instagram.com/lovetoair/"
													className="ms-0  d-flex insta-inner-div-anchor text-decoration-none text-black"
												>
													<img
														width="30"
														height="30"
														src="/icons/best-writer-icons/insta.png"
														alt="white shirt"
													/>{" "}
													<div className="simple-text-space">
														{" "}
														Follow us on IG
													</div>
												</Link>
											</div>
										</p>
									</div>
								</div>
							</div>
						)}
						{((alreadyWinnerData && alreadyWinnerData?.length <= 1) ||
							!alreadyWinnerData) && (
							<div className="col-md-4  insta-imag-col-margins mb-md-2 mb-2 position-relative">
								<div className="insta-photo-div">
									<Link className="ms-2  insta-inner-div-anchor text-decoration-none text-black">
										<img
											width="30"
											height="30"
											src="/icons/small-icons/book1.png"
											alt="white shirt"
										/>{" "}
										Read Winning Review!
									</Link>
								</div>
								<div className="h-100 mb-2">
									<div className="mt-5">
										<div
											style={{
												border: "3px solid black", // Inner border
												outline: "6px solid var(--limegreen)", // Outer border
												outlineOffset: "7px",
											}}
											className="d-flex flex-row mb-1 p-1 "
										>
											<img
												src="/icons/female1.jpg"
												className="w-100"
												alt="logo"
											/>
										</div>
										<p className="card-text insta-card-text px-2">
											<span
												className="instagram-innerImage rounded w-100"
												dangerouslySetInnerHTML={{
													__html: "Coming Soon",
												}}
											/>
											<div className="insta-photo-div">
												<Link
													target="_blank"
													to="https://www.instagram.com/lovetoair/"
													className="ms-0 d-flex insta-inner-div-anchor text-decoration-none text-black"
												>
													<img
														width="30"
														height="30"
														src="/icons/best-writer-icons/insta.png"
														alt="white shirt"
													/>{" "}
													<div className="simple-text-space">
														{" "}
														Follow us on IG
													</div>
												</Link>
											</div>
										</p>
									</div>
								</div>
							</div>
						)}
					</div>
											</div> */}
			</div>
			<AddAsellerModal
				isShow={showAddASellerModal}
				setIsShow={setShowAddASellerModal}
				isFromFooter
			/>
			<WorldModal
				setShowAddASellerModal={setShowAddASellerModal}
				openWorldModal={openWorldModal}
				handleClose={handleClose}
			/>
			<WriterModal
				isWriterModal={isWriterModal}
				setIsWriterModal={setIsWriterModal}
			/>
			<InstaStory
				showInstaStory={showInstaStory}
				setShowInstStory={setShowInstStory}
				story={storyContent}
				setStoryContent={setStoryContent}
			/>
		</Layout>
	);
}

export default BestWriterAndSeller;
