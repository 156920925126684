import { Autocomplete, TextField } from "@mui/material";

export const CategoryAutoComplete = ({
	sellerCategories,
	reviews,
	setReviews,
	notFromSellerBallonPage,
}) => {
	const getPreSelectedCategory =
		sellerCategories &&
		sellerCategories?.filter((category) => category?.id === reviews?.categoryId)
			? sellerCategories?.filter(
					(category) => category?.id === reviews?.categoryId
			  )[0]
			: null;

	return (
		<div
			className={`w-100  ${
				!notFromSellerBallonPage ? "px-5 mb-5 mt-4" : "mt-2 mb-2"
			}`}
		>
			<Autocomplete
				className="inputRounded "
				popupIcon={""}
				sx={{
					"& .MuiOutlinedInput-root": {
						padding: "2px",
						borderRadius: "37px",
					},
					"& .MuiAutocomplete-input": {
						paddingLeft: "17px !important",
					},
				}}
				onChange={(e, category) => {
					if (category) {
						setReviews({
							...reviews,
							categoryId: category.id,
						});
					} else {
						setReviews({ ...reviews, categoryId: null });
					}
				}}
				value={getPreSelectedCategory}
				getOptionLabel={(option) =>
					option.categoryName ? option.categoryName : ""
				}
				renderOption={(props, option) => {
					return <div {...props}>{option?.categoryName}</div>;
				}}
				id="combo-box-demo"
				options={
					sellerCategories && sellerCategories?.length > 0
						? sellerCategories
						: []
				}
				renderInput={(params) => (
					// created the inputfield for searching
					<div className="autocomplete-search-inputfield auto-textfield">
						<TextField
							className="mb-5"
							{...params}
							placeholder="Search by Categories"
						/>
					</div>
				)}
			/>
		</div>
	);
};
