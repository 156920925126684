import React, { useState } from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import { Link } from "react-router-dom";
import ReputableModal from "../../components/modals/reputableModal";
import BuyerModal from "../../components/modals/buyerModal";
import SellerModal from "../../components/modals/sellerModal";
import "./aboutUs.css";

function AboutUs() {
	const [reputable, setReputable] = useState(false);
	const [buyer, setBuyer] = useState(false);
	const [seller, setSeller] = useState(false);

	return (
		<Layout>
			<div className="container about-wrapper-container p-4">
				{/* heading section  */}
				<div className="row d-flex justify-content-center">
					<h1 className="text-center mt-5 fw-bold ">About</h1>
					<h5 className="text-center mb-4 fw-bold">
						Welcome, we’re glad you stopped by!
					</h5>
					<h3 className="text-center mt-4 mb-4 fw-bold">What is L2a?</h3>
					<p className="mb-4 text-justify">
						Love to Air is a new kind of buyer/seller portal conceived and built
						just for you, the everyday Buyer. Our top priority is to help you
						find the most loved Sellers for whatever it is you’re looking for,
						and importantly, in as little time as possible.
					</p>
				</div>
				{/*players section*/}
				<div className="row">
					<h3 className="text-center mt-4 fw-bold mb-4">The Players:</h3>
					<p>
						Buyers and Sellers are the only actors on this platform! It's not
						complicated. Anyone who sells you anything qualifies as a Seller and
						can be clicked with one of L2a’s positive or negative emoji
						balloons.
					</p>
					<p>Your Seller can be:</p>
					<ul className="unorder_list">
						<li className="p-2">
							A large national or multinational Corporation -- such as your cell
							phone carrier, credit card company, auto or health insurance
							provider, bank or ISP.
						</li>
						<li className="p-2">
							An Online-Only Seller -- such as an online dating, virtual
							learning, online stock trading, media streaming or other
							membership or subscription-based Seller.
						</li>
						<li className="p-2">
							A Self-Employed Freelancer — such as a graphic artist,
							copy-writer, editor, web designer, programmer or marketer.
						</li>
						<li className="p-2">
							A Solo Entrepreneur, Agent, or Independent Contractor — such as an
							accountant, attorney, tutor, event coordinator, real estate agent,
							independent insurance agent, legal or financial advisor, plumber,
							electrician or landscaper.
						</li>
						<li className="p-2">
							Your Seller can also be one of the more traditionally searched
							businesses -- such as a restaurant, hotel, yoga studio,
							acupuncturist, dentist, doctor, carpet cleaner, dog walker,
							manicurist or auto mechanic.
						</li>
					</ul>
					<p>
						If you don’t find your Seller in our database, you can quickly add
						them via our Add a Seller page, which only requires the Sellers name
						and URL.
					</p>
					{/*why we are here section*/}
					<div className="row">
						<h3 className="text-center mt-4 mb-4 fw-bold">Why We're Here:</h3>
						<p>
							Our purpose is to make your life as a Buyer easier and happier.
							Seriously.
						</p>
						<p>
							If your life is anything like ours, then it’s challenging enough
							without having to endure unfair treatment or frustrating buying
							experiences … especially since each of us make an average of 50+
							purchase and payment transactions each month!
						</p>
						<p>
							By enabling you to quickly spot the Sellers who have the most
							positive balloon clicks, L2a helps you make more informed
							decisions regarding which Sellers you want to give your money to -
							aka pay or transact with.
						</p>
						<p>
							This saves you time (so you can do more of what really matters to
							you).
						</p>
						<p>
							This saves you headaches (so you can feel better and happier!).
						</p>
					</div>
					{/*L2a Community - We’re Helping Each Other section*/}
					<div className="row">
						<h3 className="text-center mt-4 mb-4 fw-bold">
							L2a Community - We’re Helping Each Other:
						</h3>
						<p className="p-2">
							When you click, all your fellow L2a users benefit!
						</p>
						<p className="p-2">
							Your balloon clicks and/or stories give other users glimpses of
							the true nature of Sellers before they engage with them.
						</p>
						<p className="p-2">
							Are they ethical, helpful, time-saving and friendly? A quick quick
							glance at their balloon grid will give you valuable clues.
						</p>
						<p className="p-2">
							Or perhaps they’re unreachable and unresponsive? Maybe they have
							hidden fees, harsh penalties or unfair terms in their policies
							that they didn’t tell you about upfront? Again a quick glance at
							their balloon grid will give you a heads up.
						</p>
						<p className="p-2">
							So what exactly is a Seller balloon grid? It’s a graphic image
							that uses L2a’s six emoji balloons to show the precise sentiment
							its customers (or the public at large) have at any given moment.
							Sellers who have positive balloons higher on the grid have
							customers who are generally happy with them! Sellers who have
							negative balloons higher on the grid have customers who are
							generally not very pleased with them.
						</p>
						<p className="p-2">
							{" "}
							This information makes it possible for users to make more informed
							decisions about which Sellers they want to transact with (and more
							informed decisions lead to happier purchasing experiences!).
						</p>
					</div>
					{/*Our Basic Philosophy section*/}
					<div className="row">
						<h3 className="text-center mt-4 mb-4 fw-bold">
							Our Basic Philosophy:
						</h3>
						<p>
							We believe when Buyers trade their hard-earned money for things
							(literally, for anything) they have a right to feel they've
							received a fair enough deal with a polite enough Seller.
						</p>
						<p>
							In other words, we believe Buyers are entitled to having positive
							transacting experiences as their norm (not as an occasional stroke
							of luck).
						</p>
					</div>
					{/*In Order to air section*/}
					<div className="row">
						<h3 className="text-center mt-4 mb-4 fw-bold">
							In Order to Air, We Ask You to First Love:
						</h3>
						<p>
							L2a values and wants to hear your negative stuff! Really. You can
							complain, criticize, cry and moan when you tell your Seller
							stories – as long as you kindly respect our{" "}
							<Link to={"/guideline"}>Guidelines</Link> for writing them (you
							knew there was a catch!).
						</p>
						<p>
							L2a stays credible by allowing – even welcoming – as many negative
							stories as Buyers want to write (though each Buyer is limited to
							one negative story per Seller).
						</p>
						<p>
							Yet to stay relevant and to be useful, we need to make sure the
							most reputable Sellers get the positive shout-outs they deserve.
							To ensure this, we ask that you click a positive balloon for every
							negative balloon and/or story you tell.
						</p>
						<p>
							For instance, if you click a negative balloon to express your
							frustration over the way your cable provider, for example, handled
							something, we ask that you offset it by clicking a positive
							balloon about another worthy Seller – perhaps about your favorite
							online furniture store, your new TV streaming service or the auto
							finance lender that was helpful in getting you a fair auto loan.
						</p>
						<p>
							Our policy of asking you to click a positive balloon for every
							negative one ensures we will always display way more positive
							balloons than negative ones across the platform in general, and we
							believe this is where the focus should be: on the positive
							experiences with the most trustworthy, friendly and helpful
							Sellers.
						</p>
					</div>
					{/*Our Higher Purpose*/}
					<div className="row">
						<h3 className="text-center mt-4 mb-4 fw-bold">
							Our Higher Purpose:
						</h3>
						<p>
							While it may sound idealistic, L2a’s goal is to help grow the
							number of positive Buyer/Seller experiences (and reduce the number
							of unhappy experiences).
						</p>
						<p>
							Firstly, this will make the lives of more Buyers easier and
							happier, and that's what we're all about.
						</p>
						<p>
							Secondly, it’s possible that a bigger and more far-reaching
							positive effect will occur.
						</p>
						<p>
							When we reward reputable Sellers – by giving them positive
							balloons, by writing about our happy experiences with them, and/or
							by giving them our money – we’re breathing life into their
							existence. We're helping them rise above their competition. We're
							helping them succeed.
						</p>
						<p>
							The end result might be that the most ethical, responsible,
							deserving and reputable Sellers will also be the most successful
							and powerful ones.
						</p>
						<p>
							Think: ethical banks, clean energy companies, reasonably priced
							pharmaceuticals, affordable healthcare, safe chemical companies,
							living-wage paying corporations and much more.
						</p>
						<p>It's possible.</p>
						<p>
							The power to move things in this positive direction lies within
							the Buyers, Your voice really matters, and your balloon clicks
							really add up and send powerful messages to Sellers.
						</p>
					</div>
				</div>
			</div>
			<ReputableModal isReputable={reputable} setIsReputable={setReputable} />
			<BuyerModal isBuyer={buyer} setIsBuyer={setBuyer} />
			<SellerModal isSeller={seller} setIsSeller={setSeller} />
		</Layout>
	);
}

export default AboutUs;
