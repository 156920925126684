import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// import { useState } from "react";

// import { updateCategoryAdmin } from "../../../redux/reducers/ducks/categoriesDuck";
import { useSelector } from "react-redux";
// import SimpleDialog from "./popup";
import Card from "@mui/material/Card";

// import AddCategory from "./addCategory";
import "./talentCompetition.css";

import moment from "moment";

export default function CategoryMainPage() {
	// const [page, setPage] = React.useState(0);
	// const [rowsPerPage, setRowsPerPage] = useState(10);
	// const [isChangeCategoryData, setIsChangeCategoryData] = useState(false);

	// const { isAdminStatus, adminReviewLoading } = useSelector((state) => {
	// 	return {
	// 		isAdminStatus: state?.seller?.isAdminChangeStatus,
	// 		adminReviewLoading: state?.adminReview?.adminReviewLoading,
	// 	};
	// });

	// const dispatch = useDispatch();

	const { categories } = useSelector((state) => {
		return {
			categories: state?.category?.category?.data,
		};
	});

	const columns = [
		{ id: "1", label: "User Name", minWidth: 130 },
		{ id: "2", label: "Created At", minWidth: 100 },
		{ id: "3", label: "Video", minWidth: 100 },
		{ id: "4", label: "Ballon", minWidth: 100 },
	];

	// const handleStatusChange = (event, Id) => {
	// 	setIsChangeCategoryData(true);
	// 	const requestData = {
	// 		categoryId: Id,
	// 		approvedByAdmin: event.target.value,
	// 		isListing: true,
	// 	};
	// 	dispatch(updateCategoryAdmin(requestData));
	// };

	// React.useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, [page]);

	// useEffect(() => {
	// 	if (isAdminStatus) {
	// 		const requestData = {
	// 			page: page + 1,
	// 			categoryName: category,
	// 			pageSize: rowsPerPage,
	// 		};
	// 		dispatch(getAdminCategory(requestData));
	// 		setIsChangeCategoryData(false);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isAdminStatus, page, dispatch, rowsPerPage]);

	// const handleRowsPerPage = (e) => {
	// 	setRowsPerPage(e.target.value);
	// 	setPage(0);
	// };

	return (
		<div className="cardParent">
			{/* {(isChangeCategoryData || adminReviewLoading) && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 100 }}
					open={isChangeCategoryData || adminReviewLoading}
				>
					<div className="d-ffAlex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)} */}
			<div>
				<Card className="card-content">
					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{categories?.records?.length > 0 ? (
										categories?.records?.map((cat) => {
											const date = moment?.utc(cat?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");

											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={cat?.id}
												>
													<TableCell>{cat.categoryName}</TableCell>
													<TableCell>{formattedDate}</TableCell>
													<TableCell>{cat?.type}</TableCell>
													{/* <TableCell sx={{ whiteSpace: "noWrap" }}>
														<EditIcon
															onClick={() =>
																setOpenDialog({
																	isOpen: true,
																	id: cat.id,
																	categoryName: cat.categoryName,
																})
															}
															className="text-primary admin-category-edit mx-3 category-panel-cursor"
														/>{" "}
													</TableCell> */}
													{/* <TableCell>
														<FormControl sx={{ minWidth: 120 }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={cat?.approvedByAdmin}
																onChange={(e) => {
																	handleStatusChange(e, cat.id);
																}}
																className={`selectStatuses ${getProperClass(
																	cat?.approvedByAdmin
																)}`}
																placeholder="Select status"
															>
																<MenuItem value="pending" className="d-none">
																	Pending
																</MenuItem>
																<MenuItem value="rejected">Reject</MenuItem>
																<MenuItem value="approved">Approve</MenuItem>
															</Select>
														</FormControl>
													</TableCell> */}
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No Category exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Card>
			</div>
		</div>
	);
}
