import { call, put, takeLatest } from "redux-saga/effects";
import {
	allSeededDataRequest,
	createSeededDataRequest,
	createSeededDataResponse,
	getAllSeededDataResponse,
	updateSeededDataRequest,
	uploadDataRequest,
	// updateSeededDataResponse,
} from "../../reducers/ducks/seededDuck";
import {
	createSeededDataApi,
	getAllSeededData,
	updateSeededDataApi,
	uploadVedioDataApi,
} from "../../../api/seededApi";
import { getToasterErrors } from "../../../utils/getToasterErrors";

export function* getSeededData({ payload }) {
	try {
		const data = yield call(getAllSeededData, payload);
		yield put(getAllSeededDataResponse(data));
	} catch (error) {
		return error;
	}
}

export function* createSeededData({ payload }) {
	try {
		const data = yield call(createSeededDataApi, payload);
		yield put(createSeededDataResponse(data));

		if (data) {
			const requestData = {
				page: 1,
				pageSize: 10,
			};
			yield put(
				allSeededDataRequest({
					requestData,
				})
			);
		}
	} catch (error) {
		getToasterErrors(error?.response?.data?.message);
		return error;
	}
}

export function* updateSeededData({ payload }) {
	try {
		const data = yield call(updateSeededDataApi, payload);
		yield put(createSeededDataResponse(data));

		const requestData = {
			page: 1,
			pageSize: 10,
		};
		if (data) {
			yield put(
				allSeededDataRequest({
					requestData,
				})
			);
		}
	} catch (error) {
		return error;
	}
}

export function* uploadVedioSaga({ payload }) {
	console.log("payload333", payload);
	try {
		const data = yield call(uploadVedioDataApi, payload);
		yield put(createSeededDataResponse(data));
	} catch (error) {
		getToasterErrors(error?.response?.data?.message);
		return error;
	}
}
export function* watchSeededSagas() {
	yield takeLatest(allSeededDataRequest.type, getSeededData);
	yield takeLatest(createSeededDataRequest.type, createSeededData);
	yield takeLatest(updateSeededDataRequest.type, updateSeededData);
	yield takeLatest(uploadDataRequest.type, uploadVedioSaga);
}
