import React from "react";
import { Modal } from "react-bootstrap";

function WriterModal({ isWriterModal, setIsWriterModal }) {
	const handleCancel = () => {
		setIsWriterModal(false);
	};
	return (
		<Modal
			show={isWriterModal}
			onHide={handleCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className="border-0" />
			<div>
				<div className="container mb-5"></div>
			</div>
		</Modal>
	);
}

export default WriterModal;
