import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";

import {
	getAdminCategory,
	updateCategoryAdmin,
} from "../../../redux/reducers/ducks/categoriesDuck";
import { useDispatch, useSelector } from "react-redux";
import SimpleDialog from "./popup";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddCategory from "./addCategory";
import "./popup.css";
import { handleAdminStatus } from "../../../redux/reducers/ducks/getSellersDuck";
import CategoriesSearch from "./categorySearch";
import moment from "moment";

export default function CategoryMainPage({ pageName }) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [showDialog, setShowDialog] = useState(false);
	const [isChangeCategoryData, setIsChangeCategoryData] = useState(false);
	const [category, setCategory] = useState("");

	const { isAdminStatus, adminReviewLoading } = useSelector((state) => {
		return {
			isAdminStatus: state?.seller?.isAdminChangeStatus,
			adminReviewLoading: state?.adminReview?.adminReviewLoading,
		};
	});

	const [openDialog, setOpenDialog] = useState({
		isOpen: false,
		id: null,
	});

	const dispatch = useDispatch();
	const handleChangePage = (event, newPage) => {
		setIsChangeCategoryData(true);
		dispatch(handleAdminStatus(false));
		setPage(newPage);
	};

	const { categories } = useSelector((state) => {
		return {
			categories: state?.category?.category?.data,
		};
	});

	const columns = [
		{ id: "name", label: `${pageName} Name`, minWidth: 130 },
		{ id: "code1", label: "Date", minWidth: 100 },
		{ id: "code3", label: "Type", minWidth: 100 },

		{ id: "code2", label: "Actions", minWidth: 100 },
		{ id: "code4", label: "Status", minWidth: 100 },
	];

	const handleStatusChange = (event, Id) => {
		setIsChangeCategoryData(true);
		const requestData = {
			categoryId: Id,
			approvedByAdmin: event.target.value,
			isListing: true,
		};
		dispatch(updateCategoryAdmin(requestData));
	};

	const getProperClass = (approvedByAdmin) => {
		if (approvedByAdmin === "pending") return "cat-pending-color";
		else if (approvedByAdmin === "approved") return "cat-approve-color";
		else return "cat-rejected-color";
	};

	useEffect(() => {
		const requestData = {
			page: page + 1,
			categoryName: category,
		};
		const timeout = setTimeout(() => {
			dispatch(getAdminCategory(requestData));
			setIsChangeCategoryData(false);
		}, 1000);

		return () => clearTimeout(timeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, category]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	useEffect(() => {
		if (isAdminStatus) {
			const requestData = {
				page: page + 1,
				categoryName: category,
				pageSize: rowsPerPage,
			};
			dispatch(getAdminCategory(requestData));
			setIsChangeCategoryData(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdminStatus, page, dispatch, rowsPerPage]);

	const handleRowsPerPage = (e) => {
		setRowsPerPage(e.target.value);
		setPage(0);
	};

	return (
		<div className="cardParent">
			{(isChangeCategoryData || adminReviewLoading) && (
				<Backdrop
					sx={{ color: "#fff", zIndex: 100 }}
					open={isChangeCategoryData || adminReviewLoading}
				>
					<div className="d-flex  loaderCenter justify-content-center align-item-center">
						<CircularProgress color="inherit" />
					</div>
				</Backdrop>
			)}
			<div>
				<Card className="card-content">
					<div className="parent-cat">
						{/* <div>{pageName} Portal</div> */}
						<div
							className="add-cat"
							onClick={() => {
								setShowDialog(true);
							}}
						>
							<Button
								variant="contained"
								sx={{ ml: 1 }}
								className="addCategoryBtn fw-bold"
							>
								Add {pageName}
							</Button>
						</div>
					</div>
					<CategoriesSearch
						isCategory={category}
						setIsCategory={setCategory}
						setPage={setPage}
					/>
					<Paper
						sx={{ width: "100%", overflow: "hidden" }}
						className="bg-white shadow"
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth, fontSize: "22px" }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{categories?.records?.length > 0 ? (
										categories?.records?.map((cat) => {
											const date = moment?.utc(cat?.createdAt);
											const formattedDate = date.format("MMMM DD, YYYY");

											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={cat?.id}
												>
													<TableCell>{cat.categoryName}</TableCell>
													<TableCell>{formattedDate}</TableCell>
													<TableCell>{cat?.type}</TableCell>
													<TableCell sx={{ whiteSpace: "noWrap" }}>
														<EditIcon
															onClick={() =>
																setOpenDialog({
																	isOpen: true,
																	id: cat.id,
																	categoryName: cat.categoryName,
																})
															}
															className="text-primary admin-category-edit mx-3 category-panel-cursor"
														/>{" "}
													</TableCell>
													<TableCell>
														<FormControl sx={{ minWidth: 120 }}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={cat?.approvedByAdmin}
																onChange={(e) => {
																	handleStatusChange(e, cat.id);
																}}
																className={`selectStatuses ${getProperClass(
																	cat?.approvedByAdmin
																)}`}
																placeholder="Select status"
															>
																<MenuItem value="pending" className="d-none">
																	Pending
																</MenuItem>
																<MenuItem value="rejected">Reject</MenuItem>
																<MenuItem value="approved">Approve</MenuItem>
															</Select>
														</FormControl>
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow sx={{ margin: 4, display: "flex" }}>
											<TableCell colSpan={4}>No Category exist</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{categories?.records?.length > 0 && (
							<>
								<TablePagination
									component="div"
									onRowsPerPageChange={handleRowsPerPage}
									rowsPerPage={rowsPerPage}
									count={
										categories?.totalRecords ? categories?.totalRecords : 0
									}
									page={page}
									onPageChange={handleChangePage}
								/>
								<div className="d-flex justify-content-end ps-5 pe-3 py-3">
									Page: {page + 1}
								</div>
							</>
						)}

						<SimpleDialog
							open={openDialog}
							openDialog={setOpenDialog}
							page={page}
							categoryName={category}
							setIsChangeCategoryData={setIsChangeCategoryData}
						/>
						<AddCategory
							showDialog={showDialog}
							isChangeCategoryData={isChangeCategoryData}
							setShowDialog={setShowDialog}
							setIsChangeCategoryData={setIsChangeCategoryData}
						/>
					</Paper>
				</Card>
			</div>
		</div>
	);
}
