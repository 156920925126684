import { token } from "../constants";
import axios from "./Axios";

export const signupApi = async (data) => {
	try {
		let res = await axios.post("auth/signup", data, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const loginApi = async (data) => {
	try {
		let res = await axios.post("auth/login", data, {
			withCredentials: true,
		});
		if (res) {
			localStorage.setItem("token", res?.data?.access_token);
		}
		return res;
	} catch (err) {
		return err;
	}
};

export const forgotPasswordApi = async (data) => {
	try {
		let res = await axios.post("auth/forgotPassword/token", data, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const userActiveApi = async (data) => {
	try {
		let res = await axios.patch("auth/active", data, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const changePasswordApi = async (data) => {
	try {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			withCredentials: true,
		};

		let res = await axios.put("auth/changeProfilePassword", data, config);
		return res;
	} catch (err) {
		return err;
	}
};

export const allUsers = async () => {
	try {
		let res = await axios.get(`auth/users/all_users`, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const latestWinner = async () => {
	try {
		let res = await axios.get(`admin/latest/Winner`, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const authRefresh = async () => {
	let token = localStorage.getItem("token");
	let adminToken = localStorage.getItem("adminToken");
	try {
		let res = await axios.get(`auth/refresh/token`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token ? token : adminToken}`,
			},
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};

export const singleUserById = async (id) => {
	try {
		if (id) {
			return await axios.get(`auth/${id}`, {
				withCredentials: true,
			});
		}
	} catch (err) {
		return err;
	}
};

export const variicationPasswordApi = async (data) => {
	try {
		let res = await axios.post("auth/changePassword/token_verification", data, {
			withCredentials: true,
		});
		return res;
	} catch (err) {
		return err;
	}
};
