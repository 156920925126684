import React, { useState } from "react";
import AutoComp from "../../components/autocomplete/autocomplete";
import Layout from "../../components/layout/parentLayout/Layout";
import "./seller.css";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";

import {
	getBalloon,
	getSellerId,
	getSellers,
	likeDislikeReviewResponse,
	sellerTypeWiseRequest,
	setScrollableRecords,
	updateLoveSellerName,
} from "../../redux/reducers/ducks/getSellersDuck";
import { useDispatch, useSelector } from "react-redux";
import SellersChoice from "../../components/sellersChoice/sellersChoice";
import { CircularProgress } from "@mui/material";
// import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { recentActivitiesRequest } from "../../redux/reducers/ducks/adminReviewDuck";
import InfiniteScroll from "react-infinite-scroll-component";
import ThanksModal from "../../components/modals/thanksModal";
import moment from "moment";
import { refreshTokenResponse } from "../../redux/reducers/ducks/autorizeDuck";
import CountryDialog from "./countryModal";
import AddAsellerModal from "../../components/modals/addAsellerModal";
import { latestWinnerRequest } from "../../redux/reducers/ducks/userDuck";
// import { updaterulesRef } from "../../redux/reducers/ducks/categoriesDuck";
import { getUniqueReviews } from "../../utils/constant";
import L2aYotubeModal from "../../components/youtubModal/youtubModal";
import { HomeCategoryModal } from "../../components/homeCategoryModal/homeCategoryModal";
import { getSelectedCategoryId } from "../../redux/reducers/ducks/categoriesDuck";
import { sellerTypeArray } from "../../utils/addSellerHelper";
// import YouTubeIcon from "@mui/icons-material/YouTube";
import axios from "axios";
const API_KEY = "AIzaSyBgiOVq4_6Kkzsc7M_rDbHssP5LOFIQ6hU";
async function fetchVideoTitle(videoId) {
	const response = await axios.get(
		`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet`
	);
	return response.data.items[0].snippet.title;
}

function Seller() {
	const dispatch = useDispatch();

	const [show, setShow] = useState(false);
	const [isShowCategory, setIsShowCategory] = useState(false);
	const [thanks, setThanks] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [showAddASellerModal, setShowAddASellerModal] = useState(false);
	const [isL2aYoutubeOpen, setIsL2aYoutubeOpen] = useState(false);

	const [categoryId, setCategoryId] = useState();
	const [isOpenCountry, setIsOpenCountry] = useState(false);
	const youtubeDummyVideos = ["_gU4dGlrGis", "VxOXhkLEcyQ"];

	useEffect(() => {
		const fetchTitles = async () => {
			const titles = {};
			for (const videoId of youtubeDummyVideos) {
				titles[videoId] = await fetchVideoTitle(videoId);
			}
		};
		fetchTitles();
	}, []);

	const { recentActivitiesData, scrollableRecords, tokenActivation } =
		useSelector((state) => ({
			recentActivitiesData: state?.adminReview?.recentActivitiesData,
			tokenActivation: state.authorization?.tokenActivation?.data,
			scrollableRecords: state?.seller?.scrollableRecords,
			latestWinner: state?.adminUser?.latestWinner,
		}));

	useEffect(() => {
		dispatch(getBalloon());
		dispatch(latestWinnerRequest());
		dispatch(likeDislikeReviewResponse(null));
	}, [dispatch]);

	// const [closeModal, setCloseModal] = useState(true);
	const handleClose = () => {
		setShow(false);
		dispatch(setScrollableRecords(10));
	};

	const handleCloseCategory = () => {
		setIsShowCategory(false);
	};

	useEffect(() => {
		dispatch(setScrollableRecords(10));
		dispatch(getSellers());
	}, []);

	const fecthMoreActivities = () => {
		setTimeout(() => {
			dispatch(setScrollableRecords(scrollableRecords + 10));
		}, 2000);
	};

	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
	useEffect(() => {
		dispatch(
			recentActivitiesRequest({
				pageSize: scrollableRecords,
				timeZone: timeZone,
			})
		);
	}, [scrollableRecords]);

	useEffect(() => {
		dispatch(refreshTokenResponse());
	}, []);

	useEffect(() => {
		if (tokenActivation?.isReview) {
			dispatch(updateLoveSellerName(tokenActivation?.sellerName));
			setThanks(true);
		}
	}, [tokenActivation]);

	useEffect(() => {
		if (selectedCountry) {
			setShowAddASellerModal(true);
		}
	}, [selectedCountry]);

	useEffect(() => {
		if (categoryId) {
			dispatch(getSelectedCategoryId(categoryId));
			// setSelectedSellerType(sellerTypeArray[0]);
			dispatch(
				sellerTypeWiseRequest({
					id: categoryId,
					type: sellerTypeArray[0],
				})
			);
		}
		if (!categoryId) {
			dispatch(getSelectedCategoryId(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId]);

	return (
		<Layout>
			<div className="container wrapper-seller-container">
				<div className="d-flex mt-5 justify-content-between px-4">
					<div></div>
					<Link
						style={{
							"text-align-last": "center",
						}}
						to="/talent-competition"
					>
						<div
							style={{
								fontSize: "1.1rem",
								marginBottom: "1rem",
								marginTop: "1rem",
								textAlign: "center",
							}}
							to={"/displayYoutubeVideos"}
						>
							Love to Air Talent Contest
						</div>
						{/* <img
							style={{ width: "120px", height: "80px" }}
							src="/icons/contest.svg"
						/> */}
					</Link>
				</div>
				<img
					src="/icons/luv2a.gif"
					className="mx-auto mt-3 d-block my-auto img-width"
					alt="logo"
				/>
				<div className="home-inial-text text-center mx-auto mt-0">
					<h5 className="text-center home-text mb-4">
						If you want to air, you have to love!
					</h5>
				</div>
				<div>
					<div className="row  auto-seller-parent mx-auto d-flex justify-content-center input-group">
						<AutoComp />
					</div>
					<SellersChoice />
					<div
						className="d-flex justify-content-center mt-1"
						style={{
							fontSize: "1.2rem",
						}}
					>
						<Link
							className="text-decoration-none"
							onClick={() => setShow(true)}
						>
							See Latest Activity
						</Link>
					</div>
					<div
						className="d-flex justify-content-center mt-1"
						style={{
							fontSize: "1rem",
						}}
					>
						<Link
							className="text-decoration-none"
							onClick={() => setIsOpenCountry(true)}
						>
							Not from the U.S.?
						</Link>
					</div>
					{/* <Link
						style={{
							"text-align-last": "center",
						}}
						onClick={() => setIsL2aYoutubeOpen(true)}
						className="mb-3"
						to={"/displayYoutubeVideos"}
					>
						<img
							style={{ width: "80px", height: "60px" }}
							src="/icons/Thumbnail.jpg"
						/>
						<br></br>
						<div
							style={{
								fontSize: "1.1rem",
								marginBottom: "1rem",
								marginTop: "1rem",
								textAlign: "center",
							}}
							to={"/displayYoutubeVideos"}
						>
							<div
								className="d-flex justify-content-center mt-3"
								style={{ height: "150px" }}
							>
								<div className="d-flex flex-col">
									<div className="d-flex justify-content-center position-relative">
										<img
											src={`http://img.youtube.com/vi/${youtubeDummyVideos[0]}/0.jpg`}
											alt="thumbnail"
											className={
												"d-flex justify-content-center video-thumbnail rounded-4 col-12"
											}
										/>
										<div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center bg-black bg-opacity-50 opacity-0 hover-overlay rounded-4">
											<YouTubeIcon sx={{ fontSize: "70px", color: "red" }} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</Link> */}
					<Modal
						show={show}
						// style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)" }}
						onHide={handleClose}
						size="lg"
						aria-labelledby="contained-modal-title-center"
						centered
						className="seller-list-Modal"
					>
						<div className="recent-modal">
							<Modal.Header closeButton className="border-0 text-light">
								{/* Recent Activity */}
							</Modal.Header>
							<div className="px-md-5 px-3  py-md-2 pb-3">
								<InfiniteScroll
									height={335}
									dataLength={scrollableRecords}
									next={fecthMoreActivities}
									hasMore={
										recentActivitiesData?.totalRecords >= scrollableRecords
									}
									loader={
										<div className="d-flex justify-content-center">
											<CircularProgress color="inherit" />
										</div>
									}
								>
									{/* <div className="latest-seller-header">
										Latest Reviews of Sellers
									</div> */}
									{recentActivitiesData?.reviews?.map(
										(overAllReview, index) => {
											const date = moment?.utc(overAllReview?.date);

											const formattedDate = date.format("MMMM DD, YYYY");

											const uniqueReviews = getUniqueReviews(
												overAllReview && overAllReview?.reviews
											);

											return (
												<div key={index} className="white">
													<div className="row">
														{/* <div className="col-lg-2" /> */}
														<div className="mt-3 mb-2 date-font col-lg-10 pagination-white">
															{formattedDate}
														</div>
													</div>
													{overAllReview.reviews?.length > 0 ? (
														uniqueReviews.map((element, index) => {
															const isReviewUpdated = element?.isUpdatedReviews
																? "Updated"
																: "";
															return (
																<div className="row mobile-spacing" key={index}>
																	{/* <div classNae="col-lg-2" /> */}
																	<div className="col-lg-11 mb-2">
																		<Link
																			to={`/seller-balloon/${element.sellerId}`}
																			className="text-decoration-none links d-flex"
																			onClick={() =>
																				dispatch(getSellerId(element.sellerId))
																			}
																		>
																			<div className="fw-bold">
																				{element?.sellerName}
																			</div>
																			<Link
																				to={`/seller-balloon/${element?.sellerId}`}
																				className="text-decoration-none links ps-1 text-light fw-normal"
																				onClick={() =>
																					dispatch(
																						getSellerId(element?.sellerId)
																					)
																				}
																			>
																				<span className="">
																					{element?.message
																						? isReviewUpdated
																							? `recieved a Balloon & Review (${isReviewUpdated})`
																							: `recieved a Balloon & Review`
																						: "recieved a Balloon Click"}
																				</span>
																			</Link>
																		</Link>
																		{/* <span> */}
																		<span
																			style={{
																				cursor: "pointer",
																			}}
																			onClick={() => {
																				setIsShowCategory(true);
																				setCategoryId(element.catId);
																				setShow(false);
																			}}
																		>
																			{element?.sellerType ===
																				"For U.S. Locals Only!" &&
																				"(For Locals Only!)"}
																			{element?.sellerType ===
																				"Outside the U.S." &&
																				"(Outside the U.S.)"}
																			{element?.sellerType === "B2B" && "(B2B)"}
																			{element?.categoryName + " Category"}
																		</span>
																	</div>
																</div>
															);
														})
													) : (
														<h4>Nothing to show today</h4>
													)}
												</div>
											);
										}
									)}
								</InfiniteScroll>
							</div>
						</div>
					</Modal>
				</div>
			</div>

			<ThanksModal
				reviews={tokenActivation?.review}
				isThanks={thanks}
				setIsThanks={setThanks}
			/>
			<CountryDialog
				setSelectedCountry={setSelectedCountry}
				selectedCountry={selectedCountry}
				open={isOpenCountry}
				setOpen={setIsOpenCountry}
			/>
			<AddAsellerModal
				selectedOutsideUsCountry={selectedCountry}
				setSelectedOutsideUsCountry={setSelectedCountry}
				isShow={showAddASellerModal}
				setIsShow={setShowAddASellerModal}
				isFromFooter
				setOpen={setIsOpenCountry}
			/>
			<L2aYotubeModal
				isL2aYoutubeOpen={isL2aYoutubeOpen}
				setIsL2aYoutubeOpen={setIsL2aYoutubeOpen}
			/>
			<HomeCategoryModal
				setShowAddASellerModal={setShowAddASellerModal}
				show={isShowCategory}
				handleClose={handleCloseCategory}
				categoryId={categoryId}
			/>
		</Layout>
	);
}

export default Seller;

{
	/* <div
				open={true}
				// eslint-disable-next-line prettier/prettier
				className={`customModal  rounded    ${
					closeModal ? "d-flex" : "d-none"
				}`}
			>
				<Box
					bgcolor="#f9efef"
					className="position-relative"
					boxShadow={3}
					borderRadius={8}
					p={3}
				>
					<Box className="heading notice fw-bold">Reviewers!</Box>
					<div className="cross writer-close-icons">
						<IconButton onClick={() => setCloseModal(false)} color="inherit">
							<Close />
						</IconButton>
					</div>
					<Box className="mt-4">
						<Box className="ps-md-3 ps-2 pb-3">
							🌟 Turn your everyday experiences into winning reviews right here
							on <strong> Love to Air!</strong> 🌟
						</Box>
						<ul className="modal-ul-links">
							<li>
								Read easy contest rules{" "}
								<Link
									className="text-decoration-none"
									to={`/best-writers-sellers`}
									onClick={() => {
										dispatch(
											updaterulesRef({
												isScrollDownToShirts: false,
												isScrollDownToIGPicture: false,
												isScrollDownToPoints: true,
											})
										);
									}}
								>
									here!
								</Link>
								<img
									src={`/icons/small-icons/book.png`}
									className="view-prize-left"
									width={28}
								/>
							</li>
							<li>
								View prizes{" "}
								<Link
									className="text-decoration-none"
									to={`/best-writers-sellers`}
									onClick={() => {
										dispatch(
											updaterulesRef({
												isScrollDownToPoints: false,
												isScrollDownToIGPicture: false,
												isScrollDownToShirts: true,
											})
										);
									}}
								>
									here!
								</Link>
								<img
									className="view-prize-left"
									src={`/icons/small-icons/prize.png`}
									width={28}
								/>
							</li>
							<li>
								Check out our previous winning reviews{" "}
								<Link
									className="text-decoration-none"
									to="/best-writers-sellers"
									onClick={() => {
										dispatch(
											updaterulesRef({
												isScrollDownToShirts: false,
												isScrollDownToPoints: false,
												isScrollDownToIGPicture: true,
											})
										);
									}}
								>
									here!
								</Link>
								<img
									className="view-prize-left"
									src={`/icons/small-icons/book1.png`}
									width={28}
								/>
							</li>
							<li>
								See pictures of winners{" "}
								<Link
									className="text-decoration-none"
									to="/best-writers-sellers"
									onClick={() => {
										dispatch(
											updaterulesRef({
												isScrollDownToShirts: false,
												isScrollDownToPoints: false,
												isScrollDownToIGPicture: true,
											})
										);
									}}
								>
									here!
								</Link>
								{/* <img
									className="view-prize-left"
									src={`/icons/small-icons/avatar1.png`}
									width={28}
								/> /}
								<span style={{ fontSize: "1.2rem" }}>👧🧔🏼👩🏻‍🦰🧑🏾‍🦱</span>
								{/* <img src={`/icons/small-icons/avatar2.png`} width={28} />
								<img src={`/icons/small-icons/avatar3.png`} width={28} /> /}
							</li>
						</ul>
						{/* <Box>
							See examples of winning stories{" "}
							<Link
								className="text-decoration-none"
								to="https://www.lovetoair.com/seller-balloon/040d4112-669f-4f11-aa4b-7ce6c980d705"
							>
								here!
							</Link>
						</Box>

						<Box>
							Read the easy contest rules{" "}
							<Link
								className="text-decoration-none"
								to="https://www.instagram.com/p/C1AhF7iSeE7/?utm_source=ig_web_copy_link"
							>
								here!
							</Link>
						</Box>
						<Box>
							See pics of winners{" "}
							<Link
								className="text-decoration-none"
								to="https://www.instagram.com/p/C1AhF7iSeE7/?utm_source=ig_web_copy_link"
							>
								here!
							</Link>
						</Box> /}
						{/* <Box>
							📢 Spread the Word: Help us build a community of informed
							shoppers! Share lovetoair.com with friends and family.
						</Box> /}
						{/* <Box>
							🌟 Ready to Influence, Earn, and Transform Your Shopping
							Experience? Join lovetoair.com Today!
						</Box> /}
					</Box>
				</Box>
			</div> */
}
