import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./adminReview.css";

export default function MessagePopup(props) {
	const { showPopup, setShowPopup } = props;
	const handleClose = () => {
		setShowPopup({ isOpen: false });
	};

	function BootstrapDialogTitle(props) {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	}

	return (
		<div>
			<Dialog
				open={showPopup.isOpen}
				onClose={handleClose}
				fullWidth
				maxWidth="lg"
				maxHeight="md"
				aria-labelledby="draggable-dialog-title"
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				/>
				<div className="d-flex justify-content-center">
					<div className="rounded-4 w-100 mb-5 mt-1 px-5">
						<h5 className="text-center fw-bold">
							{showPopup.sellerName && showPopup.sellerName?.toUpperCase()}
						</h5>
						<div className="text-start review-msg">
							<p
								className="inputField rounded w-100"
								value={showPopup?.msg}
								onChange={(event) => {
									setShowPopup({
										...showPopup,
										name: event.target.value,
									});
								}}
								dangerouslySetInnerHTML={{ __html: showPopup.msg }}
							/>
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
