import { CircularProgress } from "@mui/material";
import * as React from "react";
import { activeLoginUser } from "../../redux/reducers/ducks/getSellersDuck";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
	loginResponse,
	updateTokenActivation,
} from "../../redux/reducers/ducks/autorizeDuck";
import "./emailVarification.css";
import { getToasterNotifications } from "../../utils/getToasterNotification";
import { getToasterErrors } from "../../utils/getToasterErrors";

export const EmailVerification = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { tokenActivation } = useSelector((state) => {
		return {
			tokenActivation: state.authorization?.tokenActivation,
		};
	});
	const search = window.location.href;
	const url = search.split("?");
	const urlEmail = new URLSearchParams(url[1])?.get("email");
	const resetToken = new URLSearchParams(url[1])?.get("Token");
	const token = resetToken?.replaceAll(" ", "+");

	useEffect(() => {
		if (token && urlEmail) {
			const dispatchedData = {
				token: token,
				email: urlEmail,
				status: "active",
			};
			setTimeout(() => {
				dispatch(activeLoginUser(dispatchedData));
			}, 3000);
		}
	}, [token, urlEmail, dispatch]);

	useEffect(() => {
		if (
			(tokenActivation?.status === 200 ||
				tokenActivation?.status === 201 ||
				tokenActivation?.response?.status === 409 ||
				tokenActivation?.response?.status === 404 ||
				tokenActivation?.response?.status === 401 ||
				tokenActivation?.response?.status === 404 ||
				tokenActivation?.response?.status === 400) &&
			!tokenActivation?.data?.isReview
		) {
			dispatch(updateTokenActivation(null));
		}
	});

	useEffect(() => {
		if (tokenActivation?.status === 200 || tokenActivation?.status === 201) {
			getToasterNotifications(
				!tokenActivation?.data?.loginResult?.keyLogin
					? "Nice to meet you!"
					: "Nice to see you again!"
			);
			localStorage.setItem(
				"token",
				tokenActivation?.data?.loginResult?.access_token
			);
			const loginResData = {
				data: tokenActivation?.data?.loginResult,
			};
			dispatch(loginResponse(loginResData));

			navigate("/");
		} else if (
			!tokenActivation?.data?.isReview &&
			(tokenActivation?.response?.status === 404 ||
				tokenActivation?.response?.status === 401 ||
				tokenActivation?.response?.status === 404 ||
				tokenActivation?.response?.status === 400)
		) {
			getToasterErrors(tokenActivation?.response?.data?.message);
			navigate("/sign-up");
		} else if (tokenActivation?.response?.status === 409) {
			getToasterErrors(tokenActivation?.response?.data?.message);
			navigate("/loginUser");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenActivation]);

	return (
		<div className="d-flex  loaderCenter justify-content-center align-item-center">
			<CircularProgress color="inherit" />
		</div>
	);
};
