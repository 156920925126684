import { Card, Paper, TableContainer } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "../weeklyCount/weeklyCount.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { monthlyGraphRequest } from "../../../redux/reducers/ducks/adminReviewDuck";

const MonthlyCount = ({ isAverage, isGrowth }) => {
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState(new Date());

	const { monthlyGraphData } = useSelector((state) => {
		return {
			monthlyGraphData: state?.adminReview?.monthlyGraphData,
		};
	});
	const [signUpName, setSignUpName] = useState("Total Monthly SignUps");

	useEffect(() => {
		const date = moment(startDate);
		const startOfYear = moment(startDate).startOf("year");
		const lastDayMonth = date.endOf("year");
		dispatch(
			monthlyGraphRequest({
				startDate: moment(startOfYear).format("YYYY-MM-DD"),
				endDate: moment(lastDayMonth).format("YYYY-MM-DD"),
			})
		);
	}, [startDate]);

	let monthlyData = monthlyGraphData?.monthlyResult;

	let monthValues = [];

	let monthNames = [];

	for (const date in monthlyData) {
		// eslint-disable-next-line
		if (monthlyData.hasOwnProperty(date)) {
			const parsedDate = moment(date, "MM-YYYY");
			const prevMonth = parsedDate.format("MMMM");
			monthNames.push(prevMonth);
			if (monthlyData[date]) {
				if (isGrowth) {
					monthValues.push(monthlyData[date].growthRate);
				} else {
					monthValues.push(monthlyData[date].count);
				}
			}
		}
	}

	useEffect(() => {
		if (isGrowth) {
			setSignUpName(" Monthly SignUps Growth ");
		} else {
			setSignUpName("Total Monthly SignUps");
		}
	}, [isGrowth, isAverage]);

	const chartData = {
		chart: {
			height: 350,
			type: "area",
			toolbar: {
				show: true,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: true,
					selection: true,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: true,
					customIcons: [],
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 90, 100],
			},
		},
		colors: ["#21144c", "#F87161", "#009E7E"],
		legend: {
			position: "top",
		},
		series: [
			{
				name: signUpName,
				data: monthValues,
			},
		],
		xaxis: {
			categories: monthNames,
		},
	};

	return (
		<>
			<div className="WeekyCardParent">
				<div className="mb-3 signUp-names-bold  text-center">{signUpName}</div>
				<div>
					<Card className="card-content">
						<div className="parent-cat" />
						<Paper sx={{ width: "100%", overflow: "hidden" }}>
							<TableContainer>
								<div className="">
									<div className="mt-3 datePoicker-parent">
										<div className="weekly mb-3" />
										<div className="datePicker-child">
											<div>
												<ReactDatePicker
													className="datePicker-input"
													dateFormat="yyyy"
													maxDate={new Date()}
													selected={startDate}
													showYearPicker
													dropdownMode="select"
													onChange={(date) => setStartDate(date)}
												/>
											</div>
										</div>
									</div>
									<div className="p-3">
										<ReactApexChart
											options={chartData}
											series={chartData.series}
											type="area"
											height={350}
										/>
									</div>
								</div>
							</TableContainer>
						</Paper>
					</Card>
				</div>
			</div>
		</>
	);
};

export default MonthlyCount;
