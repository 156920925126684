import React from "react";
import Header from "./header/header";
import Footer from "./footer/footer";
import "./layout.css";
import { appededUrl } from "../../../utils/constant";

function Layout({ children }) {
	let isTestServer = JSON.parse(process.env.REACT_APP_IS_TEST_SERVER);
	const pathArray = isTestServer
		? window.location.pathname.split("/")[4]
		: window.location.pathname.split("/")[3];

	return (
		<div className="position-relative">
			<Header />
			<div
				className={` ${
					window.location.pathname === appededUrl("/") ||
					window.location.pathname === appededUrl("/home")
						? "child-home-height"
						: "child-height"
				} ${
					pathArray === "seller-profile" ? "profile-page-background-image" : ""
				}`}
			>
				{children}
			</div>
			<Footer />
		</div>
	);
}

export default Layout;
