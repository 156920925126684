import React from "react";
import Layout from "../../components/layout/parentLayout/Layout";
import "./viewSellerProfile.css";

function ViewSellerProfile() {
	return (
		<Layout>
			<div className="container text-center view_seller_profile">
				<h1 className="fw-bold">Coming Soon</h1>
			</div>
		</Layout>
	);
}

export default ViewSellerProfile;
